export enum AnalyticsView {
  CHART_BAR = 'chart-bar',
  CHART_AREA = 'chart-area',
  CHART_LINE = 'chart-line',
  TABLE = 'table',
}

export enum AnalyticsDataType {
  FLOWS = 'flows',
  FLEET_METRICS = 'fleetMetrics',
  INVENTORIES = 'inventories',
  CONGESTION = 'congestion',
  FREIGHT_METRICS = 'freightMetrics',
  FLEET_DEVELOPMENT = 'fleetDevelopment',
  BALLAST_CAPACITY = 'ballastCapacity',
  FLEET_UTILIZATION = 'fleetUtilization',
  REFINERY_CAPACITIES = 'refineryCapacities',
}
