import { pick } from '@kpler/generic-utils';

import { MissingEnvVarsError } from './MissingEnvVarsError';

type ProcessEnv = Record<string, string | undefined>;
type VarNames<EnvVars extends Record<string, string | undefined>> = Array<
  Extract<keyof EnvVars, string>
>;

/**
 * By implementing this class, you can build a module config service from the environment variables and make sure all required environment variables are defined.
 */
export abstract class AbstractModuleConfigService<
  EnvVars extends Record<string, string | undefined>,
  ModuleConfig extends Record<string, unknown>,
> {
  moduleConfig: ModuleConfig;

  /**
   * @example
   * ```ts
   * type EnvVars = Readonly<{
   *  NX_PUBLIC_MY_MODULE_VAR_1: string;
   * }>;
   *
   * type ModuleConfig = Readonly<{
   *   myModuleVar1: string;
   * }>;
   *
   * const varNames = ['NX_PUBLIC_MY_MODULE_VAR_1'];
   * const processEnv = process.env as ProcessEnv;
   *
   * const myModuleConfigService = new MyModuleConfigService<EnvVars, ModuleConfig>(varNames, processEnv);
   * ```
   */
  constructor(varNames: VarNames<EnvVars>, processEnv: ProcessEnv) {
    this.#assertDefinedEnvVars(varNames, processEnv);

    const envVars: EnvVars = this.#pickModuleEnvVars(varNames, processEnv);
    this.moduleConfig = this.parseConfig(envVars);
  }

  /**
   * Build the module config from the environment variables values.
   * This method must be implemented by the child class.
   * In simple cases, this can be a simple mapping of environment variables to config properties.
   */
  protected abstract parseConfig(envVars: EnvVars): ModuleConfig;

  #pickModuleEnvVars(varNames: Array<keyof ProcessEnv>, processEnv: ProcessEnv): EnvVars {
    return pick(processEnv, ...varNames) as EnvVars;
  }

  #assertDefinedEnvVars(varNames: VarNames<EnvVars>, processEnv: ProcessEnv): void {
    const missingEnvVars = varNames.filter(varName => processEnv[varName] === undefined);

    if (missingEnvVars.length > 0) {
      throw new MissingEnvVarsError(missingEnvVars);
    }
  }
}
