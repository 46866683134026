import { AppStore } from 'src/store/types';
import { useInjectStore } from 'src/store/useInjectStore';

import { CompliancePermissions } from './constants';

export const userHasAccessToComplianceBetaFactory = (store: AppStore) =>
  store.getters.userHasPermission(CompliancePermissions.BETA_ACCESS);

export const userHasAccessToComplianceReadFactory = (store: AppStore) =>
  store.getters.userHasPermission(CompliancePermissions.READ_ACCESS);

export const userHasAccessToComplianceDevFactory = (store: AppStore) =>
  store.getters.userHasPermission(CompliancePermissions.DEV_ACCESS);

export const useCompliancePermissions = () => {
  const store = useInjectStore();

  return {
    userHasAccessToComplianceBeta: userHasAccessToComplianceBetaFactory(store),
    userHasAccessToComplianceRead: userHasAccessToComplianceReadFactory(store),
    userHasAccessToComplianceDev: userHasAccessToComplianceDevFactory(store),
  };
};
