<script setup lang="ts">
import { isDefined } from '../../../utils/isDefined';

import { useInjectSetResetFilters } from './useInjectSetResetFilters';

type Props = {
  resetFiltersFn?: () => void;
};

const props = defineProps<Props>();

const setResetFilters = useInjectSetResetFilters();

if (isDefined(setResetFilters) && isDefined(props.resetFiltersFn)) {
  setResetFilters(props.resetFiltersFn);
}
</script>

<template>
  <Fragment>
    <Portal to="workspace-sidebar-title">
      <slot name="sidebarTitle" />
    </Portal>

    <Portal to="workspace-sidebar-filters">
      <slot name="sidebarFilters" />
    </Portal>

    <Portal to="workspace-header-title">
      <slot name="headerTitle" />
    </Portal>

    <Portal to="workspace-header-filters">
      <slot name="headerFilters" />
    </Portal>

    <slot name="tabContent" />
  </Fragment>
</template>
