const graph01 = '#0a315a';
const graph02 = '#22659b';
const graph03 = '#3997e3';
const graph04 = '#96dbff';
const graph05 = '#43adab';

const graph06 = '#2f6a4a';
const graph07 = '#45a03d';
const graph08 = '#a3c973';
const graph09 = '#eccf13';
const graph10 = '#f29d63';

const graphOther = '#6d7a87';
const graphMain = '#2c3c49'; // Visually matches color --black (#29333c)

const sparkGreen = '#21c08e';
const sparkBlue = '#1f77b4';
const sparkPurple = '#9467bd';
const sparkBrown = '#8c564b';
const sparkPink = '#e377c2';

export default {
  brandOrange: '#ee5b11',
  brandOrangeLight: '#f37535',
  brandGreen: '#00c068',
  brandGreenLight: '#e5fae5',
  brandBlue: '#52b6ff',
  brandRed: '#ff1e4f',
  brandOrangeFaded: '#f6c6ae',
  brandGreenFaded: '#ade7cc',

  brandBlack: '#29333c',
  brandGray850: '#38424c',
  brandGray800: '#545d66',
  brandGray700: '#9aa0a9',
  brandGray600: '#adb8c1',
  brandGray500: '#cfd7de',
  brandGray400: '#dddddd',
  brandGray200: '#f3f3f3',
  brandWhite: '#ffffff',

  movingAverage: '#ee5b11',

  graphColors: [
    graph01,
    graph02,
    graph03,
    graph04,
    graph05,
    graph06,
    graph07,
    graph08,
    graph09,
    graph10,
  ],
  shuffledGraphColors: [
    graph01,
    graph10,
    graph02,
    graph09,
    graph03,
    graph08,
    graph07,
    graph06,
    graph04,
    graph05,
  ],
  graphOther,
  graphMain,

  inventories: {
    level: '#3997e3',
    heel: '#6D7A87',
    capacity: '#DD6A1F',
  },

  spark: [sparkGreen, sparkBlue, sparkPurple, sparkBrown, sparkPink],

  sparkGreen,
  sparkBlue,

  markets: {
    liquids: '#FF3125',
    lpg: '#409CE7',
    lng: '#9638FA',
    dry: '#64BE64',
  },
  statuses: {
    loaded: '#00c068',
    ballast: '#f37535',
    open: '#ffc24e',
  },
};
