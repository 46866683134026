import { RouteRecordRaw, Market } from '@kpler/web-ui';

import { AppRoutes } from 'src/app/routes';
import { hasAccessToLNGDashboard, hasAccessToLPGDashboard } from 'src/features/dashboards/policies';
import store from 'src/store';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

const TheOverview = () =>
  import(/* webpackChunkName: "dashboards" */ 'src/main/dashboard/TheOverview.vue');
const TheDashboardContainer = () =>
  import(/* webpackChunkName: "dashboards" */ 'src/main/dashboard/TheDashboardContainer.vue');

const TheCushingDroneInventories = () =>
  import(
    /* webpackChunkName: "cushing-drone-inventories" */ 'src/main/dashboard/cushingDroneInventories/TheCushingDroneInventories.vue'
  );

const dashboardRoutes: RouteRecordRaw[] = [
  {
    name: 'overview-dashboard-lng',
    path: 'dashboard/overview-lng',
    component: TheOverview,
    meta: { title: 'LNG dashboard' },
    props: { market: Market.LNG },
    beforeEnter: (_to, _from, next) => {
      if (hasAccessToLNGDashboard(store)) {
        return next();
      }
      return next({ name: AppRoutes.FORBIDDEN });
    },
  },
  {
    name: 'overview-dashboard-lpg',
    path: 'dashboard/overview-lpg',
    component: TheOverview,
    props: { market: Market.LPG },
    meta: { title: 'LPG dashboard' },
    beforeEnter: (_to, _from, next) => {
      if (hasAccessToLPGDashboard(store)) {
        return next();
      }
      return next({ name: AppRoutes.FORBIDDEN });
    },
  },
  {
    name: 'drone-inventories',
    path: 'dashboard/drone-inventories',
    component: TheCushingDroneInventories,
    meta: { title: 'Cushing drone inventories' },
    beforeEnter: (_to, _from, next) => {
      if (store.getters.userHasOnePermission(['drone:read', 'drone:read:trial'])) {
        return next();
      }
      return next({ name: AppRoutes.FORBIDDEN });
    },
  },
  {
    name: 'dashboard',
    path: 'dashboard/:id',
    component: TheDashboardContainer,
    beforeEnter: (to, _from, next) => {
      const nextWithRefresh = forceRefreshGuard(to, next);
      if (!store.getters.userHasPermission('dashboard:read')) {
        return nextWithRefresh({ name: AppRoutes.FORBIDDEN });
      }
      return nextWithRefresh();
    },
    meta: {
      title: 'Dashboard',
    },
  },
];

export default dashboardRoutes;
