import { AppStore } from 'src/store/types';
import { useInjectStore } from 'src/store/useInjectStore';

export const EMISSIONS_VESSELS_OVERVIEW_READ = 'cargo_emission_dashboard:read';
export const EMISSIONS_ON_THE_MAP_READ = 'emissions_map:read';
export const EMISSIONS_INTERNAL_FEATURES_READ = 'feature_flag:emissions_internal';
export const EMISSIONS_BETA_FEATURE_FLAG = 'feature_flag:emissions_beta';
export const EMISSIONS_VOYAGE_CALC = 'emissions_voyage_calc';

export const userHasAccessToEmissionsOverviewFactory = (store: AppStore) => () => {
  const userHasAccessToAtLeastOneMarket = store.getters.accessibleMarketsForEmissions.size > 0;

  return (
    store.getters.userHasPermission(EMISSIONS_VESSELS_OVERVIEW_READ) &&
    userHasAccessToAtLeastOneMarket
  );
};

export const userHasAccessToEmissionsOnTheMapFactory = (store: AppStore) => () =>
  store.getters.userHasPermission(EMISSIONS_ON_THE_MAP_READ) ||
  store.getters.userHasPermission(EMISSIONS_INTERNAL_FEATURES_READ);

export const userHasAccessToInternalEmissionsFeaturesFactory = (store: AppStore) => () =>
  store.getters.userHasPermission(EMISSIONS_INTERNAL_FEATURES_READ);

export const userHasAccessToEmissionsBetaFeatureFlagFactory = (store: AppStore) => () =>
  store.getters.userHasPermission(EMISSIONS_BETA_FEATURE_FLAG);

export const userHasAccessToVoyageCalcAdvancedEmissionsFactory = (store: AppStore) => () =>
  store.getters.userHasPermission(EMISSIONS_VOYAGE_CALC);

export const useEmissionsPermissions = () => {
  const store = useInjectStore();

  return {
    userHasAccessToEmissionsOverview: userHasAccessToEmissionsOverviewFactory(store),
    userHasAccessToEmissionsOnTheMap: userHasAccessToEmissionsOnTheMapFactory(store),
    userHasAccessToInternalEmissionsFeatures:
      userHasAccessToInternalEmissionsFeaturesFactory(store),
    userHasAccessToEmissionsBetaFeatureFlag: userHasAccessToEmissionsBetaFeatureFlagFactory(store),
    userHasAccessToVoyageCalcAdvancedEmissions:
      userHasAccessToVoyageCalcAdvancedEmissionsFactory(store),
  };
};
