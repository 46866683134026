import type { Moment } from 'moment';

export class RoutingError extends Error {
  override name = 'RoutingError';
}

export class DataError extends Error {
  override name = 'DataError';
}

export class NotANumberError extends Error {
  override name = 'NotANumberError';

  constructor(value: string) {
    super(`${value} isn't a number.`);
  }
}

export class AuthorizationError extends Error {
  override name = 'AuthorizationError';
}

export class ForbiddenError extends Error {
  override name = 'ForbiddenError';
}

export class InvalidMomentDateError extends Error {
  override name = 'InvalidMomentDateError';

  constructor(public date: Moment) {
    super('This moment date is not valid');
  }
}

export class MomentParseError extends Error {
  override name = 'MomentParseError';

  constructor(
    public date: string,
    public format: string,
  ) {
    super(`Can't parse date "${date}" for format "${format}"`);
  }
}

export class NotImplementedError extends Error {
  override name = 'NotImplementedError';

  constructor(context?: string) {
    super(context ? 'Not implemented.' : `Not implemented: ${context}.`);
  }
}

export class UnexpectedValueError extends Error {
  override name = 'UnexpectedValueError';

  constructor(value: unknown) {
    super(`Unexpected value: ${value}.`);
  }
}
