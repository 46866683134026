import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { getAuthService } from 'src/app/providers/singletons/authService';
import { cacheTypePolicies as insightApolloCachePolicies } from 'src/domains/insight/apollo.config';
import introspectionResult from 'src/graphql/fragmentTypes.json';

import { AuthHeadersOptions, getAuthHeaders } from './apiAuthHeaders.service';
import { forwardError, handleError } from './apollo.errorhandling';

import { name, version } from 'src/helpers/version.helper';

export const createRetryOnUnauthenticatedLink = () =>
  new RetryLink({
    attempts: async (count, operation, error) => {
      if (error?.statusCode === 401 && count >= 3) {
        await getAuthService().logout(window.location.pathname).catch();
        return false;
      }
      return error?.statusCode === 401;
    },
  });

const httpLink = createHttpLink({
  uri: '/graphql/',
});

export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if ((networkError as ServerError)?.statusCode === 401) {
    return forwardError(operation, forward);
  }
  handleError(() => getAuthService().logout(window.location.pathname), {
    graphQLErrors,
    networkError,
  });
});

export const createAuthLink = (
  options: AuthHeadersOptions = { includeUseAccessTokenHeader: true },
) =>
  setContext(async (_, { headers }) => {
    const authHeaders = await getAuthHeaders(options);

    return {
      headers: {
        ...headers,
        ...authHeaders,
        'x-web-application-version': version,
      },
    };
  });

export const authLink = createAuthLink();

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([createRetryOnUnauthenticatedLink(), authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      ...insightApolloCachePolicies,
      InventoriesSnapshotDetailCountry: {
        keyFields: false,
      },
      InventoriesSnapshotDetailInstallationBase: {
        keyFields: false,
      },
      InventoriesSnapshotDetailInstallationWithCargoTrackingEnhancementExtraProperties: {
        keyFields: false,
      },
      InventoriesSnapshotDetailTankOil: {
        keyFields: false,
      },
      InventoriesSnapshotDetailTankProducts: {
        keyFields: false,
      },
    },
  }),
  name,
  version,
});
