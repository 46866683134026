import { Moment } from 'moment';

// eslint-disable-next-line no-restricted-imports
import { InvalidMomentDateError } from '../error';

export const assertValidMomentDate = (date: Moment) => {
  if (!date.isValid()) {
    throw new InvalidMomentDateError(date);
  }
};
