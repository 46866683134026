import { computed } from 'vue';

import { AppStore } from 'src/store/types';

enum NotificationsPermissions {
  POC_ACCESS = 'notifications:ea_access',
}

export const useNotificationsPermissions = (store: AppStore) => {
  const hasNotificationPocPermission = computed<boolean>(() =>
    store.getters.userHasPermission(NotificationsPermissions.POC_ACCESS),
  );

  return {
    hasNotificationPocPermission,
  };
};
