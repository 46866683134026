<script setup lang="ts">
import { ref, onMounted } from 'vue';

import { useInjectStore } from 'src/store/useInjectStore';

import NotificationListItem from './NotificationListItem.vue';
import { WebNotification, fetchNotifications } from './notifications.service';

const store = useInjectStore();

const notifications = ref<WebNotification[]>([]);

onMounted(async () => {
  const userId = store.getters.userId ? store.getters.userId : '';
  notifications.value = await fetchNotifications(userId);
});
</script>

<template>
  <div>
    <NotificationListItem v-for="notification in notifications" :key="notification.title">
      <template #title>{{ notification.title }}</template>
      <template #message>{{ notification.message }}</template>
    </NotificationListItem>
  </div>
</template>
