import axios from 'axios';

import { ARRAY_REPEAT_SERIALIZER } from 'src/services/axios.service';

const COMPLIANCE_BACKEND_URL = process.env?.NX_PUBLIC_COMPLIANCE_SERVER_URL
  ? process.env.NX_PUBLIC_COMPLIANCE_SERVER_URL
  : 'https://terminal.kpler.com/compliance';

export const axiosComplianceApi = axios.create({
  baseURL: COMPLIANCE_BACKEND_URL,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});
