export const handleClickOff = (
  openClickEvent: MouseEvent,
  callback: () => void,
  toggleable = true,
) => {
  const onClick = (clickEvent: MouseEvent) => {
    const isTheSameEventButCaughtLaterOnDocument = openClickEvent === clickEvent;
    const isAToggleClick = openClickEvent.target === clickEvent.target;
    const isNotAClickOff =
      isTheSameEventButCaughtLaterOnDocument || (isAToggleClick && !toggleable);

    if (isNotAClickOff) {
      return;
    }

    document.removeEventListener('click', onClick);
    callback();
  };

  document.addEventListener('click', onClick);
  return () => {
    document.removeEventListener('click', onClick);
  };
};
