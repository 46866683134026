import { RouteRecordRaw } from '@kpler/web-ui';

import { LngInstallationMetricsRouteName } from './enums';
import { usePermissions } from './hooks/usePermissions';

const Workspace = () =>
  usePermissions(true).userHasAccessToInstallationMetrics
    ? import(
        /* webpackChunkName: "lng-installation-metrics" */ './WorkspaceLngInstallationMetrics.vue'
      )
    : import(/* webpackChunkName: "lng-installation-metrics" */ './Workspace.vue');

const TheInstallationMetricsCapacity = () =>
  usePermissions(true).userHasAccessToInstallationMetrics
    ? import(
        /* webpackChunkName: "lng-installation-metrics" */ './TheInstallationMetricsCapacity.vue'
      )
    : import(
        /* webpackChunkName: "lng-installation-metrics" */ './TheInstallationMetricsCapacityPreview.vue'
      );

const TheInstallationMetricsUtilization = () =>
  usePermissions(true).userHasAccessToInstallationMetrics
    ? import(
        /* webpackChunkName: "lng-installation-metrics" */ './TheInstallationMetricsUtilization.vue'
      )
    : import(
        /* webpackChunkName: "lng-installation-metrics" */ './TheInstallationMetricsUtilizationPreview.vue'
      );

const TheInventoriesByRegion = () =>
  usePermissions(true).userHasAccessToInstallationMetrics
    ? import(/* webpackChunkName: "lng-installation-metrics" */ './TheInventoriesByRegion.vue')
    : import(
        /* webpackChunkName: "lng-installation-metrics" */ './TheInventoriesByRegionPreview.vue'
      );

const InventoriesTab = () =>
  usePermissions(true).userHasAccessToInstallationMetrics
    ? import(
        /* webpackChunkName: "lng-installation-metrics" */ './InventoriesTab/InventoriesTab.vue'
      )
    : import(
        /* webpackChunkName: "lng-installation-metrics" */ './InventoriesTab/InventoriesTabPreview.vue'
      );

const TheInventoriesRightPanel = () =>
  import(
    /* webpackChunkName: "lng-installation-metrics" */ 'src/main/analytics/inventories/TheInventoriesRightPanel.vue'
  );

const installationMetricsRoutes: RouteRecordRaw = {
  name: LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS,
  path: 'lng-installation-metrics',
  component: Workspace,
  meta: { title: 'LNG installation metrics' },
  redirect: { name: LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_CAPACITY },
  children: [
    {
      path: 'capacity',
      name: LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_CAPACITY,
      component: TheInstallationMetricsCapacity,
    },
    {
      path: 'utilization',
      name: LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_UTILIZATION,
      component: TheInstallationMetricsUtilization,
    },
    {
      path: 'inventories-by-country',
      name: LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_INVENTORIES_BY_COUNTRY,
      component: TheInventoriesByRegion,
    },
    {
      path: 'inventories',
      name: LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_INVENTORIES,
      component: InventoriesTab,
      children: [
        {
          name: LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_INVENTORIES_DETAIL,
          path: 'details/:date',
          component: TheInventoriesRightPanel,
        },
      ],
    },
  ],
};

export default installationMetricsRoutes;
