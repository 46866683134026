<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

import { Icon } from '../../../Icon';
import { Suggestion } from '../../inputs/Typeahead/SuggestionList/SuggestionList.props';
import { CascaderOption } from '../Cascader.props';

import { computeOptionPath } from './helper';
import { useEllipsis } from './useEllipsis';

const props = defineProps<{
  suggestion: Suggestion;
  options: readonly CascaderOption[];
}>();

const pathContainer = ref<HTMLElement>();

const { shouldHaveEllipsis, updateEllipsis } = useEllipsis(pathContainer);

onMounted(() => {
  updateEllipsis();
});

watch(
  () => [props.options, props.suggestion],
  () => {
    updateEllipsis();
  }
);

/**
 * The path is computed in reverse order because the text is rendered from right to left, to overflow/ellipsis on the left side.
 */
const path = computed<readonly string[]>(() =>
  [...computeOptionPath(props.suggestion.id, props.options)].reverse()
);

const isLastChunk = (chunkIndex: number) => chunkIndex === 0;
</script>

<template>
  <div class="Suggestion d-flex flex-row">
    <span v-if="shouldHaveEllipsis"> ... </span>

    <div ref="pathContainer" class="path d-flex flex-row">
      <span
        v-for="(pathSection, index) in path"
        :key="pathSection"
        class="d-flex flex-row align-center justify-center"
      >
        <Icon v-if="!isLastChunk(index)" name="angle-right" />

        <span
          class="pathChunk"
          :class="{
            lastChunk: isLastChunk(index),
          }"
        >
          {{ pathSection }}
        </span>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';
@use 'src/scss/variables';

.Suggestion {
  color: theme.$color-text-secondary;
  font-size: variables.$font-size-small;
  gap: variables.$spacing-xxs;

  .path {
    text-align: left;
    direction: rtl;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
  }

  .lastChunk {
    color: theme.$color-text-primary;
    font-size: variables.$font-size-normal;
  }
}
</style>
