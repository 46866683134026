<script setup lang="ts">
import { Icon, IconName } from '../Icon';

type DimensionsProps = {
  width: string;
  height: string;
  fontSize: string;
};

type Props = {
  url: string;
  date: Date;
  isHighlighted?: boolean;
  highlightColor?: string;
  title?: string;
  subtitle?: string;
  iconName?: IconName;
  isDisabled?: boolean;
  dimensions?: DimensionsProps;
};
const props = withDefaults(defineProps<Props>(), {
  isHighlighted: false,
  isDisabled: false,
  highlightColor: 'var(--color-brand-commodities)',
  dimensions: () => ({
    width: '88px',
    height: '120px',
    fontSize: '10px',
  }),
});

const day = props.date.getDate().toString().padStart(2, '0');
const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(props.date);
const year = props.date.getFullYear().toString();
</script>

<template>
  <a
    class="file-downloader-card"
    :class="{ highlight: isHighlighted, disable: isDisabled }"
    :href="isDisabled ? undefined : url"
    :style="dimensions"
  >
    <span class="day-month">{{ day }} {{ month }}</span>
    <span class="year">{{ year }}</span>
    <span class="download">
      <Icon class="icon" :name="iconName ?? 'download'" />
      <span class="text">Download</span>
    </span>
    <span class="title">{{ title }}</span>
    <span class="subtitle">{{ subtitle }}</span>
  </a>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';
@use 'src/scss/theme';

.file-downloader-card {
  background-color: theme.$color-bg-tertiary;
  padding: variables.$spacing-small variables.$spacing-xs variables.$spacing-xs;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 2px transparent;
  border-radius: variables.$border-radius-large;
  position: relative;
  line-height: 1.5;

  /* Cancelling link CSS attributes */
  color: theme.$color-text-primary;
  text-decoration: none;

  > * {
    max-width: 100%;
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .day-month {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .year {
    color: theme.$color-text-secondary;
    font-size: 1.2em;
  }
  .download {
    display: flex;
    align-items: center;
    height: 3.2em;
    .icon {
      font-size: 2.4em;
    }
    .text {
      display: none;
      color: v-bind('highlightColor');
      font-size: 1.2em;
      font-weight: bold;
    }
  }
  .title {
    font-size: 1.2em;
    font-weight: bold;
  }
  .subtitle {
    color: theme.$color-text-secondary;
  }

  &:not(.disable) {
    &:hover {
      background-color: theme.$color-bg-secondary;
      border-color: theme.$color-input-focus;
      .download {
        .icon {
          display: none;
        }
        .text {
          display: inherit;
        }
      }
    }
  }

  &.highlight::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    top: -5px;
    right: -5px;
    background-color: v-bind('highlightColor');
    border-radius: variables.$border-radius-full;
  }

  &.disable {
    cursor: not-allowed;
    color: theme.$color-text-placeholder;
    .year {
      color: theme.$color-text-placeholder;
    }
    .subtitle {
      color: theme.$color-text-placeholder;
    }
  }
}
</style>
