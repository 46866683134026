<script lang="ts" setup>
import type { Tab } from './types';

const DEFAULT_DISABLED_REASON = 'This tab is disabled';

defineProps<{ tabs: Tab[] }>();
</script>

<template>
  <div class="base-tabs" data-qa="panelBar">
    <nav class="base-tabs__nav" data-qa="tabNav">
      <component
        :is="tab.disabled ? 'span' : 'router-link'"
        v-for="tab in tabs"
        :key="tab.label"
        v-tooltip="{
          text: tab.disabledReason || DEFAULT_DISABLED_REASON,
          placement: 'bottom-start',
          disabled: !tab.disabled,
        }"
        :to="tab.route"
        :class="{ disabled: tab.disabled }"
        :data-testid="tab.label"
      >
        <div class="link-inner">{{ tab.label }}</div>
      </component>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/mixins/ui';
@use 'src/scss/variables';
@use 'src/scss/theme';
@use 'src/scss/theme-internal';

.base-tabs {
  border-bottom: 1px solid theme.$color-border-primary;
  padding-left: variables.$spacing;

  &__nav {
    display: flex;
    margin-bottom: -1px;
    gap: variables.$spacing-xs;

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    > * {
      text-decoration: none;
      padding-bottom: 6px;

      .link-inner {
        color: theme.$color-text-primary;
        padding: variables.$spacing-xxs variables.$spacing-small;
        border-radius: variables.$border-radius;
        font-size: variables.$font-size-almost-large;
      }

      &:hover .link-inner {
        background-color: theme-internal.$color-nav-tab-hover;
      }

      &.router-link-active {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 0;
          border-radius: 1px;
          background-color: theme-internal.$color-nav-tab-underline;
        }

        .link-inner {
          font-weight: variables.$font-medium;
        }
      }

      &.disabled {
        @include ui.disabled;
      }
    }
  }
}
</style>
