export default {
  darkGreen: '#4D924C',
  goldenDreamYellow: '#EEE82E',
  yellowOrange: '#FEBC43',
  hotCinnamon: '#DD6A1F',
  matisseBlue: '#22659B',
  paleSkyGray: '#6D7A87',
  madisonBlue: '#0A315A',
  lightBlue: '#95C9F8',
  blue: '#207DDDFF',
  whiteGradient: '#FFFFFF',
  darkBlue: '#0D4074',
  orange: '#DD6A1F',
  gray: '#6D7A87',
};
