import { AxiosErrorWithMessage } from 'src/services/axios.service';

export class AnalystEditError extends Error {
  name = 'AnalystEditError';

  constructor(err: AxiosErrorWithMessage) {
    let message = err.response?.data || 'No message';
    if (typeof message === 'object') {
      message = JSON.stringify(message);
    }
    super(message);
    this.name = err.response?.statusText || this.name;
  }
}

export class FlaskV2Error extends Error {
  constructor(err: Error) {
    const errors = JSON.parse(err.message).errors;
    let message = 'No message, please contact AT team :)';
    if (errors.length > 0) {
      message = errors.map((e: any) => e.message).join('\n');
    }
    super(message);
    this.name = err.name;
  }
}
