import { DateRange, DateRangePreset, PastFutureDateRangePreset } from '@kpler/terminal-utils';

import { DemandKind } from 'src/domains/power/Analytics/Visualization/Demand/filters';
import { Timezone } from 'src/domains/power/Forecasts/constants';
import { PowerInstallationLegend, PowerLegend } from 'src/domains/power/constants';
import {
  Fuel,
  AnalyticsSupplyMetrics,
  Region,
  ForecastModel,
  ForecastRun,
  ForecastHorizon,
} from 'src/domains/power/enums';
import { ProductionUnit, Provider } from 'src/domains/power/types';

import { LatLon } from 'types/legacy-globals';

type Bounds = {
  northEast: LatLon;
  southWest: LatLon;
};

export type MapViewOption = 'status' | 'fuel';

export type Country = {
  isoCode: string;
  name: string;
  bounds: Bounds;
};

export enum Granularity {
  HOURS = 'hours',
  DAYS = 'days',
  MONTHS = 'months',
}

export type DashboardGranularity = Exclude<Granularity, Granularity.MONTHS>;

export enum SplitBy {
  UNIT = 'unit',
}

export type DehydratedState = {
  mapView: PowerLegend;
  installationView: string;
  country?: string;
  installation?: string;
  fuels: readonly string[];
  dateRange: PastFutureDateRangePreset | DateRange; // Used for outages
  providers: readonly Provider[];
  dates: DateRangePreset | DateRange; // Used for analytics
  granularity: Granularity;
  snapshotDate?: string;
  metrics: readonly AnalyticsSupplyMetrics[];
  seasonal: boolean;
  price: boolean;
  showPercentages: boolean;
  showTemperature: boolean;
  splitByUnit: boolean;
  region?: Region;
  forecastCountries: readonly string[];
  forecastModel?: ForecastModel;
  forecastModels: ForecastModel[];
  forecastRun?: ForecastRun;
  forecastHorizon?: string;
  forecastHorizons: readonly ForecastHorizon[];
  forecastDateRange: DateRangePreset | DateRange;
  forecastGranularity: Granularity;
  forecastTimezone: Timezone;
  timezone: Timezone;
  lastRun: boolean;
  demandKind: DemandKind;
  spotDashboardGranularity: DashboardGranularity;
};

export type HydratedState = {
  mapView: PowerLegend;
  installationView: PowerInstallationLegend;
  country?: Country;
  installation?: ProductionUnit;
  fuels: readonly Fuel[];
  dateRange: PastFutureDateRangePreset | DateRange;
  providers: readonly Provider[];
  dates: DateRangePreset | DateRange;
  granularity: Granularity;
  snapshotDate?: string;
  metrics: readonly AnalyticsSupplyMetrics[];
  seasonal: boolean;
  price: boolean;
  showPercentages: boolean;
  showTemperature: boolean;
  splitByUnit: boolean;
  region?: Region;
  forecastCountries: readonly string[];
  forecastModel?: ForecastModel;
  forecastModels: ForecastModel[];
  forecastRun?: ForecastRun;
  forecastHorizon?: string;
  forecastHorizons: readonly ForecastHorizon[];
  forecastDateRange: DateRangePreset | DateRange;
  forecastGranularity: Granularity;
  forecastTimezone: Timezone;
  timezone: Timezone;
  lastRun: boolean;
  demandKind: DemandKind;
  spotDashboardGranularity: DashboardGranularity;
};
