import { AppRoutes } from 'src/app/routes';
import store from 'src/store';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

import { RouteRecordRaw } from 'types/router-migration';

const TheWorkspace = () =>
  import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/grains/TheWorkspace.vue');
const OverviewTab = () =>
  import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/grains/OverviewTab.vue');

export enum Routes {
  OVERVIEW = 'grains-supply-demand-overview',
}

const routes: RouteRecordRaw = {
  name: 'grains-supply-demand',
  path: 'grains-supply-demand',
  component: TheWorkspace,
  redirect: 'grains-supply-demand/overview',
  beforeEnter: (_to, _from, next) => {
    const nextWithRefresh = forceRefreshGuard(_to, next);
    if (!store.getters.userHasAccessToGrainsSupplyDemand) {
      return nextWithRefresh({ name: AppRoutes.FORBIDDEN });
    }
    return nextWithRefresh();
  },
  meta: { title: 'Grains Supply & Demand' },
  children: [
    {
      path: 'overview',
      name: Routes.OVERVIEW,
      component: OverviewTab,
    },
  ],
};

export default routes;
