type Mutable<T> = {
  -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? U[] : Mutable<T[P]>;
};

export const hasDefinedProperty = (object: Record<string, unknown>, key: string): boolean =>
  key in object && object[key] != null && object[key] !== '';

/**
 * Clone any raw data (object, array, date, etc.) without returning the original object.
 * Does not support functions, symbols, or other non-serializable data.
 * Return a mutable copy of the original object.
 */
export function deepCloneData<T>(source: T): Mutable<T> {
  if (Array.isArray(source)) {
    return source.map(item => deepCloneData(item)) as Mutable<T>;
  }

  if (source instanceof Date) {
    return new Date(source.getTime()) as Mutable<T>;
  }

  if (source && typeof source === 'object') {
    const entries = Object.entries(source);
    const copiedEntries = entries.map(([key, value]) => [key, deepCloneData(value)]);
    return Object.fromEntries(copiedEntries);
  }

  return source as Mutable<T>;
}

/**
 * Type-safely pick properties from an object.
 * @example
 * ```ts
 * const obj = { a: 1, b: 2, c: 3 };
 * const picked = pick(obj, 'a', 'c');
 * // picked is { a: 1, c: 3 }
 * ```
 */
export const pick = <T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> => {
  const picked: Record<string | number | symbol, unknown> = {};

  keys.forEach(key => {
    picked[key] = obj[key];
  });

  return picked as Pick<T, K>;
};
