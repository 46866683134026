<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ToastError',
  props: {
    title: {
      type: String,
      required: true,
    },
    error: {
      type: Error,
      required: true,
    },
  },
});
</script>

<template>
  <div class="toast-error">
    <strong> {{ title }}</strong>
    <p class="toast-error--message">
      {{ error.name }}:
      {{ error.message }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';

.toast-error {
  max-width: variables.$width-sidebar;
  &--message {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}
</style>
