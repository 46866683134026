<script setup lang="ts">
/**
 * This component is the Proton component to implement
 * Tags and Chips from the design system
 * @see https://zeroheight.com/974e938b9/p/944ebd-tag--chip
 */
import { computed } from 'vue';

import { Icon } from '../../Icon';

import { Props as ProtonProps } from './Proton.props';

type Props = {
  backgroundColor?: ProtonProps['backgroundColor'];
  border?: ProtonProps['border'];
  ellipsis?: ProtonProps['ellipsis'];
  rounded?: ProtonProps['rounded'];
  size?: ProtonProps['size'];
  textColor?: ProtonProps['textColor'];
  removable?: ProtonProps['removable'];
  clickable?: ProtonProps['clickable'];
};

type Emits = {
  (e: 'click'): void;
};

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  border: false,
  ellipsis: true,
  rounded: false,
  size: 'medium',
});

const computedClasses = computed(() => {
  return {
    border: props.border,
    ellipsis: props.ellipsis,
    rounded: props.rounded,
    small: props.size === 'small',
    large: props.size === 'large',
    removable: props.removable,
    clickable: props.clickable,
  };
});

const computedStyle = computed(() => {
  return {
    backgroundColor: props.backgroundColor,
    color: props.textColor,
    borderColor: props.textColor,
  };
});

const onClick = () => {
  if (!props.clickable) {
    return;
  }
  emit('click');
};
</script>

<template>
  <span class="ProtonTag" :class="computedClasses" :style="computedStyle" @click="onClick">
    <slot>Please provide a slot</slot>

    <Icon v-if="removable" name="close" />
  </span>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';

.ProtonTag {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding: 1px variables.$spacing-xs;
  line-height: variables.$line-height-dense;
  border-radius: variables.$border-radius;
  font-size: variables.$font-size-normal;
  font-weight: variables.$font-medium;

  &.clickable {
    cursor: pointer;
  }

  &.removable {
    justify-content: space-between;
    gap: variables.$spacing-xs;
    :deep(i) {
      font-size: variables.$icon-size-xxs;
    }
  }

  &.border {
    border-width: 1px;
    border-style: solid;
  }

  &.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.rounded {
    border-radius: variables.$border-radius-full;
    padding: variables.$spacing-xxs variables.$spacing-small;
  }

  &.small {
    padding: 0 variables.$spacing-xs;

    &.rounded {
      padding: 0 variables.$spacing-small;
    }
  }

  &.large {
    padding: variables.$spacing-xxs variables.$spacing-xs;
    font-size: variables.$font-size-almost-large;
  }
}
</style>
