<script setup lang="ts">
import { ref } from 'vue';

import { Icon } from '../../Icon';
import { ButtonSmall } from '../../buttons';

const props = defineProps<{ hasContent: boolean }>();
defineEmits<{ (e: 'reset'): void }>();

const isCollapsed = ref(false);

function toggleCollapse() {
  if (!props.hasContent) {
    return;
  }
  isCollapsed.value = !isCollapsed.value;
}
</script>

<template>
  <aside
    theme="light"
    class="WorkspaceSidebar"
    :class="{ 'is-collapsed': !hasContent || isCollapsed }"
    data-qa="WorkspaceSidebar"
  >
    <header :class="{ 'is-collapsed': !hasContent || isCollapsed }">
      <template v-if="!hasContent || isCollapsed">
        <div class="expand-icon" @click="toggleCollapse">
          <Icon name="funnel" />
        </div>
      </template>
      <template v-else>
        <div class="space-between">
          <div class="d-flex align-center gap-xs">
            <Icon name="funnel" />
            <h2>
              <portal-target name="workspace-sidebar-title" />
            </h2>
          </div>
          <div class="expand-icon" @click="toggleCollapse">
            <Icon name="end" />
          </div>
        </div>

        <ButtonSmall class="ml-auto reset-button" @click="$emit('reset')"> Reset all </ButtonSmall>
      </template>
    </header>

    <div :class="{ 'd-none': isCollapsed }">
      <slot />
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@use 'variables' as workspace;
@use 'src/scss/variables';
@use 'src/scss/theme';
@use 'src/scss/z-index';

$height-header: 26px;
$width-sidebar: 300px;

.WorkspaceSidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: theme.$color-bg-tertiary;
  color: theme.$color-text-primary;
  width: $width-sidebar;

  &.is-collapsed {
    width: variables.$width-sidebar-panel-collapsed;
  }
}

header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: variables.$spacing;
  line-height: $height-header;
  min-height: workspace.$workspace-header-height;
  border-bottom: 1px solid theme.$color-border-primary;
  z-index: z-index.$workspace-header;
  background-color: theme.$color-bg-tertiary;

  &.is-collapsed {
    padding: variables.$spacing 0;
  }
}

.expand-icon {
  height: $height-header;
  color: theme.$color-text-secondary;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: theme.$color-text-primary;
  }
}

.reset-button {
  color: theme.$color-text-primary;
  font-weight: variables.$font-regular;
}

.d-none {
  display: none;
}
</style>
