import { AuthorizationError, ForbiddenError } from '@kpler/generic-utils';
import { DateTimeDisplay } from '@kpler/terminal-utils';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import { ref } from 'vue';

import ResearchNewsService from 'src/common/researchNews';

import { axiosInsight } from 'src/services/axios.service';
import ToastService from 'src/services/toast.service';

type AxiosErrorResponseWithMessage = AxiosResponse & {
  message?: string;
};
export type AxiosErrorWithMessage = AxiosError & {
  response: AxiosErrorResponseWithMessage;
};

export const isLoadingStatus = ref(false);

const AUTH_INTERCEPTOR_WITH_BEARER = async (config: AxiosRequestConfig) => {
  const token = await ResearchNewsService.getToken();
  /* eslint-disable no-param-reassign */
  if (token !== undefined) {
    config.headers ||= {};
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['client-date'] = moment().format(DateTimeDisplay.EXPORT_HOURLY);
  }
  /* eslint-enable no-param-reassign */
  return config;
};

export const setupAxiosInsightInterceptors = () => {
  axiosInsight.interceptors.request.use((config: AxiosRequestConfig) => {
    isLoadingStatus.value = true;
    return AUTH_INTERCEPTOR_WITH_BEARER(config);
  });

  axiosInsight.interceptors.response.use(
    (res: AxiosResponse) => {
      isLoadingStatus.value = false;
      return res.data;
    },
    async (err: AxiosErrorWithMessage) => {
      isLoadingStatus.value = false;
      ToastService.toastError('Error downloading PDF');
      const url = err.config?.url;
      if (err.response?.status === 401) {
        if (url) {
          ResearchNewsService.deleteToken();
        }
        throw new AuthorizationError(err.response?.data.message ?? 'Unauthorized.');
      }

      if (err.response?.status === 403) {
        throw new ForbiddenError(err.response?.data.message ?? `Error on ${url}.` ?? 'No message.');
      }

      throw new Error(err.response?.data.message ?? `Error on ${url}.` ?? 'No message.');
    },
  );
};
