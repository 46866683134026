export const areCollectionsEqual = <T extends string | number>(arr1: T[], arr2: T[]) => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return set1.size === set2.size && arr1.every(x => set2.has(x));
};

export function slidingWindow<T>(inputArray: T[], options: { size: number }): T[][] {
  const { size } = options;
  return Array.from({ length: inputArray.length - (size - 1) }, (_, index) =>
    inputArray.slice(index, index + size),
  );
}

type KeySelector<T> = (item: T) => string;
export const groupByKey = <T>(
  array: Iterable<T>,
  keySelector: KeySelector<T>,
): Record<string, T[]> =>
  Array.from(array).reduce(
    (acc: Record<string, T[]>, item: T) => {
      const key = keySelector(item);
      if (key in acc) {
        // found key, push new item into existing array
        acc[key].push(item);
      } else {
        // did not find key, create new array
        // eslint-disable-next-line no-param-reassign
        acc[key] = [item];
      }
      return acc;
    },
    {}, // start with empty object
  );

export const replaceItemIfExist = <T>(array: T[], index: number, newValue: T): T[] => {
  const newArray = [...array];
  const isIndexOutOfBound = index < 0 || index >= newArray.length;
  if (newArray.length === 0 || isIndexOutOfBound) {
    return newArray;
  }
  newArray.splice(index, 1, newValue);

  return newArray;
};
export function isArray<T, U>(input: Readonly<T[] | U>): input is Readonly<T[]>;
export function isArray<T, U>(input: T[] | U): input is T[];
export function isArray<T, U>(input: T[] | U) {
  return Array.isArray(input);
}

export class NotAnArrayError extends Error {
  constructor(public givenValue: unknown) {
    super(`Expected an array but got ${typeof givenValue}`);
  }
}

export function assertIsArray<T, U>(
  maybeArray: Readonly<T[] | U>,
): asserts maybeArray is Readonly<T[]>;
export function assertIsArray<T, U>(maybeArray: T[] | U): asserts maybeArray is T[];
export function assertIsArray<T, U>(maybeArray: T[] | U) {
  if (!isArray(maybeArray)) {
    throw new NotAnArrayError(maybeArray);
  }
}

const identityMapper = <T>(i: T): T => i;

export function unique<T>(source: T[]): [];
export function unique<T>(source: readonly T[]): readonly T[];
export function unique<T, U>(source: readonly T[], mapper: (item: T) => U): readonly U[];
export function unique<T, U>(source: T[], mapper: (item: T) => U): U[];
export function unique<T>(source: T[] | readonly T[], mapper = identityMapper) {
  const isSourceReadOnly = Object.isFrozen(source);
  const uniqueMappedValues = [...new Set(source.map(mapper))];

  return isSourceReadOnly ? Object.freeze(uniqueMappedValues) : uniqueMappedValues;
}

export function uniqueArrayOfObjects<T>(source: T[], key: keyof T): T[];
export function uniqueArrayOfObjects<T>(source: readonly T[], key: keyof T): readonly T[];
export function uniqueArrayOfObjects<T>(source: T[] | readonly T[], key: keyof T) {
  const uniqueObjects = new Map();
  source.forEach(object => {
    if (!uniqueObjects.has(object[key])) {
      uniqueObjects.set(object[key], object);
    }
  });
  return Array.from(uniqueObjects.values());
}

export const definedFilter = <T>(i?: T): boolean => i != null;

export function wrapInArray<T>(source: T | T[]): T[];
export function wrapInArray<T>(source: T | readonly T[]): readonly T[];
export function wrapInArray<T>(source: T | T[] | readonly T[]) {
  const isSourceReadOnly = Object.isFrozen(source);
  const destination = isArray(source) ? source : [source].filter(definedFilter);

  return isSourceReadOnly ? Object.freeze(destination) : destination;
}
