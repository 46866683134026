<script setup lang="ts">
import { ref } from 'vue';

import { useOutsideClick } from '../../hooks';

import { Button } from './Button';
import { Menu, Option } from './Menu';

type Props = {
  text?: string;
  prefix?: string;
  disabled?: boolean;
  options: readonly Option[];
  selected?: string;
  menuPosition?: 'left' | 'right';
};

withDefaults(defineProps<Props>(), {
  disabled: false,
  menuPosition: 'right',
});

const emit = defineEmits<{ (e: 'update:selected', v: string): void }>();

const dropdownEl = ref<HTMLElement | undefined>();
const isMenuOpened = ref(false);
useOutsideClick(dropdownEl, handleOutsideClick);

function handleOutsideClick() {
  if (!isMenuOpened.value) {
    return;
  }
  toggleMenu();
}

function toggleMenu() {
  isMenuOpened.value = !isMenuOpened.value;
}

function onSelect(value: string) {
  emit('update:selected', value);
  toggleMenu();
}
</script>

<template>
  <div ref="dropdownEl" class="dropdown">
    <Button v-bind="{ text, prefix, disabled, focused: isMenuOpened }" @click="toggleMenu" />
    <Menu
      v-show="isMenuOpened"
      v-bind="{ options, active: selected }"
      class="dropdown-menu"
      :class="menuPosition"
      @update:active="onSelect"
    />
  </div>
</template>

<style scoped lang="scss">
@use 'src/scss/theme';
@use 'src/scss/variables';
@use 'src/scss/z-index';

.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-menu {
    position: absolute;
    z-index: z-index.$dropdown-menu;
    margin-top: variables.$spacing-xs;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }
  }
}
</style>
