import type { ObjectBase } from '@kpler/terminal-utils';
import { ResourceType, ZoneSearchResult } from 'types/legacy-globals';
import { ZoneType, ZoneBase, ZoneEdit } from 'types/zone';

export const WORLD_ZONE: ZoneSearchResult = {
  name: 'World',
  resourceType: ResourceType.ZONE,
  id: 0,
  type: ZoneType.WORLD_FRONT_ONLY,
  isStorageSelected: false,
  isInUnitedStates: false,
};

export const WORLD_ZONE_BASE: ZoneBase = {
  id: 0,
  name: 'World',
  resourceType: ResourceType.ZONE,
};

export const ZONE_TYPES_ABOVE_COUNTRY: readonly ZoneType[] = Object.freeze([
  ZoneType.WORLD_FRONT_ONLY,
  ZoneType.CONTINENT,
  ZoneType.SUBCONTINENT,
  ZoneType.CUSTOM,
  ZoneType.OCEAN,
  ZoneType.SEA,
  ZoneType.GULF,
]);

export const ZONE_TYPES_CREATE: ReadonlyArray<ObjectBase<ZoneType>> = Object.freeze([
  { id: ZoneType.ANCHORAGE, name: 'Anchorage' },
  { id: ZoneType.CHECKPOINT, name: 'Checkpoint' },
  { id: ZoneType.CUSTOM, name: 'Custom' },
  { id: ZoneType.PORT, name: 'Port' },
  { id: ZoneType.REGION, name: 'Region' },
  { id: ZoneType.SEA, name: 'Sea' },
  { id: ZoneType.STORAGE, name: 'Storage' },
]);

export const ZONE_TYPES_EDIT: ReadonlyArray<ObjectBase<ZoneType>> = Object.freeze([
  ...ZONE_TYPES_CREATE,
  { id: ZoneType.COUNTRY, name: 'Country' },
  { id: ZoneType.SUBREGION, name: 'Subregion' },
  { id: ZoneType.BAY, name: 'Bay' },
  { id: ZoneType.CANAL, name: 'Canal' },
  { id: ZoneType.CAPE, name: 'Cape' },
  { id: ZoneType.CONTINENT, name: 'Continent' },
  { id: ZoneType.COUNTRY_CHECKPOINT, name: 'Country checkpoint' },
  { id: ZoneType.GULF, name: 'Gulf' },
  { id: ZoneType.GULF_CHECKPOINT, name: 'Gulf checkpoint' },
  { id: ZoneType.ISLAND, name: 'Island' },
  { id: ZoneType.OCEAN, name: 'Ocean' },
  { id: ZoneType.STRAIT, name: 'Strait' },
  { id: ZoneType.SUBCONTINENT, name: 'Subcontinent' },
]);

export const SEARCHABLE_ZONE_TYPE: readonly ZoneType[] = Object.freeze(
  ZONE_TYPES_EDIT.map(z => z.id),
);

export const ZONE_TYPES_BY_CATEGORY = {
  land: [
    ZoneType.CONTINENT,
    ZoneType.COUNTRY,
    ZoneType.ISLAND,
    ZoneType.REGION,
    ZoneType.SUBCONTINENT,
    ZoneType.STORAGE,
    ZoneType.CHECKPOINT,
    ZoneType.COUNTRY_CHECKPOINT,
    ZoneType.SUBREGION,
  ],
  sea: [
    ZoneType.BAY,
    ZoneType.CANAL,
    ZoneType.CAPE,
    ZoneType.GULF,
    ZoneType.OCEAN,
    ZoneType.SEA,
    ZoneType.STRAIT,
  ],
  other: [ZoneType.CUSTOM],
};

export const ZONE_TYPES_WHERE_PARENTS_CAN_HAVE_SAME_TYPE = {
  land: [ZoneType.REGION, ZoneType.CHECKPOINT],
  sea: [ZoneType.GULF, ZoneType.SEA],
  other: [ZoneType.CUSTOM],
};

export const NEW_ZONE: ZoneEdit = Object.freeze({
  id: null,
  name: null,
  platforms: [],
  type: ZoneType.PORT,
  parentSea: null,
  parentLand: null,
  parentCustom: [],
  range: 5000,
  polygon: null,
  description: null,
  portCallMaxOpeningSpeed: null,
  portCallMinDuration: null,
  latitude: null,
  longitude: null,
  resourceType: ResourceType.STATIC_ZONE_CREATE,
  isSts: false,
  isForecastCheckpoint: false,
  unlocodes: [],
  aliases: [],
  isFloatingArea: false,
  isPort: true,
});

export const zoneTypeDisplay: { [key in ZoneType]: string } = {
  [ZoneType.ANCHORAGE]: 'anchorage',
  [ZoneType.BAY]: 'bay',
  [ZoneType.CANAL]: 'canal',
  [ZoneType.CAPE]: 'cape',
  [ZoneType.CHECKPOINT]: 'checkpoint',
  [ZoneType.CONTINENT]: 'continent',
  [ZoneType.COUNTRY]: 'country',
  [ZoneType.COUNTRY_CHECKPOINT]: 'country checkpoint',
  [ZoneType.CUSTOM]: 'custom',
  [ZoneType.DUMMY]: 'dummy',
  [ZoneType.GULF]: 'gulf',
  [ZoneType.GULF_CHECKPOINT]: 'gulf checkpoint',
  [ZoneType.ISLAND]: 'island',
  [ZoneType.METRICS]: 'metrics',
  [ZoneType.OCEAN]: 'ocean',
  [ZoneType.PENINSULA]: 'peninsula',
  [ZoneType.PORT]: 'port',
  [ZoneType.REGION]: 'region',
  [ZoneType.SEA]: 'sea',
  [ZoneType.STORAGE]: 'storage',
  [ZoneType.STRAIT]: 'strait',
  [ZoneType.SUBCONTINENT]: 'subcontinent',
  [ZoneType.SUBREGION]: 'subregion',
  [ZoneType.WAITING]: 'waiting',
  [ZoneType.WORLD_METRICS]: 'world metrics',
  [ZoneType.WORLD_FRONT_ONLY]: 'world',
};
