<script setup lang="ts">
import { ref, toRef } from 'vue';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { isDefined } from '../../utils/isDefined';

import Header from './Header.vue';

import type { DrawerPosition } from './types';
import type { IconName } from '../Icon';

type Props = {
  isOpen: boolean;
  icon?: IconName;
  title?: string;
  position?: DrawerPosition;
  autoClose?: boolean;
};

const props = withDefaults(defineProps<Props>(), { position: 'left', autoClose: true });

const emit = defineEmits<{ (e: 'close'): void }>();

const close = () => emit('close');

const elementRef = ref<HTMLElement | undefined>();
const autoClose = toRef(props, 'autoClose');
useOutsideClick(elementRef, close, autoClose);
</script>

<template>
  <div class="WithDrawer">
    <div v-if="isOpen" ref="elementRef" class="drawer" :class="position">
      <Header
        v-if="isDefined(icon) && isDefined(title)"
        :icon="icon"
        :title="title"
        @close="close"
      />

      <slot name="drawer" />
    </div>

    <div class="behind-drawer">
      <div v-if="isOpen" class="backdrop"></div>

      <slot name="behind-drawer" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';
@use 'src/scss/theme';
@use 'src/scss/z-index';

$panel-width: 390px;

.WithDrawer {
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
  height: 100%;

  .drawer {
    position: absolute;
    z-index: z-index.$drawer-panel;

    top: 0;
    bottom: 0;
    width: $panel-width;

    background-color: theme.$color-bg-primary;
    box-shadow: variables.$box-shadow;

    padding: variables.$spacing-small;
    overflow-y: auto;

    &.right {
      right: 0;
    }
  }

  .backdrop {
    position: absolute;
    z-index: z-index.$drawer-backdrop;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    backdrop-filter: blur(3px) brightness(0.85) grayscale(0.5);
  }

  .behind-drawer {
    width: 100%;
    position: relative;
    overflow-y: auto;
  }
}
</style>
