<script setup lang="ts">
import { computed } from 'vue';

import { Icon } from '../../Icon';
import { Tag } from '../../tags';
import { marketToLabelMap } from '../constants';

import { Props as MarketTagProps } from './MarketTag.props';

type Props = {
  market: MarketTagProps['market'];
  disabled?: MarketTagProps['disabled'];
  locked?: MarketTagProps['locked'];
};

const props = defineProps<Props>();

const color = computed<string>(() => {
  return `--color-commodity-${props.market}`;
});

const marketLabel = computed<string>(() => marketToLabelMap[props.market]);
</script>

<template>
  <Tag
    class="d-flex gap-xs"
    data-testid="MarketTag"
    variant="custom"
    :color="color"
    :disabled="locked || disabled"
  >
    <Icon v-if="locked" name="locked" class="text-small" />
    {{ marketLabel }}
  </Tag>
</template>
