<script setup lang="ts">
import { computed, ref } from 'vue';

import { useOutsideClick } from '../../../../hooks';
import { Tag } from '../../../tags';
import { InputWrapper } from '../InputWrapper';
import { UnstyledInput } from '../UnstyledInput';

import { SuggestionList, SuggestionItem } from './SuggestionList';
import { SelectedItem, Props as TypeaheadProps } from './Typeahead.props';

const DEFAULT_PLACEHOLDER = 'Search';

type Props = {
  search: TypeaheadProps['search'];
  selectedItems: TypeaheadProps['selectedItems'];
  suggestions: TypeaheadProps['suggestions'];

  label?: TypeaheadProps['label'];
  placeholder?: TypeaheadProps['placeholder'];
};

type Emits = {
  (e: 'update:search', id: string): void;
  (e: 'update:selectedItems', id: TypeaheadProps['selectedItems']): void;
  (e: 'inputFocus'): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const typeaheadRef = ref<HTMLElement>();

const notAlreadySelectedSuggestions = computed<TypeaheadProps['suggestions']>(() =>
  props.suggestions.filter(
    suggestion => !props.selectedItems.find(selected => suggestion.id === selected.id)
  )
);

const resetSearch = () => {
  emit('update:search', '');
};

useOutsideClick(typeaheadRef, resetSearch);

const updateSelectionAndResetSearchOnSelect = (suggestion: TypeaheadProps['suggestions'][0]) => {
  const updatedSelectedItems = [...props.selectedItems, suggestion];
  emit('update:selectedItems', updatedSelectedItems);
  resetSearch();
};

const updateSelectionOnRemove = (id: string) => {
  const updatedSelectedItems = props.selectedItems.filter(item => item.id !== id);
  emit('update:selectedItems', updatedSelectedItems);
};

const canShowPlaceholder = () => props.selectedItems.length < 1;
const computedPlaceholder = computed<string | undefined>(() =>
  canShowPlaceholder() ? props.placeholder ?? DEFAULT_PLACEHOLDER : undefined
);

const onBackspace = () => {
  if (props.search || !props.selectedItems.length) {
    return;
  }

  const { id } = props.selectedItems.at(-1) as SelectedItem;
  updateSelectionOnRemove(id);
};
</script>

<template>
  <div ref="typeaheadRef" class="Typeahead">
    <label> {{ label }} </label>

    <InputWrapper :label="label" class="wrapper">
      <template #badges>
        <Tag
          v-for="item in selectedItems"
          :key="item.id"
          variant="neutral"
          size="small"
          :removable="true"
          :clickable="true"
          @click="updateSelectionOnRemove(item.id)"
        >
          {{ item.label }}
        </Tag>
      </template>

      <template #text>
        <UnstyledInput
          :value="search"
          :placeholder="computedPlaceholder"
          @backspace="onBackspace"
          @focus="$emit('inputFocus')"
          @update="$emit('update:search', $event)"
        />
      </template>

      <template #icon>
        <slot name="icon"></slot>
      </template>
    </InputWrapper>

    <SuggestionList
      v-if="notAlreadySelectedSuggestions.length"
      :suggestions="notAlreadySelectedSuggestions"
    >
      <template #suggestion="{ suggestion }">
        <SuggestionItem
          :suggestion="suggestion"
          @click="updateSelectionAndResetSearchOnSelect(suggestion)"
        >
          <template #default>
            <slot name="suggestion" :suggestion="suggestion">
              {{ suggestion.label }}
            </slot>
          </template>
        </SuggestionItem>
      </template>
    </SuggestionList>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';
@use 'src/scss/variables';

.Typeahead {
  color: theme.$color-text-primary;
  position: relative;

  .wrapper {
    height: auto;
  }

  label {
    display: block;
    color: theme.$color-text-primary;
    font-weight: variables.$font-medium;
  }
}
</style>
