<script lang="ts" setup>
import { computed, useSlots } from 'vue';

type Props = {
  progressPercentage: number;
  color?: string;
};

const props = defineProps<Props>();

const slots = useSlots();

const hasADefaultSlot = computed(() => {
  return !!slots.default;
});

const normalizedPercentage = computed<number>(() => {
  if (props.progressPercentage < 0) {
    return 0;
  }
  if (props.progressPercentage > 100) {
    return 100;
  }
  return props.progressPercentage;
});

const computedCSS = computed(() => {
  return {
    width: `${normalizedPercentage.value}%`,
    backgroundColor: props.color ?? 'var(--color-number-variation-neutral)',
  };
});
</script>

<template>
  <span class="container">
    <span class="bar-wrapper">
      <span class="bar" :style="computedCSS"></span>
    </span>

    <span
      v-if="hasADefaultSlot"
      class="progress-marker"
      :style="{ left: `${normalizedPercentage}%` }"
    >
      <slot></slot>
    </span>
  </span>
</template>

<style scoped lang="scss">
@use 'src/scss/theme';
@use 'src/scss/variables';

.container {
  position: relative;
  display: block;

  .bar-wrapper {
    height: 6px;
    position: relative;
    overflow: hidden;
    display: block;
    background-color: var(--gray-100);
    border-radius: variables.$border-radius;

    .bar {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      transition: transform 0.2s linear;
    }
  }

  .progress-marker {
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
</style>
