import { ref } from 'vue';

const isModalVisible = ref(false);

const useUpgradeModal = () => {
  function toggleModal() {
    isModalVisible.value = !isModalVisible.value;
  }

  return { isModalVisible, toggleModal };
};

export default useUpgradeModal;
