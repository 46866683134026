import { EmptyObject } from 'types/legacy-globals';
import { PortCall } from 'types/portCall';
import { Trade, TradePayload } from 'types/trade';

const isPortCall = (portCall: PortCall | EmptyObject): portCall is PortCall =>
  Object.keys(portCall).length > 0;

export const adaptTrade = (trade: TradePayload): Trade => {
  let portCallDestination: PortCall | null = null;
  if (trade.forecastPortCallDestination && isPortCall(trade.forecastPortCallDestination)) {
    portCallDestination = trade.forecastPortCallDestination;
  } else if (trade.portCallDestination && isPortCall(trade.portCallDestination)) {
    portCallDestination = trade.portCallDestination;
  }
  return {
    ...trade,
    portCallOrigin: trade.forecastPortCallOrigin || trade.portCallOrigin,
    portCallDestination,
  };
};
