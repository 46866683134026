import { CookiesService } from 'src/services/cookies.service';

const getApiUrl = (): string => {
  const fromCookie = CookiesService.get('supplyDemandGrainsApi');
  const fromEnv = process.env.NX_PUBLIC_SUPPLY_DEMAND_GRAINS_API;

  const apiUrl = fromCookie ?? fromEnv;
  if (!apiUrl) {
    throw new Error('Missing NX_PUBLIC_SUPPLY_DEMAND_GRAINS_API');
  }
  return apiUrl;
};

export const apiUrl = getApiUrl();
