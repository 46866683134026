import type { Moment } from 'moment';

export type SerializedDateRange = { startDate: string; endDate: string };

// @TODO feature: refactor/dates
// DateRange must change to match DeserializedDateRange
export type DateRange = SerializedDateRange;

export type DeserializedDateRange = {
  startDate: Moment;
  endDate: Moment;
};

export type FormatDateFn = (date: string | Moment, dateParseFormat?: string) => string;

export enum Granularity {
  DAYS = 'days',
  WEEKS = 'weeks',
  EIAS = 'eia_weeks',
  MONTHS = 'months',
  QUARTERS = 'quarters',
  YEARS = 'years',
  MID_WEEKS = 'midweeks',
}

export enum DateRangePreset {
  ONE_MONTH = '1m',
  THREE_MONTHS = '3m',
  SIX_MONTHS = '6m',
  TWELVE_MONTHS = '12m',
  EIGHTEEN_MONTHS = '18m',
  TWENTY_FOUR_MONTHS = '24m',
  ALL_DATA = 'all',
}

export enum PastFutureDateRangePreset {
  NEXT_MONTH = 'next1m',
  LAST_MONTH = 'last1m',
  LAST_THREE_MONTHS = 'last3m',
  NEXT_THREE_MONTHS = 'next3m',
  LAST_TWELVE_MONTHS = 'last12m',
  NEXT_TWELVE_MONTHS = 'next12m',
  ALL_DATA = 'all',
}
