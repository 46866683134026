import { RouteRecordRaw } from '@kpler/web-ui';

import store from 'src/store';

import { FreightRouteName } from './enums';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

const TheFreightWorkspace = () =>
  import(/* webpackChunkName: "freight-workspace" */ './TheFreightWorkspace.vue');

const BallastCapacity = () =>
  store.getters.userHasAccessToBallastCapacityFeature
    ? import(/* webpackChunkName: "freight-workspace" */ './BallastCapacity/BallastCapacity.vue')
    : import(
        /* webpackChunkName: "freight-workspace" */ './BallastCapacity/BallastCapacityPreview.vue'
      );

const TheBallastCapacityRightPanel = () =>
  import(
    /* webpackChunkName: "freight-workspace" */ 'src/domains/freight/analytics/ballastCapacity/TheBallastCapacityRightPanel.vue'
  );

const Congestion = () =>
  store.getters.userHasAccessToCongestionFeature
    ? import(/* webpackChunkName: "freight-workspace" */ './Congestion/Congestion.vue')
    : import(/* webpackChunkName: "freight-workspace" */ './Congestion/CongestionPreview.vue');

const TheCongestionRightPanel = () =>
  import(
    /* webpackChunkName: "freight-workspace" */ 'src/domains/freight/analytics/congestion/TheCongestionRightPanel.vue'
  );

const FleetDevelopment = () =>
  store.getters.userHasAccessToFleetDevelopmentFeature
    ? import(/* webpackChunkName: "freight-workspace" */ './FleetDevelopment/FleetDevelopment.vue')
    : import(
        /* webpackChunkName: "freight-workspace" */ './FleetDevelopment/FleetDevelopmentPreview.vue'
      );

const TheFleetDevelopmentRightPanel = () =>
  import(
    /* webpackChunkName: "freight-workspace" */ 'src/domains/freight/analytics/fleetDevelopment/TheFleetDevelopmentRightPanel.vue'
  );

const FleetUtilization = () =>
  store.getters.userHasAccessToFleetUtilizationFeature
    ? import(/* webpackChunkName: "freight-workspace" */ './FleetUtilization/FleetUtilization.vue')
    : import(
        /* webpackChunkName: "freight-workspace" */ './FleetUtilization/FleetUtilizationPreview.vue'
      );

const TheFleetUtilizationRightPanel = () =>
  import(
    /* webpackChunkName: "freight-workspace" */ 'src/domains/freight/analytics/fleetUtilization/TheFleetUtilizationRightPanel.vue'
  );

const FreightMetrics = () =>
  store.getters.userHasAccessToFreightMetricsFeature
    ? import(/* webpackChunkName: "freight-workspace" */ './FreightMetrics/FreightMetrics.vue')
    : import(
        /* webpackChunkName: "freight-workspace" */ './FreightMetrics/FreightMetricsPreview.vue'
      );

const TheFreightMetricsRightPanel = () =>
  import(
    /* webpackChunkName: "freight-workspace" */ 'src/domains/freight/analytics/freightMetrics/TheFreightMetricsRightPanel.vue'
  );

export const routes: RouteRecordRaw = {
  name: FreightRouteName.FREIGHT,
  path: 'freight',
  component: TheFreightWorkspace,
  redirect: '/freight/ballast-capacity',
  meta: { title: 'freight' },
  children: [
    {
      name: FreightRouteName.FREIGHT_BALLAST_CAPACITY,
      path: 'ballast-capacity',
      component: BallastCapacity,
      meta: { title: 'Ballast Capacity' },
      children: [
        {
          name: FreightRouteName.FREIGHT_BALLAST_CAPACITY_DETAILS,
          path: 'details/:date',
          component: TheBallastCapacityRightPanel,
          beforeEnter: (to, from, next) => {
            const nextWithRefresh = forceRefreshGuard(to, next);
            if (!store.getters.userHasAccessToBallastCapacityFeature) {
              nextWithRefresh({ name: FreightRouteName.FREIGHT_BALLAST_CAPACITY });
              return;
            }
            nextWithRefresh();
          },
        },
      ],
    },
    {
      name: FreightRouteName.FREIGHT_CONGESTION,
      path: 'congestion',
      component: Congestion,
      meta: { title: 'Congestion' },
      children: [
        {
          name: FreightRouteName.FREIGHT_CONGESTION_DETAILS,
          path: 'details/:date',
          component: TheCongestionRightPanel,
          beforeEnter: (to, from, next) => {
            const nextWithRefresh = forceRefreshGuard(to, next);
            if (!store.getters.userHasAccessToCongestionFeature) {
              nextWithRefresh({ name: FreightRouteName.FREIGHT_CONGESTION });
              return;
            }
            nextWithRefresh();
          },
        },
      ],
    },
    {
      name: FreightRouteName.FREIGHT_FLEET_DEVELOPMENT,
      path: 'fleet-development',
      component: FleetDevelopment,
      meta: { title: 'Fleet development' },
      children: [
        {
          name: FreightRouteName.FREIGHT_FLEET_DEVELOPMENT_DETAILS,
          path: 'details/:date',
          component: TheFleetDevelopmentRightPanel,
          beforeEnter: (to, from, next) => {
            const nextWithRefresh = forceRefreshGuard(to, next);
            if (!store.getters.userHasAccessToFleetDevelopmentFeature) {
              nextWithRefresh({ name: FreightRouteName.FREIGHT_FLEET_DEVELOPMENT });
              return;
            }
            nextWithRefresh();
          },
        },
      ],
    },
    {
      name: FreightRouteName.FREIGHT_FLEET_UTILIZATION,
      path: 'fleet-utilization',
      component: FleetUtilization,
      meta: { title: 'Fleet utilization' },
      children: [
        {
          name: FreightRouteName.FREIGHT_FLEET_UTILIZATION_DETAILS,
          path: 'details/:date',
          component: TheFleetUtilizationRightPanel,
          beforeEnter: (to, from, next) => {
            const nextWithRefresh = forceRefreshGuard(to, next);
            if (!store.getters.userHasAccessToFleetUtilizationFeature) {
              nextWithRefresh({ name: FreightRouteName.FREIGHT_FLEET_UTILIZATION });
              return;
            }
            nextWithRefresh();
          },
        },
      ],
    },
    {
      name: FreightRouteName.FREIGHT_FREIGHT_METRICS,
      path: 'freight-metrics',
      component: FreightMetrics,
      meta: { title: 'Freight metrics' },
      children: [
        {
          name: FreightRouteName.FREIGHT_FREIGHT_METRICS_DETAILS,
          path: 'details/:date',
          component: TheFreightMetricsRightPanel,
          beforeEnter: (to, from, next) => {
            const nextWithRefresh = forceRefreshGuard(to, next);
            if (!store.getters.userHasAccessToFreightMetricsFeature) {
              nextWithRefresh({ name: FreightRouteName.FREIGHT_FREIGHT_METRICS });
              return;
            }
            nextWithRefresh();
          },
        },
      ],
    },
  ],
};
