import { FilterCallback } from 'src/types';

import { createFilterHideIfMoreThanTwoVesselsAreSelected } from 'src/main/map/helpers/installationFilters.helper';

import { InstallationTypeFilter } from 'types/graphql';
import { MapFilters, MapFiltersPayload, MapSearch } from 'types/map';
import { Zone } from 'types/zone';

type Filter = FilterCallback<Zone>;

const createFilterHideOnSearch = (mapFiltersPayload: MapFiltersPayload | null): Filter => {
  const isASearchHappening = mapFiltersPayload !== null;
  return () => !isASearchHappening;
};

const createFilterAreAdvancedFiltersEnabled =
  (areAdvancedFiltersEnabled: boolean): Filter =>
  () =>
    areAdvancedFiltersEnabled;

const createFilterIsAnchorageInstallationTypeEnabled = (
  selectedInstallationTypes: Set<InstallationTypeFilter>,
): Filter => {
  if (selectedInstallationTypes.size === 0) {
    return () => true;
  }
  return () => selectedInstallationTypes.has(InstallationTypeFilter.Anchorage);
};

export const applyAnchorageFilters = (
  allAnchorages: readonly Zone[],
  filterValues: MapFilters,
  searchValues: MapSearch,
  options: {
    areAdvancedFiltersEnabled: boolean;
    mapFiltersPayload: MapFiltersPayload | null;
  },
): readonly Zone[] => {
  if (!options.areAdvancedFiltersEnabled) {
    return allAnchorages;
  }
  const searchFilters: readonly Filter[] = [
    createFilterHideOnSearch(options.mapFiltersPayload),
    createFilterHideIfMoreThanTwoVesselsAreSelected(searchValues.vessels),
  ];

  const advancedFilters: readonly Filter[] = [
    createFilterAreAdvancedFiltersEnabled(options.areAdvancedFiltersEnabled),
    createFilterIsAnchorageInstallationTypeEnabled(filterValues.installationTypes),
  ];

  return allAnchorages.filter(anchorage =>
    [...searchFilters, ...advancedFilters].every(filter => filter(anchorage)),
  );
};
