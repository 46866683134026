export class TokenNotPresentError extends Error {
  readonly name = 'TokenNotPresentError';
  constructor() {
    super('Refresh token is not present in the Auth0 response');
  }
}

export class AuthorizeError extends Error {
  readonly name = 'AuthorizeError';
  readonly originalError: unknown;

  constructor(originalError: unknown) {
    super(
      'Auth0 denied authorization to get a refresh token. Has the authorization code expired or already used? See `originalError` for details.',
    );
    this.originalError = originalError;
    console.error({ originalError });
  }
}
