import { isDefined } from '@kpler/generic-utils';

import { BaseCheckButtonGroupOption } from 'src/components/BaseCheckboxButtonGroup.types';
import { FilterCallback } from 'src/types';

import { BetaStatus } from 'types/graphql';
import { InstallationMapPayload } from 'types/installation';
import { VesselMapPayload } from 'types/vessel';

export const createFilterOnAttribute =
  <T extends Record<string, unknown>>(
    selectedAttributeValues: Set<string>,
    attributeAccessor: (object: T) => string | undefined,
  ): FilterCallback<T> =>
  (object: T): boolean => {
    if (selectedAttributeValues.size === 0) {
      return true;
    }

    const attribute = attributeAccessor(object);
    if (!isDefined(attribute)) {
      return false;
    }

    return selectedAttributeValues.has(attribute);
  };

export const betaFilter =
  (beta: Set<BetaStatus>) =>
  (vessel: VesselMapPayload | InstallationMapPayload): boolean => {
    if (beta.size === 0) {
      return true;
    }
    const isBeta = vessel.beta;
    return (beta.has(BetaStatus.Beta) && isBeta) || (beta.has(BetaStatus.Regular) && !isBeta);
  };

export const getAvailableOptionsFromItems = <T = VesselMapPayload | InstallationMapPayload>(
  items: T[],
  getOptionValue: (currentItem: T) => string,
): readonly BaseCheckButtonGroupOption[] => {
  const optionValues: string[] = [...new Set(items.map(getOptionValue))];
  const baseSelectOptions = optionValues.sort().map(optionValue => ({
    id: optionValue,
    name: optionValue,
    className: optionValue,
  }));

  return Object.freeze<BaseCheckButtonGroupOption[]>(baseSelectOptions);
};
