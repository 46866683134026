import { areCollectionsEqual, isArray } from '@kpler/generic-utils';

import {
  BetaStatus,
  CargoState,
  InstallationTypeFilter,
  LayerState,
  Speed,
  VesselStatus,
  YesNo,
  ZoneTypeFilter,
} from 'types/graphql';
import { ResourceType, SetContent } from 'types/legacy-globals';
import {
  InstallationTypeForRoute,
  LayerStateForRoute,
  MapFiltersMapping,
  MapSearch,
  MapSearchDehydrated,
  VesselStateForRoute,
  ZoneTypeForRoute,
} from 'types/map';

const repr = (x: { id: number; resourceType: ResourceType }) => `${x.id}-${x.resourceType}`;

const doesMatch = <T extends { id: number; resourceType: ResourceType }>(
  a: readonly T[],
  b: readonly T[],
): boolean => areCollectionsEqual(a.map(repr), b.map(repr));

export const areMapSearchesEqual = (newValue: MapSearch, oldValue: MapSearch): boolean => {
  const others =
    areCollectionsEqual(
      newValue.vessels.map(x => x.id),
      oldValue.vessels.map(x => x.id),
    ) &&
    areCollectionsEqual(
      newValue.products.map(x => x.id),
      oldValue.products.map(x => x.id),
    ) &&
    areCollectionsEqual(
      newValue.players.map(x => x.id),
      oldValue.players.map(x => x.id),
    );

  if (isArray(newValue.locations) && isArray(oldValue.locations)) {
    return doesMatch(newValue.locations, oldValue.locations) && others;
  }

  if (!isArray(newValue.locations) && !isArray(oldValue.locations)) {
    return (
      doesMatch(newValue.locations.loads, oldValue.locations.loads) &&
      doesMatch(newValue.locations.discharges, oldValue.locations.discharges) &&
      others
    );
  }

  return false;
};

export const cargoStatusFromRoute = (
  statuses: Set<SetContent<MapFiltersMapping['cargoStatus']>>,
): CargoState[] => {
  const mapping: { [key in SetContent<MapFiltersMapping['cargoStatus']>]: CargoState } = {
    ballast: CargoState.Ballast,
    loaded: CargoState.Loaded,
  };
  return Array.from(statuses).map(x => mapping[x]);
};

export const vesselStatesFromRoute = (
  states: Set<SetContent<MapFiltersMapping['vesselStates']>>,
): VesselStatus[] => {
  const mapping: { [key in VesselStateForRoute]: VesselStatus } = {
    [VesselStateForRoute.OPEN]: VesselStatus.Open,
    [VesselStateForRoute.LAID_UP]: VesselStatus.LaidUp,
    [VesselStateForRoute.FLOATING_STORAGE]: VesselStatus.FloatingStorage,
    [VesselStateForRoute.UNDER_CONSTRUCTION]: VesselStatus.UnderConstruction,
    [VesselStateForRoute.INACTIVE]: VesselStatus.Inactive,
    [VesselStateForRoute.IN_SERVICE]: VesselStatus.InService,
  };
  return Array.from(states).map(x => mapping[x]);
};

export const speedFromRoute = (speed: Set<SetContent<MapFiltersMapping['speed']>>): Speed[] => {
  const mapping: { [key in SetContent<MapFiltersMapping['speed']>]: Speed } = {
    moving: Speed.Moving,
    stopped: Speed.Stopped,
  };
  return Array.from(speed).map(x => mapping[x]);
};

export const yesNoFromRoute = (yesNo: Set<'yes' | 'no'>): YesNo[] => {
  const mapping: { [key: string]: YesNo } = {
    yes: YesNo.Yes,
    no: YesNo.No,
  };
  return Array.from(yesNo).map(x => mapping[x]);
};

export const betaStatusFromRoute = (statuses: Set<'beta' | 'regular'>): BetaStatus[] => {
  const mapping: { [key: string]: BetaStatus } = {
    beta: BetaStatus.Beta,
    regular: BetaStatus.Regular,
  };
  return Array.from(statuses).map(x => mapping[x]);
};

export const installationTypesFromRoute = (
  types: Set<InstallationTypeForRoute>,
): InstallationTypeFilter[] => {
  const mapping: Record<InstallationTypeForRoute, InstallationTypeFilter> = {
    export: InstallationTypeFilter.Export,
    import: InstallationTypeFilter.Import,
    storage: InstallationTypeFilter.Storage,
    shipyard: InstallationTypeFilter.Shipyard,
    anchorage: InstallationTypeFilter.Anchorage,
    refinery: InstallationTypeFilter.Refinery,
  };
  return Array.from(types).map(x => mapping[x]);
};

export const zoneTypesFromRoute = (types: Set<ZoneTypeForRoute>): ZoneTypeFilter[] => {
  const mapping: { [key: string]: ZoneTypeFilter } = {
    sea: ZoneTypeFilter.Sea,
    country: ZoneTypeFilter.Country,
    subregion: ZoneTypeFilter.Subregion,
  };
  return Array.from(types).map(x => mapping[x]);
};

export const isMapSearchLocationEmpty = (
  locations: MapSearch['locations'] | MapSearchDehydrated['locations'],
): boolean => {
  if (typeof locations === 'object' && 'loads' in locations && 'discharges' in locations) {
    return locations.loads.length === 0 && locations.discharges.length === 0;
  }

  return locations.length === 0;
};

export const checkIfSearchingOnlyVessels = (mapSearch: MapSearch | MapSearchDehydrated) =>
  isMapSearchLocationEmpty(mapSearch.locations) &&
  mapSearch.products.length === 0 &&
  mapSearch.players.length === 0;

export const layerStateFromRoute = (layerState: LayerStateForRoute): LayerState => {
  const mapping: { [key: string]: LayerState } = {
    all: LayerState.All,
    default: LayerState.Default,
    hidden: LayerState.Hidden,
  };
  return mapping[layerState];
};

export const getMapSearchProperties = (search: MapSearch | MapSearchDehydrated) => {
  const { vessels, locations, products, players } = search;

  const oneInstallation =
    isArray(locations) &&
    locations.length === 1 &&
    locations[0].resourceType === ResourceType.INSTALLATION &&
    vessels.length === 0 &&
    products.length === 0 &&
    players.length === 0;
  const oneZone =
    isArray(locations) &&
    locations.length === 1 &&
    locations[0].resourceType === ResourceType.ZONE &&
    vessels.length === 0 &&
    products.length === 0 &&
    players.length === 0;
  const onePlayer =
    vessels.length === 0 &&
    isMapSearchLocationEmpty(locations) &&
    products.length === 0 &&
    players.length === 1;
  const oneVessel =
    vessels.length === 1 &&
    isMapSearchLocationEmpty(locations) &&
    products.length === 0 &&
    players.length === 0;
  const emptySearch =
    vessels.length === 0 &&
    isMapSearchLocationEmpty(locations) &&
    products.length === 0 &&
    players.length === 0;

  /**
   * @see https://kpler.atlassian.net/wiki/spaces/KSD/pages/243535155/UI+Map+zoom+filter+for+dynamic+search
   */
  const severalVesselsInSearch =
    vessels.length > 1 &&
    isMapSearchLocationEmpty(locations) &&
    products.length === 0 &&
    players.length === 0;

  return {
    oneInstallation,
    oneZone,
    onePlayer,
    oneVessel,
    emptySearch,
    severalVesselsInSearch,
  };
};
