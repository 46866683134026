<script setup lang="ts">
import { computed, useSlots } from 'vue';

import { ControlWrapper } from '../../../controls/ControlWrapper';

const slots = useSlots();
const hasBadges = computed<boolean>(() => !!slots.badges);
const hasText = computed<boolean>(() => !!slots.text);
const hasIcon = computed<boolean>(() => !!slots.icon);
</script>

<template>
  <ControlWrapper class="InputWrapper">
    <span class="content d-flex gap-xs flex-wrap">
      <span v-if="hasBadges" class="d-flex flex-wrap gap-xs">
        <slot name="badges"></slot>
      </span>

      <span v-if="hasText" class="text">
        <slot name="text"></slot>
      </span>
    </span>

    <span v-if="hasIcon" class="righ-icon d-flex align-center">
      <slot name="icon"></slot>
    </span>
  </ControlWrapper>
</template>

<style scoped lang="scss">
@use 'src/scss/theme';
@use 'src/scss/variables';

.InputWrapper {
  width: 100%;
  max-height: 80px;
  padding: variables.$spacing-xs;
  background-color: theme.$color-bg-primary;
  display: flex;
  gap: variables.$spacing-xs;
  border: 1px solid theme.$color-border-primary;
  border-radius: variables.$border-radius;
  line-height: variables.$line-height-dense;
  justify-content: space-between;
  position: relative;

  .content {
    max-height: 70px;

    overflow-y: auto;
    flex-grow: 1;
    flex-wrap: wrap;

    .text {
      flex-grow: 1;
    }
  }

  .righ-icon {
    align-self: end;
  }
}
</style>
