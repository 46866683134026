import { Auth0Client as Auth0SpaClient, Auth0ClientOptions } from '@auth0/auth0-spa-js';

import AuthClientInterface from './AuthClient.interface';
import { AuthConfig } from './config';

type AppState = {
  origin: string;
};

export default class AuthClient implements AuthClientInterface {
  readonly #auth0Client: Auth0SpaClient;
  readonly #webAppHost: string;

  constructor(authConfig: AuthConfig) {
    this.#webAppHost = window.location.host;
    const authOptions = this.#createAuth0Settings(authConfig);
    this.#auth0Client = new Auth0SpaClient(authOptions);
  }

  async signInWithRedirect(origin: string): Promise<void> {
    const appState: AppState = { origin };
    return this.#auth0Client.loginWithRedirect({ appState });
  }

  async handleSignInRedirect() {
    const result = await this.#auth0Client.handleRedirectCallback<AppState>();
    return { origin: result.appState?.origin ?? '/' };
  }

  async getToken() {
    return this.#auth0Client.getTokenSilently();
  }

  async isAuthenticated() {
    return this.#auth0Client.isAuthenticated();
  }

  async logout(origin: string): Promise<void> {
    return this.#auth0Client.logout({
      logoutParams: {
        returnTo: `https://${this.#webAppHost}/oauth/logout?origin=${origin}`,
      },
    });
  }

  async revokeRefreshToken() {
    /**
     * Hacky solution, but it is the best we can have now, and it is recommended by the auth0 team
     * The idea is to force the sdk to request a new refresh token which will invalidate the current one, and we don't store the new refresh token returned
     * https://github.com/auth0/auth0-spa-js/issues/1013
     */
    try {
      await this.#auth0Client.getTokenSilently({ cacheMode: 'off' });
    } catch {
      /* empty */
    }
  }

  #createAuth0Settings(authConfig: AuthConfig): Auth0ClientOptions {
    const { clientId, audienceHost, authorityHost } = authConfig.auth;
    return {
      domain: `https://${authorityHost}`,
      clientId,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      leeway: 120,
      useRefreshTokensFallback: true,
      authorizationParams: {
        redirect_uri: `https://${this.#webAppHost}/oauth/callback`,
        audience: `https://${audienceHost}`,
        scope: 'openid profile email',
      },
    };
  }
}
