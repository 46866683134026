<script setup lang="ts">
import { Icon } from '../Icon';

type Props = {
  text: string;
  wrap?: boolean;
  removeable?: boolean;
};

withDefaults(defineProps<Props>(), {
  wrap: false,
  removeable: false,
});

const emit = defineEmits<{ (e: 'remove'): void }>();

const remove = () => {
  emit('remove');
};
</script>

<template>
  <div class="base-chip" :class="{ wrap, removeable }" @click="remove">
    <span>
      <slot> {{ text }} </slot>
    </span>
    <Icon v-if="removeable" name="close" />
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';
@use 'src/scss/theme';

.base-chip {
  color: theme.$color-text-inverted;
  font-weight: variables.$font-medium;
  background-color: theme.$color-bg-inverted;
  padding: 0 8px;
  border-radius: variables.$border-radius;
  font-size: 11px;
  &.wrap {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.removeable {
    padding-right: variables.$spacing;
    position: relative;
    .icon-close {
      position: absolute;
      right: variables.$spacing-xs;
      top: 25%;
    }
  }
  .icon-close {
    color: theme.$color-text-secondary;
    font-size: variables.$icon-size-xxs;
    cursor: pointer;
  }
}
</style>
