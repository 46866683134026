import { RouteLocationNormalized, RouteRecordRaw } from '@kpler/web-ui';

import { AppRoutes } from 'src/app/routes';
import store from 'src/store';

import { ContractType } from 'types/contract';
import { Next, ResourceType } from 'types/legacy-globals';

const TheMapContainer = () =>
  store.getters.userHasPermission('display_map')
    ? import(/* webpackChunkName: "map" */ 'src/main/map/TheMapContainer.vue')
    : import(/* webpackChunkName: "map" */ 'src/main/map/TheMapContainerPreview.vue');

const TheDynamicPanel = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/TheDynamicPanel.vue');

const Voyages = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/voyages/Voyages.vue');

const PortCalls = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/portCalls/PortCalls.vue');

const Trades = () => import(/* webpackChunkName: "map" */ 'src/main/map/panels/trades/Trades.vue');

const Charters = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/charters/Charters.vue');

const Compliance = () =>
  import(/* webpackChunkName: "map" */ 'src/domains/compliance/panel/ComplianceContainer.vue');

const ContractsTabs = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/contracts/ContractsTabs.vue');

const Contracts = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/contracts/Contracts.vue');

const OfflineEvents = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/offlineEvents/OfflineEvents.vue');

// VESSELS

const TheVesselPanelContainer = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/vessel/TheVesselPanelContainer.vue');

const VesselRawSignals = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/vessel/VesselRawSignals.vue');

const VesselInfo = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/vessel/VesselInfo.vue');

const VesselEdit = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/vessel/vesselEdit/VesselEditContainer.vue'
  );

const VesselStaticPanel = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/vessel/vesselEdit/VesselStaticPanel.vue'
  );

// INSTALLATIONS

const TheInstallationPanelContainer = () =>
  import(
    /* webpackChunkName: "map" */ 'src/main/map/panels/installation/TheInstallationPanelContainer.vue'
  );

const InstallationInfo = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/installation/InstallationInfo.vue');

const InstallationTanks = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/installation/InstallationTanks.vue');

const InstallationEdit = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/installation/InstallationEditContainer.vue'
  );

const InstallationStaticPanel = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/installation/InstallationStaticPanel.vue'
  );

// ZONES

const TheZonePanelContainer = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/zone/TheZonePanelContainer.vue');

const ZoneInstallations = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/zone/ZoneInstallations.vue');

const ZoneTerminals = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/zone/ZoneTerminals.vue');

const ZoneShareholders = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/zone/ZoneShareholders.vue');

const ZoneCountries = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/zone/ZoneCountries.vue');

const ZoneInventories = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/zone/ZoneInventories.vue');

const ZoneEdit = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/zone/zoneEdit/ZoneEditContainer.vue'
  );

const ZoneStaticPanel = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/zone/zoneEdit/ZoneStaticPanel.vue'
  );

// PLAYERS

const ThePlayerPanelContainer = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/player/ThePlayerPanelContainer.vue');

const PlayerActivity = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/player/PlayerActivity.vue');

const PlayerFleet = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/player/PlayerFleet.vue');

const PlayerInfo = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/player/PlayerInfo.vue');

const PlayerEdit = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/player/playerEdit/PlayerEditContainer.vue'
  );

const PlayerStaticPanel = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/player/playerEdit/PlayerStaticPanel.vue'
  );

// PRODUCTS

const TheProductPanelContainer = () =>
  import(/* webpackChunkName: "map" */ 'src/main/map/panels/product/TheProductPanelContainer.vue');

const ProductStaticPanel = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/product/ProductStaticPanel.vue'
  );

// BERTHS

const BerthStaticPanel = () =>
  import(/* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/berth/BerthStaticPanel.vue');

// TANKS

const TankStaticPanel = () =>
  import(/* webpackChunkName: "kplerEditTools" */ 'src/main/map/panels/tank/TankStaticPanel.vue');

const StaticDataEdit = () =>
  import(/* webpackChunkName: "kplerEditTools" */ 'src/main/map/TheStaticDataEdit.vue');

// PROVIDERS

const ProviderStaticPanel = () =>
  import(
    /* webpackChunkName: "kplerEditTools" */ 'src/main/edits/provider/ProviderEditContainer.vue'
  );

// CALCULATOR

const oilInventoriesGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: Next,
) => {
  if (!store.getters.userIsEligibleForOilInventoriesFeature) {
    return next({ name: AppRoutes.FORBIDDEN });
  }
  return next();
};

const children: RouteRecordRaw[] = [
  {
    path: 'search',
    component: TheDynamicPanel,
    name: 'dynamic',
    children: [
      // COMMON
      {
        path: 'voyages',
        name: 'dynamic-voyages',
        component: Voyages,
        props: { context: 'dynamic' },
      },
      {
        path: 'trades',
        name: 'dynamic-trades',
        component: Trades,
        props: { context: 'dynamic' },
      },
      {
        path: 'contracts',
        name: 'dynamic-contracts',
        component: ContractsTabs,
        redirect: 'contracts/tenders',
        props: { context: 'dynamic' },
        children: [
          {
            path: 'tenders',
            name: 'dynamic-tenders',
            component: Contracts,
            props: { context: 'dynamic', type: ContractType.TENDER },
          },
          {
            path: 'spas',
            name: 'dynamic-spas',
            component: Contracts,
            props: { context: 'dynamic', type: ContractType.SPA },
          },
          {
            path: 'tuas',
            name: 'dynamic-tuas',
            component: Contracts,
            props: { context: 'dynamic', type: ContractType.TUA },
          },
          {
            path: 'ltas',
            name: 'dynamic-ltas',
            component: Contracts,
            props: { context: 'dynamic', type: ContractType.LTA },
          },
        ],
      },
      {
        path: 'port-calls',
        name: 'dynamic-port-calls',
        component: PortCalls,
        props: { context: 'dynamic' },
      },
      {
        path: 'charters',
        name: 'dynamic-charters',
        component: Charters,
        props: { context: 'dynamic' },
      },
      {
        path: 'compliance',
        name: 'dynamic-compliance',
        component: Compliance,
        props: { context: 'dynamic' },
      },
      {
        path: 'offline-events',
        name: 'dynamic-offline-events',
        component: OfflineEvents,
        props: { context: 'dynamic' },
      },
      // VESSEL
      {
        path: 'vessel-info',
        name: 'dynamic-vessel-info',
        component: VesselInfo,
      },
      {
        path: 'raw-signals',
        name: 'dynamic-raw-signals',
        component: VesselRawSignals,
        props: { dynamic: true },
      },
      {
        path: 'vessel-edit',
        name: 'dynamic-vessel-edit',
        component: VesselEdit,
      },
      // INSTALLATION
      {
        path: 'installation-info',
        name: 'dynamic-installation-info',
        component: InstallationInfo,
      },
      {
        path: 'installation-edit',
        name: 'dynamic-installation-edit',
        component: InstallationEdit,
      },
      // Inventories
      {
        path: 'tanks',
        name: 'dynamic-tanks',
        component: InstallationTanks,
        beforeEnter: oilInventoriesGuard,
        props: { dynamic: true },
      },
      {
        path: 'inventories',
        name: 'dynamic-inventories',
        component: ZoneInventories,
        beforeEnter: oilInventoriesGuard,
        props: { dynamic: true },
      },
      // ZONE
      {
        path: 'installations',
        name: 'dynamic-installations',
        component: ZoneInstallations,
        redirect: 'installations/countries',
        children: [
          {
            path: 'countries',
            name: 'dynamic-countries',
            component: ZoneCountries,
          },
          {
            path: 'terminals',
            name: 'dynamic-terminals',
            component: ZoneTerminals,
          },
          {
            path: 'shareholders',
            name: 'dynamic-shareholders',
            component: ZoneShareholders,
          },
        ],
      },
      {
        path: 'zone-edit',
        name: 'dynamic-zone-edit',
        component: ZoneEdit,
      },
      // PLAYERS
      {
        path: 'activity',
        name: 'dynamic-activity',
        component: PlayerActivity,
        redirect: 'activity/controlled',
        children: [
          {
            path: 'controlled',
            name: 'dynamic-controlled-fleet',
            component: PlayerFleet,
            props: { owned: false },
          },
          {
            path: 'owned',
            name: 'dynamic-owned-fleet',
            component: PlayerFleet,
            props: { owned: true },
          },
        ],
      },
      {
        path: 'info',
        name: 'dynamic-player-info',
        component: PlayerInfo,
      },
      {
        path: 'edit',
        name: 'dynamic-player-edit',
        component: PlayerEdit,
      },
    ],
  },
  {
    path: 'vessel/:id',
    component: TheVesselPanelContainer,
    name: 'vessel',
    children: [
      {
        path: 'voyages',
        name: 'vessel-voyages',
        component: Voyages,
        props: { context: ResourceType.VESSEL },
      },
      {
        path: 'port-calls',
        name: 'vessel-port-calls',
        component: PortCalls,
        props: () => ({ context: ResourceType.VESSEL }),
      },
      {
        path: 'trades',
        name: 'vessel-trades',
        component: Trades,
        props: { context: ResourceType.VESSEL },
      },
      {
        path: 'raw-signals',
        name: 'vessel-raw-signals',
        component: VesselRawSignals,
      },
      {
        path: 'charters',
        name: 'vessel-charters',
        component: Charters,
        props: { context: ResourceType.VESSEL },
      },
      {
        path: 'compliance',
        name: 'vessel-compliance',
        component: Compliance,
        props: { context: ResourceType.VESSEL },
      },
      {
        path: 'info',
        name: 'vessel-info',
        component: VesselInfo,
      },
      {
        path: 'edit',
        name: 'vessel-edit',
        component: VesselEdit,
      },
    ],
  },
  {
    path: 'installation/:id',
    component: TheInstallationPanelContainer,
    name: 'installation',
    children: [
      {
        path: 'voyages',
        name: 'installation-voyages',
        component: Voyages,
        props: { context: ResourceType.INSTALLATION },
      },
      {
        path: 'port-calls',
        name: 'installation-port-calls',
        component: PortCalls,
        props: { context: ResourceType.INSTALLATION },
      },
      {
        path: 'trades',
        name: 'installation-trades',
        component: Trades,
        props: { context: ResourceType.INSTALLATION },
      },
      {
        path: 'offline-events',
        name: 'installation-offline-events',
        component: OfflineEvents,
        props: { context: ResourceType.INSTALLATION },
      },
      {
        path: 'tanks',
        name: 'installation-tanks',
        component: InstallationTanks,
        props: { context: ResourceType.INSTALLATION },
      },
      {
        path: 'contracts',
        name: 'installation-contracts',
        component: ContractsTabs,
        redirect: 'contracts/tenders',
        props: { context: ResourceType.INSTALLATION },
        children: [
          {
            path: 'tenders',
            name: 'installation-tenders',
            component: Contracts,
            props: { context: ResourceType.INSTALLATION, type: ContractType.TENDER },
          },
          {
            path: 'spas',
            name: 'installation-spas',
            component: Contracts,
            props: { context: ResourceType.INSTALLATION, type: ContractType.SPA },
          },
          {
            path: 'tuas',
            name: 'installation-tuas',
            component: Contracts,
            props: { context: ResourceType.INSTALLATION, type: ContractType.TUA },
          },
          {
            path: 'ltas',
            name: 'installation-ltas',
            component: Contracts,
            props: { context: ResourceType.INSTALLATION, type: ContractType.LTA },
          },
        ],
      },
      {
        path: 'info',
        name: 'installation-info',
        component: InstallationInfo,
      },
      {
        path: 'edit',
        name: 'installation-edit',
        component: InstallationEdit,
      },
    ],
  },
  {
    path: 'zone/:id',
    component: TheZonePanelContainer,
    name: 'zone',
    children: [
      {
        path: 'voyages',
        name: 'zone-voyages',
        component: Voyages,
        props: { context: ResourceType.ZONE },
      },
      {
        path: 'port-calls',
        name: 'zone-port-calls',
        component: PortCalls,
        props: { context: ResourceType.ZONE },
      },
      {
        path: 'trades',
        name: 'zone-trades',
        component: Trades,
        props: { context: ResourceType.ZONE },
      },
      {
        path: 'offline-events',
        name: 'zone-offline-events',
        component: OfflineEvents,
        props: { context: ResourceType.ZONE },
      },
      {
        path: 'contracts',
        name: 'zone-contracts',
        component: ContractsTabs,
        props: { context: ResourceType.ZONE },
        redirect: 'contracts/tenders',
        children: [
          {
            path: 'tenders',
            name: 'zone-tenders',
            component: Contracts,
            props: { context: ResourceType.ZONE, type: ContractType.TENDER },
          },
          {
            path: 'spas',
            name: 'zone-spas',
            component: Contracts,
            props: { context: ResourceType.ZONE, type: ContractType.SPA },
          },
          {
            path: 'tuas',
            name: 'zone-tuas',
            component: Contracts,
            props: { context: ResourceType.ZONE, type: ContractType.TUA },
          },
          {
            path: 'ltas',
            name: 'zone-ltas',
            component: Contracts,
            props: { context: ResourceType.ZONE, type: ContractType.LTA },
          },
        ],
      },
      {
        path: 'inventories',
        name: 'zone-inventories',
        component: ZoneInventories,
        beforeEnter: oilInventoriesGuard,
        props: { context: ResourceType.ZONE },
      },
      {
        path: 'installations',
        name: 'zone-installations',
        component: ZoneInstallations,
        redirect: 'installations/countries',
        children: [
          {
            path: 'countries',
            name: 'zone-countries',
            component: ZoneCountries,
          },
          {
            path: 'terminals',
            name: 'zone-terminals',
            component: ZoneTerminals,
          },
          {
            path: 'shareholders',
            name: 'zone-shareholders',
            component: ZoneShareholders,
          },
        ],
      },
      {
        path: 'edit',
        name: 'zone-edit',
        component: ZoneEdit,
      },
    ],
  },
  {
    path: 'player/:id',
    component: ThePlayerPanelContainer,
    name: 'player',
    redirect: 'player/:id/activity',
    children: [
      {
        path: 'activity',
        name: 'player-activity',
        component: PlayerActivity,
        redirect: 'activity/controlled',
        children: [
          {
            path: 'controlled',
            name: 'player-controlled-fleet',
            component: PlayerFleet,
            props: { owned: false },
          },
          {
            path: 'owned',
            name: 'player-owned-fleet',
            component: PlayerFleet,
            props: { owned: true },
          },
        ],
      },
      {
        path: 'trades',
        name: 'player-trades',
        component: Trades,
        props: { context: ResourceType.PLAYER },
      },
      {
        path: 'contracts',
        name: 'player-contracts',
        component: ContractsTabs,
        props: { context: ResourceType.PLAYER },
        redirect: 'contracts/tenders',
        children: [
          {
            path: 'tenders',
            name: 'player-tenders',
            component: Contracts,
            props: { context: ResourceType.PLAYER, type: ContractType.TENDER },
          },
          {
            path: 'spas',
            name: 'player-spas',
            component: Contracts,
            props: { context: ResourceType.PLAYER, type: ContractType.SPA },
          },
          {
            path: 'tuas',
            name: 'player-tuas',
            component: Contracts,
            props: { context: ResourceType.PLAYER, type: ContractType.TUA },
          },
          {
            path: 'ltas',
            name: 'player-ltas',
            component: Contracts,
            props: { context: ResourceType.PLAYER, type: ContractType.LTA },
          },
        ],
      },
      {
        path: 'charters',
        name: 'player-charters',
        component: Charters,
        props: { context: ResourceType.PLAYER },
      },
      {
        path: 'info',
        name: 'player-info',
        component: PlayerInfo,
      },
      {
        path: 'edit',
        name: 'player-edit',
        component: PlayerEdit,
      },
    ],
  },
  {
    path: 'product/:id',
    component: TheProductPanelContainer,
    name: 'product',
    children: [
      {
        path: 'voyages',
        name: 'product-voyages',
        component: Voyages,
        props: { context: ResourceType.PRODUCT },
      },
      {
        path: 'port-calls',
        name: 'product-port-calls',
        component: PortCalls,
        props: { context: ResourceType.PRODUCT },
      },
      {
        path: 'trades',
        name: 'product-trades',
        component: Trades,
        props: { context: ResourceType.PRODUCT },
      },
    ],
  },
  {
    path: 'static/zone/:id/edit',
    component: ZoneStaticPanel,
    name: 'static-zone-edit',
  },
  {
    path: 'static/zone/create',
    component: ZoneStaticPanel,
    name: 'static-zone-create',
  },
  {
    path: 'static/installation/:id/edit',
    component: InstallationStaticPanel,
    name: 'static-installation-edit',
  },
  {
    path: 'static/installation/create',
    component: InstallationStaticPanel,
    name: 'static-installation-create',
  },
  {
    path: 'static/berth/:id/edit',
    component: BerthStaticPanel,
    name: 'static-berth-edit',
  },
  {
    path: 'static/berth/create',
    component: BerthStaticPanel,
    name: 'static-berth-create',
  },
  {
    path: 'static/player/:id/edit',
    component: PlayerStaticPanel,
    name: 'static-player-edit',
  },
  {
    path: 'static/player/create',
    component: PlayerStaticPanel,
    name: 'static-player-create',
  },
  {
    path: 'static/tank/:id/edit',
    component: TankStaticPanel,
    name: 'static-tank-edit',
  },
  {
    path: 'static/tank/create',
    component: TankStaticPanel,
    name: 'static-tank-create',
  },
  {
    path: 'static/edit',
    component: StaticDataEdit,
    name: 'static-edit',
  },
  {
    path: 'static/provider/create',
    component: ProviderStaticPanel,
    name: 'static-provider-create',
  },
  {
    path: 'static/provider/:id/edit',
    component: ProviderStaticPanel,
    name: 'static-provider-edit',
  },
  {
    path: 'static/vessel/:id/edit',
    component: VesselStaticPanel,
    name: 'static-vessel-edit',
  },
  {
    path: 'static/product/create',
    component: ProductStaticPanel,
    name: 'static-product-create',
  },
  {
    path: 'static/product/:id/edit',
    component: ProductStaticPanel,
    name: 'static-product-edit',
  },
];
const mapRoutes: RouteRecordRaw = {
  path: 'map',
  name: 'map',
  component: TheMapContainer,
  children,
  redirect: 'map/search',
  meta: {
    title: 'Map',
  },
};

export default mapRoutes;
