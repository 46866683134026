import { FleetMetricsState } from 'src/domains/cargo/fleetMetrics/fleetMetrics.types';
import { DashboardDataHistoryInMonths } from 'src/main/dashboard/types';
import { FlowsState } from 'src/types/flows';

import { RefineryCapacitiesMapping } from './refineries';

import { BallastCapacityState } from 'types/ballastCapacity';
import { CongestionState } from 'types/congestion';
import { FleetDevelopmentState } from 'types/fleetDevelopment';
import { FleetUtilizationState } from 'types/fleetUtilization';
import { FreightMetricsState } from 'types/freightMetrics';
import { PricesContractFilter, DashboardQuery, InventoriesMarket } from 'types/graphql';
import { InventoriesState } from 'types/inventories';
import { PricesMapping, PricesView } from 'types/prices';

// @TODO /!\ charts currently have to be same as ChartType
export enum WidgetView {
  CHART_LINE = 'line',
  CHART_BAR = 'bar',
  CHART_AREA = 'area',
  TABLE = 'table',
}

export enum AnalyticsWidgetType {
  FLOWS = 'flows',
  FLEET_METRICS = 'fleetMetrics',
  INVENTORIES = 'inventories',
  CONGESTION = 'congestion',
  FLEET_UTILIZATION = 'fleetUtilization',
  FLEET_DEVELOPMENT = 'fleetDevelopment',
  FREIGHT_METRICS = 'freightMetrics',
  BALLAST_CAPACITY = 'ballastCapacity',
  PRICES = 'prices',
  REFINERIES_CAPACITIES = 'refineriesCapacities',
}

export type WidgetBase = {
  title: string;
  id: string;
};

type IsEia = {
  isEia: boolean;
};

type Typename = {
  readonly __typename?: string;
};

type FlowsWidgetMapping = Omit<
  FlowsState,
  'granularity' | 'truncateY' | 'movingAverage' | 'dates'
> &
  IsEia &
  Typename;
type FleetMetricsWidgetMapping = Omit<
  FleetMetricsState,
  'granularity' | 'truncateY' | 'movingAverage' | 'dates'
> &
  IsEia &
  Typename;
export type InventoriesWidgetMapping = Omit<
  InventoriesState,
  'granularity' | 'truncateY' | 'movingAverage' | 'dates'
> &
  IsEia &
  Typename;
export type CongestionWidgetMapping = Omit<
  CongestionState,
  'granularity' | 'truncateY' | 'movingAverage' | 'dates'
> &
  Typename;
export type FleetUtilizationWidgetMapping = Omit<
  FleetUtilizationState,
  'granularity' | 'truncateY' | 'movingAverage' | 'dates'
> &
  Typename;
export type FleetDevelopmentWidgetMapping = Omit<
  FleetDevelopmentState,
  'granularity' | 'truncateY' | 'movingAverage' | 'dates'
> &
  Typename;
export type FreightMetricsWidgetMapping = Omit<
  FreightMetricsState,
  'granularity' | 'truncateY' | 'movingAverage' | 'dates'
> &
  Typename;
export type BallastCapacityWidgetMapping = Omit<
  BallastCapacityState,
  'granularity' | 'truncateY' | 'movingAverage' | 'dates'
> &
  Typename;
export type RefineriesCapacitiesWidgetMapping = Omit<
  RefineryCapacitiesMapping,
  'granularity' | 'truncateY' | 'dateRange'
> &
  Typename;
export type PricesWidgetMapping = Omit<PricesMapping, 'granularity' | 'dateRange' | 'contracts'> & {
  pricesContractFilters: PricesContractFilter[];
  isForwardView?: boolean;
} & Typename;

export type KpWidgetFlows = WidgetBase & {
  type: AnalyticsWidgetType.FLOWS;
  config: Omit<FlowsWidgetMapping, 'view'> & {
    view: WidgetView.CHART_LINE | WidgetView.CHART_BAR | WidgetView.TABLE;
  };
  canBeEia: boolean;
};
export type KpWidgetFleetMetrics = WidgetBase & {
  type: AnalyticsWidgetType.FLEET_METRICS;
  config: Omit<FleetMetricsWidgetMapping, 'view'> & {
    view: WidgetView.CHART_LINE | WidgetView.CHART_BAR | WidgetView.TABLE;
  };
  canBeEia: boolean;
};
export type KpWidgetInventoriesConfig = Omit<InventoriesWidgetMapping, 'view'> & {
  view: WidgetView.CHART_LINE | WidgetView.CHART_BAR | WidgetView.CHART_AREA | WidgetView.TABLE;
  columns?: string[];
  market?: InventoriesMarket;
};
export type KpWidgetInventories = WidgetBase & {
  type: AnalyticsWidgetType.INVENTORIES;
  config: KpWidgetInventoriesConfig;
  canBeEia: boolean;
};
export type KpWidgetCongestion = WidgetBase & {
  type: AnalyticsWidgetType.CONGESTION;
  config: Omit<CongestionWidgetMapping, 'view'> & {
    view: WidgetView.CHART_LINE | WidgetView.CHART_BAR | WidgetView.CHART_AREA | WidgetView.TABLE;
  };
};

export type KpWidgetFleetUtilization = WidgetBase & {
  type: AnalyticsWidgetType.FLEET_UTILIZATION;
  config: Omit<FleetUtilizationWidgetMapping, 'view'> & {
    view: WidgetView.CHART_LINE | WidgetView.CHART_BAR | WidgetView.CHART_AREA | WidgetView.TABLE;
  };
};

export type KpWidgetFleetDevelopment = WidgetBase & {
  type: AnalyticsWidgetType.FLEET_DEVELOPMENT;
  config: Omit<FleetDevelopmentWidgetMapping, 'view'> & {
    view: WidgetView.CHART_LINE | WidgetView.CHART_BAR | WidgetView.CHART_AREA | WidgetView.TABLE;
  };
};

export type KpWidgetFreightMetrics = WidgetBase & {
  type: AnalyticsWidgetType.FREIGHT_METRICS;
  config: Omit<FreightMetricsWidgetMapping, 'view'> & {
    view: WidgetView.CHART_LINE | WidgetView.CHART_BAR | WidgetView.CHART_AREA | WidgetView.TABLE;
  };
};

export type KpWidgetBallastCapacity = WidgetBase & {
  type: AnalyticsWidgetType.BALLAST_CAPACITY;
  config: Omit<BallastCapacityWidgetMapping, 'view'> & {
    view: WidgetView.CHART_LINE | WidgetView.CHART_BAR | WidgetView.CHART_AREA | WidgetView.TABLE;
  };
};

export type KpWidgetPrices = WidgetBase & {
  type: AnalyticsWidgetType.PRICES;
  config: Omit<PricesWidgetMapping, 'view'> & {
    view: PricesView.CHART_LINE | PricesView.TABLE;
  };
};

export type KpWidgetRefineriesCapacities = WidgetBase & {
  type: AnalyticsWidgetType.REFINERIES_CAPACITIES;
  config: Omit<RefineriesCapacitiesWidgetMapping, 'view'> & {
    view: WidgetView.CHART_BAR;
  };
};

export type Dashboard = NonNullable<DashboardQuery['dashboard']> & {
  readonly months: DashboardDataHistoryInMonths;
};

export type DashboardWidget = Dashboard['widgets'][number];

export type WidgetTypename = DashboardWidget['__typename'];
