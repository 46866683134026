import { assertNever } from '@kpler/generic-utils';
import {
  Granularity,
  getDateParseFormatFromGranularity,
  getFirstDayPeriod,
  serializeDate,
  toMoment,
} from '@kpler/terminal-utils';
import { Market } from '@kpler/web-ui';
import moment, { Moment, unitOfTime } from 'moment';

import { productIds } from 'src/constants/ids/static';
import {
  floatingStorageTankSplitValue,
  internationalWatersSplitValue,
  oilInTransitTankSplitValue,
} from 'src/constants/inventories.constants';
import { ZONE_TYPES_ABOVE_COUNTRY } from 'src/constants/zone.constants';
import {
  FleetMetricsAlgo,
  FleetMetricsFloatingState,
  FleetMetricsParamsSplit,
  FleetMetricsVesselsParams,
} from 'src/domains/cargo/fleetMetrics/fleetMetrics.types';
import { InventoriesMarket } from 'src/domains/onshore-asset-monitoring/inventories/useInventoriesContext';
import colors from 'src/scss/colors.constants';
import { FlowsDirection, FlowsParamsSplit, FlowsTradesParams } from 'src/types/flows';

import { platform } from 'src/helpers/platform.helper';
import { filterTimeseriesByDatasetsAndSort, isOthersSplit } from 'src/helpers/series.helper';

import { ChartTooltipEventSerie, ConvertedTimeSeries, LegendItem } from 'types/chart';
import {
  InventoriesArea,
  InventoriesCargoSplitValues,
  InventoriesDatasetName,
  InventoriesSnapshotParams,
  InventoriesSnapshotSplit,
  InventoriesSplit,
  InventoriesSplitStatusType,
  InventoriesState,
  InventoriesStateHydrated,
  InventoriesZone,
  LabelIndex,
} from 'types/inventories';
import { Platform, ResourceType } from 'types/legacy-globals';
import { UnitName } from 'types/unit';
import { ZoneType } from 'types/zone';

// @TODO identified: these two functions are type guards, not assertions
export const assertIsInventoryZone = (area: InventoriesArea): area is InventoriesZone =>
  area.resourceType === ResourceType.ZONE;

export const assertIsZoneOnly = (areas: InventoriesArea[]): areas is InventoriesZone[] =>
  areas.every(assertIsInventoryZone);

const getSnapshotSplitCriteria = (
  areas: InventoriesArea[],
  split: InventoriesSplit,
  splitValuesToExclude: string[],
  splitId: string | null,
): InventoriesSnapshotSplit => {
  if (
    split === InventoriesSplit.TOTAL ||
    split === InventoriesSplit.TANK_TYPE ||
    split === InventoriesSplit.PLAYER ||
    split === InventoriesSplit.STATUS ||
    split === InventoriesSplit.TANK_STATUS
  ) {
    // above countries (continents, etc)
    if (
      areas.length === 0 ||
      areas.some(
        area =>
          area.resourceType === ResourceType.ZONE &&
          ZONE_TYPES_ABOVE_COUNTRY.includes(area.type as ZoneType),
      )
    ) {
      return InventoriesSnapshotSplit.COUNTRY;
    }

    // multiple countries or above
    const zonesOnly = areas.filter(area => area.resourceType === ResourceType.ZONE);
    if (
      assertIsZoneOnly(zonesOnly) &&
      zonesOnly.length > 1 &&
      zonesOnly.some(zone => zone.type === ZoneType.COUNTRY)
    ) {
      return InventoriesSnapshotSplit.COUNTRY;
    }

    // one country, one port, one PADD
    if (
      areas.length === 1 &&
      areas[0].resourceType === ResourceType.ZONE &&
      [
        ZoneType.COUNTRY,
        ZoneType.COUNTRY_CHECKPOINT,
        ZoneType.CHECKPOINT,
        ZoneType.PORT,
        ZoneType.REGION,
        ZoneType.STORAGE,
        ZoneType.SUBREGION,
      ].includes(areas[0].type as ZoneType)
    ) {
      return InventoriesSnapshotSplit.INSTALLATION;
    }

    // multiple installations
    if (areas.filter(area => area.resourceType === ResourceType.INSTALLATION).length >= 2) {
      return InventoriesSnapshotSplit.INSTALLATION;
    }

    if (areas.length === 1 && areas[0].resourceType === ResourceType.INSTALLATION) {
      return InventoriesSnapshotSplit.TANK;
    }
  }
  if (split === InventoriesSplit.COUNTRY) {
    return splitValuesToExclude?.length > 0 || splitId === null
      ? InventoriesSnapshotSplit.COUNTRY
      : InventoriesSnapshotSplit.INSTALLATION;
  }
  if (split === InventoriesSplit.INSTALLATION) {
    return splitValuesToExclude?.length > 0
      ? InventoriesSnapshotSplit.INSTALLATION
      : InventoriesSnapshotSplit.TANK;
  }

  throw new Error('Cannot determine snapshot split.');
};

const getRSPLocations = (
  areas: InventoriesArea[],
  split: InventoriesSplit,
  splitId: string | null,
): {
  locationIds: number[];
  locationResourceType: ResourceType.ZONE | ResourceType.INSTALLATION;
} => {
  if (
    split === InventoriesSplit.TOTAL ||
    split === InventoriesSplit.STATUS ||
    split === InventoriesSplit.TANK_TYPE ||
    split === InventoriesSplit.PLAYER ||
    split === InventoriesSplit.TANK_STATUS ||
    splitId === null ||
    splitId === 'Others'
  ) {
    return {
      locationIds: areas.map(x => Number(x.id)),
      locationResourceType: areas[0]?.resourceType ?? ResourceType.ZONE,
    };
  }
  if (split === InventoriesSplit.COUNTRY) {
    return {
      locationIds: [Number(splitId)],
      locationResourceType: ResourceType.ZONE,
    };
  }
  if (split === InventoriesSplit.INSTALLATION) {
    return {
      locationIds: [Number(splitId)],
      locationResourceType: ResourceType.INSTALLATION,
    };
  }
  return assertNever(split);
};

const getSnapshotFilters = (
  splitType: InventoriesSplit,
  splitId: string | null,
): InventoriesSnapshotParams['filters'] => {
  if (
    [InventoriesSplit.TANK_TYPE, InventoriesSplit.PLAYER, InventoriesSplit.STATUS].includes(
      splitType,
    ) &&
    typeof splitId === 'string' &&
    splitId !== 'Others'
  ) {
    return [{ splitType, splitValue: splitId }];
  }
  return [];
};

const granularityToMomentUnitOfTimeMap: {
  [key: string]: unitOfTime.StartOf;
} = {
  [Granularity.DAYS]: 'days',
  [Granularity.EIAS]: 'days',
  [Granularity.WEEKS]: 'isoWeek',
  [Granularity.MONTHS]: 'months',
  [Granularity.YEARS]: 'years',
};

export const convertFilterStateToSnapshotParams = (
  filterState: InventoriesStateHydrated,
  date: string,
  splitId: string | null,
  splitValuesToExclude: string[],
  market: InventoriesMarket,
): InventoriesSnapshotParams => {
  const marketBasedPlatform = market === Market.LIQUIDS ? Platform.COMMODITIES : Platform.LNG;
  return {
    platform: marketBasedPlatform,
    cargoTrackingEnhancement: filterState.cargoTrackingEnhancement,
    endDate: serializeDate(
      toMoment(date, getDateParseFormatFromGranularity(filterState.granularity)).endOf(
        granularityToMomentUnitOfTimeMap[filterState.granularity],
      ),
    ),
    granularity: filterState.granularity,
    ...getRSPLocations([...filterState.areas], filterState.split, splitId),
    splitCriteria: getSnapshotSplitCriteria(
      [...filterState.areas],
      filterState.split,
      splitValuesToExclude,
      splitId,
    ),
    filters: getSnapshotFilters(filterState.split, splitId),
    notFilters: splitValuesToExclude.map(value => ({
      splitType: filterState.split,
      splitValue: value.toString(),
    })),
    droneData: filterState.droneData,
  };
};

const getFlowsSplitOn = (splitOn: InventoriesSplit): FlowsParamsSplit | undefined => {
  if (splitOn === InventoriesSplit.COUNTRY) {
    return FlowsParamsSplit.ORIGIN_COUNTRY;
  }
  if (splitOn === InventoriesSplit.INSTALLATION) {
    return FlowsParamsSplit.ORIGIN_INSTALLATION;
  }
  return undefined;
};

export const convertFilterStateToTradesParams = (
  filters: InventoriesStateHydrated,
  date: string,
  splitId: string | null,
  splitValuesToExclude: string[],
  market: InventoriesMarket,
): FlowsTradesParams => {
  const { locationResourceType, locationIds } = getRSPLocations(
    [...filters.areas],
    filters.split,
    splitId,
  );

  const params: FlowsTradesParams = {
    granularity: filters.granularity,
    period: date,
    toLocations: [],
    fromLocations: locationIds.map(x => ({ id: x, resourceType: locationResourceType })),
    exchangeType: FlowsDirection.EXPORT,
    filters: {
      product: [
        market === Market.LIQUIDS
          ? productIds.CRUDE_OIL_CONDENSATE.toString()
          : productIds.LNG.toString(),
      ],
    },
    flowDirection: FlowsDirection.NET_EXPORT,
    interIntra: 'interintra',
    onlyRealized: false,
    splitOn: getFlowsSplitOn(filters.split),
    splitValues: splitId && !isOthersSplit(splitId) ? [splitId] : [],
    splitValuesToExclude,
    withBetaVessels: true,
    withForecasted: true,
    withIncompleteTrades: true,
    withIntraCountry: true,
    withFreightView: false,
    withProductEstimation: false,
  };
  return params;
};

export const findLastDateOfLastMonth = (date: string, granularity: Granularity) =>
  serializeDate(
    toMoment(date, getDateParseFormatFromGranularity(granularity))
      .subtract(1, 'months')
      .endOf('month'),
  );

export const datasetNameToHeaderCSVMap = (
  key: InventoriesDatasetName,
  droneData: boolean,
): string => {
  switch (key) {
    case InventoriesDatasetName.LEVEL:
      return 'Level';
    case InventoriesDatasetName.ONSHORE_OUTFLOW:
      return platform === Platform.LNG ? 'Send-out' : 'Overland outflow';
    case InventoriesDatasetName.ONSHORE_INFLOW:
      return platform === Platform.LNG ? 'Send-in' : 'Overland inflow';
    case InventoriesDatasetName.PORT_CALLS_NET:
      return 'Cargo';
    case InventoriesDatasetName.CAPACITY:
      return 'Capacity';
    case InventoriesDatasetName.CRUDE_CAPACITY:
      return 'Crude Capacity';
    case InventoriesDatasetName.REVISIT_RATE:
      return 'Revisit Rate';
    case InventoriesDatasetName.UTILIZATION:
      return 'Capacity Utilization';
    case InventoriesDatasetName.CARGO:
      return 'Cargo';
    case InventoriesDatasetName.IMAGE:
      return 'Satellite Refreshed';
    case InventoriesDatasetName.HEEL:
      return 'Heel';
    case InventoriesDatasetName.HEEL_CAPACITY:
      return 'Heel Capacity';
    case InventoriesDatasetName.DELTA_LEVEL:
      return 'Delta Level';
    case InventoriesDatasetName.EIA_LEVEL:
      return droneData ? 'EIA Level' : 'EIA Adjustment Level';
    case InventoriesDatasetName.EIA_DELTA_LEVEL:
      return 'EIA Delta Level';
    case InventoriesDatasetName.EIA_CAPACITY:
      return 'EIA Adjustment Capacity';
    default:
      return assertNever(key);
  }
};

export const getFleetMetricsVesselParamsFromInventoriesFilter = (
  splitId: string | null,
  date: string,
  filterState: InventoriesStateHydrated,
): FleetMetricsVesselsParams => {
  const params = {
    algo: FleetMetricsAlgo.COMMODITY_ON_WATER,
    zoneIds: filterState.areas.map(area => Number(area.id)),
    date: getFirstDayPeriod(date, filterState.granularity),
    periodicity: filterState.granularity,
    period: date,
    splitValuesToExclude: [],
    filters: {
      product: [productIds.CRUDE_OIL_CONDENSATE],
    },
    withFreightView: false,
    withProductEstimation: false,
    splitValues: [],
  };
  if (splitId === null) {
    return params;
  }
  return {
    ...params,
    splitOn: FleetMetricsParamsSplit.FLOATING_STATE,
    splitValues: [
      splitId === InventoriesSplitStatusType.FLOATING_STORAGE
        ? FleetMetricsFloatingState.FLOATING
        : FleetMetricsFloatingState.NON_FLOATING,
    ],
    ...(filterState.floatingFrom ? { floatingFrom: filterState.floatingFrom } : {}),
  };
};

export const getFlowsUnitName = (unit: UnitName): UnitName => {
  if (unit === UnitName.NCM || unit === UnitName.GWH) {
    return UnitName.CM;
  }
  if (unit === UnitName.SCF) {
    return UnitName.CF;
  }
  if (unit === UnitName.SMCF) {
    return UnitName.MCF;
  }
  return unit;
};

export const hasUserSelectedOnlyOneInstallation = (filters: InventoriesState): boolean =>
  filters.zones.length === 0 && filters.installations.length === 1;

export const getSummaryDeltaLevelClass = (value: number | undefined): string => {
  if (value === undefined) {
    return 'text-secondary';
  }
  return value < 0 ? 'red' : 'green';
};

const noSnapshotStatusTypes: string[] = [
  InventoriesSplitStatusType.OIL_ON_SHIP,
  InventoriesSplitStatusType.FLOATING_STORAGE,
];
const noSnapshotTankTypes = [floatingStorageTankSplitValue, oilInTransitTankSplitValue];

export const splitValueHasSnapshot = (
  splitType: InventoriesSplit,
  splitId: string | null,
): boolean =>
  splitId === null ||
  !(
    (splitType === InventoriesSplit.STATUS && noSnapshotStatusTypes.includes(splitId)) ||
    (splitType === InventoriesSplit.COUNTRY && splitId === internationalWatersSplitValue) ||
    (splitType === InventoriesSplit.TANK_TYPE && noSnapshotTankTypes.includes(splitId))
  );

export const getDefaultGranularity = (droneData: boolean) =>
  droneData ? Granularity.EIAS : Granularity.MONTHS;

export const getInventoriesRequestEndDate = (
  showFutureData: boolean,
  granularity: Granularity,
): Moment => {
  let endDate = moment.utc();
  if (showFutureData) {
    endDate = endDate.add(31, 'days');
  }
  if ([Granularity.MONTHS, Granularity.YEARS].includes(granularity)) {
    endDate = endDate.endOf('month');
  }
  return endDate;
};

export const addFlowSerie = (
  data: ReadonlyArray<ConvertedTimeSeries<InventoriesDatasetName>>,
  market: InventoriesMarket,
): ReadonlyArray<ConvertedTimeSeries<InventoriesDatasetName>> => {
  const isLng = market === Market.LNG;

  if (data.length === 0) {
    return data;
  }

  const filteredTimeseries = filterTimeseriesByDatasetsAndSort<InventoriesDatasetName>(data, [
    InventoriesDatasetName.ONSHORE_OUTFLOW,
    InventoriesDatasetName.ONSHORE_INFLOW,
    InventoriesDatasetName.PORT_CALLS_NET,
  ]);

  const portCallsIndex = filteredTimeseries[0].series.findIndex(
    serie => serie.key === InventoriesDatasetName.PORT_CALLS_NET,
  );
  const onshoreOutflowIndex = filteredTimeseries[0].series.findIndex(
    serie => serie.key === InventoriesDatasetName.ONSHORE_OUTFLOW,
  );
  const onshoreInflowIndex = filteredTimeseries[0].series.findIndex(
    serie => serie.key === InventoriesDatasetName.ONSHORE_INFLOW,
  );
  return data.map((d, index) => {
    const currentFilteredElement: ConvertedTimeSeries = filteredTimeseries[index];
    const cargoValue = currentFilteredElement.series[portCallsIndex].value ?? 0;
    const importValue = cargoValue > 0 ? cargoValue : 0;
    const exportValue = cargoValue < 0 ? cargoValue : 0;
    // demand is negated
    const onshoreOutflowValue = -(currentFilteredElement.series[onshoreOutflowIndex].value ?? 0);
    const onshoreInflowValue = currentFilteredElement.series[onshoreInflowIndex].value ?? 0;

    const total = importValue + exportValue + onshoreOutflowValue + onshoreInflowValue;
    const cargo = {
      key: InventoriesDatasetName.CARGO,
      splitValues: [
        {
          id: InventoriesCargoSplitValues.IMPORT,
          name: 'Import',
          value: importValue,
        },
        {
          id: InventoriesCargoSplitValues.EXPORT,
          name: 'Export',
          value: exportValue,
        },
        {
          id: InventoriesCargoSplitValues.ONSHORE_INFLOW,
          name: isLng ? 'Send-in' : 'Overland inflow',
          value: onshoreInflowValue,
        },
        {
          id: InventoriesCargoSplitValues.ONSHORE_OUTFLOW,
          name: isLng ? 'Send-out' : 'Overland outflow',
          value: onshoreOutflowValue,
        },
      ],
      value: total,
    };
    return {
      date: d.date,
      series: [...d.series, cargo],
    };
  });
};

export const IMPORT_SPLIT = Object.freeze({
  id: InventoriesCargoSplitValues.IMPORT,
  name: 'Import',
  color: colors.brandGreen,
});
const EXPORT_SPLIT = Object.freeze({
  id: InventoriesCargoSplitValues.EXPORT,
  name: 'Export',
  color: colors.brandOrange,
});

export const getOnshoreInflowSplit = (market: InventoriesMarket): LegendItem => ({
  id: InventoriesCargoSplitValues.ONSHORE_INFLOW,
  name: market === Market.LNG ? 'Send-in' : 'Overland inflow',
  color: colors.brandGreenFaded,
});

export const getOnshoreOutflowSplit = (market: InventoriesMarket): LegendItem => ({
  id: InventoriesCargoSplitValues.ONSHORE_OUTFLOW,
  name: market === Market.LNG ? 'Send-out' : 'Overland outflow',
  color: colors.brandOrangeFaded,
});

export const getSplitOrderForFlows = (
  sortedTimeseries: ReadonlyArray<ConvertedTimeSeries<InventoriesDatasetName>>,
  market: InventoriesMarket,
): readonly LegendItem[] => {
  const serie = sortedTimeseries[0].series;
  const portCallsIndex = serie.findIndex(s => s.key === InventoriesDatasetName.PORT_CALLS_NET);
  const onshoreOutflowIndex = serie.findIndex(
    s => s.key === InventoriesDatasetName.ONSHORE_OUTFLOW,
  );
  const onshoreInflowIndex = serie.findIndex(s => s.key === InventoriesDatasetName.ONSHORE_INFLOW);

  const splits: LegendItem[] = [];
  if (
    sortedTimeseries.some(x => {
      const val = x.series[portCallsIndex]?.value ?? null;
      return val !== null && val > 0;
    })
  ) {
    splits.push(IMPORT_SPLIT);
  }
  if (
    sortedTimeseries.some(x => {
      const val = x.series[portCallsIndex]?.value ?? null;
      return val !== null && val < 0;
    })
  ) {
    splits.push(EXPORT_SPLIT);
  }
  if (
    sortedTimeseries.some(x => {
      const val = x.series[onshoreInflowIndex]?.value ?? null;
      return val !== null && val > 0;
    })
  ) {
    splits.push(getOnshoreInflowSplit(market));
  }
  if (
    sortedTimeseries.some(x => {
      const val = x.series[onshoreOutflowIndex]?.value ?? null;
      return val !== null && val > 0;
    })
  ) {
    splits.push(getOnshoreOutflowSplit(market));
  }
  return splits;
};

export const correctAdditionalDatasetsForTooltip = (
  additionalDatasets: LabelIndex[] | undefined,
  series: ChartTooltipEventSerie[],
) => {
  if (!additionalDatasets) {
    return [];
  }
  return additionalDatasets.map(dataset => {
    const serieIndex = series.findIndex(s => s.key === dataset.id);
    return {
      ...dataset,
      index: serieIndex,
    };
  });
};
