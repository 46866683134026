<script setup lang="ts">
import { CheckboxButtonGroupWrapper } from '../../../CheckboxButtonGroupWrapper';
import { ORDERED_MARKETS } from '../../constants';
import { Market } from '../../types';

import { MarketCheckbox } from './MarketCheckbox';

type Props = {
  selected: readonly Market[];
  accessibleMarkets: Set<Market>;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:selected', newSelection: Market[]): void;
  (e: 'update:latestSelected', latestSelected: Market): void;
}>();

const checkIsChecked = (market: Market) => new Set(props.selected).has(market);

const onUpdate = (updatedMarket: Market, isChecked: boolean) => {
  const newSelection = isChecked
    ? [...props.selected, updatedMarket]
    : props.selected.filter(x => x !== updatedMarket);

  emit('update:selected', newSelection);

  if (isChecked) {
    emit('update:latestSelected', updatedMarket);
  }
};
</script>

<template>
  <CheckboxButtonGroupWrapper data-testid="MarketCheckboxGroup">
    <MarketCheckbox
      v-for="market in ORDERED_MARKETS"
      :key="market"
      :market="market"
      :checked="checkIsChecked(market)"
      :disabled="!accessibleMarkets.has(market)"
      @update:checked="onUpdate(market, $event)"
    />
  </CheckboxButtonGroupWrapper>
</template>
