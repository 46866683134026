import { DataError } from '@kpler/generic-utils';
import { Granularity, getDateParseFormatFromGranularity, toMoment } from '@kpler/terminal-utils';
import moment, { Moment } from 'moment';

import { FlowsProjection } from 'src/types/flows';

import { QuantityObject } from 'types/quantity';
import {
  ConvertToTemporalUnitOptions,
  Unit,
  UnitName,
  TemporalUnitName,
  TemporalUnitGranularity,
} from 'types/unit';

const SMALLEST_EQUIVALENT_UNIT: {
  [key in UnitName]: UnitName;
} = {
  [UnitName.CM]: UnitName.CM,
  [UnitName.CF]: UnitName.CF,
  [UnitName.MCF]: UnitName.CF,
  [UnitName.NCM]: UnitName.NCM,
  [UnitName.SCF]: UnitName.SCF,
  [UnitName.SMCF]: UnitName.SCF,
  [UnitName.KB]: UnitName.BARREL,
  [UnitName.BARREL]: UnitName.BARREL,
  [UnitName.MMBBL]: UnitName.BARREL,
  [UnitName.QUINTAL]: UnitName.TONS,
  [UnitName.KTONS]: UnitName.TONS,
  [UnitName.KG]: UnitName.TONS,
  [UnitName.TONS]: UnitName.TONS,
  [UnitName.TON]: UnitName.TON,
  [UnitName.MTONS]: UnitName.TONS,
  [UnitName.TON_MILE]: UnitName.TON_MILE,
  [UnitName.KTON_MILE]: UnitName.TON_MILE,
  [UnitName.MTON_MILE]: UnitName.TON_MILE,
  [UnitName.TON_DAY]: UnitName.TON_DAY,
  [UnitName.KTON_DAY]: UnitName.TON_DAY,
  [UnitName.MTON_DAY]: UnitName.TON_DAY,
  [UnitName.KNOT]: UnitName.KNOT,
  [UnitName.NAUTICAL_MILE]: UnitName.NAUTICAL_MILE,
  [UnitName.GWH]: UnitName.GWH,
  [UnitName.METER]: UnitName.METER,
  [UnitName.KG_CM]: UnitName.KG_CM,
};

const BIGGEST_EQUIVALENT_UNIT: {
  [key in UnitName]: UnitName;
} = {
  [UnitName.CM]: UnitName.CM,
  [UnitName.CF]: UnitName.MCF,
  [UnitName.MCF]: UnitName.MCF,
  [UnitName.KB]: UnitName.MMBBL,
  [UnitName.NCM]: UnitName.NCM,
  [UnitName.SCF]: UnitName.SMCF,
  [UnitName.SMCF]: UnitName.SMCF,
  [UnitName.BARREL]: UnitName.MMBBL,
  [UnitName.MMBBL]: UnitName.MMBBL,
  [UnitName.QUINTAL]: UnitName.MTONS,
  [UnitName.KTONS]: UnitName.MTONS,
  [UnitName.KG]: UnitName.MTONS,
  [UnitName.TON]: UnitName.MTONS,
  [UnitName.TONS]: UnitName.MTONS,
  [UnitName.MTONS]: UnitName.MTONS,
  [UnitName.TON_MILE]: UnitName.MTON_MILE,
  [UnitName.KTON_MILE]: UnitName.MTON_MILE,
  [UnitName.MTON_MILE]: UnitName.MTON_MILE,
  [UnitName.TON_DAY]: UnitName.MTON_DAY,
  [UnitName.KTON_DAY]: UnitName.MTON_DAY,
  [UnitName.MTON_DAY]: UnitName.MTON_DAY,
  [UnitName.KNOT]: UnitName.KNOT,
  [UnitName.NAUTICAL_MILE]: UnitName.NAUTICAL_MILE,
  [UnitName.GWH]: UnitName.GWH,
  [UnitName.METER]: UnitName.METER,
  [UnitName.KG_CM]: UnitName.KG_CM,
};

const FLOWS_TIME_PERIOD: { [key in TemporalUnitGranularity]: number } = {
  [Granularity.DAYS]: 1,
  [Granularity.YEARS]: 365,
};

const TEMPORAL_UNIT_MAP: {
  [key in TemporalUnitName]: {
    unitName: UnitName;
    granularity: TemporalUnitGranularity;
  };
} = {
  [TemporalUnitName.BD]: {
    unitName: UnitName.BARREL,
    granularity: Granularity.DAYS,
  },
  [TemporalUnitName.KBD]: {
    unitName: UnitName.KB,
    granularity: Granularity.DAYS,
  },
  [TemporalUnitName.KBPA]: {
    unitName: UnitName.KB,
    granularity: Granularity.YEARS,
  },
  [TemporalUnitName.MTPA]: {
    unitName: UnitName.MTONS,
    granularity: Granularity.YEARS,
  },
};

export const getTemporalUnitDecomposition = (
  temporalUnitName: TemporalUnitName,
): { unitName: UnitName; granularity: TemporalUnitGranularity } =>
  TEMPORAL_UNIT_MAP[temporalUnitName];

export const getSmallestEquivalentUnit = (unitName: UnitName): UnitName =>
  SMALLEST_EQUIVALENT_UNIT[unitName];

export const getBiggestEquivalentUnit = (unitName: UnitName): UnitName =>
  BIGGEST_EQUIVALENT_UNIT[unitName];

export const getComputedValue = (
  physicalQuantity: QuantityObject | number,
  unitObj: Unit,
  toReferenceUnit = false,
): number => {
  const originalValue =
    typeof physicalQuantity === 'number' ? physicalQuantity : physicalQuantity[unitObj.key];
  if (!originalValue) {
    return 0;
  }

  // Compute Physical Value to display according to current unit
  // Parse and evaluate unit conversion formula to get computed value to display
  const formula = (x: number): number => eval(unitObj.conversionFormula); // eslint-disable-line

  return toReferenceUnit ? 1 / formula(1 / originalValue) : formula(originalValue);
};

type ConvertToTemporalUnitFullOptions = ConvertToTemporalUnitOptions & {
  unitGranularity: TemporalUnitGranularity;
  now: Moment;
};

const toFlow = (options: ConvertToTemporalUnitFullOptions): number => {
  const { now, qtyPeriod, unitGranularity, qtyGranularity, onlyRealized } = options;
  let numberOfDays;
  const hoursToRemove = options.roundToEndOfDay ? 0 : 1 - now.hour() / 24;

  switch (qtyGranularity) {
    case Granularity.YEARS: {
      if (onlyRealized && qtyPeriod.isAfter(now, 'year')) {
        throw new DataError('Cannot compute a future period in realized mode');
      }

      numberOfDays =
        qtyPeriod.isSame(now, 'year') && onlyRealized
          ? Math.max(1, now.dayOfYear() - hoursToRemove)
          : qtyPeriod.endOf('year').dayOfYear();
      break;
    }
    case Granularity.MONTHS: {
      if (onlyRealized && qtyPeriod.isAfter(now, 'month')) {
        throw new DataError('Cannot compute a future period in realized mode');
      }

      numberOfDays =
        qtyPeriod.isSame(now, 'month') && onlyRealized
          ? Math.max(1, now.date() - hoursToRemove)
          : qtyPeriod.daysInMonth();
      break;
    }
    case Granularity.WEEKS: {
      if (onlyRealized && qtyPeriod.isAfter(now, 'isoWeek')) {
        throw new DataError('Cannot compute a future period in realized mode');
      }

      numberOfDays =
        qtyPeriod.isSame(now, 'isoWeek') && onlyRealized
          ? Math.max(1, now.isoWeekday() - hoursToRemove)
          : 7;
      break;
    }
    case Granularity.EIAS: {
      if (qtyPeriod.isoWeekday() !== 5) {
        throw new DataError(`EIA ${qtyPeriod} is not a Friday.`);
      }

      const eiaPeriodEnd = moment.utc({
        year: qtyPeriod.year(),
        month: qtyPeriod.month(),
        day: qtyPeriod.date(),
        hour: 12,
      });
      const diff = now.diff(eiaPeriodEnd, 'days', true);

      if (onlyRealized && diff < -7) {
        throw new DataError('Cannot compute a future period in realized mode');
      }

      const isSameEIAWeek = diff >= -7 && diff < 0;
      numberOfDays = isSameEIAWeek && onlyRealized ? Math.max(1, diff + 7) : 7;
      break;
    }
    case Granularity.DAYS: {
      if (onlyRealized && qtyPeriod.isAfter(now, 'day')) {
        throw new DataError('Cannot compute a future period in realized mode');
      }

      numberOfDays = 1;
      break;
    }
    default:
      throw new TypeError('Invalid options.');
  }

  return FLOWS_TIME_PERIOD[unitGranularity] / numberOfDays;
};

export const convertToUnit = (qtyObj: QuantityObject | number, toUnitObj: Unit): number =>
  getComputedValue(qtyObj, toUnitObj);

export const convertMetersToNauticalMiles = (value: number): number => value / 1852;

export const convertPerSecondsToPerDays = (value: number): number => value / (24 * 3600);

export const convertMeterSecondsToKnots = (value: number): number => value * 1.94384;

export const convertToTemporalUnit = (
  qtyObj: QuantityObject | number,
  toUnitObj: Unit,
  options: ConvertToTemporalUnitFullOptions,
): number => {
  const value = convertToUnit(qtyObj, toUnitObj);
  const temporalRatio = toFlow(options);
  return value * temporalRatio;
};

export const getConversionOptionsFn =
  (
    granularity: Granularity,
    projection: FlowsProjection,
    roundToEndOfDay = false,
  ): ((date: string) => ConvertToTemporalUnitOptions) =>
  date => {
    const parseFormat = getDateParseFormatFromGranularity(granularity);
    return {
      qtyPeriod: toMoment(date, parseFormat),
      qtyGranularity: granularity,
      onlyRealized: projection === FlowsProjection.ACTUAL,
      roundToEndOfDay,
    };
  };
