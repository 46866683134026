import { AppRoutes } from 'src/app/routes';
import { useWorkflowsPermissions } from 'src/domains/freight/common/permissions/userHashFreightWorkflowsPermissions';
import store from 'src/store';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

import {
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNextRaw,
} from 'types/router-migration';

const { hasBetaVesselMatcherPermissions } = useWorkflowsPermissions(store);
const Nowcast = () =>
  import(
    /* webpackChunkName: "freight-workspace" */ 'src/domains/freight/TheVesselMatcherWorkspace/Nowcast.vue'
  );
const Historical = () =>
  import(
    /* webpackChunkName: "freight-workspace" */ 'src/domains/freight/TheVesselMatcherWorkspace/Historical.vue'
  );

const TheVesselMatcherWorkspace = () =>
  import(
    /* webpackChunkName: "vessel-matcher-workspace" */ 'src/domains/freight/TheVesselMatcherWorkspace/TheVesselMatcherWorkspace.vue'
  );

const beforeEnterFn = (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNextRaw,
) => {
  const nextWithRefresh = forceRefreshGuard(to, next);
  if (!hasBetaVesselMatcherPermissions) {
    nextWithRefresh({ name: AppRoutes.FORBIDDEN });
    return;
  }
  nextWithRefresh();
};

const vesselMatcherRoutes: RouteRecordRaw = {
  name: 'vessel-matcher-beta',
  path: 'workflows/vessel-matcher-beta',
  component: TheVesselMatcherWorkspace,
  redirect: 'workflows/vessel-matcher-beta/nowcast',
  children: [
    {
      name: 'vessel-matcher-nowcast',
      path: 'nowcast',
      component: Nowcast,
      meta: { title: 'Nowcast' },
      beforeEnter: beforeEnterFn,
    },
    {
      name: 'vessel-matcher-historical',
      path: 'historical',
      component: Historical,
      meta: { title: 'Historical' },
      beforeEnter: beforeEnterFn,
    },
  ],
};

export default vesselMatcherRoutes;
