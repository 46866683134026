import {
  DateRange,
  DateRangePreset,
  Granularity,
  SerializedDateRange,
  RangeNumber,
} from '@kpler/terminal-utils';

import { FlowsProjection } from 'src/types/flows';

import type { ObjectBase } from '@kpler/terminal-utils';
import { AnalyticsView } from 'types/analytics';
import {
  PaginationParams,
  DeadWeightObject,
  CapacityObject,
  VesselTypeClassificationSplit,
  VesselTypeClassification,
} from 'types/legacy-globals';
import { PricesBaseFiltersMapping, PricesSidebarFilterState } from 'types/prices';
import { UnitName, UnmanagedUnitName } from 'types/unit';
import { Vessel } from 'types/vessel';

export enum FleetDevelopmentSplit {
  TOTAL = 'total',
  VESSEL_TYPE = 'vesselType',
  COMPLIANCE_METHOD = 'complianceMethod',
  VESSEL_COATING = 'vesselCoating',
  ENGINE_TYPE = 'engineType',
}

export enum FleetDevelopmentMetric {
  AVAILABLE = 'Available',
  DELIVERIES = 'Deliveries',
  SCRAPPING = 'Scrapping',
  CONTRACTING = 'Contracting',
}

export enum FleetDevelopmentAggMetric {
  COUNT = 'Count',
  DEADWEIGHT = 'SumDeadWeight',
  CAPACITY = 'SumCapacity',
}

export enum FleetDevelopmentCoating {
  FULLY_COATED = 'Fully Coated',
  PARTLY_COATED = 'Partly Coated',
  UNCOATED = 'Uncoated',
  UNKNOWN = 'Unknown',
}

export type FleetDevelopmentStateHydrated = {
  metric: FleetDevelopmentMetric;
  aggMetric: FleetDevelopmentAggMetric;
  split: FleetDevelopmentSplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vesselClassifications: readonly string[];
  engineTypes: readonly string[];
  complianceMethod: readonly string[];
  coating: readonly FleetDevelopmentCoating[];
  vessels: ReadonlyArray<ObjectBase<number>>;
  pricesFilters: PricesSidebarFilterState;
  dateRange: DateRangePreset | DateRange;
  granularity: Granularity;
  seasonal: boolean;
  projection: FlowsProjection;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
};

export type FleetDevelopmentState = {
  metric: FleetDevelopmentMetric;
  aggMetric: FleetDevelopmentAggMetric;
  granularity: Granularity;
  seasonal: boolean;
  projection: FlowsProjection;
  splitOn: FleetDevelopmentSplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vesselClassifications: readonly string[];
  engineTypes: readonly string[] | null;
  complianceMethod: readonly string[];
  coating: readonly FleetDevelopmentCoating[];
  dates: DateRangePreset | DateRange;
  vessels: readonly number[];
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
  pricesFilters: PricesBaseFiltersMapping;
};

type FleetDevelopmentParamsSplit =
  | Exclude<FleetDevelopmentSplit, FleetDevelopmentSplit.TOTAL>
  | VesselTypeClassificationSplit;

export type FleetDevelopmentParams = SerializedDateRange & {
  metric: FleetDevelopmentMetric;
  aggMetric: FleetDevelopmentAggMetric;
  numberOfSplits?: number;
  granularity: Granularity;
  splitOn?: FleetDevelopmentParamsSplit;
  deadWeight?: DeadWeightObject;
  capacity?: CapacityObject;
  vesselClassifications?: readonly string[];
  engineTypes: readonly string[];
  complianceMethod?: readonly string[];
  coating?: readonly FleetDevelopmentCoating[];
  withOrderbook?: boolean;
  vesselIds?: readonly number[];
  vesselTypeClassification?: VesselTypeClassification;
};

export type FleetDevelopmentVesselTypesParams = PaginationParams &
  SerializedDateRange & {
    date: string;
    metric: FleetDevelopmentMetric;
    aggMetric: FleetDevelopmentAggMetric;
    granularity: Granularity;
    deadWeight?: DeadWeightObject;
    capacity?: CapacityObject;
    vesselClassifications?: readonly string[];
    engineTypes: readonly string[];
    complianceMethod?: readonly string[];
    coating?: readonly FleetDevelopmentCoating[];
    withOrderbook?: boolean;
    splitOn?: FleetDevelopmentParamsSplit;
    splitValues?: readonly string[];
    splitValuesToExclude?: readonly string[];
    vesselIds?: readonly number[];
    vesselTypeClassification?: VesselTypeClassification;
  };

export type FleetDevelopmentVesselPayload = {
  id: string;
  day: string;
  vessel: Vessel;
};

export type FleetDevelopmentTooltipProps = {
  unitName: UnitName | UnmanagedUnitName;
  granularity: Granularity;
};
