<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue';

import { Icon } from '../../Icon';
import { Typeahead } from '../inputs/Typeahead';

import { CascaderOption, Props as CascaderProps } from './Cascader.props';
import {
  getSelectedAncestorIds,
  buildNodesFromOptionsAndSelectedIds,
  UPDATE_SELECTED_IDS,
} from './Cascader.utils';
import { Suggestion } from './Suggestion';
import { FlatNode } from './Tree/Tree.types';
import Tree from './Tree/Tree.vue';
import { useCascader } from './useCascader';

type Props = {
  options: CascaderProps['options'];
  selectedIds: CascaderProps['selectedIds'];
  placeholder?: CascaderProps['placeholder'];
  treeSortMethod?: CascaderProps['treeSortMethod'];
  propagateSelectionToParents?: CascaderProps['propagateSelectionToParents'];
  rootLevelAsRadioButtons?: CascaderProps['rootLevelAsRadioButtons'];
};

const props = withDefaults(defineProps<Props>(), {
  propagateSelectionToParents: true,
});

const emit = defineEmits<{
  (event: typeof UPDATE_SELECTED_IDS, selectedIds: readonly string[]): void;
}>();

const containerRef = ref<HTMLElement>();
const cascaderRef = ref<HTMLElement>();

const cascader = useCascader(
  containerRef,
  cascaderRef,
  toRef(props, 'options'),
  toRef(props, 'selectedIds'),
  toRef(props, 'treeSortMethod'),
  props.propagateSelectionToParents,
  props.rootLevelAsRadioButtons ?? false
);

const showClear = computed(() => props.selectedIds.length > 1);

const onUpdateTypeaheadSelectedItems = (selectedItems: readonly CascaderOption[]) => {
  const selectedIds = selectedItems.map(item => item.id);
  const nodes = buildNodesFromOptionsAndSelectedIds(props.options, selectedIds, {
    propagateSelectionToParents: props.propagateSelectionToParents,
    treeSortMethod: props.treeSortMethod,
  });
  const newSelectedIds = getSelectedAncestorIds(nodes);
  emit(UPDATE_SELECTED_IDS, newSelectedIds);
};

const onUpdateNodes = (nodes: readonly FlatNode[]) => {
  const newSelectedIds = getSelectedAncestorIds(nodes);
  emit(UPDATE_SELECTED_IDS, newSelectedIds);
};

const search = ref<string>('');
const suggestions = computed<readonly CascaderOption[]>(() => {
  const isSearchEmpty = search.value === '';
  if (isSearchEmpty) {
    return [];
  }

  return props.options.filter(
    option => !option.disabled && option.label.toLowerCase().includes(search.value.toLowerCase())
  );
});

const closeClascaderOnGettingSuggestions = () => {
  const hasSuggestions = suggestions.value.length > 0;
  if (hasSuggestions) {
    cascader.close();
  }
};

watch(suggestions, closeClascaderOnGettingSuggestions);
</script>

<template>
  <div ref="containerRef" class="Cascader">
    <Typeahead
      ref="typeaheadRef"
      :search.sync="search"
      :selected-items="cascader.selectedNodes.value"
      :suggestions="suggestions"
      :placeholder="placeholder"
      @update:selectedItems="onUpdateTypeaheadSelectedItems"
      @inputFocus="cascader.open"
    >
      <template #icon>
        <span class="icon-wrapper">
          <Icon
            v-if="showClear"
            name="close"
            class="pointer clear"
            @click="onUpdateTypeaheadSelectedItems([])"
          />
          <Icon name="angle-right" class="pointer rotate-90" @click="cascader.toggle" />
        </span>
      </template>

      <template #suggestion="{ suggestion }">
        <Suggestion :suggestion="suggestion" :options="options" />
      </template>
    </Typeahead>

    <Tree
      v-if="cascader.isOpen.value"
      ref="cascaderRef"
      class="mt-xs"
      :propagate-selection-to-parents="propagateSelectionToParents"
      :style="cascader.cssPosition.value"
      :nodes="cascader.flatNodesTree.value"
      :tree-sort-method="treeSortMethod"
      @update:nodes="onUpdateNodes"
    />
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';

.icon-wrapper {
  display: inline-flex;
}

.clear:not(:hover) {
  color: theme.$color-text-secondary;
}
</style>
