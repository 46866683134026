import {
  InstallationSearchFragment,
  PlayerSearchFragment,
  ProductSearchFragment,
  VesselSearchFragment,
  ZoneSearchFragment,
} from 'types/graphql';
import { ResourceType } from 'types/legacy-globals';
import {
  InstallationSearchResult,
  PlayerSearchResult,
  ProductSearchResult,
  VesselSearchResult,
  ZoneSearchResult,
} from 'types/search';
import { ZoneType } from 'types/zone';

export const suggestionToProductSearch = (
  suggestion: ProductSearchFragment,
): ProductSearchResult => ({
  id: Number(suggestion.product.id),
  name: suggestion.product.name,
  type: suggestion.product.type,
  highlight: suggestion.highlight,
  resourceType: ResourceType.PRODUCT,
});

export const suggestionToVesselSearch = (suggestion: VesselSearchFragment): VesselSearchResult => ({
  id: Number(suggestion.vessel.id),
  name: suggestion.vessel.name,
  status: suggestion.vessel.status,
  imo: suggestion.vessel.imo,
  hasLastPosition: suggestion.vessel.hasLastPosition,
  highlight: suggestion.highlight,
  resourceType: ResourceType.VESSEL,
  currentCommodityType: suggestion.vessel.currentCommodityType,
});

export const suggestionToInstallationSearch = (
  suggestion: InstallationSearchFragment,
): InstallationSearchResult => ({
  id: Number(suggestion.installation.id),
  name: suggestion.installation.name,
  iirName: suggestion.installation.iirName,
  type: suggestion.installation.type,
  unlocodes: suggestion.installation.unlocodes as string[],
  portCost: suggestion.installation.portCost,
  port: {
    id: Number(suggestion.installation.port.id),
  },
  hasCargoTracking: suggestion.installation.hasCargoTracking,
  hasStorage: suggestion.installation.hasStorage,
  isInUnitedStates: suggestion.installation.isInUnitedStates,
  highlight: suggestion.highlight,
  resourceType: ResourceType.INSTALLATION,
  commodityTypes: suggestion.installation.commodityTypes
    ? [...suggestion.installation.commodityTypes]
    : [],
});

export const suggestionToPlayerSearch = (suggestion: PlayerSearchFragment): PlayerSearchResult => ({
  id: Number(suggestion.player.id),
  name: suggestion.player.name,
  highlight: suggestion.highlight,
  resourceType: ResourceType.PLAYER,
});

export const suggestionToZoneSearch = (suggestion: ZoneSearchFragment): ZoneSearchResult => ({
  id: Number(suggestion.zone.id),
  name: suggestion.zone.name,
  type: suggestion.zone.type as ZoneType,
  isInUnitedStates: suggestion.zone.isInUnitedStates,
  hasStorage: suggestion.zone.hasStorage,
  highlight: suggestion.highlight,
  resourceType: ResourceType.ZONE,
});
