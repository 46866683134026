import { assertDefined } from '@kpler/generic-utils';
import { deserializeDate, getDateParseFormatFromGranularity } from '@kpler/terminal-utils';
import cloneDeep from 'lodash.clonedeep';

import { TimeSeriesInventories, InventoriesDatasetName } from 'types/inventories';
import { TimeSeriesPayload } from 'types/series';

export const adaptInventoriesDates = <T>(
  payload: TimeSeriesPayload<T, InventoriesDatasetName>,
): TimeSeriesPayload<T, InventoriesDatasetName> => ({
  metadata: payload.metadata,
  series: payload.series.map(item => ({
    ...item,
    date: deserializeDate(item.date).format(
      getDateParseFormatFromGranularity(payload.metadata.granularity),
    ),
  })),
});

export const addCapacityUtilization = (
  payload: TimeSeriesPayload<TimeSeriesInventories, InventoriesDatasetName>,
): TimeSeriesPayload<TimeSeriesInventories, InventoriesDatasetName> => {
  const capacityDatasets = [
    InventoriesDatasetName.UTILIZATION,
    InventoriesDatasetName.HEEL_CAPACITY,
  ];
  return {
    metadata: payload.metadata,
    series: payload.series.map(item => {
      const capacityIndex = item.datasets.findIndex(
        d => d.datasetName === InventoriesDatasetName.CAPACITY,
      );
      const levelIndex = item.datasets.findIndex(
        d => d.datasetName === InventoriesDatasetName.LEVEL,
      );
      const heelIndex = item.datasets.findIndex(d => d.datasetName === InventoriesDatasetName.HEEL);

      const capacity = item.datasets[capacityIndex].values;
      const splitCapacities = item.datasets[capacityIndex].splitValues;
      const capacityUtilization = cloneDeep(item.datasets[levelIndex]);
      capacityUtilization.datasetName = InventoriesDatasetName.UTILIZATION;
      item.datasets.push(capacityUtilization);

      if (heelIndex > -1) {
        const heelCapacity = cloneDeep(item.datasets[heelIndex]);
        heelCapacity.datasetName = InventoriesDatasetName.HEEL_CAPACITY;
        item.datasets.push(heelCapacity);
      }

      const updatedDatasets = item.datasets.map(dataset => {
        if (!capacityDatasets.includes(dataset.datasetName)) {
          return dataset;
        }

        return {
          ...dataset,
          values: {
            providerName: dataset.values.providerName,
            mass: 0,
            volume: (dataset.values.volume / capacity.volume) * 100,
            volume_gas: (dataset.values.volume_gas / capacity.volume_gas) * 100,
            energy: (dataset.values.energy / capacity.energy) * 100,
          },
          splitValues: dataset.splitValues?.map((split, idx) => {
            assertDefined(splitCapacities);
            const splitCapacity = splitCapacities[idx].values;
            return {
              ...split,
              values: {
                providerName: split.values.providerName,
                mass: 0,
                volume: (split.values.volume / splitCapacity.volume) * 100,
                volume_gas: (split.values.volume_gas / splitCapacity.volume_gas) * 100,
                energy: (split.values.energy / splitCapacity.energy) * 100,
              },
            };
          }),
        };
      });

      return {
        ...item,
        datasets: updatedDatasets,
      };
    }),
  };
};

export const adaptDroneCapacityUtilization = (
  payload: TimeSeriesPayload<TimeSeriesInventories, InventoriesDatasetName>,
): TimeSeriesPayload<TimeSeriesInventories, InventoriesDatasetName> => ({
  metadata: payload.metadata,
  series: payload.series.map(item => ({
    ...item,
    datasets: item.datasets.map(dataset => {
      if (dataset.datasetName !== InventoriesDatasetName.UTILIZATION) {
        return dataset;
      }
      return {
        ...dataset,
        values: {
          ...dataset.values,
          volume: dataset.values.volume * 100,
          volume_gas: dataset.values.volume_gas * 100,
        },
        splitValues: dataset.splitValues?.map(splitValue => ({
          ...splitValue,
          values: {
            ...splitValue.values,
            volume: splitValue.values.volume * 100,
            volume_gas: splitValue.values.volume_gas * 100,
          },
        })),
      };
    }),
  })),
});
