import { TypePolicies } from '@apollo/client/core';

export const cacheTypePolicies: TypePolicies = {
  Query: {
    keyFields: ['contentId', 'contentType'],
    fields: {
      readingLists: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
};
