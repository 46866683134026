import { isArray } from '@kpler/generic-utils';
import { Market } from '@kpler/web-ui';

import { VesselClassificationRangeTypes } from 'src/platform-merge/analytics/VesselClassificationFilter/types';
import { MapView } from 'src/platform-merge/map/enums';
import {
  URLMapping,
  createTypedStringArraySerializer,
  stringArraySerializer,
  numberArraySerializer,
  createTypedSetSerializer,
  tupleOfNumberSerializer,
  createTypedStringSerializer,
  createNullableTypedStringSerializer,
} from 'src/services/convertRouteQuery';

import {
  CargoState,
  VesselStatus,
  Speed,
  YesNo,
  BetaStatus,
  InstallationTypeFilter,
  ZoneTypeFilter,
  LayerState,
} from 'types/graphql';
import { MapSearchCategory, ResourceType, SetContent } from 'types/legacy-globals';
import {
  InstallationTypeForRoute,
  LayerStateForRoute,
  MapArea,
  MapFilters,
  MapFiltersMapping,
  MapLayers,
  MapLayersMapping,
  MapSearch,
  MapSearchMapping,
  VesselStateForRoute,
  ZoneTypeForRoute,
} from 'types/map';

export const getDefaultSearch = (): MapSearch => ({
  categories: [MapSearchCategory.ALL],
  locations: [],
  vessels: [],
  players: [],
  products: [],
});

export const getDefaultMarketView = (accessibleMarkets: Set<Market>): MapView => {
  const hasAccessToMoreThanOneMarket = accessibleMarkets.size > 1;
  return hasAccessToMoreThanOneMarket ? MapView.MARKET : MapView.VESSEL_STATE;
};

export const getDefaultAccessibleMarkets = (
  accessibleMarkets = new Set<Market>(),
  defaultMarkets: Market[] = [],
): Set<Market> => {
  const accessibleDefaultMarkets = defaultMarkets.filter(market => accessibleMarkets.has(market));

  return new Set(accessibleDefaultMarkets);
};

export const getDefaultFilters = (
  accessibleMarkets = new Set<Market>(),
  defaultMarkets: Market[] = [],
  mapView: MapView | undefined = undefined,
): MapFilters => ({
  cargoStatus: new Set(),
  vesselStates: new Set(),
  markets:
    defaultMarkets.length > 0
      ? getDefaultAccessibleMarkets(accessibleMarkets, defaultMarkets)
      : accessibleMarkets,
  view: mapView || getDefaultMarketView(accessibleMarkets),
  vesselTypes: new Set(),
  vesselTypesOil: new Set(),
  vesselTypesCpp: new Set(),
  speed: new Set(),
  engine: new Set(),
  carrierType: new Set(),
  ethyleneCapable: new Set(),
  asphaltBitumenCapable: new Set(),
  betaVesselStatus: new Set(),
  capacity: null,
  vesselClassificationRangeType: null,
  buildYear: null,
  draught: null,
  installationTypes: new Set(),
  installationStatus: new Set(),
  betaInstallationStatus: new Set(),
  zoneTypes: new Set(),
  cargoTypes: new Set(),
});

export const getDefaultVesselFilters = (
  accessibleMarkets = new Set<Market>(),
  defaultMarkets: Market[] = [],
  mapView: MapView | undefined = undefined,
): Partial<MapFilters> => {
  const {
    markets,
    view,
    installationTypes,
    installationStatus,
    betaInstallationStatus,
    zoneTypes,
    ...vesselFilters
  } = getDefaultFilters(accessibleMarkets, defaultMarkets, mapView);

  return vesselFilters;
};

export const getDefaultInstallationFilters = (
  accessibleMarkets = new Set<Market>(),
  defaultMarkets: Market[] = [],
  mapView: MapView | undefined = undefined,
): Partial<MapFilters> => {
  const { installationTypes, installationStatus, betaInstallationStatus } = getDefaultFilters(
    accessibleMarkets,
    defaultMarkets,
    mapView,
  );

  return {
    installationTypes,
    installationStatus,
    betaInstallationStatus,
  };
};

export const getDefaultLayers = (): MapLayers => ({
  vesselLayer: LayerState.Default,
  installationLayer: LayerState.Default,
  pipelineLayer: LayerState.Hidden,
  zoneLayer: LayerState.Hidden,
});

const mapToTypedIds = (x: MapArea) => `${x.resourceType === ResourceType.ZONE ? 'z' : 'i'}${x.id}`;

export const getSerializableSearch = (search: MapSearch): MapSearchMapping => {
  let locationsToSpread;
  if (isArray(search.locations)) {
    locationsToSpread = {
      locations: search.locations.map(mapToTypedIds),
      loads: [],
      discharges: [],
    };
  } else {
    locationsToSpread = {
      locations: [],
      loads: search.locations.loads.map(mapToTypedIds),
      discharges: search.locations.discharges.map(mapToTypedIds),
    };
  }
  return {
    fields: search.categories,
    products: search.products.map(x => x.id),
    vessels: search.vessels.map(x => x.id),
    players: search.players.map(x => x.id),
    ...locationsToSpread,
  };
};

export const cargoStatusForRoute = (
  statuses: Set<CargoState>,
): Set<SetContent<MapFiltersMapping['cargoStatus']>> => {
  const mapping: { [key in CargoState]: SetContent<MapFiltersMapping['cargoStatus']> } = {
    [CargoState.Ballast]: 'ballast',
    [CargoState.Loaded]: 'loaded',
  };
  return new Set(Array.from(statuses, x => mapping[x]));
};

export const vesselStatesForRoute = (
  states: Set<VesselStatus>,
): Set<SetContent<MapFiltersMapping['vesselStates']>> => {
  const mapping: { [key in VesselStatus]: SetContent<MapFiltersMapping['vesselStates']> } = {
    [VesselStatus.Open]: VesselStateForRoute.OPEN,
    [VesselStatus.LaidUp]: VesselStateForRoute.LAID_UP,
    [VesselStatus.FloatingStorage]: VesselStateForRoute.FLOATING_STORAGE,
    [VesselStatus.UnderConstruction]: VesselStateForRoute.UNDER_CONSTRUCTION,
    [VesselStatus.Inactive]: VesselStateForRoute.INACTIVE,
    [VesselStatus.InService]: VesselStateForRoute.IN_SERVICE,
  };
  return new Set(Array.from(states, x => mapping[x]));
};

export const speedForRoute = (speed: Set<Speed>): Set<SetContent<MapFiltersMapping['speed']>> => {
  const mapping: { [key in Speed]: SetContent<MapFiltersMapping['speed']> } = {
    [Speed.Moving]: 'moving',
    [Speed.Stopped]: 'stopped',
  };
  return new Set(Array.from(speed, x => mapping[x]));
};

export const yesNoForRoute = (yesNo: Set<YesNo>): Set<'yes' | 'no'> => {
  const mapping: { [key in YesNo]: 'yes' | 'no' } = {
    [YesNo.Yes]: 'yes',
    [YesNo.No]: 'no',
  };
  return new Set(Array.from(yesNo, x => mapping[x]));
};

export const betaStatusForRoute = (statuses: Set<BetaStatus>): Set<'beta' | 'regular'> => {
  const mapping: { [key in BetaStatus]: 'beta' | 'regular' } = {
    [BetaStatus.Beta]: 'beta',
    [BetaStatus.Regular]: 'regular',
  };
  return new Set(Array.from(statuses, x => mapping[x]));
};
export const vesselClassificationRangeTypeForRoute = (
  vesselClassificationRangeTypes: string | null,
): VesselClassificationRangeTypes | null =>
  vesselClassificationRangeTypes === VesselClassificationRangeTypes.CAPACITY ||
  vesselClassificationRangeTypes === VesselClassificationRangeTypes.DEADWEIGHT
    ? vesselClassificationRangeTypes
    : null;
export const installationTypesForRoute = (
  types: Set<InstallationTypeFilter>,
): Set<InstallationTypeForRoute> => {
  const mapping: {
    [key in InstallationTypeFilter]: InstallationTypeForRoute;
  } = {
    [InstallationTypeFilter.Export]: 'export',
    [InstallationTypeFilter.Import]: 'import',
    [InstallationTypeFilter.Storage]: 'storage',
    [InstallationTypeFilter.Shipyard]: 'shipyard',
    [InstallationTypeFilter.Anchorage]: 'anchorage',
    [InstallationTypeFilter.Refinery]: 'refinery',
  };
  return new Set(Array.from(types, x => mapping[x]));
};

export const zoneTypesForRoute = (types: Set<ZoneTypeFilter>): Set<ZoneTypeForRoute> => {
  const mapping: { [key in ZoneTypeFilter]: ZoneTypeForRoute } = {
    [ZoneTypeFilter.Sea]: 'sea',
    [ZoneTypeFilter.Country]: 'country',
    [ZoneTypeFilter.Subregion]: 'subregion',
  };
  return new Set(Array.from(types, x => mapping[x]));
};

export const getSerializableFilters = (filters: MapFilters): MapFiltersMapping => ({
  cargoStatus: cargoStatusForRoute(filters.cargoStatus),
  vesselStates: vesselStatesForRoute(filters.vesselStates),
  markets: filters.markets,
  view: filters.view,
  vesselTypes: filters.vesselTypes,
  vesselTypesOil: filters.vesselTypesOil,
  vesselTypesCpp: filters.vesselTypesCpp,
  speed: speedForRoute(filters.speed),
  engine: filters.engine,
  carrierType: filters.carrierType,
  ethyleneCapable: yesNoForRoute(filters.ethyleneCapable),
  asphaltBitumenCapable: yesNoForRoute(filters.asphaltBitumenCapable),
  betaVesselStatus: betaStatusForRoute(filters.betaVesselStatus),
  capacity: filters.capacity,
  vesselClassificationRangeType: vesselClassificationRangeTypeForRoute(
    filters.vesselClassificationRangeType,
  ),
  buildYear: filters.buildYear,
  draught: filters.draught,
  installationTypes: installationTypesForRoute(filters.installationTypes),
  installationStatus: filters.installationStatus,
  betaInstallationStatus: betaStatusForRoute(filters.betaInstallationStatus),
  zoneTypes: zoneTypesForRoute(filters.zoneTypes),
  cargoTypes: filters.cargoTypes,
});

const layerStateForRoute = (layerState: LayerState): LayerStateForRoute => {
  const mapping: { [key in LayerState]: LayerStateForRoute } = {
    [LayerState.All]: 'all',
    [LayerState.Default]: 'default',
    [LayerState.Hidden]: 'hidden',
  };
  return mapping[layerState];
};

export const getSerializableLayers = (layers: MapLayers): MapLayersMapping => ({
  vesselLayer: layerStateForRoute(layers.vesselLayer),
  installationLayer: layerStateForRoute(layers.installationLayer),
  zoneLayer: layerStateForRoute(layers.zoneLayer),
  pipelineLayer: layerStateForRoute(layers.pipelineLayer),
});

export const layerMapping: URLMapping<MapLayersMapping> = {
  vesselLayer: createTypedStringSerializer<LayerStateForRoute>(),
  installationLayer: createTypedStringSerializer<LayerStateForRoute>(),
  zoneLayer: createTypedStringSerializer<LayerStateForRoute>(),
  pipelineLayer: createTypedStringSerializer<LayerStateForRoute>(),
};

export const searchMapping: URLMapping<MapSearchMapping> = {
  fields: createTypedStringArraySerializer<MapSearchCategory>(),
  locations: stringArraySerializer,
  loads: stringArraySerializer,
  discharges: stringArraySerializer,
  products: stringArraySerializer,
  vessels: numberArraySerializer,
  players: numberArraySerializer,
};

export const filterMapping: URLMapping<MapFiltersMapping> = {
  cargoStatus: createTypedSetSerializer<'loaded' | 'ballast'>(),
  vesselStates: createTypedSetSerializer<VesselStateForRoute>(),
  markets: createTypedSetSerializer<Market>(),
  view: createTypedStringSerializer<MapView>(),
  vesselTypes: createTypedSetSerializer<string>(),
  vesselTypesOil: createTypedSetSerializer<string>(),
  vesselTypesCpp: createTypedSetSerializer<string>(),
  speed: createTypedSetSerializer<'moving' | 'stopped'>(),
  engine: createTypedSetSerializer<string>(),
  carrierType: createTypedSetSerializer<string>(),
  ethyleneCapable: createTypedSetSerializer<'yes' | 'no'>(),
  asphaltBitumenCapable: createTypedSetSerializer<'yes' | 'no'>(),
  betaVesselStatus: createTypedSetSerializer<'beta' | 'regular'>(),
  capacity: tupleOfNumberSerializer,
  vesselClassificationRangeType:
    createNullableTypedStringSerializer<VesselClassificationRangeTypes>(),
  buildYear: tupleOfNumberSerializer,
  draught: tupleOfNumberSerializer,
  installationTypes: createTypedSetSerializer<
    'export' | 'import' | 'storage' | 'shipyard' | 'anchorage' | 'refinery'
  >(),
  installationStatus: createTypedSetSerializer<string>(),
  betaInstallationStatus: createTypedSetSerializer<'beta' | 'regular'>(),
  zoneTypes: createTypedSetSerializer<'sea' | 'country' | 'subregion'>(),
  cargoTypes: createTypedSetSerializer<string>(),
};
