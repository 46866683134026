import { RouteRecordRaw } from 'types/router-migration';

const AddTonnageList = () =>
  import(
    /* webpackChunkName: "tonnage-list-workspace" */ 'src/domains/freight/TheTonnageListWorkspace/AddTonnageList/AddTonnageList.vue'
  );

const DisplayTonnageList = () =>
  import(
    /* webpackChunkName: "tonnage-list-workspace" */ 'src/domains/freight/TheTonnageListWorkspace/DisplayTonnageList/DisplayTonnageList.vue'
  );

const routes: RouteRecordRaw[] = [
  {
    name: 'tonnage-list',
    path: '/workflows/tonnage-list',
    meta: { title: 'Tonnage List' },
    beforeEnter: async (to, from, next) => {
      // TODO eventually we'll dynamically choose which route to go based on API (WIP) response
      const shouldGoToList = true;
      if (shouldGoToList) {
        const listId = '123';
        next({ name: 'view-tonnage-list', params: { id: listId } });
      } else {
        next({ name: 'add-tonnage-list' });
      }
    },
  },
  {
    name: 'add-tonnage-list',
    path: '/workflows/tonnage-list/add',
    component: AddTonnageList,
    meta: { title: 'Add New Tonnage List' },
  },
  {
    name: 'view-tonnage-list',
    path: '/workflows/tonnage-list/:id',
    component: DisplayTonnageList,
  },
];

export default routes;
