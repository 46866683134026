// eslint-disable-next-line no-restricted-imports
import { UnexpectedValueError } from '../error';

export function assertNever(value: never): never {
  throw new UnexpectedValueError(value);
}

export function assertNeverRuntime(value: unknown): asserts value is never {
  throw new UnexpectedValueError(value);
}
