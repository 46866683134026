import axios, { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';

import { getAuthService } from 'src/app/providers/singletons/authService';
import { DecodedToken } from 'src/domains/insight/types';

import { getAuthHeaders, shouldUseRefreshToken } from 'src/services/apiAuthHeaders.service';
import { ARRAY_REPEAT_SERIALIZER } from 'src/services/axios.service';

let token: Promise<string> | null = null;

const insightServerURL = process.env.NX_PUBLIC_INSIGHT_API ?? '/insight-server';

const authInterceptor = async (config: AxiosRequestConfig) => {
  const accessToken = await getAuthService().getToken();
  if (shouldUseRefreshToken(accessToken)) {
    return config;
  }
  const authHeaders = await getAuthHeaders();
  /* eslint-disable no-param-reassign */
  config.headers = {
    ...config.headers,
    ...authHeaders,
  };
  /* eslint-enable no-param-reassign */
  return config;
};

const insightAuthAxiosInstance = axios.create({
  baseURL: process.env.NX_PUBLIC_INSIGHT_API ?? '/insight-server',
  headers: Object.freeze({ 'x-web-application-version': window.version }),
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

insightAuthAxiosInstance.interceptors.request.use(authInterceptor);

const getToken = async () => {
  if (token === null) {
    const { data } = await insightAuthAxiosInstance.post('/api/auth/local', {
      // TODO: Remove this header as soon as this ticket is done : https://kpler.atlassian.net/browse/PTFM-9554
      // We have to do this for now even if is not used by insight server
      kplerToken: `Bearer`,
    });
    token = Promise.resolve(data.jwt);
  }
  return token;
};

const deleteToken = () => {
  token = null;
};

const getPermissionsFromToken = async (): Promise<DecodedToken> => {
  const jwt = await getToken();
  return jwt_decode(jwt);
};

export default {
  getToken,
  deleteToken,
  insightServerURL,
  getPermissionsFromToken,
};
