<script lang="ts">
import { RouteLocationRaw } from '@kpler/web-ui';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseNavItem',
  props: {
    icon: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    trigger: {
      type: String as () => 'hover' | 'click',
      default: 'hover',
    },
    to: {
      type: Object as () => RouteLocationRaw,
      default: null,
    },
  },
  setup(props, ctx) {
    const onClick = (e: MouseEvent) => {
      if (props.trigger === 'click') {
        ctx.emit('click', e);
      }
    };

    return { onClick };
  },
});
</script>

<template>
  <component
    :is="to ? 'router-link' : 'a'"
    :to="to"
    :class="{ 'router-link-active': active }"
    class="nav-item relative"
    @click="onClick"
  >
    <i :class="`icon icon-${icon}`"></i>
    <div :class="{ 'hover-trigger': trigger === 'hover' }" class="submenu"><slot /></div>
  </component>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';
@use 'src/scss/theme';

.nav-item {
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: variables.$nav-item-size;
  height: variables.$nav-item-size;
  color: theme.$color-white;
  border-radius: variables.$border-radius;
  margin: variables.$nav-item-margin auto;
  cursor: pointer;
  i {
    font-size: variables.$icon-size-normal;
  }
  &:hover,
  &.hover {
    background-color: theme.$color-bg-primary;
  }
  &.router-link-active {
    color: theme.$color-white;
    background-color: theme.$color-primary;
  }
  .submenu.hover-trigger {
    display: none;
  }
  &:hover {
    .submenu.hover-trigger {
      display: block;
    }
  }
}
</style>
