import { RouteLocationNormalized } from '@kpler/web-ui';

import store from 'src/store';

import { platform } from 'src/helpers/platform.helper';
import { computeMapSearchTitle } from 'src/helpers/title.helper';

import { Platform } from 'types/legacy-globals';

const platformNames: { [key in Platform]: string } = {
  [Platform.COMMODITIES]: 'Terminal',
  [Platform.LNG]: 'LNG',
  [Platform.LPG]: 'LPG',
  [Platform.DRY]: 'Dry',
  [Platform.MERGE]: 'Terminal',
};

const getSearchTitle = (route: RouteLocationNormalized): string | undefined => {
  const parentIsMap = route.matched.some(r => r.name === 'map');
  if (parentIsMap) {
    return computeMapSearchTitle(...store.getters.mapTitleParams);
  }

  return undefined;
};

const getPageTitle = (route: RouteLocationNormalized): string | undefined => {
  const nearestWithTitle = route.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  return nearestWithTitle?.meta.title;
};

export default {
  set(fragment: string) {
    document.title = `${fragment} | Kpler ${platformNames[platform]}`;
  },
  updateFromRoute(to: RouteLocationNormalized) {
    const fullTitle = [getSearchTitle(to), getPageTitle(to)]
      .filter((x): x is string => x !== undefined && x.length > 0)
      .join(' | ');
    this.set(fullTitle);
  },
};
