import { GrowthBook } from '@growthbook/growthbook';
import { isDefined } from '@kpler/generic-utils';

import { User } from 'types/user';

const GROWTHBOOK_CLIENT_KEY = process.env.NX_PUBLIC_GROWTHBOOK_CLIENT_KEY;

export class FeatureFlagService {
  static readonly INJECT_KEY = 'featureFlagService';
  static #singleton: FeatureFlagService;

  #gbClient?: GrowthBook;
  #isInitizaled = false;

  static create() {
    if (!isDefined(FeatureFlagService.#singleton)) {
      FeatureFlagService.#singleton = new FeatureFlagService();
    }
    return FeatureFlagService.#singleton;
  }

  private constructor() {
    if (!GROWTHBOOK_CLIENT_KEY) {
      console.error('Failed to initialize feature flag service. Missing GrowthBook client key');
      return;
    }

    try {
      this.#gbClient = new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: GROWTHBOOK_CLIENT_KEY,
      });
    } catch {
      console.error('Failed to initialize feature flag service');
    }
  }

  get injectKey() {
    return FeatureFlagService.INJECT_KEY;
  }

  public async initialize(user: User): Promise<void> {
    if (this.#gbClient) {
      try {
        const { id, email } = user;
        await this.#gbClient.setAttributes({
          id,
          email,
        });
        await this.#gbClient.init({
          streaming: true,
        });
      } catch {
        console.error('Failed to initialize feature flag service');
      }
      this.#isInitizaled = true;
    }
  }

  public isOn(featureKey: string): boolean {
    try {
      return this.#gbClient?.isOn(featureKey) ?? false;
    } catch {
      return false;
    }
  }

  public getValue(featureKey: string, defaultValue?: string) {
    try {
      return this.#gbClient?.getFeatureValue(featureKey, defaultValue);
    } catch {
      return defaultValue;
    }
  }

  public isInitialized(): boolean {
    return this.#isInitizaled;
  }
}
