import { getAuthService } from 'src/app/providers/singletons/authService';

export type AuthHeadersOptions = {
  includeUseAccessTokenHeader: boolean;
};

export const getAuthHeaders = async (
  options: AuthHeadersOptions = { includeUseAccessTokenHeader: true },
): Promise<Record<string, string>> => {
  const authService = getAuthService();

  const accessToken = await authService.getToken();

  // This is a temporary workaround. We should always use access tokens in the future
  if (shouldUseRefreshToken(accessToken)) {
    const refreshToken = authService.getRefreshToken();
    if (refreshToken) {
      return {
        authorization: `Bearer ${refreshToken}`,
        'x-use-refresh-token-legacy': 'true',
      };
    }
  }

  const baseAccessHeaders = {
    'X-Access-Token': accessToken,
  };
  if (!options.includeUseAccessTokenHeader) {
    return baseAccessHeaders;
  }
  return {
    'use-access-token': 'true',
    ...baseAccessHeaders,
  };
};

export function shouldUseRefreshToken(accessToken: string) {
  // Maximum allowed size header is 16Kb, we add 1Kb margin just in case
  const MAX_ALLOWED_ACCESS_TOKEN_SIZE_KB = 15;
  return accessToken.length >= MAX_ALLOWED_ACCESS_TOKEN_SIZE_KB * 1024;
}
