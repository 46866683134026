export class CookiesService {
  static readonly #DEFAULT_COOKIE_EXPIRATION_DAYS = 7;

  static get(name: string): string | undefined {
    const rawCookieItems: string[] = document.cookie.split(';').map(str => str.trim());
    const rawCookieItem: string | undefined = rawCookieItems.find(cookieItem =>
      cookieItem.startsWith(`${encodeURI(name)}=`),
    );
    return rawCookieItem?.substring(name.length + 1);
  }

  static set(
    name: string,
    value: string,
    days = CookiesService.#DEFAULT_COOKIE_EXPIRATION_DAYS,
  ): void {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;

    document.cookie = `${encodeURI(name)}=${encodeURIComponent(value)};${expires};path=/`;
  }

  static delete(name: string): void {
    CookiesService.set(name, '', -1);
  }

  /**
   * @deprecated use `CookiesService.get` instead
   */
  static getCookie(name: string): string | undefined {
    return CookiesService.get(name);
  }
}

/** @deprecated use the named import instead */
export default CookiesService;
