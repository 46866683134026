<script setup lang="ts">
import { NavigationItem } from '@kpler/web-ui';

import { useInjectStore } from 'src/store/useInjectStore';

import { useNotificationsPermissions } from './permissions';

const store = useInjectStore();
const { hasNotificationPocPermission } = useNotificationsPermissions(store);

type Props = {
  active: boolean;
};

const props = defineProps<Props>();
</script>

<template>
  <NavigationItem
    v-if="hasNotificationPocPermission"
    label="Notifications wip"
    icon-name="alert-solid"
    :active="props.active"
    :to="{ name: 'notifications' }"
  />
</template>
