import { AppRoutes } from 'src/app/routes';
import { AppStore } from 'src/store/types';

import NotificationWorkspace from './NotificationWorkspace.vue';
import { useNotificationsPermissions } from './permissions';

import { createAuthorizationHook } from 'src/helpers/router.helper';

import { RouteRecordRaw } from 'types/router-migration';

export const notificationRoutes = (storeGetter: () => AppStore): RouteRecordRaw => ({
  name: 'notifications',
  path: 'notifications',
  component: NotificationWorkspace,
  beforeEnter: createAuthorizationHook(
    () => useNotificationsPermissions(storeGetter()).hasNotificationPocPermission.value,
    AppRoutes.FORBIDDEN,
  ),
  meta: { title: 'Notifications' },
});
