import { isEmpty } from '@kpler/generic-utils';
import {
  DateRange,
  DateRangePreset,
  PastFutureDateRangePreset,
  deserializeDate,
  serializeDate,
  RangeNumber,
} from '@kpler/terminal-utils';
import { RouteLocationNormalized } from '@kpler/web-ui';
import isEqual from 'lodash.isequal';
import { Moment } from 'moment';

import {
  deserializeContractObjects,
  serializeContractObjects,
} from 'src/main/analytics/prices/prices.helper';

import { PricesContractFilterParamsInput } from 'types/graphql';
import { PricesBaseFiltersMapping, PricesSidebarFilterState } from 'types/prices';

export type Serializer<T> = {
  serialize: (x: T) => string;
  deserialize: (x: string) => T;
};

export type URLMapping<T> = {
  [U in keyof T]: Serializer<T[U]>;
};

export const addDefaultValueToParams = <T, K extends keyof T>(obj: T, defaults: T, key: K) => {
  try {
    if (obj[key] === undefined) {
      return {
        ...obj,
        [key]: defaults[key],
      };
    }
  } catch {
    console.warn(
      `Failed to apply default for ${String(key)}: ${obj[key]}. Default is ${defaults[key]}`,
    );
  }
  return obj;
};

export const formatToQueryParams = <T, K extends keyof T>(
  obj: T,
  defaults: T,
  mapping: URLMapping<T>,
): { [key in K]: string | undefined } => {
  const mapped = (Object.keys(mapping) as K[]).map(key => {
    try {
      const serialized = mapping[key].serialize(obj[key]);
      const defaultSerialized = mapping[key].serialize(defaults[key]);
      return [key, serialized === defaultSerialized ? undefined : serialized];
    } catch {
      throw new Error(`Invalid value for ${String(key)}: ${obj[key]}. Default is ${defaults[key]}`);
    }
  });

  return Object.fromEntries(mapped) as { [key in K]: string | undefined };
};

export const readFromQueryParams = <T, K extends keyof T>(
  defaults: T,
  mapping: URLMapping<T>,
  queryObj?: { [key in keyof Partial<T>]: string },
): T => {
  const obj = { ...defaults };
  if (!queryObj) {
    return obj;
  }
  (Object.keys(mapping) as K[]).forEach(key => {
    if (queryObj[key] !== undefined) {
      obj[key] = mapping[key].deserialize(queryObj[key]);
    }
  });
  return obj;
};

export const areParamsEqual = <T>(
  mapping: URLMapping<T>,
  params: { [key in keyof T]: string | undefined },
  route: RouteLocationNormalized,
) => {
  const keys = new Set(Object.keys(mapping));
  const definedParams = Object.fromEntries(
    Object.entries(params)
      .filter(([key]) => keys.has(key))
      .filter((tuple): tuple is [string, string] => tuple[1] !== undefined),
  );
  const definedFromRoute = Object.fromEntries(
    Object.entries(route.query)
      .filter(([key]) => keys.has(key))
      .filter(([, value]) => value !== undefined),
  );

  return isEqual(definedParams, definedFromRoute);
};

export const booleanSerializer: Serializer<boolean> = {
  serialize: x => x.toString(),
  deserialize: x => x === 'true',
};

export const optionalBooleanSerializer: Serializer<boolean> = {
  serialize: x => (x === undefined ? 'false' : x.toString()),
  deserialize: x => x === 'true',
};

export const stringSerializer: Serializer<string> = {
  serialize: x => x,
  deserialize: x => x,
};

export const optionalStringSerializer: Serializer<string | undefined> = {
  serialize: x => (x === undefined ? '' : x),
  deserialize: x => (x === '' ? undefined : x),
};

export const nullableStringSerializer: Serializer<string | null> = {
  serialize: x => (x === null ? '' : x),
  deserialize: x => (x === '' ? null : x),
};

export const nullableNumberSerializer: Serializer<number | null> = {
  serialize: x => (x === null ? '' : x.toString()),
  deserialize: x => (x === '' ? null : Number(x)),
};

export const numberSerializer: Serializer<number> = {
  serialize: x => x.toString(),
  deserialize: x => Number(x),
};

export const stringArraySerializer: Serializer<readonly string[]> = {
  serialize: x => x.join(','),
  deserialize: x => x.split(','),
};

export const nullableStringArraySerializer: Serializer<readonly string[] | null> = {
  serialize: x => (x === null ? '' : x.join(',')),
  deserialize: x => (x === '' ? [] : x.split(',')),
};

export const numberArraySerializer: Serializer<readonly number[]> = {
  serialize: x => x.join(','),
  deserialize: x => Object.freeze(x.split(',').map(y => Number(y))),
};

export const nullableNumberArraySerializer: Serializer<readonly number[] | null> = {
  serialize: x => (x === null ? '' : x.join(',')),
  deserialize: x => (x === '' ? [] : Object.freeze(x.split(',').map(y => Number(y)))),
};

export const createNullableTypedStringSerializer = <T extends string>(): Serializer<T | null> => ({
  serialize: x => (x === null ? '' : x.toString()),
  deserialize: x => (x === '' ? null : (x as T)),
});

export const createTypedStringSerializer = <T extends string>(): Serializer<T> => ({
  serialize: x => x,
  deserialize: x => x as T,
});

export const createTypedStringArraySerializer = <T extends string>(): Serializer<readonly T[]> => ({
  serialize: x => x.join(','),
  deserialize: x => x.split(',') as T[],
});

export const createNullableTypedStringArraySerializer = <T extends string>(): Serializer<
  readonly T[] | null
> => ({
  serialize: x => (x === null ? '' : x.join(',')),
  deserialize: x => (x === '' ? null : (x.split(',') as T[])),
});

export const tupleOfNumberSerializer: Serializer<RangeNumber | null> = {
  serialize: tuple => {
    if (tuple === null) {
      return '';
    }

    return tuple.map(x => x.toString()).join(',');
  },
  deserialize: params => {
    if (params === '') {
      return null;
    }
    return params.split(',').map(x => Number(x)) as unknown as RangeNumber;
  },
};

export const tupleOfStringSerializer: Serializer<[string, string] | null> = {
  serialize: tuple => {
    if (tuple === null) {
      return '';
    }

    return tuple.join(',');
  },
  deserialize: params => {
    if (params === '') {
      return null;
    }
    return params.split(',') as [string, string];
  },
};

export const createTypedSetSerializer = <T extends string>(): Serializer<Set<T>> => ({
  serialize: (set: Set<T>) => [...set].join(','),
  deserialize: (serializedParam: string) => {
    if (isEmpty(serializedParam)) {
      return new Set<T>();
    }

    const paramAsArray = serializedParam.split(',');
    return new Set(paramAsArray) as Set<T>;
  },
});

export const dateSerializer: Serializer<Moment> = {
  serialize: x => serializeDate(x),
  deserialize: x => deserializeDate(x),
};

export const nullableDateSerializer: Serializer<Moment | null> = {
  serialize: x => (x === null ? '' : serializeDate(x)),
  deserialize: x => (x === '' ? null : deserializeDate(x)),
};

export const optionalDateSerializer: Serializer<Moment | undefined> = {
  serialize: x => (x === undefined ? '' : serializeDate(x)),
  deserialize: x => (x === '' ? undefined : deserializeDate(x)),
};

function assertDateRangePreset(value: string): asserts value is DateRangePreset {
  if (!Object.values(DateRangePreset).includes(value as DateRangePreset)) {
    throw new Error(`${value} isn't a valid date range preset.`);
  }
}

export const dateRangeSerializer: Serializer<DateRangePreset | DateRange> = {
  serialize: (value: DateRange | DateRangePreset) => {
    if (typeof value === 'string') {
      return value;
    }
    return `${value.startDate},${value.endDate}`;
  },
  deserialize: (value: string) => {
    const splits = value.split(',');
    if (splits.length === 2) {
      return {
        startDate: splits[0],
        endDate: splits[1],
      };
    }
    assertDateRangePreset(value);
    return value;
  },
};

function assertPastFutureDateRangePreset(
  value: string,
): asserts value is PastFutureDateRangePreset {
  if (!Object.values(PastFutureDateRangePreset).includes(value as PastFutureDateRangePreset)) {
    throw new Error(`${value} isn't a valid fixture date range preset.`);
  }
}

export const pastFutureDateRangeSerializer: Serializer<PastFutureDateRangePreset | DateRange> = {
  serialize: (value: DateRange | PastFutureDateRangePreset) => {
    if (typeof value === 'string') {
      return value;
    }
    return `${value.startDate},${value.endDate}`;
  },
  deserialize: (value: string) => {
    const splits = value.split(',');
    if (splits.length === 2) {
      return {
        startDate: splits[0],
        endDate: splits[1],
      };
    }
    assertPastFutureDateRangePreset(value);
    return value;
  },
};

export const pricesContractObjectSerializer: Serializer<
  readonly PricesContractFilterParamsInput[]
> = {
  serialize: pricesContractFilters => serializeContractObjects(pricesContractFilters),
  deserialize: queryString => deserializeContractObjects(queryString),
};

// @TODO: Need to check why app is broken when mappingPricesFilters and defaultPricesBaseFilters move to other files
export const mappingPricesFilters: URLMapping<PricesBaseFiltersMapping> = {
  contracts: pricesContractObjectSerializer,
};

export const defaultPricesBaseFilters: PricesSidebarFilterState = {
  contracts: [],
};

export const pricesFiltersSerializer: Serializer<PricesBaseFiltersMapping> = {
  serialize: filters => {
    // @TODO need to check why this can be undefined
    if (filters === undefined) {
      return '';
    }
    const params = formatToQueryParams(filters, defaultPricesBaseFilters, mappingPricesFilters);
    const changedQueryParams: string[] = [];

    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        changedQueryParams.push(`${key}=${value}`);
      }
    });

    return changedQueryParams.join('&');
  },
  deserialize: (value: string) => {
    if (value === undefined) {
      return defaultPricesBaseFilters;
    }

    const urlParams = new URLSearchParams(value);
    const params = {
      contracts: urlParams.get('contracts') || '',
    };

    return readFromQueryParams(defaultPricesBaseFilters, mappingPricesFilters, params);
  },
};
