import { RouteRecordRaw } from '@kpler/web-ui';

import store from 'src/store';

import { OilInventoriesRouteName } from './enums';

const Workspace = () =>
  store.getters.userHasOnePermission(['crude_oil_inventory:read', 'drone:read', 'drone:read:trial'])
    ? import(/* webpackChunkName: "oil-inventories" */ './WorkspaceOilInventories.vue')
    : import(/* webpackChunkName: "oil-inventories" */ './Workspace.vue');

const InventoriesTab = () =>
  store.getters.userHasPermission('crude_oil_inventory:read')
    ? import(/* webpackChunkName: "oil-inventories" */ './InventoriesTab/InventoriesTab.vue')
    : import(
        /* webpackChunkName: "oil-inventories" */ './InventoriesTab/InventoriesTabPreview.vue'
      );

const TheInventoriesRightPanel = () =>
  import(
    /* webpackChunkName: "oil-inventories" */ 'src/main/analytics/inventories/TheInventoriesRightPanel.vue'
  );

const TheCushingDroneInventories = () =>
  store.getters.userHasOnePermission(['drone:read', 'drone:read:trial'])
    ? import(
        /* webpackChunkName: "oil-inventories" */ 'src/main/dashboard/cushingDroneInventories/TheCushingDroneInventories.vue'
      )
    : import(
        /* webpackChunkName: "oil-inventories" */ './CushingDronesTab/CushingDronesTabPreview.vue'
      );

export const routes: RouteRecordRaw = {
  name: OilInventoriesRouteName.OIL_INVENTORIES,
  path: 'oil-inventories',
  component: Workspace,
  redirect: { name: OilInventoriesRouteName.OIL_INVENTORIES_OVERVIEW },
  meta: { title: 'Oil inventories' },
  children: [
    {
      name: OilInventoriesRouteName.OIL_INVENTORIES_OVERVIEW,
      path: 'overview',
      component: InventoriesTab,
      meta: { title: 'Oil inventories overview' },
      children: [
        {
          name: OilInventoriesRouteName.OIL_INVENTORIES_OVERVIEW_DETAILS,
          path: 'details/:date',
          component: TheInventoriesRightPanel,
        },
      ],
    },
    {
      name: OilInventoriesRouteName.OIL_INVENTORIES_DRONES,
      path: 'cushing-drones',
      component: TheCushingDroneInventories,
      meta: { title: 'Cushing drone inventories' },
    },
  ],
};
