import {
  DateRange,
  DateRangePreset,
  Granularity,
  SerializedDateRange,
} from '@kpler/terminal-utils';
import { MultiPolygon } from 'geojson';

import { TimeSeries } from './series';

import { AnalyticsView } from 'types/analytics';
import { ChartClickEvent } from 'types/chart';
import { InventoriesZoneFragment, SlotsQuery } from 'types/graphql';
import { InstallationDetailsPayload, InstallationWithResourceType } from 'types/installation';
import { ResourceType, Platform, RequestPayload } from 'types/legacy-globals';
import {
  PricesBaseFiltersMapping,
  PricesSidebarFilterState,
  PricesValueObject,
} from 'types/prices';
import { QuantityObject } from 'types/quantity';
import { BaseSearchResult } from 'types/staticData';
import { Trade } from 'types/trade';
import { TemporalUnitName, UnitName, UnmanagedUnitName } from 'types/unit';
import { ZoneType, ZoneWithCountry } from 'types/zone';

export enum InventoriesSplit {
  TOTAL = 'total',
  INSTALLATION = 'byInstallation',
  TANK_TYPE = 'byTankType',
  COUNTRY = 'byCountry',
  PLAYER = 'byPlayer',
  STATUS = 'byOnshoreOffshoreStatus',
  TANK_STATUS = 'byTankStatus',
}

export enum InventoriesSplitStatusType {
  ONSHORE = '3',
  FLOATING_STORAGE = '4',
  OIL_ON_SHIP = '5',
}

export enum InventoriesSnapshotSplit {
  COUNTRY = 'byCountry',
  INSTALLATION = 'byInstallation',
  TANK = 'byTank',
}

export enum TankRoofType {
  FLOATING = 'floating',
  UNDERGROUND = 'underground',
  FIXED = 'fixed',
}

export enum TankStatusType {
  OPERATIONAL = 'operational',
  MAINTENANCE = 'maintenance',
}

type InventoriesBaseInstallation = {
  id: number;
  name: string;
  resourceType: ResourceType.INSTALLATION;
  type: string;
};

export type InventoriesZone = InventoriesZoneFragment & { resourceType: ResourceType.ZONE };

export type InventoriesInstallationMap = InventoriesBaseInstallation & {
  zone: ZoneWithCountry;
};

export type InventoriesInstallationSearch = BaseSearchResult & {
  isInUnitedStates: boolean;
  resourceType: ResourceType.INSTALLATION;
};

export type InventoriesZoneSearchResult = BaseSearchResult & {
  type: ZoneType;
  isInUnitedStates: boolean | null;
  resourceType: ResourceType.ZONE;
};

export type InventoriesInstallation =
  | InventoriesInstallationMap
  | InventoriesInstallationSearch
  | InstallationDetailsPayload;

export type InventoriesArea = InstallationWithResourceType | InventoriesZone;

export type InventoriesStateHydrated = {
  areas: ReadonlyArray<InstallationWithResourceType | InventoriesZone>;
  split: InventoriesSplit;
  cargoTrackingEnhancement: boolean;
  capacityUtilization: boolean;
  fleetMetricsEnhancement: boolean;
  floatingFrom: number | null;
  droneData: boolean;
  droneDataAtmEnhancement: boolean;
  addEiaDatasets: boolean;
  deltaLevel: boolean;
  pricesFilters: PricesSidebarFilterState;
  granularity: Granularity;
  seasonal: boolean;
  dateRange: DateRangePreset | DateRange;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
};

export type InventoriesParams = SerializedDateRange & {
  granularity: Granularity;
  locationIds: readonly number[];
  locationResourceType: ResourceType.ZONE | ResourceType.INSTALLATION;
  splitCriteria: InventoriesSplit;
  cumulative: false;
  fillMissing: true;
  cargoTrackingEnhancement: boolean;
  fleetMetricsEnhancement: boolean;
  numberOfSplits?: number;
  floatingStorageDuration?: {
    min: number;
    max?: number;
  };
  /** @deprecated must be replaced by `market: InventoriesMarket` */
  platform: Platform;
  droneData: boolean;
  droneDataAtmEnhancement: boolean;
  addEiaDatasets?: boolean;
  deltaLevel?: boolean;
  selection?: {
    splitValuesMetadata: readonly string[];
    datasetsSplitValues: readonly InventoriesDatasetName[];
  };
};

export type InventoriesState = {
  zones: readonly number[];
  installations: readonly number[];
  granularity: Granularity;
  seasonal: boolean;
  split: InventoriesSplit;
  cargoTrackingEnhancement: boolean;
  capacityUtilization: boolean;
  fleetMetricsEnhancement: boolean;
  floatingFrom: number | null;
  dates: DateRangePreset | DateRange;
  droneData: boolean;
  droneDataAtmEnhancement: boolean;
  addEiaDatasets: boolean;
  deltaLevel: boolean;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
  pricesFilters: PricesBaseFiltersMapping;
};

export type CushingDroneInventoriesStateHydrated = {
  areas: InventoriesArea[];
  granularity: Granularity;
  dateRange: DateRangePreset | DateRange;
};

export type CushingDroneInventoriesState = {
  installations: readonly number[];
  granularity: Granularity;
  dates: DateRangePreset | DateRange;
};
export type LabelIndex = {
  id: string;
  label: string;
  index: number;
  unitName?: UnitName | UnmanagedUnitName;
};

export type InventoriesTooltipProps = {
  unitName: UnitName | UnmanagedUnitName;
  granularity: Granularity;
  datasetName: InventoriesDatasetName;
  cargoTrackingEnhancement: boolean;
  additionalDatasets: LabelIndex[];
  droneData: boolean;
};

export type InventoriesSecondaryTooltipProps = {
  unitName: UnitName | TemporalUnitName;
  granularity: Granularity;
};

export type CushingPlayerChangesSplitValues = {
  date: string;
  playerValues: Array<{ name: string; value: number | null; id: string }>;
};

export type CushingPlayerChangesPlayers = {
  title: string | undefined;
  values: Array<string | null>;
  variation: Array<string | null>;
};

export type CushingSeasonalValues = {
  date: string;
  value: number | null;
};

export type CushingDroneInventoriesTooltipProps = {
  unitName: UnitName | UnmanagedUnitName;
  granularity: Granularity;
  additionalDatasets: LabelIndex[];
};

export type CushingInventoriesData = {
  series: ReadonlyArray<TimeSeries<TimeSeriesInventories, InventoriesDatasetName>>;
  filters: CushingDroneInventoriesState;
  prices: ReadonlyArray<TimeSeries<PricesValueObject, string>> | null;
};

export type CushingInventoriesResponse = RequestPayload<CushingInventoriesData>;

type CushingPlayerChangesRowTitle = {
  title: string;
};
type CushingPlayerChangesRowValues = {
  [key: string]: number;
};

export type CushingPlayerChangesRow = CushingPlayerChangesRowTitle & CushingPlayerChangesRowValues;

export enum InventoriesProviderName {
  SENTINEL = 'Sentinel',
  AGGREGATION_ALGO = 'AggregationAlgo',
  ANALYST = 'Analyst',
  JODI = 'JODI',
  EIA = 'EIA',
  ALASKA_DEPARTMENT_OF_REVENUE = 'Alaska Department of Revenue',
  SEOUL_LINE = 'Seoul Line',
}

export enum InventoriesDatasetName {
  LEVEL = 'level',
  ONSHORE_OUTFLOW = 'demand',
  ONSHORE_INFLOW = 'supply',
  PORT_CALLS_NET = 'portcallsNet',
  CAPACITY = 'capacity',
  CRUDE_CAPACITY = 'crudeCapacity',
  REVISIT_RATE = 'revisitRate',
  HEEL = 'heel',
  HEEL_CAPACITY = 'heelCapacity', // added on frontend
  CARGO = 'cargo', // added on frontend
  IMAGE = 'image', // added on frontend
  DELTA_LEVEL = 'deltaLevel',
  UTILIZATION = 'utilization', // added on frontend for non-drone inventories
  EIA_LEVEL = 'eiaLevel',
  EIA_DELTA_LEVEL = 'eiaDeltaLevel',
  EIA_CAPACITY = 'eiaCapacity',
}

export enum InventoriesCargoSplitValues {
  IMPORT = 'import',
  EXPORT = 'export',
  ONSHORE_OUTFLOW = 'demand',
  ONSHORE_INFLOW = 'supply',
}

export type TimeSeriesInventories = QuantityObject & {
  providerName: InventoriesProviderName;
  energy: number;
};

export type InventoriesSnapshotDetailCountry = {
  id: number;
  name: string;
  volume: number;
  deltaVolume?: number;
  revisitRate?: number;
  capacity: number;
};

export type InventoriesSnapshotDetailInstallationBase = {
  id: number;
  name: string;
  volume?: number;
  deltaVolume?: number;
  capacity: number;
  lastImageDate: string;
  lastLastImageDate: string; // @TODO rename to penultimateSignalDate
};

export type InventoriesSnapshotDetailInstallationWithCargoTrackingEnhancementExtraProperties =
  InventoriesSnapshotDetailInstallationBase & {
    onshoreInflow: number;
    onshoreOutflow: number;
  };

export type InventoriesSnapshotDetailInstallation =
  | InventoriesSnapshotDetailInstallationBase
  | InventoriesSnapshotDetailInstallationWithCargoTrackingEnhancementExtraProperties;

export type InventoriesSnapshotDetailTankBase = {
  id: number;
  name: string;
  volume?: number;
  deltaVolume?: number;
  capacity: number;
  crudeCapacity: number;
  roofType: TankRoofType;
};

export enum TankType {
  OIL = 'oil',
  PRODUCTS = 'products',
}

export type InventoriesSnapshotDetailTankOil = InventoriesSnapshotDetailTankBase & {
  tankType: TankType.OIL;
  lastImageDate?: string;
};
export type InventoriesSnapshotDetailTankProducts = InventoriesSnapshotDetailTankBase & {
  tankType: TankType.PRODUCTS;
};
export type InventoriesSnapshotDetailTank =
  | InventoriesSnapshotDetailTankOil
  | InventoriesSnapshotDetailTankProducts;

type InventoriesSnapshotBase = {
  granularity: Granularity;
  endDate: string;
  volume: number;
  deltaVolume?: number;
  revisitRate?: number;
  capacity: number;
};

export type InventoriesSnapshotCountry = InventoriesSnapshotBase & {
  splitBy: InventoriesSnapshotSplit.COUNTRY;
  details?: InventoriesSnapshotDetailCountry[];
};

export type InventoriesSnapshotInstallation = InventoriesSnapshotBase & {
  splitBy: InventoriesSnapshotSplit.INSTALLATION;
  details?: InventoriesSnapshotDetailInstallation[];
};

export type InventoriesSnapshotTank = InventoriesSnapshotBase & {
  splitBy: InventoriesSnapshotSplit.TANK;
  details?: InventoriesSnapshotDetailTank[];
};

export type InventoriesSnapshot =
  | InventoriesSnapshotCountry
  | InventoriesSnapshotInstallation
  | InventoriesSnapshotTank;

export type InventoriesSnapshotParams = {
  cargoTrackingEnhancement: boolean;
  endDate: string;
  granularity: Granularity;
  locationIds: number[];
  locationResourceType: ResourceType.ZONE | ResourceType.INSTALLATION;
  splitCriteria: InventoriesSnapshotSplit;
  filters: Array<{
    splitType: InventoriesSplit;
    splitValue: string;
  }>;
  notFilters?: Array<{
    splitType: InventoriesSplit;
    splitValue: string;
  }>;
  /** @deprecated must be replaced by `market: InventoriesMarket` */
  platform: Platform;
  droneData: boolean;
};

export type InventoriesChartClickEvent = ChartClickEvent & {
  tab: InventoriesRightPanelTabs;
};

export enum InventoriesRightPanelTabs {
  TRADES = 'trades',
  INVENTORIES = 'inventories',
  SLOTS = 'slots',
  VESSELS = 'vessels',
}

export type TankRSPGroups = {
  [key: string]: InventoriesSnapshotDetailTank[];
};

enum OtherTab {
  UNKNOWN = 'unknown',
}

export enum TankGroupNonDateCategory {
  UNDERGROUND = TankRoofType.UNDERGROUND,
  FIXED = TankRoofType.FIXED,
  PRODUCTS = TankType.PRODUCTS,
  UNKNOWN = OtherTab.UNKNOWN,
}

export enum TankFunction {
  COMMERCIAL = 'commercial',
  REFINERY = 'refinery',
  SPR = 'spr',
}

export type TankDetectionParams = {
  polygons: MultiPolygon[];
  includePreviouslyMappedTanks: boolean;
};

export type SlotGQL = SlotsQuery['slots'][number];

export type LNGActivity =
  | {
      type: 'trade';
      date: string;
      trade: Trade;
    }
  | {
      type: 'slot';
      date: string;
      slot: SlotGQL;
    };
