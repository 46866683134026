export enum OilInventoriesTabLabels {
  OVERVIEW = 'Overview',
  DRONES = 'Cushing drones',
}

export enum OilInventoriesRouteName {
  OIL_INVENTORIES = 'oil-inventories',
  OIL_INVENTORIES_OVERVIEW = 'oil-inventories-overview',
  OIL_INVENTORIES_OVERVIEW_DETAILS = 'oil-inventories-overview-details',
  OIL_INVENTORIES_DRONES = 'oil-inventories-drones',
}
