import {
  DateRange,
  DateRangePreset,
  Granularity,
  SerializedDateRange,
  RangeNumber,
} from '@kpler/terminal-utils';

import { ProductFragment } from './graphql';

import type { ObjectBase } from '@kpler/terminal-utils';
import { AnalyticsView } from 'types/analytics';
import { Installation } from 'types/installation';
import {
  CapacityObject,
  DeadWeightObject,
  FlowQuantity,
  ResourceType,
  PaginationParams,
  VesselTypeClassificationSplit,
  VesselTypeClassification,
} from 'types/legacy-globals';
import { PricesBaseFiltersMapping, PricesSidebarFilterState } from 'types/prices';
import { UnitName, UnmanagedUnitName } from 'types/unit';
import { Vessel } from 'types/vessel';
import { ZoneType } from 'types/zone';

export enum CongestionSplit {
  COUNTRY = 'country',
  INSTALLATION = 'installation',
  PORT = 'port',
  TOTAL = 'total',
  VESSEL_OPERATION = 'vesseloperation',
  VESSEL_TYPE = 'vesseltype',
  WAITING_STATUS = 'waitingstatus',
  PRODUCT = 'product',
  GRADE = 'grade',
  ORIGIN_PORT = 'originPort',
  ORIGIN_COUNTRY = 'originCountry',
  ENGINE_TYPE = 'engineType',
}

export enum CongestionMetric {
  COUNT = 'count',
  DURATION = 'duration',
  DEADWEIGHT = 'deadweight',
  CAPACITY = 'capacity',
}

export type WaitingDurationObject = {
  gte: number | null;
  lte: number | null;
};

type CongestionZone = ObjectBase<number> & {
  resourceType: ResourceType.ZONE;
  type: ZoneType;
};

export type CongestionStateHydrated = {
  zones: readonly CongestionZone[];
  originZones: readonly CongestionZone[];
  split: CongestionSplit;
  metric: CongestionMetric;
  products: readonly ProductFragment[];
  vessels: ReadonlyArray<ObjectBase<number>>;
  vesselClassifications: readonly string[];
  engineTypes: readonly string[];
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  allVesselsInWaitingZone: boolean;
  vesselOperation: readonly CongestionVesselOperation[];
  waitingDuration: [string, string] | null;
  pricesFilters: PricesSidebarFilterState;
  dateRange: DateRangePreset | DateRange;
  granularity: Granularity;
  seasonal: boolean;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
};

export type CongestionState = {
  zones: readonly number[];
  originZones: readonly number[];
  granularity: Granularity;
  seasonal: boolean;
  split: CongestionSplit;
  metric: CongestionMetric;
  products: readonly string[];
  vesselClassifications: readonly string[];
  engineTypes: readonly string[] | null;
  vessels: readonly number[];
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  allVesselsInWaitingZone: boolean;
  vesselOperation: readonly CongestionVesselOperation[];
  waitingDuration: [string, string] | null;
  dates: DateRangePreset | DateRange;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
  pricesFilters: PricesBaseFiltersMapping;
};

export type LocationResource = {
  id: number;
  resourceType: ResourceType.ZONE | ResourceType.INSTALLATION;
};

type CongestionParamsSplit =
  | Exclude<CongestionSplit, CongestionSplit.TOTAL>
  | VesselTypeClassificationSplit;

export type CongestionParams = SerializedDateRange & {
  granularity: Granularity;
  locations: readonly LocationResource[];
  splitOn?: CongestionParamsSplit;
  metric: CongestionMetric;
  product?: readonly number[];
  vesselClassifications: readonly string[];
  engineTypes: readonly string[];
  numberOfSplits?: number;
  deadWeight?: DeadWeightObject;
  capacity?: CapacityObject;
  isCongestion: boolean;
  vesselOperation: readonly CongestionVesselOperation[];
  waitingDuration?: WaitingDurationObject;
  withFreightView: boolean;
  originZones?: readonly number[];
  vesselIds?: readonly number[];
  vesselTypeClassification?: VesselTypeClassification;
};

export type CongestionVesselPayload = {
  id: string;
  duration: number;
  vessel: Vessel;
  flowQuantities: FlowQuantity[];
  installation: Installation;
};

export type CongestionVesselsParams = PaginationParams &
  SerializedDateRange & {
    granularity: Granularity;
    locations: readonly LocationResource[];
    splitOn?: CongestionParamsSplit;
    splitValues: readonly string[];
    splitValuesToExclude: readonly string[];
    metric: CongestionMetric;
    product?: readonly number[];
    vesselClassifications: readonly string[];
    engineTypes: readonly string[];
    date: string;
    deadWeight?: DeadWeightObject;
    capacity?: CapacityObject;
    isCongestion: boolean;
    vesselOperation: readonly CongestionVesselOperation[];
    waitingDuration?: WaitingDurationObject;
    withFreightView: boolean;
    originZones?: readonly number[];
    vesselIds?: readonly number[];
    vesselTypeClassification?: VesselTypeClassification;
  };

export enum CongestionVesselOperation {
  LOAD = 'Load',
  DISCHARGE = 'Discharge',
}

export type CongestionTooltipProps = {
  unitName: UnitName | UnmanagedUnitName;
  granularity: Granularity;
};
