export enum VesselState {
  BALLAST = 'BALLAST',
  LOADED = 'LOADED',
  OPEN = 'OPEN',
}

export enum MapView {
  VESSEL_STATE = 'vesselState',
  MARKET = 'market',
}
