<script setup lang="ts">
import { computed, ref } from 'vue';

import { NodeList } from './NodeList';
import { Props as TreeProps } from './Tree.props';
import { FlatNode, TreeNode } from './Tree.types';
import {
  findNodeDeepById,
  getNewFlatTreeOnToggle,
  transformFlatTreeToNestedTree,
} from './Tree.utils';

type Props = {
  nodes: TreeProps['nodes'];
  maxDepth?: TreeProps['maxDepth'];
  treeSortMethod?: TreeProps['treeSortMethod'];
  propagateSelectionToParents: TreeProps['propagateSelectionToParents'];
};

const props = withDefaults(defineProps<Props>(), {
  maxDepth: Infinity,
});

const emit = defineEmits<{
  (e: 'update:nodes', nodes: readonly FlatNode[]): void;
}>();

const nestedTree = computed<readonly TreeNode[]>(() => {
  return transformFlatTreeToNestedTree(props.nodes, props.maxDepth, props.treeSortMethod);
});

const expandedIds = ref<Array<TreeNode['id']>>([]);

const onToggleNode = (id: string) => {
  const newFlatTree = getNewFlatTreeOnToggle(id, nestedTree.value, {
    propagateSelectionToParents: props.propagateSelectionToParents,
  });
  emit('update:nodes', newFlatTree);
};

const updateExpandedIdsOnExpand = (currentDepth: number, expandindNodeId: TreeNode['id']): void => {
  const newExpandedIds: string[] = [...expandedIds.value];
  newExpandedIds.splice(currentDepth, Infinity, expandindNodeId);
  expandedIds.value = newExpandedIds;
};

const getNodesForDepth = (depth: number): readonly TreeNode[] => {
  if (depth === 0) {
    return nestedTree.value;
  }

  const expandedNodeId = expandedIds.value[depth - 1];
  const expandedNode = findNodeDeepById(expandedNodeId, nestedTree.value);
  return expandedNode?.children ?? [];
};
</script>

<template>
  <div class="Tree">
    <NodeList
      v-for="(_, currentDepth) in [undefined, ...expandedIds]"
      :key="currentDepth"
      :expanded-id="expandedIds.at(currentDepth) ?? null"
      :nodes="getNodesForDepth(currentDepth)"
      @expand="updateExpandedIdsOnExpand(currentDepth, $event)"
      @update:checked="onToggleNode"
    />
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';
@use 'src/scss/variables';

.Tree {
  $max-width: 700px;

  display: flex;
  z-index: 100;
  width: fit-content;
  max-width: min($max-width, 100%);
  overflow-x: auto;
  padding: variables.$spacing-xs;
  border-radius: variables.$border-radius;
  color: theme.$color-text-primary;
  background-color: theme.$color-bg-primary;
  box-shadow: variables.$box-shadow-small;
}
</style>
