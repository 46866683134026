const getTimestamp = (date: string) => {
  const parsedDate = Date.parse(date);

  return Number.isNaN(parsedDate) ? Number(date) : parsedDate;
};

export const sortAlphabetically = (strA: string, strB: string): number =>
  strA
    .toLowerCase()
    .localeCompare(strB.toLowerCase(), 'en', { sensitivity: 'base', numeric: true });

export const sortDate = (dateA: string, dateB: string) => {
  const optionA = getTimestamp(dateA);
  const optionB = getTimestamp(dateB);

  if (optionA > optionB) {
    return 1;
  }

  if (optionA < optionB) {
    return -1;
  }

  return 0;
};
