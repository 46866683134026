import type { ObjectBase } from '@kpler/terminal-utils';
import { ValidateField, TOptions } from 'types/staticData';
import { ValidationResult, ValidationStatus } from 'types/validation';

// deprecated
export const required = (field: string | number | null | ObjectBase) => {
  if (!field) {
    return { valid: false, message: 'This field is required.' };
  }
  return { valid: true, message: null };
};

export const validateRequired = (field: string | number | null | ObjectBase) => {
  if (!field) {
    return { status: ValidationStatus.ERROR, message: 'This field is required.' };
  }
  return { status: ValidationStatus.SUCCESS, message: null };
};

export const validateEmailFormat = (field: string | null) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (field !== null && !re.test(field)) {
    return { status: ValidationStatus.ERROR, message: 'Please use an email format.' };
  }
  return { status: ValidationStatus.SUCCESS, message: null };
};

export const sortOptions = (options: Array<ObjectBase<string | number>>) =>
  options.sort((a, b) => a.name.localeCompare(b.name));

export const checkOptionValidity = (
  options: Readonly<TOptions>,
  fieldValue: string | number | null,
  fieldKey: string,
): ValidateField => {
  const option = options.find(x => x.id === fieldValue) !== undefined;
  if (option) {
    return {
      valid: true,
      message: null,
    };
  }
  return {
    valid: false,
    message: `This ${fieldKey}: ${fieldValue} is not found. Please reach out to support`,
  };
};

export const VALIDATION_SUCCESS: ValidationResult = {
  status: ValidationStatus.SUCCESS,
  message: null,
};
