export const capitalizeFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const toNumber = (str: string) => {
  const num = Number(str);

  if (Number.isNaN(num)) {
    throw new Error(`"${str}" is not convertible to a number`);
  }

  return num;
};

export const isEmpty = (str: string) => str.trim().length === 0;

export const collapseExtraSpaces = (text: string) => text.replace(/\s+/g, ' ').trim();
