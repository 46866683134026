import { assertDefined, assertNever } from '@kpler/generic-utils';
import {
  Granularity,
  DateTimeDisplay,
  deserializeDate,
  serializeDate,
  convertPredictivePresetToDateRange,
} from '@kpler/terminal-utils';
import { Market } from '@kpler/web-ui';
import moment from 'moment';

import {
  adaptDroneCapacityUtilization,
  adaptInventoriesDates,
  addCapacityUtilization,
} from 'src/adapters/inventories.adapter';
import { adaptTrade } from 'src/adapters/trade.adapter';
import {
  inventoriesParamsFrontendToGraphql,
  timeSeriesPayloadGraphqlToFrontend,
  inventoriesSnapshotParamsFrontendToGraphql,
  inventoriesSnapshotGraphqlToFrontend,
} from 'src/common/inventories/inventories.mapper';
import { InventoriesMarket } from 'src/domains/onshore-asset-monitoring/inventories/useInventoriesContext';
import { conversionServiceLegacy } from 'src/services/ConversionService/conversion.service.legacy';

import { apolloClient } from 'src/services/apollo.service';
import { axiosApi } from 'src/services/axios.service';

import { convertToUnit } from 'src/helpers/conversion.helper';
import { convertTimeSeries } from 'src/helpers/series.helper';
import {
  convertFilterStateToTradesParams,
  getInventoriesRequestEndDate as getRequestEndDate,
} from 'src/main/analytics/inventories/inventories.helper';

import { inventoriesSeries, inventoriesSnapshot } from 'src/graphql/Inventories.gql';
import { slots as slotsQuery } from 'src/graphql/Slot.gql';

import { AnalyticsView } from 'types/analytics';
import { ConvertedTimeSeries } from 'types/chart';
import {
  InventoriesSeriesQuery,
  InventoriesSeriesQueryVariables,
  InventoriesSnapshotQuery,
  InventoriesSnapshotQueryVariables,
  SlotsQuery,
  SlotsQueryVariables,
} from 'types/graphql';
import {
  InventoriesStateHydrated,
  InventoriesParams,
  InventoriesSnapshot,
  InventoriesSnapshotParams,
  TimeSeriesInventories,
  InventoriesDatasetName,
  InventoriesState,
} from 'types/inventories';
import { Platform, ResourceType } from 'types/legacy-globals';
import { QuantityObject } from 'types/quantity';
import { TimeSeries, TimeSeriesPayload } from 'types/series';
import { Trade, TradePayload } from 'types/trade';
import { Unit, UnitName } from 'types/unit';

export const convertFilterStateToParams = (
  filterState: InventoriesState,
  market: InventoriesMarket,
  numberOfSplits: number | undefined,
): InventoriesParams => {
  const minDate = deserializeDate('2000-01-01');
  const showFutureData = market === Market.LNG;
  let dateRange;
  if (filterState.seasonal) {
    dateRange = {
      startDate: serializeDate(minDate),
      endDate: getRequestEndDate(showFutureData, filterState.granularity).format(
        DateTimeDisplay.EXPORT_WEEKLY,
      ),
    };
  } else if (typeof filterState.dates === 'string') {
    dateRange = convertPredictivePresetToDateRange(
      filterState.dates,
      minDate,
      getRequestEndDate(showFutureData, filterState.granularity),
      showFutureData,
    );
  } else {
    dateRange = filterState.dates;
  }

  const obj: InventoriesParams = {
    ...dateRange,
    granularity: filterState.granularity,
    locationIds: [...filterState.zones, ...filterState.installations],
    // @TODO this is terrible
    locationResourceType:
      filterState.zones.length > 0 ||
      (filterState.zones.length === 0 && filterState.installations.length === 0)
        ? ResourceType.ZONE
        : ResourceType.INSTALLATION,
    splitCriteria: filterState.split,
    cumulative: false,
    fillMissing: true,
    cargoTrackingEnhancement: filterState.cargoTrackingEnhancement,
    fleetMetricsEnhancement: filterState.fleetMetricsEnhancement,
    // the `platform` must be replaced by market on the server side
    // currently should it be Platform.COMMODITIES or Platform.MERGE?
    platform: market === Market.LNG ? Platform.LNG : Platform.COMMODITIES,
    droneData: filterState.droneData,
    droneDataAtmEnhancement: filterState.droneDataAtmEnhancement,
    addEiaDatasets: filterState.addEiaDatasets,
    deltaLevel: filterState.deltaLevel,
  };
  if (filterState.floatingFrom) {
    obj.floatingStorageDuration = {
      min: filterState.floatingFrom,
    };
  }
  if (numberOfSplits) {
    obj.numberOfSplits = numberOfSplits;
  }
  if (filterState.droneData) {
    obj.addEiaDatasets =
      filterState.view === AnalyticsView.TABLE &&
      !filterState.seasonal &&
      filterState.granularity === Granularity.EIAS;
  } else {
    obj.selection = {
      splitValuesMetadata: [],
      datasetsSplitValues: [InventoriesDatasetName.LEVEL, InventoriesDatasetName.CAPACITY],
    };
  }

  return obj;
};

const getSeriesFromParams = async (
  params: InventoriesParams,
): Promise<TimeSeriesPayload<TimeSeriesInventories, InventoriesDatasetName>> => {
  const graphQlResult = await apolloClient.query<
    InventoriesSeriesQuery,
    InventoriesSeriesQueryVariables
  >({
    query: inventoriesSeries,
    variables: inventoriesParamsFrontendToGraphql(params),
  });

  let payload = timeSeriesPayloadGraphqlToFrontend(graphQlResult.data);
  payload = adaptInventoriesDates(payload);
  if (!params.fleetMetricsEnhancement && !params.droneData) {
    payload = addCapacityUtilization(payload);
  } else if (params.droneData) {
    payload = adaptDroneCapacityUtilization(payload);
  }

  return payload;
};

const getSeries = (
  filters: InventoriesState,
  market: InventoriesMarket,
  numberOfSplits: number | undefined,
): Promise<TimeSeriesPayload<TimeSeriesInventories, InventoriesDatasetName>> => {
  const params = convertFilterStateToParams(filters, market, numberOfSplits);
  return getSeriesFromParams(params);
};

const getSnapshot = async (params: InventoriesSnapshotParams): Promise<InventoriesSnapshot> => {
  const graphQlResult = await apolloClient.query<
    InventoriesSnapshotQuery,
    InventoriesSnapshotQueryVariables
  >({
    query: inventoriesSnapshot,
    variables: inventoriesSnapshotParamsFrontendToGraphql(params),
  });
  return inventoriesSnapshotGraphqlToFrontend(graphQlResult.data);
};

const getTrades = (
  filters: InventoriesStateHydrated,
  date: string,
  splitId: string | null,
  splitValuesToExclude: string[],
  page: number,
  paginationSize: number,
  market: InventoriesMarket,
): Promise<Trade[]> => {
  const splitValuesToExcludeFiltered = splitValuesToExclude.filter(
    splitValue => !Number.isNaN(Number(splitValue)),
  );
  const size = paginationSize;
  const from = page * size;

  const params = convertFilterStateToTradesParams(
    filters,
    date,
    splitId,
    splitValuesToExcludeFiltered,
    market,
  );
  return axiosApi
    .post<TradePayload[]>('flows/trades', { ...params, from, size })
    .then(data =>
      data.map(adaptTrade).sort((a, b) => moment.utc(b.start).diff(moment.utc(a.start))),
    );
};

const getTradesAsCSV = (
  filters: InventoriesStateHydrated,
  date: string,
  splitId: string | null,
  splitValuesToExclude: string[],
  page: number,
  paginationSize: number,
  market: InventoriesMarket,
): Promise<string> => {
  const from = 0;
  const size = (page + 1) * paginationSize;

  const params = convertFilterStateToTradesParams(
    filters,
    date,
    splitId,
    splitValuesToExclude,
    market,
  );
  const headers = { Accept: 'text/csv' };
  return axiosApi.post<string>('flows/trades', { ...params, from, size }, { headers });
};

const convertSeries = (
  series: ReadonlyArray<TimeSeries<TimeSeriesInventories, InventoriesDatasetName>>,
  unit: Unit,
  market: InventoriesMarket,
): ReadonlyArray<ConvertedTimeSeries<InventoriesDatasetName>> =>
  convertTimeSeries(
    series,
    (qty, _, datasetName) => {
      switch (datasetName) {
        case InventoriesDatasetName.LEVEL:
        case InventoriesDatasetName.CAPACITY:
        case InventoriesDatasetName.HEEL:
        case InventoriesDatasetName.DELTA_LEVEL:
        case InventoriesDatasetName.EIA_LEVEL:
        case InventoriesDatasetName.EIA_DELTA_LEVEL:
        case InventoriesDatasetName.EIA_CAPACITY:
          return convertToUnit(qty, unit);
        case InventoriesDatasetName.UTILIZATION:
        case InventoriesDatasetName.HEEL_CAPACITY:
          return qty[unit.key];
        case InventoriesDatasetName.REVISIT_RATE:
        case InventoriesDatasetName.IMAGE:
          return qty.volume;
        case InventoriesDatasetName.ONSHORE_INFLOW:
        case InventoriesDatasetName.ONSHORE_OUTFLOW:
        case InventoriesDatasetName.PORT_CALLS_NET:
        case InventoriesDatasetName.CARGO:
        case InventoriesDatasetName.CRUDE_CAPACITY:
          return market === Market.LNG
            ? convertToUnit(qty, unit)
            : conversionServiceLegacy.convertTo(qty as unknown as QuantityObject, UnitName.KB);
        default:
          return assertNever(datasetName);
      }
    },
    qty => ({ providerName: qty.providerName }),
  );

const getSlots = async (installationIds: string[]) =>
  apolloClient
    .query<SlotsQuery, SlotsQueryVariables>({
      query: slotsQuery,
      variables: { installationIds },
    })
    .then(({ data }) => {
      assertDefined(data);
      return data.slots;
    });

export default {
  getSeriesFromParams,
  getSeries,
  getSnapshot,
  getTrades,
  getTradesAsCSV,
  convertSeries,
  getSlots,
};
