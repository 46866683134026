<script setup lang="ts">
import { IconName, Icon } from '../Icon';

type Props = {
  icon: IconName;
  title: string;
};

defineProps<Props>();

const emit = defineEmits<{ (e: 'close'): void }>();

const close = () => emit('close');
</script>

<template>
  <div class="Header">
    <Icon :name="icon" />

    <h2>{{ title }}</h2>

    <div class="close-icon" @click="close">
      <Icon name="close" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';
@use 'src/scss/theme';

.Header {
  display: flex;
  align-items: center;
  gap: variables.$spacing-small;
  margin-bottom: variables.$spacing-small;

  h2 {
    margin: 0;
  }

  .close-icon {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      cursor: pointer;
    }
  }
}
</style>
