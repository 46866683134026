<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { Node } from './Node';
import { Props as NodeListProps } from './NodeList.props';

type Props = {
  expandedId: NodeListProps['expandedId'];
  nodes: NodeListProps['nodes'];
};

defineProps<Props>();

const emit = defineEmits<{
  (e: 'expand', id: string): void;
  (e: 'update:checked', id: string): void;
}>();

const nodeListRef = ref<HTMLDivElement>();

const autoScroll = () => {
  nodeListRef.value?.scrollIntoView({
    block: 'end',
    inline: 'end',
    behavior: 'smooth',
  });
};

onMounted(() => {
  autoScroll();
});
</script>

<template>
  <div ref="nodeListRef" class="NodeList">
    <Node
      v-for="node in nodes"
      :key="node.id"
      v-bind="node"
      :active="node.id === expandedId"
      @update:checked="emit('update:checked', node.id)"
      @expand="emit('expand', node.id)"
    />
  </div>
</template>

<style scoped lang="scss">
@use 'src/scss/variables';

.NodeList {
  max-height: 200px;
  min-width: 150px;
  max-width: 250px;
  overflow-y: auto;
}
</style>
