import { provide } from 'vue';

import { applicationProviders } from './applicationProviders';
import { APPLICATION_PROVIDERS_KEY } from './applicationProviders.key';

export const useApplicationProviders = (): void => {
  Object.entries(applicationProviders).forEach(([injectionKey, provider]) => {
    provide(injectionKey, provider);
  });
  provide(APPLICATION_PROVIDERS_KEY, applicationProviders);
};
