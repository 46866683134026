<script setup lang="ts">
import { ORDERED_MARKETS } from '../constants';
import { Market } from '../types';

import { Props as MarketLegendIndicatorProps } from './MarketLegendIndicator.props';

type Props = {
  markets: MarketLegendIndicatorProps['markets'];
  layout: MarketLegendIndicatorProps['layout'];
  selectedMarkets?: MarketLegendIndicatorProps['selectedMarkets'];
};

const props = withDefaults(defineProps<Props>(), {
  selectedMarkets: () => ORDERED_MARKETS,
});

const isMarketSelected = (market: Market) => props.selectedMarkets.includes(market);

const getColor = (market: Market): string => {
  if (isMarketSelected(market)) {
    return `var(--color-commodity-${market})`;
  }
  return 'var(--color-bg-quaternary)';
};
</script>

<template>
  <div :class="layout === 'line' ? 'market-legend' : 'market-legend-grid'">
    <div
      v-for="market in markets"
      :key="market"
      :style="{ backgroundColor: getColor(market) }"
      class="indicator"
      data-testid="MarketLegendIndicator"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';

.market-legend {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: variables.$spacing-xxs;
}

.market-legend-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: variables.$spacing-xxs;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
</style>
