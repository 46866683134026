export type ObjectValues<T> = T[keyof T];

export enum OutageStatus {
  NO_OUTAGES = 'No outages',
  ONGOING = 'Ongoing',
  SCHEDULED = 'Scheduled',
  DECOMMISSIONED = 'Decommissioned',
  PLANNED = 'Planned commissioning',
}

export enum OutageStatusColor {
  NO_OUTAGES = '#00C068',
  ONGOING = '#FAB12A',
  SCHEDULED = '#52B6FF',
  DECOMMISSIONED = '#545d66',
  PLANNED = '#80549F',
}

export enum BiddingZonePriceRangeGradient {
  BLUE = '#52b6ff',
  GREEN = '#00C068',
  YELLOW = '#FFC24E',
  ORANGE = '#F37535',
  RED = '#FF1E4F',
}

export const FUEL = {
  BIOMASS: 'biomass',
  FOSSIL_BROWN_COAL_LIGNITE: 'fossil brown coal/lignite',
  FOSSIL_COAL_DERIVED_GAS: 'fossil coal-derived gas',
  FOSSIL_GAS: 'fossil gas',
  FOSSIL_HARD_COAL: 'fossil hard coal',
  FOSSIL_OIL: 'fossil oil',
  FOSSIL_OIL_SHALE: 'fossil oil shale',
  FOSSIL_PEAT: 'fossil peat',
  GEOTHERMAL: 'geothermal',
  HYDRO_PUMPED_STORAGE: 'hydro pumped storage',
  HYDRO_PUMP: 'hydro pump',
  HYDRO_RUN_OF_RIVER_AND_POUNDAGE: 'hydro run-of-river and poundage',
  HYDRO_WATER_RESERVOIR: 'hydro water reservoir',
  MARINE: 'marine',
  NUCLEAR: 'nuclear',
  OTHER: 'other',
  OTHER_RENEWABLE: 'other renewable',
  SOLAR: 'solar',
  WASTE: 'waste',
  WIND_OFFSHORE: 'wind offshore',
  WIND_ONSHORE: 'wind onshore',
} as const;

export type Fuel = ObjectValues<typeof FUEL>;

export const isFuel = (fuel: string): fuel is Fuel => Object.values(FUEL).includes(fuel as Fuel);

export enum Region {
  EUROPE = 'Europe',
  EU_27 = 'EU 27',
  CENTRAL_WESTERN_EUROPE = 'Central Western Europe',
  CENTRAL_EASTERN_EUROPE = 'Central Eastern Europe',
  NORTHERN_EUROPE = 'Northern Europe',
  SOUTHERN_EASTERN_EUROPE = 'Southern Eastern Europe',
}

export const isRegion = (location: string): location is Region =>
  Object.values(Region).includes(location as Region);

export enum PowerTerminalFuelTypeColor {
  BIOMASS = '#4D924C',
  FOSSIL_GAS = '#C72347',
  FOSSIL_OIL = '#80549F',
  HARD_COAL = '#A17917',
  HYDRO = '#3997E3',
  NUCLEAR = '#FEBC43',
  OTHERS = '#6D7A87',
  SOLAR = '#DD6A1F',
  WIND = '#5CB19D',
}

export enum AnalyticsSupplyMetrics {
  AVAILABILITY = 'availability',
  GENERATION = 'generation',
}

export enum ForecastModel {
  EC_OP = 'EC_OP',
  EC_ENS = 'EC_ENS',
  EC_46 = 'EC_46',
  GFS_OP = 'GFS_OP',
  GFS_ENS = 'GFS_ENS',
}

export enum ForecastRun {
  MIDNIGHT = '00',
  SIX_AM = '06',
  NOON = '12',
  SIX_PM = '18',
}

export enum ForecastHorizon {
  D_0 = 'D-0',
  D_1 = 'D-1',
  D_2 = 'D-2',
  D_3 = 'D-3',
  D_4 = 'D-4',
  D_5 = 'D-5',
  D_6 = 'D-6',
  D_7 = 'D-7',
  D_8 = 'D-8',
  D_9 = 'D-9',
  D_10 = 'D-10',
  D_11 = 'D-11',
  D_12 = 'D-12',
  D_13 = 'D-13',
  D_14 = 'D-14',
  D_15 = 'D-15',
}

export const FORECAST_DOMAINS = {
  PRICE: 'Price',
  TEMPERATURE: 'Temperature',
  DEMAND: 'Demand',
  GENERATION: 'Generation',
} as const;

export type ForecastDomain = keyof typeof FORECAST_DOMAINS;

export const RENEWABLE_FUEL = {
  SOLAR: 'solar',
  WIND: 'wind',
} as const;

export type RenewableFuel = ObjectValues<typeof RENEWABLE_FUEL>;

export const POWER_TERMINAL_REFUELLING_STATUS = {
  OK: {
    api: 'ok',
    humanReadable: 'Ok',
  },
  REFUELLING: {
    api: 'refuelling',
    humanReadable: 'Refuelling',
  },
  SHORT_ON_FUEL: {
    api: 'short_on_fuel',
    humanReadable: 'Short on fuel',
  },
} as const;

export type PowerTerminalRefuellingStatusKeys = keyof typeof POWER_TERMINAL_REFUELLING_STATUS;

export type RefuellingStatusResponseKeys =
  (typeof POWER_TERMINAL_REFUELLING_STATUS)[PowerTerminalRefuellingStatusKeys]['api'];
export type RefuellingStatusHumanReadableKeys =
  (typeof POWER_TERMINAL_REFUELLING_STATUS)[PowerTerminalRefuellingStatusKeys]['humanReadable'];

export const POWER_TERMINAL_REFUELLING_STATUS_COLOR = {
  OK: '#4D924C',
  REFUELLING: '#3997E3',
  SHORT_ON_FUEL: '#ee5b11',
} as const;

export type PowerTerminalRefuellingStatusColor = ObjectValues<
  typeof POWER_TERMINAL_REFUELLING_STATUS_COLOR
>;
