<script lang="ts" setup>
import { computed } from 'vue';

import BaseNavItem from 'src/components/BaseNavItem.vue';

import { useEmissionsPermissions } from 'src/domains/emissions/hooks/useEmissionsPermissions';

const { userHasAccessToInternalEmissionsFeatures, userHasAccessToEmissionsOverview } =
  useEmissionsPermissions();

const hasAccess = computed(
  () => userHasAccessToEmissionsOverview() || userHasAccessToInternalEmissionsFeatures(),
);
</script>

<template>
  <BaseNavItem v-if="hasAccess" :to="{ name: 'emissions' }" icon="emissions" class="navbar-item" />
</template>
