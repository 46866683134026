<script lang="ts" setup>
import { Component, computed } from 'vue';

import { Size, Tag, Variant } from '../tags';

type Props = {
  checked: boolean;

  /**
   * @property {Component} as - The component to render as, intended to be a custom Tag.
   * @see CustomTag.vue
   * @default Tag
   */
  as?: Component;
  asProps?: Record<string, unknown>;

  // from Tag
  variant: Variant;
  outline?: boolean;
  size?: Size;
  color?: string;
  disabled?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{ (e: 'update:checked', v: boolean): void }>();

function toggleCheck() {
  if (props.disabled) {
    return;
  }
  emit('update:checked', !props.checked);
}

const isDisabled = computed<boolean>(() => {
  return props.disabled || !props.checked;
});
</script>

<template>
  <span
    data-testid="checkbox-button"
    class="checkbox-button"
    :class="{ disabled }"
    @click="toggleCheck"
  >
    <Tag :is="as" v-if="as" :disabled="isDisabled" v-bind="asProps" />

    <Tag
      v-else
      :outline="outline"
      :size="size"
      :variant="variant"
      :color="color"
      :disabled="isDisabled"
    >
      <slot></slot>
    </Tag>
  </span>
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';
@use 'src/scss/theme-internal';
@use 'src/scss/variables';

.checkbox-button {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}
</style>
