import { assertIsArray, isDefined } from '@kpler/generic-utils';
import { RangeNumber } from '@kpler/terminal-utils';
import { Market } from '@kpler/web-ui';

import { VesselClassificationRangeTypes } from 'src/platform-merge/analytics/VesselClassificationFilter/types';
import { FilterCallback } from 'src/types';

import { betaFilter, createFilterOnAttribute } from './commonFilters.helper';
import { checkIfSearchingOnlyVessels } from './mapSearch.helper';

import {
  getVesselCargoProducts,
  getVesselCargoState,
  getVesselStatus,
} from 'src/main/helpers/itemIcon.helper';

import { CargoState, Speed, VesselStatus, YesNo } from 'types/graphql';
import { VesselTypeClassification } from 'types/legacy-globals';
import {
  MapFilters,
  MapFiltersPayload,
  MapSearch,
  MapSearchDehydrated,
  MapVessel,
} from 'types/map';
import { ProductSearchResult } from 'types/search';
import { VesselMapPayload, VessselStatcode } from 'types/vessel';

type Filter = FilterCallback<VesselMapPayload>;

export const UNKNOWN_OPTION_NAME = 'Unknown';

export const createFilterVesselsByMarkets =
  (selectedMarkets: Set<Market>): Filter =>
  (vessel: VesselMapPayload) =>
    vessel.commodityTypes.some(commodityType => selectedMarkets.has(commodityType));

export const getVesselTypeForClassification = (
  vessel: VesselMapPayload,
  classification: VesselTypeClassification,
): string | undefined => vessel.classification?.vesselTypes[classification];

export const getVesselCarrierType = (vessel: VesselMapPayload): string =>
  vessel.classification?.carrierType || UNKNOWN_OPTION_NAME;

export const getVesselEngine = (vessel: VesselMapPayload): string =>
  vessel.engineMetrics?.powerPlantType || UNKNOWN_OPTION_NAME;

export const getVesselCargoType = (vessel: VesselMapPayload): string =>
  vessel.cargoMetrics?.cargoType || UNKNOWN_OPTION_NAME;

const createFilterMapPayload = (mapFiltersPayload: MapFiltersPayload | null): Filter => {
  const shouldFilterOnSpecificIds =
    isDefined(mapFiltersPayload) && mapFiltersPayload.vesselIds !== 'all';

  if (!shouldFilterOnSpecificIds) {
    return () => true;
  }

  assertIsArray(mapFiltersPayload.vesselIds);
  const vesselIds = new Set(mapFiltersPayload.vesselIds);

  return (vessel: VesselMapPayload): boolean => vesselIds.has(vessel.id);
};

export const createSelectedVesselsFilter = (
  selectedVessels: readonly MapVessel[] | readonly number[],
) => {
  const selectedVesselsSet = new Set(selectedVessels.map(x => (typeof x === 'number' ? x : x.id)));
  return (vessel: VesselMapPayload): boolean => selectedVesselsSet.has(vessel.id);
};

export const createCargoStatusFilter =
  (cargoStatuses: Set<CargoState>) =>
  (vessel: VesselMapPayload): boolean => {
    if (cargoStatuses.size === 0) {
      return true;
    }
    const cargoStatus = getVesselCargoState(vessel);
    return (
      (cargoStatuses.has(CargoState.Loaded) && cargoStatus === CargoState.Loaded) ||
      (cargoStatuses.has(CargoState.Ballast) && cargoStatus === CargoState.Ballast)
    );
  };

export const productFilterPredicate =
  (products: ProductSearchResult[] | readonly number[]) =>
  (vessel: VesselMapPayload): boolean => {
    if (products.length === 0) {
      return true;
    }
    const filterProductIds = products.map(product =>
      typeof product === 'number' ? product : Number(product.id),
    );
    const cargoProducts = getVesselCargoProducts(vessel);
    const cargoProductIds: Set<number> = new Set(cargoProducts.map(cp => cp.id));
    const cargoProductAndAncestorsIds = Array.from(
      new Set([...cargoProductIds, ...cargoProducts.flatMap(cp => cp.ancestors).map(cp => cp.id)]),
    );

    return cargoProductAndAncestorsIds.some(cp => filterProductIds.includes(cp));
  };

export const vesselStatesFilter =
  (vesselStates: Set<VesselStatus>, showOpenVessels: boolean) =>
  (vessel: VesselMapPayload): boolean => {
    if (vesselStates.size === 0) {
      return true;
    }
    return vesselStates.has(getVesselStatus(vessel, showOpenVessels));
  };

export const speedFilter =
  (speed: Set<Speed>) =>
  (vessel: VesselMapPayload): boolean => {
    if (speed.size === 0) {
      return true;
    }
    const isMoving = vessel.lastPosition !== null && vessel.lastPosition.speed > 1;
    return (speed.has(Speed.Moving) && isMoving) || (speed.has(Speed.Stopped) && !isMoving);
  };

export const ethyleneCapableFilter =
  (ethyleneCapable: Set<YesNo>) =>
  (vessel: VesselMapPayload): boolean => {
    if (ethyleneCapable.size === 0) {
      return true;
    }
    const isCapable = vessel.isEthyleneCapable;
    return (
      (ethyleneCapable.has(YesNo.Yes) && isCapable) || (ethyleneCapable.has(YesNo.No) && !isCapable)
    );
  };

export const asphaltBitumenCapableFilter =
  (asphaltBitumenCapable: Set<YesNo>) =>
  (vessel: VesselMapPayload): boolean => {
    if (asphaltBitumenCapable.size === 0) {
      return true;
    }
    // We don't have the statcode property on first load of the vessels
    // because we use the "light" query for performance reasons
    if (!isDefined(vessel.statcode)) {
      return false;
    }
    const isAsphaltBitumenCapable = vessel.statcode.code === VessselStatcode.ASPHALT_BITUMEN;
    return (
      (asphaltBitumenCapable.has(YesNo.Yes) && isAsphaltBitumenCapable) ||
      (asphaltBitumenCapable.has(YesNo.No) && !isAsphaltBitumenCapable)
    );
  };

export const buildYearFilter =
  (buildYear: RangeNumber | null) =>
  (vessel: VesselMapPayload): boolean => {
    if (buildYear === null) {
      return true;
    }
    const [min, max] = buildYear;

    return (
      vessel.build !== undefined && min <= vessel.build.buildYear && vessel.build.buildYear <= max
    );
  };

export const capacityFilter =
  (capacity: RangeNumber | null) =>
  (vessel: VesselMapPayload): boolean => {
    if (capacity === null) {
      return true;
    }
    const [min, max] = capacity;

    return min <= vessel.capacityMultiUnits.volume && vessel.capacityMultiUnits.volume <= max;
  };
export const deadWeightFilter =
  (deadWeight: RangeNumber | null) =>
  (vessel: VesselMapPayload): boolean => {
    if (deadWeight === null) {
      return true;
    }
    const [min, max] = deadWeight;

    return min <= vessel.deadWeight && vessel.deadWeight <= max;
  };

export const capacityOrDeadweightFilter = (
  vesselClassificationRangeType: VesselClassificationRangeTypes | null,
  capacity: RangeNumber | null,
) => {
  if (vesselClassificationRangeType === VesselClassificationRangeTypes.CAPACITY) {
    return capacityFilter(capacity);
  }
  if (vesselClassificationRangeType === VesselClassificationRangeTypes.DEADWEIGHT) {
    return deadWeightFilter(capacity);
  }
  return (): boolean => true;
};

export const vesselDraughtFilter =
  (draughtRange: RangeNumber | null) =>
  (vessel: VesselMapPayload): boolean => {
    if (draughtRange === null) {
      return true;
    }
    const [min, max] = draughtRange;

    const lastDraught = vessel.lastPosition?.draughtComputed ?? null;

    if (lastDraught === null) {
      return true;
    }

    return min <= lastDraught && lastDraught <= max;
  };

export const vesselClassificationFilter =
  (classifications: Set<string>) =>
  (vessel: VesselMapPayload): boolean => {
    if (classifications.size === 0 || vessel.vesselTypeClass === undefined) {
      return true;
    }
    return classifications.has(vessel.vesselTypeClass);
  };

/**
 * Create and combine all the diffrerent filters for the advanced vessel filters
 * @param filterValues the user defined values of the advanced vessel filters
 * @param options.vesselClassification the vessel classification to use for the vessel type filter
 * @param options.shouldShowOpenVessels whether or not to show open vessels depending the user permissions
 */
const createAdvancedVesselFilters = (
  filterValues: MapFilters,
  options: {
    vesselClassification: VesselTypeClassification;
    shouldShowOpenVessels: boolean;
  },
): readonly Filter[] => [
  createCargoStatusFilter(filterValues.cargoStatus),
  vesselStatesFilter(filterValues.vesselStates, options.shouldShowOpenVessels),
  vesselClassificationFilter(filterValues.vesselTypes),
  speedFilter(filterValues.speed),
  createFilterOnAttribute(filterValues.engine, getVesselEngine),
  createFilterOnAttribute(filterValues.carrierType, getVesselCarrierType),
  betaFilter(filterValues.betaVesselStatus),
  ethyleneCapableFilter(filterValues.ethyleneCapable),
  asphaltBitumenCapableFilter(filterValues.asphaltBitumenCapable),
  createFilterOnAttribute(filterValues.cargoTypes, vessel => getVesselCargoType(vessel)),
  buildYearFilter(filterValues.buildYear),
  capacityOrDeadweightFilter(filterValues.vesselClassificationRangeType, filterValues.capacity),
  vesselDraughtFilter(filterValues.draught),
];

const shouldShowVesselBecauseOfSearch = (
  mapSearch: MapSearch | MapSearchDehydrated,
  vessel: VesselMapPayload,
): boolean => {
  const isSearchingOnlyVessels = checkIfSearchingOnlyVessels(mapSearch);
  const selectedVesselsFilter = createSelectedVesselsFilter(mapSearch.vessels);

  if (!isSearchingOnlyVessels) {
    return false;
  }

  return selectedVesselsFilter(vessel);
};

export const applyVesselFilters = (
  allVessels: readonly VesselMapPayload[],
  filterValues: MapFilters,
  searchValues: MapSearch | MapSearchDehydrated,
  options: {
    mapFiltersPayload: MapFiltersPayload | null;
    vesselClassification: VesselTypeClassification;
    shouldShowOpenVessels: boolean;
    areAdvancedFiltersEnabled: boolean;
  },
): readonly VesselMapPayload[] => {
  const searchFilters: readonly Filter[] = [createFilterMapPayload(options.mapFiltersPayload)];
  const marketFilters: readonly Filter[] = [createFilterVesselsByMarkets(filterValues.markets)];

  const advancedFilters: readonly Filter[] = options.areAdvancedFiltersEnabled
    ? createAdvancedVesselFilters(filterValues, {
        vesselClassification: options.vesselClassification,
        shouldShowOpenVessels: options.shouldShowOpenVessels,
      })
    : [];

  const allFilters = [...searchFilters, ...marketFilters, ...advancedFilters];

  const filteredVessels: readonly VesselMapPayload[] = allVessels.filter(vessel => {
    if (shouldShowVesselBecauseOfSearch(searchValues, vessel)) {
      return true;
    }

    return allFilters.every(filter => filter(vessel));
  });

  return filteredVessels;
};
