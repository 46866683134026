import {
  DateRange,
  DateRangePreset,
  Granularity,
  SerializedDateRange,
  RangeNumber,
} from '@kpler/terminal-utils';

import type { ObjectBase } from '@kpler/terminal-utils';
import { AnalyticsView } from 'types/analytics';
import { FleetUtilizationZoneFragment } from 'types/graphql';
import {
  PaginationParams,
  DeadWeightObject,
  CapacityObject,
  VesselTypeClassificationSplit,
  VesselTypeClassification,
} from 'types/legacy-globals';
import { PricesBaseFiltersMapping, PricesSidebarFilterState } from 'types/prices';
import { UnitName, UnmanagedUnitName } from 'types/unit';
import { Vessel } from 'types/vessel';

export enum FleetUtilizationAggMetric {
  COUNT = 'count',
  DEADWEIGHT = 'deadWeight',
  CAPACITY = 'capacity',
}

export enum FleetUtilizationSplit {
  TOTAL = 'total',
  VESSEL_TYPE = 'vesselType',
  VESSEL_STATE = 'state',
  VESSEL_SUB_STATE = 'subState',
  VESSEL_DIRECTION = 'direction',
  PRODUCT = 'product',
  CURRENT_CONTINENTS = 'currentContinents',
  CURRENT_SUBCONTINENTS = 'currentSubContinents',
  CURRENT_SUBREGIONS = 'currentSubRegions',
  CURRENT_COUNTRIES = 'currentCountries',
  PREVIOUS_TRADING_REGIONS = 'previousTradingRegions',
  PREVIOUS_CONTINENTS = 'previousContinents',
  PREVIOUS_SUBCONTINENTS = 'previousSubContinents',
  PREVIOUS_COUNTRIES = 'previousCountries',
  PREVIOUS_PORTS = 'previousPorts',
  NEXT_TRADING_REGIONS = 'nextTradingRegions',
  NEXT_CONTINENTS = 'nextContinents',
  NEXT_SUBCONTINENTS = 'nextSubContinents',
  NEXT_COUNTRIES = 'nextCountries',
  NEXT_PORTS = 'nextPorts',
  CURRENT_SEAS = 'currentSeas',
  ENGINE_TYPE = 'engineType',
}

export enum FleetUtilizationVesselState {
  LOADED = 'Loaded',
  FLOATING = 'Floating',
  BALLAST = 'Ballast',
  OTHERS = 'Others',
  MAINTENANCE = 'Maintenance',
}

export enum FleetUtilizationVesselDirection {
  NORTH = 'North',
  NORTH_EAST = 'Northeast',
  EAST = 'East',
  SOUTH_EAST = 'Southeast',
  SOUTH = 'South',
  SOUTH_WEST = 'Southwest',
  WEST = 'West',
  NORTH_WEST = 'Northwest',
  UNKNOWN = 'Unknown',
}

type FleetUtilizationParamsSplit =
  | Exclude<FleetUtilizationSplit, FleetUtilizationSplit.TOTAL>
  | VesselTypeClassificationSplit;

export type FleetUtilizationParams = SerializedDateRange & {
  zoneIds?: readonly number[];
  previousZoneIds?: readonly number[];
  nextZoneIds?: readonly number[];
  deadWeight?: DeadWeightObject;
  capacity?: CapacityObject;
  aggMetric: FleetUtilizationAggMetric;
  granularity: Granularity;
  numberOfSplits?: number;
  splitOn?: FleetUtilizationParamsSplit;
  vesselClassifications?: readonly string[];
  engineTypes: readonly string[];
  lastFamilyProducts?: readonly string[];
  states?: readonly FleetUtilizationVesselState[];
  directions?: readonly FleetUtilizationVesselDirection[];
  percent: boolean;
  withFreightView: boolean;
  distinctAggregation: boolean;
  vesselIds?: readonly number[];
  vesselTypeClassification?: VesselTypeClassification;
  showSubStates?: boolean;
};

export type FleetUtilizationStateHydrated = {
  zones: readonly FleetUtilizationZoneFragment[];
  previousZones: readonly FleetUtilizationZoneFragment[];
  nextZones: readonly FleetUtilizationZoneFragment[];
  aggMetric: FleetUtilizationAggMetric;
  split: FleetUtilizationSplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vessels: ReadonlyArray<ObjectBase<number>>;
  vesselClassifications: readonly string[];
  engineTypes: readonly string[];
  lastFamilyProducts: readonly string[];
  vesselState: readonly FleetUtilizationVesselState[];
  vesselDirection: readonly FleetUtilizationVesselDirection[];
  percent: boolean;
  showSubStates: boolean;
  distinctAggregation: boolean;
  pricesFilters: PricesSidebarFilterState;
  dateRange: DateRangePreset | DateRange;
  seasonal: boolean;
  granularity: Granularity;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
};

export type FleetUtilizationState = {
  zoneIds: readonly number[];
  previousZoneIds: readonly number[];
  nextZoneIds: readonly number[];
  aggMetric: FleetUtilizationAggMetric;
  granularity: Granularity;
  seasonal: boolean;
  split: FleetUtilizationSplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vesselClassifications: readonly string[];
  engineTypes: readonly string[] | null;
  lastFamilyProducts: readonly string[];
  vesselState: readonly FleetUtilizationVesselState[];
  vesselDirection: readonly FleetUtilizationVesselDirection[];
  percent: boolean;
  showSubStates: boolean;
  distinctAggregation: boolean;
  dates: DateRangePreset | DateRange;
  vessels: readonly number[];
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
  pricesFilters: PricesBaseFiltersMapping;
};

export type FleetUtilizationVesselsParams = PaginationParams &
  SerializedDateRange & {
    zoneIds?: readonly number[];
    previousZoneIds?: readonly number[];
    nextZoneIds?: readonly number[];
    deadWeight?: DeadWeightObject;
    capacity?: CapacityObject;
    metric: FleetUtilizationAggMetric;
    granularity: Granularity;
    vesselClassifications?: readonly string[];
    engineTypes: readonly string[];
    lastFamilyProducts?: readonly string[];
    date: string;
    states?: readonly FleetUtilizationVesselState[];
    directions?: readonly FleetUtilizationVesselDirection[];
    splitOn?: FleetUtilizationParamsSplit;
    splitValues?: readonly string[];
    splitValuesToExclude?: readonly string[];
    withFreightView: boolean;
    distinctAggregation: boolean;
    vesselIds?: readonly number[];
    vesselTypeClassification?: VesselTypeClassification;
    showSubStates?: boolean;
  };

export type ShipyardInfo = {
  installation: string;
  arrival: string;
  departure: string | null;
  duration: string;
};

export type FleetUtilizationVesselPayload = {
  id: string;
  vessel: Vessel;
  loadingStatusBis: FleetUtilizationVesselState;
  loadingSubStatusBis: FleetUtilizationVesselState;
  shipyardInfo?: ShipyardInfo;
};

export type FleetUtilizationTooltipProps = {
  unitName: UnitName | UnmanagedUnitName;
  granularity: Granularity;
};
