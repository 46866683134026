import { isDefined } from '@kpler/generic-utils';
import { AxiosInstance } from 'axios';
import { Store } from 'vuex-typescript-interface';

import { RootState } from 'src/store/types';

import AuthClientInterface from './AuthClient.interface';
import { AuthServiceInterface } from './AuthService.interface';
import { AuthorizeError } from './errors';

import { enforceProductIdsAsStringAndCleanupNullProducts } from 'src/store/modules/auth.helper';

import { Unit } from 'types/unit';
import { User, SettingsResponse, RawUserFromApi } from 'types/user';

export class AuthService implements AuthServiceInterface {
  #authClient: AuthClientInterface;
  #getStore: () => Store<RootState>;
  #axiosInstance: AxiosInstance;
  user: User | undefined = undefined;
  userSettings: SettingsResponse | undefined = undefined;
  units: Unit[] = [];

  constructor(
    authClient: AuthClientInterface,
    getStore: () => Store<RootState>,
    axiosInstance: AxiosInstance,
  ) {
    this.#authClient = authClient;
    this.#getStore = getStore;
    this.#axiosInstance = axiosInstance;
  }

  async login(origin: string): Promise<void> {
    return this.#authClient.signInWithRedirect(origin);
  }

  async logout(origin: string): Promise<void> {
    await this.#authClient.revokeRefreshToken();
    return this.#authClient.logout(origin);
  }

  async handleSignInRedirectCallback(): Promise<string> {
    try {
      const { origin } = await this.#authClient.handleSignInRedirect();
      return origin;
    } catch (err) {
      throw new AuthorizeError(err);
    }
  }

  async handleLogoutRedirectCallback(): Promise<void> {
    await this.clearAuthSession();
  }

  async clearAuthSession(): Promise<void> {
    await this.#getStore().dispatch('onLogout');
  }

  async loadUserData(): Promise<void> {
    const rawUser = await this.#axiosInstance.get<RawUserFromApi>('users/current');
    this.user = rawUser ? enforceProductIdsAsStringAndCleanupNullProducts(rawUser) : rawUser;
    this.#getStore().dispatch('setUser', this.user);

    this.userSettings = await this.#axiosInstance.get<SettingsResponse>('users/current/settings');
    this.#getStore().dispatch('loadSettings', this.userSettings, { root: true });

    this.units = (await this.#axiosInstance.get<Unit[]>(`/units`)) ?? [];
    this.#getStore().dispatch('setUnits', this.units);
  }

  async hasToken(): Promise<boolean> {
    return this.#authClient.isAuthenticated();
  }

  async getToken(): Promise<string> {
    try {
      return await this.#authClient.getToken();
    } catch (e) {
      console.error(e);
      await this.logout(window.location.pathname);
      throw e;
    }
  }

  getRefreshToken(): string | undefined {
    const auth0DataRegExp = /@@auth0spajs@@.+openid profile email/;
    const auth0DataStr = Object.entries(localStorage)?.find(([key]) =>
      auth0DataRegExp.test(key),
    )?.[1];
    if (!auth0DataStr) {
      return;
    }
    return JSON.parse(auth0DataStr)?.body?.refresh_token;
  }

  hasUserData(): boolean {
    return isDefined(this.user) && isDefined(this.userSettings) && this.units.length > 0;
  }
}
