<script setup lang="ts">
import { useApplicationProviders } from 'src/app/providers/useApplicationProviders';
import store from 'src/store';

import { useShowIdsKeyboardShortcut } from './hooks/useShowIdsKeyboardShortcut';

useShowIdsKeyboardShortcut(store);

useApplicationProviders();
</script>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<!-- eslint-disable vue-scoped-css/enforce-style-type -->
<style lang="scss">
@use 'src/scss/base';
</style>
