<script setup lang="ts">
import { Props as UnstyledInputProps } from './UnstyledInput.props';

type Props = {
  value: UnstyledInputProps['value'];
  placeholder?: UnstyledInputProps['placeholder'];
};

type Emits = {
  (e: 'update', v: string): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
  (e: 'backspace'): void;
};

defineProps<Props>();
const emit = defineEmits<Emits>();

const onInput = (e: unknown) => {
  const event = e as Event;
  const target = event.target as HTMLInputElement;
  emit('update', target.value);
};
</script>

<template>
  <input
    :value="value"
    :placeholder="placeholder"
    class="UnstyledInput"
    @input="onInput"
    @keydown.delete="$emit('backspace')"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  />
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';
@use 'src/scss/variables';

@mixin input-reset {
  outline: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  font-size: variables.$font-size-normal;
}

.UnstyledInput {
  color: theme.$color-text-primary;

  @include input-reset;
  width: 100%;

  &::placeholder {
    font-size: variables.$font-size-normal;
    color: theme.$color-text-placeholder;
    font-weight: variables.$font-regular;
  }
}
</style>
