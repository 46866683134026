import { RouteRecordRaw } from '@kpler/web-ui';

import { featureFlagService } from 'src/app/providers/singletons/featureFlagService';
import { AppRoutes } from 'src/app/routes';
import { FF_SUBSCRIPTION_PAGE, MyAccessRoutesTab } from 'src/domains/myAccess/constants';

import { createAuthorizationHook } from 'src/helpers/router.helper';

const TheMyAccessWrapper = () =>
  import(/* webpackChunkName: "myAccess" */ './TheMyAccessWrapper.vue');
const TheOverviewPage = () =>
  import(/* webpackChunkName: "myAccess" */ './pages/overview/TheOverviewPage.vue');
const TheWhatsNewPage = () =>
  import(/* webpackChunkName: "myAccess" */ './pages/whatsNew/TheWhatsNewPage.vue');
const TheKnowledgeBasePage = () =>
  import(/* webpackChunkName: "myAccess" */ './pages/knowledgeBase/TheKnowledgeBasePage.vue');

const myAccessRoutes: RouteRecordRaw = {
  name: 'my-access',
  path: 'my-access',
  component: TheMyAccessWrapper,
  redirect: 'my-access/overview',
  beforeEnter: createAuthorizationHook(
    () => featureFlagService.isOn(FF_SUBSCRIPTION_PAGE),
    AppRoutes.FORBIDDEN,
  ),
  meta: { title: 'My Access' },
  children: [
    {
      name: MyAccessRoutesTab.OVERVIEW,
      path: 'overview',
      component: TheOverviewPage,
    },
    {
      name: MyAccessRoutesTab.WHATS_NEW,
      path: 'whats-new',
      component: TheWhatsNewPage,
    },
    {
      name: MyAccessRoutesTab.KNOWLEDGE_BASE,
      path: 'knowledge-base',
      component: TheKnowledgeBasePage,
    },
  ],
};

export default myAccessRoutes;
