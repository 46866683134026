import { Market } from '@kpler/web-ui';

import { PlatformStaticData } from 'types/staticData';

const mapMarketToPlatform = {
  [Market.LIQUIDS]: PlatformStaticData.COMMODITIES,
  [Market.LNG]: PlatformStaticData.LNG,
  [Market.LPG]: PlatformStaticData.LPG,
  [Market.DRY]: PlatformStaticData.DRY,
};

const mapPlatformToMarket = {
  [PlatformStaticData.COMMODITIES]: Market.LIQUIDS,
  [PlatformStaticData.LNG]: Market.LNG,
  [PlatformStaticData.LPG]: Market.LPG,
  [PlatformStaticData.DRY]: Market.DRY,
};

export const platformsToMarkets = (platforms: PlatformStaticData[]): Market[] =>
  platforms.map(p => mapPlatformToMarket[p]);

export const marketsToPlatforms = (markets: Market[]): PlatformStaticData[] =>
  markets.map(m => mapMarketToPlatform[m]);
