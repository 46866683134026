import { RouteRecordRaw } from '@kpler/web-ui';

import store from 'src/store';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

const TheSupplyDemandWorkspace = () =>
  import(
    /* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/TheSupplyDemandWorkspace.vue'
  );

const OverviewTab = () =>
  store.getters.userHasAccessToSupplyDemand
    ? import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/OverviewTab.vue')
    : import(
        /* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/WorkspacePreview/OverviewTabPreview.vue'
      );

const MetricsTab = () =>
  store.getters.userHasAccessToSupplyDemand
    ? import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/MetricsTab.vue')
    : import(
        /* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/WorkspacePreview/MetricsTabPreview.vue'
      );

const DatasetsTab = () =>
  import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/DatasetsTab.vue');

export enum SupplyDemandRoutes {
  OVERVIEW = 'supply-demand-overview',
  METRICS = 'supply-demand-metrics',
  DATASETS = 'supply-demand-datasets',
}

const supplyDemandRoutes: RouteRecordRaw = {
  name: 'supply-demand',
  path: 'supply-demand',
  component: TheSupplyDemandWorkspace,
  redirect: 'supply-demand/overview',
  beforeEnter: (_to, _from, next) => {
    const nextWithRefresh = forceRefreshGuard(_to, next);
    if (!store.getters.userHasAccessToSupplyDemand) {
      return nextWithRefresh();
    }
    if (typeof _to.query.products === 'string') {
      const productIds = _to.query.products.split(',');
      const userHasAllPermissions = productIds.every(product =>
        store.getters.userHasPermission(`supply_demand:basic:ui_access:${product}`),
      );
      if (userHasAllPermissions) {
        return nextWithRefresh();
      }
    }
    const sortedProducts = store.getters.accessibleSupplyDemandProducts.sort();
    return nextWithRefresh({
      name: 'supply-demand',
      query: {
        ..._to.query,
        products: sortedProducts[0].toString(),
      },
    });
  },
  meta: { title: 'Supply & Demand' },
  children: [
    {
      path: 'overview',
      name: SupplyDemandRoutes.OVERVIEW,
      component: OverviewTab,
    },
    {
      path: 'metrics',
      name: SupplyDemandRoutes.METRICS,
      component: MetricsTab,
    },
    {
      path: 'datasets',
      name: SupplyDemandRoutes.DATASETS,
      component: DatasetsTab,
    },
  ],
};

export default supplyDemandRoutes;
