import { AxiosRequestConfig } from 'axios';

export const axiosRetryState = {
  current: new Map<string, number>(),
};

export const getRequestKey = (requestConfig: AxiosRequestConfig) =>
  btoa(JSON.stringify(requestConfig));

export const MAX_RETRIES = 3;
