<script setup lang="ts">
import { computed, onMounted, provide, ref, watch } from 'vue';

import { Icon } from '../Icon';

import KplerWordmark from './KplerWordmark.vue';
import { BRAND_COLOR_INJECTION_KEY } from './NavigationItem/useInjectBrandColor';
import { EXPANDED_INJECTION_KEY } from './NavigationItem/useInjectExpanded';
import { Brand } from './types';

const NAV_WIDTH_COLLAPSED = 44;
const NAV_WIDTH_EXPANDED = 178;

type Props = {
  isPinned: boolean;
  brand?: Brand;
};

type Emits = {
  (e: 'pin'): void;
};

const props = withDefaults(defineProps<Props>(), {
  isPinned: false,
  brand: 'commodities',
});

defineEmits<Emits>();

const isExpanded = ref<boolean>(false);

const shouldExpand = computed(() => {
  return props.isPinned || isExpanded.value;
});

const brandColor = computed(() => {
  return `var(--color-brand-${props.brand})`;
});

onMounted(() => toggleNavWidth(props.isPinned));

watch(
  () => props.isPinned,
  isPinned => toggleNavWidth(isPinned)
);

function setNavWidth(width: number) {
  const root = document.documentElement;
  root.style.setProperty('--nav-menu-width', `${width}px`);
}

function toggleNavWidth(expanded: boolean) {
  return expanded ? setNavWidth(NAV_WIDTH_EXPANDED) : setNavWidth(NAV_WIDTH_COLLAPSED);
}

const expand = () => {
  isExpanded.value = true;
};

const collapse = () => {
  if (props.isPinned) {
    return;
  }
  isExpanded.value = false;
};

provide(EXPANDED_INJECTION_KEY, shouldExpand);
provide(BRAND_COLOR_INJECTION_KEY, brandColor);
</script>

<template>
  <div class="width-wrapper">
    <nav
      class="MainNavigation"
      :class="{ 'is-expanded': shouldExpand }"
      theme="dark"
      @mouseover="expand"
      @mouseleave="collapse"
    >
      <div class="overflow-wrapper">
        <div class="header">
          <div v-show="shouldExpand" class="space-between flex-1 ph-xs">
            <KplerWordmark class="kpler-wordmark" />

            <div class="pin-icon" @click.stop="$emit('pin')">
              <Icon v-show="isPinned" name="pinned" />
              <Icon v-show="!isPinned" name="pin" />
            </div>
          </div>

          <Icon v-show="!shouldExpand" name="kpler" class="kpler-signet" />
        </div>

        <div class="flex-1 d-flex flex-column gap-xs mt">
          <slot name="main"></slot>
        </div>

        <div class="flex-0 d-flex flex-column gap-xs">
          <slot name="footer"></slot>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';
@use 'src/scss/variables';
@use 'src/scss/z-index';

$height-logo: 24px;
$nav-menu-expanded: 178px;
$delay-transition: 0.2s;

%menuWidth {
  width: var(--nav-menu-width);
  min-width: var(--nav-menu-width);
}

.width-wrapper {
  @extend %menuWidth;
  transition: width $delay-transition, min-width $delay-transition;
}

.MainNavigation {
  @extend %menuWidth;
  background-color: theme.$color-bg-tertiary;
  color: theme.$color-text-primary;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  border-right: 4px solid v-bind(brandColor);
  transition: width $delay-transition;

  &.is-expanded {
    width: $nav-menu-expanded;
    z-index: z-index.$nav;

    .header {
      justify-content: start;
    }
  }
}

.overflow-wrapper {
  padding: 10px 6px 6px 6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 6px;
}

.is-expanded .overflow-wrapper {
  overflow-y: auto;
}

.header {
  min-height: calc($height-logo + 16px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.kpler-signet {
  font-size: $height-logo;
}

.kpler-wordmark {
  height: $height-logo;
}

.pin-icon {
  color: theme.$color-text-secondary;
  cursor: pointer;
  border-radius: variables.$spacing-xs;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: theme.$color-text-primary;
  }
}
</style>
