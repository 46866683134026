import { RouteLocationRaw } from '@kpler/web-ui';

import { CargoRouteName } from 'src/domains/cargo/enums';
import { FreightRouteName } from 'src/domains/freight/TheFreightWorkspace/enums';
import { LngInstallationMetricsRouteName } from 'src/domains/onshore-asset-monitoring/lngInstallationMetrics/enums';

import { Homepage } from 'types/legacy-globals';
import { HomepageConfig, LegacyHomepageConfig } from 'types/user';

const homepageToRouteNameMap: { [key in Homepage]: string } = {
  [Homepage.RESEARCH_NEWS]: 'insight-homepage',
  [Homepage.MAP]: 'map',
  [Homepage.DASHBOARD]: 'dashboard',
  [Homepage.FLOWS]: CargoRouteName.CARGO_FLOWS,
  [Homepage.FLEET_METRICS]: CargoRouteName.CARGO_FLEET_METRICS,
  [Homepage.INVENTORIES]: LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_INVENTORIES,
  [Homepage.BALLAST_CAPACITY]: FreightRouteName.FREIGHT_BALLAST_CAPACITY,
  [Homepage.CONGESTION]: FreightRouteName.FREIGHT_CONGESTION,
  [Homepage.FLEET_DEVELOPMENT]: FreightRouteName.FREIGHT_FLEET_DEVELOPMENT,
  [Homepage.FLEET_UTILIZATION]: FreightRouteName.FREIGHT_FLEET_UTILIZATION,
  [Homepage.FREIGHT_METRICS]: FreightRouteName.FREIGHT_FREIGHT_METRICS,
  [Homepage.SUPPLY_DEMAND]: 'supply-demand',
};

export const isLegacyHomepageConfig = (
  x: HomepageConfig | LegacyHomepageConfig,
): x is LegacyHomepageConfig => typeof x === 'string';

export const getHomepageRoute = (
  homepageConfig: HomepageConfig | LegacyHomepageConfig,
): RouteLocationRaw => {
  if (isLegacyHomepageConfig(homepageConfig)) {
    return { name: homepageToRouteNameMap[homepageConfig] };
  }
  if (homepageConfig.page === Homepage.DASHBOARD) {
    if (homepageConfig.dashboardId === 'overview-dashboard') {
      return { name: 'overview-dashboard' };
    }
    if (homepageConfig.dashboardId === 'overview-dashboard-lng') {
      return { name: 'overview-dashboard-lng' };
    }
    if (homepageConfig.dashboardId === 'overview-dashboard-lpg') {
      return { name: 'overview-dashboard-lpg' };
    }
    if (homepageConfig.dashboardId === 'inventories-dashboard') {
      return { name: 'inventories-dashboard' };
    }
    return { name: homepageConfig.page, params: { id: homepageConfig.dashboardId } };
  }
  return { name: homepageToRouteNameMap[homepageConfig.page] };
};
