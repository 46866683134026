<script setup lang="ts">
import { provide, ref } from 'vue';

import WorkspaceHeader from './WorkspaceHeader.vue';
import WorkspaceSidebar from './WorkspaceSidebar.vue';
import { RESET_FILTERS_FN_INJECTION_KEY } from './useInjectSetResetFilters';

import type { Tab } from '../../MainNavigation';

type Props = {
  navigationTabs: Tab[];
  includeSidebar?: boolean;
};

withDefaults(defineProps<Props>(), { includeSidebar: true });

const resetFiltersFn = ref<() => void>();
const hasSidebarContent = ref(false);

provide(RESET_FILTERS_FN_INJECTION_KEY, (fn: () => void) => {
  resetFiltersFn.value = fn;
});

function resetFilters() {
  if (!resetFiltersFn.value) {
    return;
  }
  resetFiltersFn.value();
}

function handlePortalContentUpdate(hasContent: boolean) {
  hasSidebarContent.value = hasContent;
}
</script>

<template>
  <main :class="['workspace-layout', { 'with-sidebar': includeSidebar }]">
    <WorkspaceSidebar v-if="includeSidebar" :has-content="hasSidebarContent" @reset="resetFilters">
      <portal-target name="workspace-sidebar-filters" @change="handlePortalContentUpdate" />
    </WorkspaceSidebar>
    <section class="workspace-main">
      <WorkspaceHeader :tabs="navigationTabs" />
      <div class="flex-1 overflow-y">
        <router-view />
      </div>
    </section>
  </main>
</template>

<style scoped lang="scss">
.workspace-layout {
  max-width: 100%;
  height: 100%;
  display: grid;

  &.with-sidebar {
    grid-template-columns: min-content 1fr;
  }
}

.workspace-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
</style>
