import { RouteLocationNormalized } from '@kpler/web-ui';
import moment from 'moment';

import { Next } from 'types/legacy-globals';

const appLoad = moment.utc();

const shouldForceRefresh = (): boolean => {
  const now = moment.utc();
  return now.diff(appLoad, 'days') > 1;
};

export const forceRefreshGuard = (to: RouteLocationNormalized, next: Next): Next => {
  if (shouldForceRefresh()) {
    console.warn('Force redirect with page refresh to', to.fullPath);
    return () => {
      window.location.href = to.fullPath;
    };
  }
  return next;
};
