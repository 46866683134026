import { NullableRangeObject } from '@kpler/terminal-utils';
import { Market } from '@kpler/web-ui';

import { VesselMetricsMapPayload } from 'types/map';

export type VesselClassification = {
  commodityType: Market;
  vesselType: string;
  deadWeightRange?: NullableRangeObject;
  capacityRange?: NullableRangeObject;
};

export type PlatformMergeVesselMetricsMapPayload = Omit<
  VesselMetricsMapPayload,
  'vesselClassifications'
> & {
  vesselClassifications: VesselClassification[];
};
export enum VesselClassificationRangeTypes {
  CAPACITY = 'capacity',
  DEADWEIGHT = 'deadweight',
}

export type VesselClassificationRangeType =
  | VesselClassificationRangeTypes.DEADWEIGHT
  | VesselClassificationRangeTypes.CAPACITY;
