import { UNITS_BY_DOMAIN } from 'src/constants/unitsByDomain.constants';

import { AnalyticsDataType } from 'types/analytics';

export const SLIDER_INFINITE_BOUND = 'inf.';

export const floatingStorageDurations = [
  '7',
  '10',
  '12',
  '15',
  '20',
  '30',
  '90',
  SLIDER_INFINITE_BOUND,
];
export const floatingFromDurations = [7, 10, 12, 15, 20, 30, 90];
export const floatingStorageDurationsExtended = [
  '1',
  '3',
  '5',
  '7',
  '10',
  '12',
  '15',
  '20',
  '30',
  '90',
  SLIDER_INFINITE_BOUND,
];

export const unitNames = UNITS_BY_DOMAIN[AnalyticsDataType.FLEET_METRICS].map(u => ({
  id: u,
  name: u,
}));
