import axios from 'axios';

import { ARRAY_REPEAT_SERIALIZER } from 'src/services/axios.service';

const VERSION_HEADERS = Object.freeze({ 'x-web-application-version': window.version });

const NOTIFICATION_API_ENDPOINT = process.env?.NX_PUBLIC_NOTIFICATIONS_API
  ? process.env.NX_PUBLIC_NOTIFICATIONS_API
  : '';

export const notificationsAxiosClient = axios.create({
  baseURL: NOTIFICATION_API_ENDPOINT,
  headers: VERSION_HEADERS,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

export const setupAxiosNotificationsApiInterceptors = () => {
  notificationsAxiosClient.interceptors.response.use(res => res.data);
};
