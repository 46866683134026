import { computed } from 'vue';

import { AppStore } from 'src/store/types';

export const useWorkflowsPermissions = (store: AppStore) => {
  const hasWorkflowsPermissions = computed<boolean>(() =>
    store.getters.userHasOnePermission(['workflows_freight_calc', 'workflows_vessel_matcher']),
  );

  const hasWorkflowsFreightCalcPermissions = computed<boolean>(() =>
    store.getters.userHasPermission('workflows_freight_calc'),
  );

  const hasWorkflowsFreightCalcCostPermissions = computed<boolean>(() =>
    store.getters.userHasPermission('workflows_freight_calc_cost'),
  );

  const hasWorkflowsFreightVesselMatcherPermissions = computed<boolean>(() =>
    store.getters.userHasPermission('workflows_vessel_matcher'),
  );

  const hasBetaVesselMatcherPermissions = computed<boolean>(() =>
    store.getters.userHasPermission('feature_flag:vessel_matcher_2.2'),
  );

  const hasBetaTonnageListPermissions = computed<boolean>(() =>
    store.getters.userHasPermission('feature_flag:tonnage_list'),
  );

  return {
    hasWorkflowsPermissions,
    hasWorkflowsFreightCalcPermissions,
    hasWorkflowsFreightCalcCostPermissions,
    hasWorkflowsFreightVesselMatcherPermissions,
    hasBetaVesselMatcherPermissions,
    hasBetaTonnageListPermissions,
  };
};
