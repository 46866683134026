<script setup lang="ts">
type Props = {
  text?: string;
  prefix?: string;
  disabled?: boolean;
  focused?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  focused: false,
});

const emit = defineEmits<{ (e: 'click'): void }>();

function onClick() {
  if (props.disabled) {
    return;
  }

  emit('click');
}
</script>

<template>
  <div class="dropdown-button" :class="{ disabled, focused }" @click="onClick">
    <label v-if="prefix">{{ prefix }}</label>
    <button>
      <span v-if="text">{{ text }}</span>
      <i class="icon-angle-right rotate-90"> </i>
    </button>
  </div>
</template>

<style scoped lang="scss">
@use 'src/scss/theme';
@use 'src/scss/variables';

.dropdown-button {
  width: fit-content;
  display: flex;
  border: 1px solid theme.$color-border-primary;
  border-radius: variables.$border-radius;
  overflow: hidden;
  color: theme.$color-text-primary;
  line-height: variables.$line-height-dense;
  font-size: variables.$font-size-normal;
  font-weight: variables.$font-medium;
  cursor: pointer;

  &:hover,
  &.focused {
    border-color: theme.$color-border-focus;
  }

  label {
    background-color: theme.$color-bg-quaternary;
    padding: 5.5px variables.$spacing-small;
    cursor: pointer;
  }

  button {
    all: unset;
    display: flex;
    gap: variables.$spacing-xs;
    align-items: center;
    padding: 5.5px variables.$spacing-small;
    background-color: theme.$color-bg-primary;
  }

  &.disabled {
    cursor: not-allowed;
    color: theme.$color-text-secondary;
    border-color: theme.$color-border-secondary;

    label {
      cursor: not-allowed;
    }
  }
}
</style>
