import Toastify from 'toastify-js';

import ToastError from 'src/main/components/ToastError.vue';

import { createApp } from 'src/createApp';

import { AnalystEditError, FlaskV2Error } from 'src/helpers/error.helper';

const baseConfig = {
  duration: 4000,
  close: false,
  gravity: 'bottom',
  positionLeft: false,
  stopOnFocus: true,
};

const toastSuccess = (text = 'Success!', gravity = baseConfig.gravity) => {
  Toastify({
    ...baseConfig,
    text,
    gravity,
    className: 'toastify--success',
  }).showToast();
};

const toastError = (
  text = 'An error has occurred.',
  error: Error | null = null,
  duration = 6000,
) => {
  const node = error ? getToastErrorNode(error, text) : undefined;
  const params = {
    ...baseConfig,
    text,
    duration,
    className: 'toastify--error',
  };
  Toastify(node ? { ...params, duration, close: true, node } : params).showToast();
};

const toastWarning = (text: string) => {
  if (!text) {
    return;
  }
  Toastify({
    ...baseConfig,
    text,
    className: 'toastify--warning',
  }).showToast();
};

function getToastErrorNode(error: Error, title: string) {
  if (error instanceof AnalystEditError) {
    const node = createApp(ToastError, { error, title }).mount();
    return node.$el;
  }
  if (error instanceof FlaskV2Error) {
    const node = createApp(ToastError, { error, title }).mount();
    return node.$el;
  }
  return null;
}

export default {
  toastSuccess,
  toastError,
  toastWarning,
};
