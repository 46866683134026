import { AbstractModuleConfigService } from 'src/core/config/AbstractModuleConfigService';

import { AuthConfig, AuthEnvVars } from './AuthConfig.type';

import CookiesService from 'src/services/cookies.service';

export class AuthConfigService extends AbstractModuleConfigService<AuthEnvVars, AuthConfig> {
  static varNames: Array<keyof AuthEnvVars> = [
    'NX_PUBLIC_AUTH0_AUTHORITY_HOST',
    'NX_PUBLIC_AUTH0_AUDIENCE_HOST',
    'NX_PUBLIC_AUTH0_CLIENT_ID',
  ];

  constructor(envVars: AuthEnvVars) {
    super(AuthConfigService.varNames, envVars);
  }

  getCurrentAuth0AudienceFromCookie(envVars: AuthEnvVars): string {
    const audienceFromCookie = CookiesService.getCookie('auth0Audience');
    const audienceFromEnv = envVars.NX_PUBLIC_AUTH0_AUDIENCE_HOST;

    return audienceFromCookie ?? audienceFromEnv;
  }

  protected parseConfig(envVars: AuthEnvVars): AuthConfig {
    return {
      auth: {
        authorityHost: envVars.NX_PUBLIC_AUTH0_AUTHORITY_HOST,
        audienceHost: this.getCurrentAuth0AudienceFromCookie(envVars),
        clientId: envVars.NX_PUBLIC_AUTH0_CLIENT_ID,
      },
    };
  }
}
