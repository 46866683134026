<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import { isDefined } from '../../../../../../../utils/isDefined';

type Props = {
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{ (e: 'update:checked'): void }>();

const inputRef = ref<HTMLInputElement | undefined>();

onMounted(() => {
  watch(
    () => props.indeterminate,
    () => {
      if (!inputRef.value || !isDefined(props.indeterminate)) {
        return;
      }
      inputRef.value.indeterminate = props.indeterminate;
    },
    { immediate: true }
  );
});
</script>

<template>
  <input
    ref="inputRef"
    :checked="checked"
    :disabled="disabled"
    type="checkbox"
    class="Checkbox"
    @change="emit('update:checked')"
  />
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';
.Checkbox {
  $color-neutral: var(--gray-850);
  $color-disabled: var(--gray-200);

  accent-color: $color-neutral;

  &[disabled] {
    accent-color: $color-disabled;
  }
}
</style>
