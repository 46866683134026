<script setup lang="ts">
import { Item } from './Item';
import { Option } from './types';

type Props = {
  options: readonly Option[];
  active?: string;
};

defineProps<Props>();

const emit = defineEmits<{ (e: 'update:active', v: string): void }>();

function selectOption(option: Option) {
  if (option.disabled) {
    return;
  }

  emit('update:active', option.id);
}
</script>

<template>
  <div class="menu">
    <span v-for="option in options" :key="option.id" @click="selectOption(option)">
      <Item :label="option.label" :disabled="option.disabled" :active="option.id === active" />
    </span>
  </div>
</template>

<style scoped lang="scss">
@use 'src/scss/theme';
@use 'src/scss/variables';

.menu {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: variables.$spacing-xs;
  background-color: theme.$color-bg-primary;
  padding: variables.$spacing-xs;
  border-radius: variables.$border-radius;
  box-shadow: variables.$box-shadow-small;
  white-space: nowrap;
}
</style>
