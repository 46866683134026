import axios from 'axios';

import { apiUrl } from './apiUrl';

import { ARRAY_REPEAT_SERIALIZER, createAuthInterceptor } from 'src/services/axios.service';

const VERSION_HEADERS = Object.freeze({ 'x-web-application-version': window.version });

export const axiosClient = axios.create({
  baseURL: `${apiUrl}/rest`,
  headers: VERSION_HEADERS,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

export const setupAxiosApiInterceptors = () => {
  axiosClient.interceptors.request.use(
    createAuthInterceptor({ includeUseAccessTokenHeader: false }),
  );
  axiosClient.interceptors.response.use(res => res.data);
};
