<script setup lang="ts">
import { Location } from 'vue-router';

import { Icon } from '../../Icon';

import { useInjectBrandColor } from './useInjectBrandColor';
import { useInjectExpanded } from './useInjectExpanded';

import type { IconName } from '../../Icon';

type Props = {
  label: string;
  iconName: IconName;
  to?: Location;
  active?: boolean;
  disabled?: boolean;
};

type Emits = {
  (e: 'click', event: unknown): void;
};

const props = withDefaults(defineProps<Props>(), { active: false });
const emit = defineEmits<Emits>();

const isExpanded = useInjectExpanded();
const brandColor = useInjectBrandColor();

function handleClick(event: unknown) {
  if (props.disabled) {
    return;
  }
  emit('click', event);
}
</script>

<template>
  <component
    :is="!disabled && to ? 'router-link' : 'div'"
    :to="to"
    class="NavigationItem"
    :class="{ active, disabled }"
    theme="dark"
    @click="handleClick"
  >
    <Icon :name="iconName" />
    <div v-show="isExpanded" class="flex-1 space-between">
      <span class="label">{{ label }}</span>
      <Icon v-if="disabled" class="locked-icon" name="locked" />
    </div>
  </component>
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';
@use 'src/scss/variables';

.NavigationItem {
  background-color: theme.$color-bg-tertiary;
  color: theme.$color-text-primary;
  text-decoration: none;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: variables.$spacing-small;
  border-radius: variables.$spacing-xs;
  cursor: pointer;
  transition: all 0.2s;

  &.active {
    background-color: v-bind(brandColor);
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:hover:not(.active) {
    background-color: theme.$color-bg-quaternary;
  }

  &:hover:is(.disabled) {
    background-color: theme.$color-bg-secondary;
  }
}

.label {
  font-size: variables.$font-size-almost-large;
  line-height: 16px;
  white-space: nowrap;
}

.locked-icon {
  font-size: variables.$font-size-small;
  color: theme.$color-text-secondary;
}
</style>
