export const CRUDE_OIL_INVENTORIES_PERMISSIONS = [
  'storage_oil',
  'storage_data_trial',
  'crude_oil_inventory:read',
];
export const LNG_INVENTORIES_PERMISSIONS = [
  'european_gas_all_installations',
  'european_gas',
  'north_american_gas',
];
