import { RangeNumber } from '@kpler/terminal-utils';
import { Market } from '@kpler/web-ui';
import { Feature, Polygon, MultiPolygon, Point } from 'geojson';

import { Cyclone } from 'src/main/map/cycloneObservable.rx';
import { VesselClassificationRangeTypes } from 'src/platform-merge/analytics/VesselClassificationFilter/types';
import { MapView } from 'src/platform-merge/map/enums';

import type { ObjectBase } from '@kpler/terminal-utils';
import { MapTooltipProps } from 'types/genericMap';
import {
  BetaStatus,
  CargoState,
  InstallationFragment,
  InstallationTypeFilter,
  LayerState,
  PlayerFragment,
  ProductFragment,
  Speed,
  VesselFragment,
  VesselStatus,
  YesNo,
  ZoneFragment,
  ZoneTypeFilter,
} from 'types/graphql';
import { InstallationPayload } from 'types/installation';
import {
  LatLon,
  MapSearchCategory,
  ResourceType,
  LatitudeLongitudeMaybe,
  LatitudeLongitudeNullable,
  VesselTypeClassification,
  Location,
} from 'types/legacy-globals';
import { Unit } from 'types/unit';
import { VesselMapPayload } from 'types/vessel';

export type MapSearchMapping = {
  products: readonly string[];
  vessels: readonly number[];
  locations: readonly string[];
  loads: readonly string[];
  discharges: readonly string[];
  players: readonly number[];
  fields: readonly MapSearchCategory[];
};

export enum MapBackground {
  DEFAULT = 'default',
  MAP = 'map',
  SATELLITE = 'satellite',
}

export enum VesselStateForRoute {
  OPEN = 'open',
  LAID_UP = 'laidUp', // <- no vessels on all 5 platforms have this status
  FLOATING_STORAGE = 'floating storage',
  UNDER_CONSTRUCTION = 'under construction',
  INACTIVE = 'inactive',
  IN_SERVICE = 'in service',
}

export type MapFilters = {
  cargoStatus: Set<CargoState>;
  vesselStates: Set<VesselStatus>;
  markets: Set<Market>;
  view: MapView;
  vesselTypes: Set<string>;
  vesselTypesOil: Set<string>;
  vesselTypesCpp: Set<string>;
  speed: Set<Speed>;
  engine: Set<string>;
  carrierType: Set<string>;
  ethyleneCapable: Set<YesNo>;
  asphaltBitumenCapable: Set<YesNo>;
  betaVesselStatus: Set<BetaStatus>;
  capacity: RangeNumber | null;
  vesselClassificationRangeType: VesselClassificationRangeTypes | null;
  buildYear: RangeNumber | null;
  draught: RangeNumber | null;
  installationTypes: Set<InstallationTypeFilter>;
  installationStatus: Set<string>;
  betaInstallationStatus: Set<BetaStatus>;
  zoneTypes: Set<ZoneTypeFilter>;
  cargoTypes: Set<string>;
};

export type MapFiltersMapping = {
  cargoStatus: Set<'loaded' | 'ballast'>;
  vesselStates: Set<VesselStateForRoute>;
  markets: Set<Market>;
  view: MapView;
  vesselTypes: Set<string>;
  vesselTypesOil: Set<string>;
  vesselTypesCpp: Set<string>;
  speed: Set<'moving' | 'stopped'>;
  engine: Set<string>;
  carrierType: Set<string>;
  ethyleneCapable: Set<'yes' | 'no'>;
  asphaltBitumenCapable: Set<'yes' | 'no'>;
  betaVesselStatus: Set<'beta' | 'regular'>;
  capacity: RangeNumber | null;
  vesselClassificationRangeType: VesselClassificationRangeTypes | null;
  draught: RangeNumber | null;
  buildYear: RangeNumber | null;
  cargoTypes: Set<string>;
  installationTypes: Set<'export' | 'import' | 'storage' | 'shipyard' | 'anchorage' | 'refinery'>;
  installationStatus: Set<string>;
  betaInstallationStatus: Set<'beta' | 'regular'>;
  zoneTypes: Set<'sea' | 'country' | 'subregion'>;
};

export type MapLayers = {
  vesselLayer: LayerState;
  installationLayer: LayerState;
  zoneLayer: LayerState;
  pipelineLayer: LayerState;
};

export type MapLayersMapping = {
  vesselLayer: LayerStateForRoute;
  installationLayer: LayerStateForRoute;
  zoneLayer: LayerStateForRoute;
  pipelineLayer: LayerStateForRoute;
};

export type MapMapping = MapSearchMapping & MapFiltersMapping & MapLayersMapping;

type WithNumericalID<T extends { id: string }> = Omit<T, 'id'> & { id: number };

// @TODO update to use stringified ID
export type MapInstallation = WithNumericalID<InstallationFragment>;
export type MapZone = WithNumericalID<ZoneFragment>;
export type MapVessel = WithNumericalID<VesselFragment>;
export type MapProduct = ProductFragment;
export type MapPlayer = WithNumericalID<PlayerFragment>;

export type MapArea =
  | (MapInstallation & { resourceType: ResourceType.INSTALLATION })
  | (MapZone & { resourceType: ResourceType.ZONE });

export type MapSearch = {
  categories: readonly MapSearchCategory[];
  locations: readonly MapArea[] | { loads: readonly MapArea[]; discharges: readonly MapArea[] };
  vessels: readonly MapVessel[];
  players: readonly MapPlayer[];
  products: readonly MapProduct[];
};

export type MapSearchDehydrated = {
  categories: readonly MapSearchCategory[];
  locations: readonly Location[] | { loads: readonly Location[]; discharges: readonly Location[] };
  vessels: readonly number[];
  players: readonly number[];
  products: readonly string[];
};

export type MinMaxRange = {
  min: number;
  max: number;
};
export type VesselMetricsMapPayload = {
  complianceMethods: string[];
  vesselClassifications: string[];
  engineTypes: string[];
  draught: MinMaxRange;
  buildYear: MinMaxRange;
  cargoQuantity: {
    min: { volume: number; volume_gas: number; mass: number };
    max: { volume: number; volume_gas: number; mass: number };
  };
  deadWeight: {
    min: number;
    max: number;
  };
};

export type MapFiltersPayload = {
  vesselIds: number[] | 'all';
  installationIds: number[];
};

export type CargoProduct = {
  id: number;
  name: string;
  ancestors: Array<{ id: number; name: string }>;
};

export type CurrentCargo = {
  loaded: boolean;
  products?: readonly CargoProduct[];
};

export type Position = {
  id: number;
  geo: LatLon;
  currentCargo: CurrentCargo;
  speed: number;
  receivedTime: string;
  draught: number | null;
  draughtChange: number | null;
  draughtComputed: number;
  // We need to consider the null case.
  // Some positions are added manually by analysts.
  provider: {
    id: number;
    fullname: string;
  } | null;
};

export type TooltipPositionInfo = Position & {
  latency: number | null;
  vesselId: number;
};

export type Focus = {
  vessels: number[];
  installations: number[];
  positions: LatLon[];
};

export type MapSelectableItem =
  | { id: number; resourceType: ResourceType.VESSEL }
  | { id: number; resourceType: ResourceType.INSTALLATION };

export type TankMapData = {
  tankType: string; // @TODO why not TankType enum?
  percentage?: number;
  volume?: number;
  name: string;
};

export type TankDataStore = {
  [key: number]: TankMapData;
};

type EditCoordinates = {
  geo: LatitudeLongitudeNullable | LatitudeLongitudeMaybe | MultiPolygon | null;
  editableType: EditableType;
};

export type EditableCircle = EditCoordinates & {
  geo: LatitudeLongitudeNullable;
  range: number | null;
  resourceType: ResourceType | null;
  editableType: EditableType.CIRCLE;
};

type EditableCircleFeature = Feature<
  Polygon,
  {
    isCircle: boolean;
    center: RangeNumber;
    radiusInKm: number;
    editableType: EditableType.CIRCLE;
  }
>;

export type EditableMarker = EditCoordinates & {
  geo: LatitudeLongitudeMaybe;
  resourceType: ResourceType;
  editableType: EditableType.POINT;
  editMode: boolean;
};

export type EditableMultiPolygon = {
  geo: MultiPolygon | null;
  editableType: EditableType.GENERIC_POLYGON;
  editMode: boolean;
  show: boolean;
};

export type EditablePointFeature = Feature<
  Point,
  {
    editableType: EditableType.POINT;
  }
>;

export type EditableMultiPolygonFeature = Feature<
  MultiPolygon,
  {
    editableType: EditableType.GENERIC_POLYGON;
  }
>;

// Geojson
export type EditableFeature =
  | EditableMultiPolygonFeature
  | EditablePointFeature
  | EditableCircleFeature;

// Kpler object in the store
export type EditableElement = EditableCircle | EditableMarker | EditableMultiPolygon;

export enum EditableType {
  POINT = 'point',
  GENERIC_POLYGON = 'generic-polygon',
  CIRCLE = 'circle',
}

export type DrawingEvent = {
  features: EditableFeature[];
  action: string;
};

export enum MapTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

// @TODO rename to Icon
export enum Icons {
  LOADED_LIGHT = 'loaded-light',
  LOADED_DARK = 'loaded-dark',
  BALLAST_LIGHT = 'ballast-light',
  BALLAST_DARK = 'ballast-dark',
  STORAGE_ONLY_LIGHT = 'storage-only-light',
  STORAGE_ONLY_DARK = 'storage-only-dark',
  OPEN_INSTALLATION_LIGHT = 'open-installation-light',
  OPEN_INSTALLATION_DARK = 'open-installation-dark',
  OPEN_INSTALLATION_EVENTS_LIGHT = 'open-installation-events-light',
  OPEN_INSTALLATION_EVENTS_DARK = 'open-installation-events-dark',
  IMPORT_CARGO_ONLY_LIGHT = 'import-cargo-light',
  IMPORT_CARGO_ONLY_DARK = 'import-cargo-dark',
  EXPORT_CARGO_ONLY_LIGHT = 'export-cargo-light',
  EXPORT_CARGO_ONLY_DARK = 'export-cargo-dark',
  IMPORT_STORAGE_CARGO_LIGHT = 'import-storage-cargo-light',
  IMPORT_STORAGE_CARGO_DARK = 'import-storage-cargo-dark',
  EXPORT_STORAGE_CARGO_LIGHT = 'export-storage-cargo-light',
  EXPORT_STORAGE_CARGO_DARK = 'export-storage-cargo-dark',
  STORAGE_ONLY_EVENTS_LIGHT = 'storage-only-events-light',
  STORAGE_ONLY_EVENTS_DARK = 'storage-only-events-dark',
  IMPORT_CARGO_ONLY_EVENTS_LIGHT = 'import-cargo-events-light',
  IMPORT_CARGO_ONLY_EVENTS_DARK = 'import-cargo-events-dark',
  EXPORT_CARGO_ONLY_EVENTS_LIGHT = 'export-cargo-events-light',
  EXPORT_CARGO_ONLY_EVENTS_DARK = 'export-cargo-events-dark',
  IMPORT_STORAGE_CARGO_EVENTS_LIGHT = 'import-storage-cargo-events-light',
  IMPORT_STORAGE_CARGO_EVENTS_DARK = 'import-storage-cargo-events-dark',
  EXPORT_STORAGE_CARGO_EVENTS_LIGHT = 'export-storage-cargo-events-light',
  EXPORT_STORAGE_CARGO_EVENTS_DARK = 'export-storage-cargo-events-dark',
  SHIPYARD_LIGHT = 'shipyard-light',
  SHIPYARD_DARK = 'shipyard-dark',
  ANCHORAGE_LIGHT = 'anchorage-light',
  ANCHORAGE_DARK = 'anchorage-dark',
  OTHER_LIGHT = 'other-light',
  OTHER_DARK = 'other-dark',
  FLOATING_STORAGE_LIGHT = 'floating-storage-light',
  FLOATING_STORAGE_DARK = 'floating-storage-dark',
  OPEN_LIGHT = 'open-light',
  OPEN_DARK = 'open-dark',
  CONSTRUCTION_LIGHT = 'contruction-light',
  CONSTRUCTION_DARK = 'contruction-dark',
  BALLAST_ARROW_LIGHT = 'ballast-arrow-light',
  BALLAST_ARROW_DARK = 'ballast-arrow-dark',
  LOADED_ARROW_LIGHT = 'loaded-arrow-light',
  LOADED_ARROW_DARK = 'loaded-arrow-dark',
  OTHER_ARROW_LIGHT = 'other-arrow-light',
  OTHER_ARROW_DARK = 'other-arrow-dark',
  BLUE_ARROW_LIGHT = 'blue-arrow-light',
  BLUE_ARROW_DARK = 'blue-arrow-dark',
  CYCLONE = 'cyclone',
  INSTALLATION_STATIC_DATA_GREY = 'installation-static-data-grey',
  INSTALLATION_STATIC_DATA_BLACK = 'installation-static-data-black',
  INSTALLATION_STATIC_DATA_ORANGE = 'installation-static-data-orange',
  VESSEL_MARKET_DRY = 'vessel-market-dry',
  VESSEL_MARKET_LIQUIDS = 'vessel-market-liquids',
  VESSEL_MARKET_LNG = 'vessel-market-lng',
  VESSEL_MARKET_LPG = 'vessel-market-lpg',
  VESSEL_DEFAULT = 'vessel-inactive',
  INTERCONNECTION_ARROW = 'interconnection-arrow',
}

export type VesselTooltipProps = MapTooltipProps & {
  accessor: (id: number) => VesselMapPayload | null;
  unit: Unit;
  vesselClassification: VesselTypeClassification;
};

export type CycloneTooltipProps = MapTooltipProps & {
  accessor: (id: string) => Cyclone;
};

export type InstallationTooltipProps = MapTooltipProps & {
  accessor: (id: number) => InstallationPayload | null;
};

export type PositionTooltipProps = MapTooltipProps & {
  id: number;
  accessor: (id: number) => TooltipPositionInfo | null;
  vesselAccessor: (id: number) => VesselMapPayload | null;
};

export type SearchableResourceType =
  | ResourceType.INSTALLATION
  | ResourceType.PLAYER
  | ResourceType.PRODUCT
  | ResourceType.VESSEL
  | ResourceType.ZONE;

export type MinimalMapSearchItem = {
  id: string | number;
  name: string;
  resourceType: SearchableResourceType;
};

type IsNew = {
  isNew?: boolean;
};

export type BookmarkTagData = {
  loads: Array<MinimalMapSearchItem & IsNew>;
  discharges: Array<MinimalMapSearchItem & IsNew>;
  locations: Array<MinimalMapSearchItem & IsNew>;
  vessels: Array<ObjectBase<string> & IsNew>;
  products: Array<ObjectBase<string> & IsNew>;
  players: Array<ObjectBase<string> & IsNew>;
};

export type PinMapSearchConfig =
  | { context: 'search' }
  | { context: 'pageView'; item: MinimalMapSearchItem };

export type InstallationTypeForRoute =
  | 'export'
  | 'import'
  | 'storage'
  | 'shipyard'
  | 'anchorage'
  | 'refinery';

export type ZoneTypeForRoute = 'sea' | 'country' | 'subregion';

export type LayerStateForRoute = 'all' | 'default' | 'hidden';

type MapSearchUpdatePayloadVessel = {
  category: MapSearchCategory.VESSEL;
  values: readonly MapVessel[];
};
type MapSearchUpdatePayloadPlayer = {
  category: MapSearchCategory.PLAYER;
  values: readonly MapPlayer[];
};
type MapSearchUpdatePayloadProduct = {
  category: MapSearchCategory.PRODUCT;
  values: readonly MapProduct[];
};
export type MapSearchUpdatePayloadLocation = {
  category: MapSearchCategory.LOCATION;
  values: readonly MapArea[];
  secondaryValues?: readonly MapArea[];
};
export type MapSearchUpdatePayload =
  | MapSearchUpdatePayloadVessel
  | MapSearchUpdatePayloadPlayer
  | MapSearchUpdatePayloadProduct
  | MapSearchUpdatePayloadLocation;
