import { Market } from '@kpler/web-ui';
import { Moment } from 'moment';

import type { ObjectBase } from '@kpler/terminal-utils';
import { Installation } from 'types/installation';
import { Linkable, PaginationParams, ResourceType, Location } from 'types/legacy-globals';

export enum ContractDelivery {
  CIF = 'CIF',
  DES = 'DES',
  FOB = 'FOB',
  FOBORCIF = 'FOB/CIF',
  FOBORDES = 'FOB/DES',
}

export enum ContractType {
  LTA = 'LTA',
  SPA = 'SPA',
  TENDER = 'Tender',
  TUA = 'TUA',
}

export enum ContractStatus {
  HOAS = 'HoAs',
  CANCELLED = 'Cancelled',
  SIGNED = 'Signed',
  AWARDED = 'Awarded',
}
export type Contract = {
  aggregatedCapacity: {
    confirmed: {
      [key: number]: {
        capacity: number;
        slots: number;
      };
    };
    potential: {
      [key: number]: {
        capacity: number;
        slots: number;
      };
    };
  };
  buyer: Linkable;
  capacity: number | null;
  comment: string | null;
  delivery: ContractDelivery;
  endDate: string | null;
  fixedPrice: string | null;
  id: number;
  installationsDelivery: Installation[];
  installationsSales: Installation[];
  open: boolean;
  resourceType: ResourceType.CONTRACT;
  seller: Linkable;
  slot: number | null;
  startDate: string | null;
  terminated: boolean;
  type: ContractType;
  products: ContractProduct[];
};

export type ContractProduct = {
  id: number;
  name: string;
};

export type ContractFilterResourceParams = {
  locations?: readonly string[] | readonly Location[];
  players?: readonly number[];
};

export type ContractParams = PaginationParams &
  ContractFilterResourceParams & {
    types?: readonly ContractType[];
  };

export type ContractForm = {
  id: number | null;
  type: ContractType;
  delivery: ContractDelivery | null;
  seller: ObjectBase<number> | null;
  buyer: ObjectBase<number> | null;
  startDate: Moment | null;
  endDate: Moment | null;
  openDate: Moment | null;
  closedDate: Moment | null;
  status: ContractStatus | null;
  capacity: number | null;
  slot: number | null;
  comment: string | null;
  installationsSales: Array<ObjectBase<number>>;
  installationsDelivery: Array<ObjectBase<number>>;
  isFlexible: boolean;
  lowPriority: boolean;
  commodityType: Market | null;
};

export type FlaskContractPayload = {
  type: ContractType;
  delivery: ContractDelivery | null;
  buyerId: number | null;
  sellerId: number | null;
  startDate: string | null;
  endDate: string | null;
  openDate: string | null;
  closedDate: string | null;
  status: ContractStatus | null;
  capacity: number | null;
  slot: number | null;
  comment: string | null;
  salesInstallationIds: number[];
  deliveryInstallationIds: number[];
  isFlexible: boolean | null;
  lowPriority: boolean;
};

export type FlaskContractFromApi = {
  id: number;
  type: ContractType;
  delivery: ContractDelivery | null;
  seller: ObjectBase<number> | null;
  buyer: ObjectBase<number> | null;
  startDate: string | null;
  endDate: string | null;
  openDate: string | null;
  closedDate: string | null;
  status: ContractStatus | null;
  capacity: number | null;
  slot: number | null;
  comment: string | null;
  salesInstallations: Array<ObjectBase<number>>;
  deliveryInstallations: Array<ObjectBase<number>>;
  isFlexible: boolean;
  lowPriority: boolean;
  isOpenTender: boolean;
  addedAt: string;
  updatedAt: string | null;
  createdByAnalystId: string | null;
  updatedByAnalystId: string | null;
};

export type ResourceWithContracts =
  | ResourceType.INSTALLATION
  | ResourceType.PLAYER
  | ResourceType.ZONE;
