export enum FreightRouteName {
  FREIGHT = 'freight',
  FREIGHT_BALLAST_CAPACITY = 'freight-ballast-capacity',
  FREIGHT_BALLAST_CAPACITY_DETAILS = 'freight-ballast-capacity-details',
  FREIGHT_CONGESTION = 'freight-congestion',
  FREIGHT_CONGESTION_DETAILS = 'freight-congestion-details',
  FREIGHT_FLEET_DEVELOPMENT = 'freight-fleet-development',
  FREIGHT_FLEET_DEVELOPMENT_DETAILS = 'freight-fleet-development-details',
  FREIGHT_FLEET_UTILIZATION = 'freight-fleet-utilization',
  FREIGHT_FLEET_UTILIZATION_DETAILS = 'freight-fleet-utilization-details',
  FREIGHT_FREIGHT_METRICS = 'freight-freight-metrics',
  FREIGHT_FREIGHT_METRICS_DETAILS = 'freight-freight-metrics-details',
}
