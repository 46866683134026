/// <reference types="segment-analytics" />

import mapbox from 'mapbox-gl';

import store from 'src/store';

import CookiesService from 'src/services/cookies.service';

import { platform } from 'src/helpers/platform.helper';

import { User } from 'types/user';

const identify = async (user: User) => {
  window.analytics.identify(user.id, {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,

    // Fullstory specific settings:
    // https://segment.com/docs/destinations/fullstory/
    displayName: `${user.firstName} ${user.lastName}`,
  });
  const sentry = await import(/* webpackChunkName: "sentry" */ '@sentry/vue');

  sentry.setUser({
    id: user.id,
    email: user.email,
    username: `${user.firstName} ${user.lastName}`,
  });
};

const reset = async () => {
  window.analytics.reset();
  const sentry = await import(/* webpackChunkName: "sentry" */ '@sentry/vue');
  sentry.getCurrentScope().clear();
};

// Track with Segment.
// 'pv' = page view.
const trackEvent = (eventName = 'pv', customOptions: Record<string, any> = {}): void => {
  const { user } = store.state.user;
  if (!user) {
    return;
  }
  const defaultOptions = {
    app_version_number: window.version,
    device: 'desktop',
    host: window.location.hostname,
    url: window.location.pathname + window.location.search, // backwards compatibility
    path: window.location.pathname,
    query: window.location.search,
    current_commodity: platform,
    browser_uuid: CookiesService.getCookie('kpler-session'),
    title: document.title,
    mapboxgl_support: {
      supported: mapbox.supported(),
      supported_without_performance_caveat: mapbox.supported({
        failIfMajorPerformanceCaveat: true,
      }),
    },
  };

  analytics.track(eventName, {
    ...defaultOptions,
    ...customOptions,
  });
};

const trackFilterUsage = (filter: string, value: unknown): void => {
  trackEvent('ux_filter_usage', { filter, value });
};

export default {
  identify,
  reset,
  trackEvent,
  trackFilterUsage,
};
