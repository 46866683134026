<script setup lang="ts">
import { ref } from 'vue';

import { useOutsideClick } from '../../../hooks';
import MarketLegendIndicator from '../MarketIndicator/MarketLegendIndicator.vue';
import { ORDERED_MARKETS } from '../constants';

import { MarketCheckboxGroup } from './MarketCheckboxGroup';

import type { Market } from '../types';

type Props = {
  selectedMarkets: readonly Market[];
  accessibleMarkets: Set<Market>;
};

defineProps<Props>();

const UPDATE_EVENT = 'update:selectedMarkets';

defineEmits<{
  (e: typeof UPDATE_EVENT, v: readonly Market[]): void;
}>();

const shouldShowMarketPanel = ref(false);

const closeMarketPanel = () => {
  shouldShowMarketPanel.value = false;
};

const marketSelectEl = ref<HTMLElement | undefined>();
useOutsideClick(marketSelectEl, closeMarketPanel);

const toggleMarketPanel = () => {
  shouldShowMarketPanel.value = !shouldShowMarketPanel.value;
};
</script>

<template>
  <Fragment>
    <div class="map-control-container checkboxes" data-intercom-target="map-market-select">
      <MarketCheckboxGroup
        :selected="selectedMarkets"
        :accessible-markets="accessibleMarkets"
        @update:selected="$event => $emit(UPDATE_EVENT, $event)"
      />
    </div>

    <div
      ref="marketSelectEl"
      v-tooltip="{ text: 'Markets enabled', disabled: shouldShowMarketPanel }"
      class="map-control-container blob pointer relative"
      data-testid="MarketSelectPureSmall"
      @click="toggleMarketPanel"
    >
      <div class="blob-wrapper">
        <MarketLegendIndicator
          :markets="ORDERED_MARKETS"
          layout="grid"
          :selected-markets="selectedMarkets"
        />

        <div v-if="shouldShowMarketPanel" class="popout" @click.stop>
          <MarketCheckboxGroup
            :selected="selectedMarkets"
            :accessible-markets="accessibleMarkets"
            @update:selected="$event => $emit(UPDATE_EVENT, $event)"
          />
        </div>
      </div>
    </div>
  </Fragment>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';
@use 'src/scss/theme';
@use 'src/scss/z-index';
@use '../controls.scss';

$height: controls.$button-size;

.map-control-container {
  height: $height;
  background: theme.$color-bg-primary;
  padding: 0 variables.$spacing-xs;
  border: 1px solid theme.$color-border-primary;
  border-radius: variables.$border-radius;
  display: flex;
  align-items: center;

  &.checkboxes {
    display: none;
  }

  @container the-map (min-width: 690px) {
    &.blob {
      display: none;
    }
    &.checkboxes {
      display: flex;
    }
  }
}

.popout {
  position: absolute;
  z-index: z-index.$map-popout;
  top: calc(100% + #{variables.$spacing-xs});
  padding: variables.$spacing-small;
  right: 0;
  background-color: theme.$color-bg-primary;
  border-radius: variables.$border-radius;
  box-shadow: variables.$box-shadow;
  border-color: theme.$color-border-primary;
}
</style>
