import { assertDefined } from '@kpler/generic-utils';

export type ModuleConfig = Record<string, Record<string, unknown>>;

export type CombineModuleConfigs<T extends ModuleConfig[]> = T[number];

export class AppConfigService<T extends ModuleConfig[], AppConfig = CombineModuleConfigs<T>> {
  #config: Readonly<AppConfig> | undefined;

  constructor(moduleConfigs: T) {
    const config = this.#mergeModuleConfigs(...moduleConfigs);
    this.#setConfig(config);
  }

  get(): Readonly<AppConfig> {
    assertDefined(this.#config);
    return this.#config;
  }

  #mergeModuleConfigs(...configs: ModuleConfig[]): AppConfig {
    return configs.reduce((acc, config) => ({ ...acc, ...config }), {} as AppConfig);
  }

  #setConfig(config: AppConfig): void {
    this.#config = Object.freeze(config);
  }
}
