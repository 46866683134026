import { AnalyticsDataType } from 'types/analytics';
import { FreightMetricsMetric } from 'types/freightMetrics';
import { TemporalUnitName, UnitName } from 'types/unit';

/** @deprecated must be split by domain and moved into each domain */
export const UNITS_BY_DOMAIN = {
  [AnalyticsDataType.FLOWS]: [
    UnitName.MMBBL,
    UnitName.KB,
    TemporalUnitName.KBD,
    UnitName.CM,
    UnitName.KTONS,
    UnitName.MTONS,
  ],

  [AnalyticsDataType.FLEET_METRICS]: [
    UnitName.KB,
    UnitName.CM,
    UnitName.KTONS,
    UnitName.MTONS,
    UnitName.MMBBL,
  ],

  [AnalyticsDataType.INVENTORIES]: [UnitName.MMBBL, UnitName.KB, UnitName.CM],

  [AnalyticsDataType.CONGESTION]: [UnitName.KTONS, UnitName.MTONS, UnitName.CM],

  [AnalyticsDataType.BALLAST_CAPACITY]: [UnitName.KTONS, UnitName.MTONS, UnitName.CM],

  [AnalyticsDataType.FLEET_UTILIZATION]: [UnitName.KTONS, UnitName.MTONS, UnitName.CM],

  [AnalyticsDataType.FLEET_DEVELOPMENT]: [UnitName.KTONS, UnitName.MTONS, UnitName.CM],

  [AnalyticsDataType.REFINERY_CAPACITIES]: [TemporalUnitName.KBD],
};

export const defaultMapUnit = UnitName.BARREL;

export const freightMetricsUnitsByMetric = {
  [FreightMetricsMetric.TON_MILES]: [UnitName.MTON_MILE],
  [FreightMetricsMetric.TON_DAYS]: [UnitName.KTON_DAY, UnitName.MTON_DAY],
  [FreightMetricsMetric.AVG_SPEED]: [UnitName.KNOT],
  [FreightMetricsMetric.AVG_DISTANCE]: [UnitName.NAUTICAL_MILE],
};
