<script setup lang="ts">
import { useRouter } from '@kpler/terminal-utils';

import { useInjectAuthService } from 'src/features/services/useInjectAuthService';
import { useInjectStore } from 'src/store/useInjectStore';

import TheTermsOfUseOptInPure from './TheTermsOfUseOptInPure.vue';

import { apolloClient } from 'src/services/apollo.service';
import toastService from 'src/services/toast.service';

import { getHomepageRoute } from 'src/helpers/homepage.helper';

import { updateTermsOfUse } from 'src/main/TheTermsOfUseOptIn/UpdateTermsOfUse.gql';

const router = useRouter();
const store = useInjectStore();
const authService = useInjectAuthService();

const acceptTermsOfUse = () => {
  apolloClient
    .mutate({
      mutation: updateTermsOfUse,
      variables: {
        accepted: true,
      },
    })
    .then(() => {
      if (router.currentRoute.value.query.redirect) {
        router.push(router.currentRoute.value.query.redirect.toString());
        return;
      }
      router.push(getHomepageRoute(store.state.settings.homepage));
    })
    .catch(() => {
      toastService.toastError('Error accepting terms of use');
    });
};

const logout = () => {
  authService.logout('/');
};
</script>

<template>
  <TheTermsOfUseOptInPure @accept="acceptTermsOfUse" @cancel="logout" />
</template>
