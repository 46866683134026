import {
  DateRange,
  DateRangePreset,
  Granularity,
  SerializedDateRange,
  RangeNumber,
} from '@kpler/terminal-utils';

import { AnalyticsView } from 'types/analytics';
import { FleetMetricsZoneFragment, ProductFragment } from 'types/graphql';
import {
  ResourceType,
  FlowQuantity,
  OperationalFilter,
  DeadWeightObject,
  CapacityObject,
  VesselTypeClassification,
} from 'types/legacy-globals';
import { PricesBaseFiltersMapping, PricesSidebarFilterState } from 'types/prices';
import { QuantityObject } from 'types/quantity';
import { UnitName } from 'types/unit';

// Used to format frontend urls
export enum FleetMetricsSplit {
  TOTAL = 'total',
  COMMODITY = 'commodity',
  GRADE = 'grade',
  CRUDE_QUALITY = 'crudequality',
  DESTINATION_TRADING_REGION = 'destination--tradingregion',
  DESTINATION_CONTINENT = 'destination--continent',
  DESTINATION_SUBCONTINENT = 'destination--subcontinent',
  DESTINATION_COUNTRY = 'destination--country',
  DESTINATION_INSTALLATION = 'destination--installation',
  DESTINATION_PORT = 'destination--port',
  ORIGIN_TRADING_REGION = 'origin--tradingregion',
  ORIGIN_CONTINENT = 'origin--continent',
  ORIGIN_SUBCONTINENT = 'origin--subcontinent',
  ORIGIN_COUNTRY = 'origin--country',
  ORIGIN_INSTALLATION = 'origin--installation',
  ORIGIN_PORT = 'origin--port',
  VESSEL_TYPE = 'vesseltype',
  TRADE_STATUS = 'tradestatus',
  CHARTERER = 'charterer',
  BUYERS = 'buyer',
  SELLERS = 'seller',
  CURRENT_CONTINENTS = 'Current Continents',
  CURRENT_SUBCONTINENTS = 'Current Subcontinents',
  CURRENT_COUNTRIES = 'Current Countries',
  CURRENT_SUBREGIONS = 'Current Subregions',
  CURRENT_SEAS = 'Current Seas',
  FLOATING_DAYS = 'Floating Days',
}

// Used to call webserver API
export enum FleetMetricsParamsSplit {
  TOTAL = 'Total',
  COMMODITY = 'Products',
  GRADE = 'Grades',
  CRUDE_QUALITY = 'Crude Quality',
  DESTINATION_TRADING_REGION = 'Destination Trading Regions',
  DESTINATION_CONTINENT = 'Destination Continents',
  DESTINATION_SUBCONTINENT = 'Destination Subcontinents',
  DESTINATION_COUNTRY = 'Destination Countries',
  DESTINATION_INSTALLATION = 'Destination Installations',
  DESTINATION_PORT = 'Destination Ports',
  ORIGIN_TRADING_REGION = 'Origin Trading Regions',
  ORIGIN_CONTINENT = 'Origin Continents',
  ORIGIN_SUBCONTINENT = 'Origin Subcontinents',
  ORIGIN_COUNTRY = 'Origin Countries',
  ORIGIN_INSTALLATION = 'Origin Installations',
  ORIGIN_PORT = 'Origin Ports',
  VESSEL_TYPE = 'Vessel Type',
  VESSEL_TYPE_OIL = 'Vessel Type Oil',
  VESSEL_TYPE_CPP = 'Vessel Type Cpp',
  TRADE_STATUS = 'Trade Status',
  CHARTERER = 'Charterers',
  BUYERS = 'buyer',
  SELLERS = 'seller',
  CURRENT_CONTINENTS = 'Current Continents',
  CURRENT_SUBCONTINENTS = 'Current Subcontinents',
  CURRENT_COUNTRIES = 'Current Countries',
  CURRENT_SUBREGIONS = 'Current Subregions',
  CURRENT_SEAS = 'Current Seas',
  FLOATING_DAYS = 'Floating Days',
  FLOATING_STATE = 'Floating State',
}

export enum FleetMetricsAlgo {
  FLOATING_STORAGE = 'floating_storage',
  COMMODITY_ON_WATER = 'loaded_vessels',
}

export enum FleetMetricsMetric {
  CARGO = 'Cargo',
  VESSEL_COUNT = 'VesselCount',
}

export enum FleetMetricsFloatingState {
  FLOATING = 'floating',
  NON_FLOATING = 'nonFloating',
}

export type FleetMetricsStateHydrated = {
  areas: readonly FleetMetricsZoneFragment[];
  algo: FleetMetricsAlgo;
  metric: FleetMetricsMetric;
  products: readonly ProductFragment[];
  split: FleetMetricsSplit;
  floatingDays: [string, string] | null;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vesselClassifications: readonly string[];
  pricesFilters: PricesSidebarFilterState;
  granularity: Granularity;
  seasonal: boolean;
  dateRange: DateRangePreset | DateRange;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
};

export type FleetMetricsState = {
  areas: readonly number[]; // @TODO change to string, and migrate favorites
  algo: FleetMetricsAlgo;
  products: readonly string[];
  granularity: Granularity;
  seasonal: boolean;
  split: FleetMetricsSplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vesselClassifications: readonly string[];
  floatingDays: [string, string] | null;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
  dates: DateRangePreset | DateRange;
  pricesFilters: PricesBaseFiltersMapping;
  metric: FleetMetricsMetric;
};

export type FleetMetricsTooltipProps = {
  unitName: UnitName;
  granularity: Granularity;
};

export type FleetMetricsParams = SerializedDateRange & {
  algo: FleetMetricsAlgo;
  metric: FleetMetricsMetric;
  cumulative: false;
  filters: {
    product?: readonly number[];
  };
  floatingStorageDurationMax?: string; // @TODO type safety for min/max?
  floatingStorageDurationMin?: string;
  numberOfSplits?: number;
  periodicity: Granularity;
  resourcetype: ResourceType.ZONE;
  splitOn?: FleetMetricsParamsSplit;
  zoneIds: readonly number[];
  withFreightView: boolean;
  withProductEstimation: boolean;
  capacity?: CapacityObject;
  deadWeight?: DeadWeightObject;
  vesselClassifications?: readonly string[];
  vesselTypeClassification?: VesselTypeClassification;
};

export type FleetMetricsTradesParams = {
  algo: FleetMetricsAlgo;
  filters: {
    product?: readonly number[];
  };
  floatingStorageDurationMin?: string;
  floatingStorageDurationMax?: string; // @TODO type safety for min/max?
  operationalFilter?: OperationalFilter;
  period: string;
  periodicity: Granularity;
  players?: readonly number[];
  resourcetype: ResourceType.ZONE;
  splitOn?: FleetMetricsParamsSplit;
  splitValues: readonly string[];
  splitValuesToExclude: readonly string[];
  vessels?: readonly number[];
  withFreightView: boolean;
  withProductEstimation: boolean;
  zoneIds: readonly number[];
  capacity?: CapacityObject;
  deadWeight?: DeadWeightObject;
  vesselClassifications?: readonly string[];
  vesselTypeClassification?: VesselTypeClassification;
};

export type FleetMetricsVesselsParams = {
  periodicity: Granularity;
  period: string;
  splitOn?: FleetMetricsParamsSplit;
  floatingFrom?: number;
  algo: FleetMetricsAlgo;
  date: string;
  zoneIds: readonly number[];
  filters: {
    product?: readonly number[];
  };
  floatingStorageDurationMax?: string;
  floatingStorageDurationMin?: string;
  splitValues: readonly string[];
  splitValuesToExclude: readonly string[];
  withFreightView: boolean;
  withProductEstimation: boolean;
  deadWeight?: DeadWeightObject;
  capacity?: CapacityObject;
  vesselClassifications?: readonly string[];
  vesselTypeClassification?: VesselTypeClassification;
};

export type FleetMetricsVessel = {
  capacityMultiUnits: QuantityObject;
  flowQuantities: FlowQuantity[];
  id: number;
  imo: string;
  name: string;
};
