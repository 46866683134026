export const EMAIL = {
  sales: 'sales@kpler.com',
  cs: 'cs@kpler.com',
  rc: 'rc@kpler.com',
  support: 'support@kpler.com',
  info: 'info@kpler.com',
} as const;

export const EMAIL_TEXT = {
  sales: `Please contact ${EMAIL.sales} to set up a trial`,
  cs: `Please contact ${EMAIL.cs} to request access`,
} as const;
