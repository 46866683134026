type UserAgentData = {
  mobile: boolean;
};

const USER_AGENT = navigator.userAgent || navigator.platform;
const IS_MOBILE_REGEX =
  /\bAndroid(?:.+)Mobile\b|webOS|iPhone|iPod|BlackBerry|Windows Phone|(?:SD4930UR|\bSilk(?:.+)Mobile\b)|Mobile(?:.+)Firefox\b/i;

export function checkIsMobileUser() {
  if ('userAgentData' in navigator) {
    return (navigator.userAgentData as UserAgentData).mobile;
  }

  return IS_MOBILE_REGEX.test(USER_AGENT);
}
