<script setup lang="ts">
/**
 * This component is the Tag component from the design system
 * @see https://zeroheight.com/974e938b9/p/944ebd-tag--chip
 */
import { computed } from 'vue';

import { Proton } from '../Proton';

import { Props as TagProps } from './Tag.props';

type Props = {
  variant: TagProps['variant'];
  outline?: TagProps['outline'];
  color?: TagProps['color'];
  disabled?: TagProps['disabled'];
  size?: TagProps['size'];
  removable?: TagProps['removable'];
  clickable?: TagProps['clickable'];
};

type Emits = {
  (e: 'click'): void;
};

const COLOR_PRIMARY_DEFAULT = '--gray-850';
const COLOR_SECONDARY = '--white';

const COLOR_PRIMARY_DEFAULT_DISABLED = '--gray-600';
const COLOR_SECONDARY_DISABLED = '--gray-200';

const props = withDefaults(defineProps<Props>(), {
  outline: false,
  size: 'medium',
  variant: 'neutral',
});

defineEmits<Emits>();

const getCssVar = (colorName: string) => {
  return `var(${colorName})`;
};

const primaryColor = computed<string>(() => {
  if (props.disabled) {
    return COLOR_PRIMARY_DEFAULT_DISABLED;
  }
  if (props.variant === 'custom') {
    return props.color ?? COLOR_PRIMARY_DEFAULT;
  }
  return `--color-${props.variant}`;
});

const secondaryColor = computed<string>(() => {
  if (props.disabled) {
    return COLOR_SECONDARY_DISABLED;
  }
  if (props.outline) {
    return props.color ?? COLOR_SECONDARY;
  }
  return COLOR_SECONDARY;
});

const textColor = computed<string>(() => {
  if (props.disabled) {
    return COLOR_PRIMARY_DEFAULT_DISABLED;
  }
  if (props.outline) {
    return primaryColor.value;
  }
  return secondaryColor.value;
});

const backgroundColor = computed<string>(() => {
  if (props.disabled) {
    return COLOR_SECONDARY_DISABLED;
  }
  if (props.outline) {
    return secondaryColor.value;
  }
  return primaryColor.value;
});
</script>

<template>
  <Proton
    class="Tag"
    :background-color="getCssVar(backgroundColor)"
    :text-color="getCssVar(textColor)"
    :border="outline"
    :size="size"
    :removable="removable"
    :clickable="clickable"
    @click="$emit('click')"
  >
    <slot>Please provide a slot</slot>
  </Proton>
</template>

<style scoped lang="scss">
@use 'src/scss/variables';
.Tag {
  --color-neutral: var(--gray-850);
  --color-disabled: var(--gray-200);
}
</style>
