export enum LngInstallationMetricsRouteName {
  LNG_INSTALLATION_METRICS = 'lng-installation-metrics',
  LNG_INSTALLATION_METRICS_CAPACITY = 'lng-installation-metrics-capacity',
  LNG_INSTALLATION_METRICS_INVENTORIES_BY_COUNTRY = 'lng-installation-metrics-inventories-by-country',
  LNG_INSTALLATION_METRICS_INVENTORIES = 'lng-installation-metrics-inventories',
  LNG_INSTALLATION_METRICS_INVENTORIES_DETAIL = 'lng-installation-metrics-inventories-details',
  LNG_INSTALLATION_METRICS_UTILIZATION = 'lng-installation-metrics-utilization',
}

export enum InstallationMetricsUnits {
  BCM = 'bcm',
}

export enum LNGInventoriesTabName {
  CAPACITY = 'Capacity',
  UTILIZATION = 'Utilization',
  INVENTORIES_BY_COUNTRY = 'Inventories by country',
  INVENTORIES = 'Inventories',
}

export enum InstallationMetricsInstallationType {
  IMPORT = 'import',
  EXPORT = 'export',
}

export enum InstallationMetricSplits {
  TOTAL = 'total',
  STATUS = 'status',
  TRAIN = 'top_train',
  INSTALLATION = 'installation',
  GLOBAL = 'global',
} // will add more after mvp deploy

export enum OperationTrainStatus {
  DECOMMISSIONED = 'decommissioned',
  UNDER_CONSTRUCTION = 'under construction',
  SPECULATIVE = 'speculative',
  PLANNED_AND_PROPOSED = 'planned and proposed',
  MOTHBALLED = 'mothballed',
  ACTIVE = 'active',
  DOWNGRADED = 'downgraded',
  SUSPENDED = 'suspended',
}

export enum InstallationMetricsLocationType {
  INSTALLATION = 'installation',
  COUNTRY = 'country',
  CONTINENT = 'continent',
  SUBCONTINENT = 'subcontinent',
}

export enum InstallationMetricsDateInterval {
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  YEARLY = 'Yearly',
}

export enum TitleType {
  WIDGET = 'widget',
  CHART = 'chart',
  TABLE = 'table',
}

export enum CapacityTableColumns {
  INSTALLATION = 'Installation',
  TYPE = 'Type',
  CONTINENT = 'Continent',
  SUBCONTINENT = 'Subcontinent',
  COUNTRY = 'Country',
  TRAIN_PHASE = 'Train/phase',
  STATUS = 'Status',
  STARTUP_DATE = 'Startup date',
  END_DATE = 'End date',
  NOMINAL_CAPACITY = 'Nominal capacity (mtpa)',
}

export enum UtilizationTableColumns {
  INSTALLATION = 'Installation',
  CONTINENT = 'Continent',
  COUNTRY = 'Country',
  NOMINAL_CAPACITY = 'Nominal capacity (mtpa)',
  YTD_VOLUME = 'YTD Volume (mt)',
  YTD_UTILIZATION_RATE = 'YTD Utilization Rate (%)',
  YOY_CHANGE = 'YoY Volume Change (%)',
  SIX_MONTH_RATE = '180 Days',
  THREE_MONTH_RATE = '90 Days',
  ONE_MONTH_RATE = '30 Days',
  TREND_INDICATOR = 'Trend Indicator',
}
