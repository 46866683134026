import { Granularity } from '@kpler/terminal-utils';
import { Moment } from 'moment';

export enum UnitName {
  KG = 'kg',
  KG_CM = 'kg/cm',
  QUINTAL = 'quintal',
  TONS = 'tons',
  KTONS = 'ktons',
  MTONS = 'mtons',
  CM = 'cm',
  NCM = 'ncm',
  CF = 'cf',
  SCF = 'scf',
  MCF = 'mcf',
  SMCF = 'smcf',
  BARREL = 'barrel',
  KB = 'kb',
  MMBBL = 'mmbbl',
  TON_MILE = 'tm',
  KTON_MILE = 'ktm',
  MTON_MILE = 'Mtm',
  TON_DAY = 'td',
  KTON_DAY = 'ktd',
  MTON_DAY = 'Mtd',
  KNOT = 'kn',
  NAUTICAL_MILE = 'nmi',
  GWH = 'gwh',
  METER = 'meter',
  TON = 't',
}

export enum TemporalUnitName {
  BD = 'bd',
  KBD = 'kbd',
  KBPA = 'kbpa',
  MTPA = 'mtpa',
}

export enum Currency {
  US_DOLLAR = '$',
}

export type TemporalUnitGranularity = Granularity.YEARS | Granularity.DAYS;

export enum UnmanagedUnitName {
  PERCENTAGE = '%',
  PERCENTAGE_DAY = '%d',
  DAYS = 'days',
  ITEMS = 'items',
  DEGREE_API = '° API',
  DOLLAR_DAY = '$/day',
  DOLLAR_TON = '$/t',
  DOLLAR_MTON = '$/mt',
  DOLLAR_MMBTU = '$/MMBTU',
  MMBTU = 'MMBTU',
  PERCENTAGE_PER_DAY = '%/day',
  TON_DAY = 't/day',
  TON_M3 = 't/m3',
  M3 = 'm3',
  TON_BBL = 't/bbl',
  BBL = 'bbl',
  MW = 'MW',
}

export enum UnitDimension {
  MASS = 'mass',
  VOLUME = 'volume',
  ENERGY = 'energy',
}

export enum UnitKey {
  MASS = 'mass',
  VOLUME = 'volume',
  VOLUME_GAS = 'volume_gas',
  ENERGY = 'energy',
}

export type Unit = {
  conversionFormula: string;
  dimension: UnitDimension;
  key: UnitKey;
  name: UnitName;
  symbol: string;
};

export type ConvertToTemporalUnitOptions = {
  qtyGranularity: Granularity;
  qtyPeriod: Moment;
  onlyRealized?: boolean;
  roundToEndOfDay?: boolean;
};
