import qs from 'qs';
import { ReplaySubject } from 'rxjs';
import Vue, { Ref, ref } from 'vue';
// eslint-disable-next-line no-restricted-imports
import Router, { NavigationFailure, RawLocation, Route, RouteConfig } from 'vue-router';

export class AppRouter extends Router {
  static #instance: AppRouter;
  public route$: ReplaySubject<Route> = new ReplaySubject<Route>(1);
  public reactiveCurrentRoute = ref<Route>();

  private constructor(base: string, routes: RouteConfig[]) {
    super({
      mode: 'history',
      base,
      routes,
      parseQuery(query) {
        return qs.parse(query);
      },
      stringifyQuery(query) {
        const result = qs.stringify(query, { arrayFormat: 'repeat' });
        return result ? `?${result}` : '';
      },
      scrollBehavior() {
        document?.querySelector('main > *')?.scrollTo(0, 0);
      },
    });

    this.onReady(() => {
      this.route$.next(this.currentRoute);
      this.reactiveCurrentRoute.value = this.currentRoute;

      this.afterEach(to => {
        this.route$.next(to);
        this.reactiveCurrentRoute.value = to;
      });
    });
  }

  // based on https://github.com/Kpler/web-app/pull/2716
  // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
  pushWithCatch(location: RawLocation): Promise<Route | NavigationFailure>;
  pushWithCatch(
    location: RawLocation,
    onResolve: (x: unknown) => unknown,
    onReject?: (err: Error) => void,
  ): void;

  pushWithCatch(
    location: RawLocation,
    onResolve?: (x: unknown) => unknown,
    onReject?: (err: Error) => void,
  ): void | Promise<Route | NavigationFailure> {
    if (onResolve || onReject) {
      return super.push(location, onResolve, onReject);
    }
    return super.push(location).catch(err => {
      if (Router.isNavigationFailure(err)) {
        console.warn(err);
        return err;
      }
      throw err;
    });
  }

  public static getRouter(base?: string, routes: RouteConfig[] = []): AppRouter {
    if (!this.#instance && !base) {
      throw new Error('You must pass a base route when creating a new router instance.');
    }

    if (!this.#instance && base) {
      Vue.use(Router);
      this.#instance = new this(base, routes);
    }

    return this.#instance;
  }
}

export const useRouter = () => {
  const router = AppRouter.getRouter();

  return {
    ...router,
    currentRoute: router.reactiveCurrentRoute as Ref<Route>,
    push: router.pushWithCatch,
    replace: router.replace,
    resolve: router.resolve,
    match: router.match,
  };
};
