<script setup lang="ts">
import { computed } from 'vue';

import { Icon } from '../../../../../Icon';

import { Checkbox } from './Checkbox';
import { Props } from './Node.props';
import { nodeHasCheckedDescendant } from './Node.utils';
import Radio from './Radio/Radio.vue';

const props = defineProps<{
  id: Props['id'];
  label: Props['label'];
  checked: Props['checked'];
  disabled?: Props['disabled'];
  children: Props['children'];
  active: Props['active'];
  isRadio?: Props['isRadio'];
}>();

const emit = defineEmits<{
  (e: 'expand', id: string): void;
  (e: 'update:checked', id: string): void;
}>();

const indeterminate = computed<boolean>(() => {
  if (props.checked) {
    return false;
  }
  const hasCheckedChildren = props.children.some(node => nodeHasCheckedDescendant(node));
  return hasCheckedChildren;
});

const amountOfChildrenChecked = computed<number>(
  () => props.children.filter(node => nodeHasCheckedDescendant(node)).length
);
const amountOfChildrenCheckedText = computed<string>(
  () => `(${amountOfChildrenChecked.value}/${props.children.length})`
);

const expand = () => {
  if (props.disabled || props.children.length === 0) {
    return;
  }
  emit('expand', props.id);
};

const onToggle = () => {
  if (props.disabled) {
    return;
  }

  if (!props.checked) {
    expand();
  }

  emit('update:checked', props.id);
};
</script>

<template>
  <span class="Node" :class="{ disabled, active }">
    <component
      :is="props.isRadio ? Radio : Checkbox"
      :checked="checked"
      :disabled="disabled"
      :indeterminate="indeterminate"
      @update:checked="onToggle"
    />

    <label @click="onToggle">{{ label }}</label>

    <span
      v-if="children.length > 0"
      class="d-flex justify-end align-center flex-1"
      role="button"
      tabindex="0"
      @click="expand"
      @keydown.enter="expand"
    >
      <span v-if="props.isRadio && amountOfChildrenChecked > 0" class="Node__checkedChildrenNumber">
        {{ amountOfChildrenCheckedText }}
      </span>
      <Icon name="angle-right" />
    </span>
  </span>
</template>

<style lang="scss" scoped>
@use 'src/scss/theme';
@use 'src/scss/variables';

$color-disabled: var(--gray-400);

.Node {
  display: flex;
  align-items: center;
  padding: variables.$spacing-xs;
  border-radius: variables.$border-radius;
  gap: variables.$spacing-xs;
  margin: variables.$spacing-xxs;

  &,
  *:not(input) {
    cursor: pointer;
  }

  &.disabled {
    &,
    * {
      cursor: not-allowed;
      color: $color-disabled;
    }
  }

  &:hover,
  &.active {
    background-color: theme.$color-bg-quaternary;
  }

  &__checkedChildrenNumber {
    font-size: variables.$font-size-normal;
    font-weight: variables.$font-regular;
    line-height: 130%;
    text-align: left;
    color: theme.$color-text-secondary;
  }
}
</style>
