import { Market } from '@kpler/web-ui';

import { AppStore } from 'src/store/types';

export const hasAccessToLNGDashboard = (store: AppStore) =>
  store.getters.accessibleMarketsForDashboard.has(Market.LNG) &&
  store.getters.userHasAllPermissions(['diversion:read', 'outage:read', 'tender:read']);
export const hasAccessToLPGDashboard = (store: AppStore) =>
  store.getters.accessibleMarketsForDashboard.has(Market.LPG) &&
  store.getters.userHasPermission('tender:read');
