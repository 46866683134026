import { RouteRecordRaw } from '@kpler/web-ui';

import { useWorkflowsPermissions } from 'src/domains/freight/common/permissions/userHashFreightWorkflowsPermissions';
import store from 'src/store';

const { hasWorkflowsFreightCalcPermissions, hasWorkflowsFreightVesselMatcherPermissions } =
  useWorkflowsPermissions(store);

const TheFreightLayout = () =>
  import(
    /* webpackChunkName: "freightCalc" */ 'src/domains/freight/common/components/TheFreightLayout.vue'
  );

const TheFreightCalcUnifiedContainer = () =>
  hasWorkflowsFreightCalcPermissions
    ? import(
        /* webpackChunkName: "freightCalcUnified" */ 'src/domains/freight/calculator/components/TheFreightCalcUnifiedContainer.vue'
      )
    : import(
        /* webpackChunkName: "freightCalcUnified" */ 'src/domains/freight/calculator/components/TheFreightCalcUnifiedContainerPreview.vue'
      );

const TheVesselMatcherRequirementsContainer = () =>
  hasWorkflowsFreightVesselMatcherPermissions
    ? import(
        /* webpackChunkName: "vesselMatcher" */ 'src/domains/freight/vesselmatcher/containers/TheVesselMatcherRequirementsContainer.vue'
      )
    : import(
        /* webpackChunkName: "vesselMatcher" */ 'src/domains/freight/vesselmatcher/containers/TheVesselMatcherRequirementsContainerPreview.vue'
      );

const freightCalcRoutes: RouteRecordRaw = {
  name: 'freight-workflows',
  path: 'workflows',
  component: TheFreightLayout,
  redirect: 'workflows/vessel-matcher',
  children: [
    {
      path: 'voyage-calculator',
      name: 'freight-calc',
      component: TheFreightCalcUnifiedContainer,
      meta: {
        title: 'Voyage calculator',
      },
    },
    {
      path: 'vessel-matcher',
      name: 'vessel-matcher',
      components: {
        default: TheVesselMatcherRequirementsContainer,
      },
      meta: {
        title: 'Vessel Matcher',
      },
    },
  ],
};
export default freightCalcRoutes;
