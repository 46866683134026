import { DirectiveOptions } from 'vue';

import TooltipDirective from 'src/directives/Tooltip.directive';

type TruncateProps = {
  textOverflow?: string;
};

const directive: DirectiveOptions = {
  bind(el, { value }: { value?: TruncateProps }, vnode, oldVnode) {
    if (TooltipDirective.bind === undefined) {
      throw new Error('TooltipDirective.bind must exist.');
    }
    const originalText = el.innerText;
    /* eslint-disable no-param-reassign */
    el.style.textOverflow = value?.textOverflow ?? 'ellipsis';
    el.style.overflow = 'hidden';
    el.style.whiteSpace = 'nowrap';
    /* eslint-enable no-param-reassign */

    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const target = entry.target as HTMLElement;
        const shouldTruncate = target.offsetWidth < target.scrollWidth;
        target.dataset.shouldTruncate = shouldTruncate.toString();

        if (TooltipDirective.update === undefined) {
          throw new Error('TooltipDirective.update must exist.');
        }

        TooltipDirective.update(
          target,
          {
            value: { text: originalText, disabled: !shouldTruncate },
            name: 'truncate',
            modifiers: {},
          },
          vnode,
          oldVnode,
        );
      });
    });

    observer.observe(el);

    const shouldTruncate = el.offsetWidth < el.scrollWidth;
    TooltipDirective.bind(
      el,
      {
        value: { text: originalText, disabled: !shouldTruncate },
        name: 'truncate',
        modifiers: {},
      },
      vnode,
      oldVnode,
    );
  },
  unbind(el, _, vnode, oldVnode) {
    if (TooltipDirective.unbind === undefined) {
      throw new Error('TooltipDirective.unbind must exist.');
    }
    TooltipDirective.unbind(el, { name: 'truncate', modifiers: {} }, vnode, oldVnode);
  },
  update(el, _, vnode, oldVnode) {
    if (TooltipDirective.update === undefined) {
      throw new Error('TooltipDirective.update must exist.');
    }
    const shouldTruncate = el.offsetWidth < el.scrollWidth;
    const originalText = el.innerText;
    TooltipDirective.update(
      el,
      {
        value: { text: originalText, disabled: !shouldTruncate },
        name: 'truncate',
        modifiers: {},
      },
      vnode,
      oldVnode,
    );
  },
};

export default directive;
