<script lang="ts">
import { ProtonButton } from '@kpler/web-ui';
import { defineComponent } from 'vue';

/*
NOTE: See ProtonButton component for available props
*/

export default defineComponent({
  name: 'ButtonAction',
  components: {
    ProtonButton,
  },
});
</script>

<template>
  <ProtonButton
    v-bind="$attrs"
    class="base-button--action"
    data-qa="baseButton"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </ProtonButton>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';
@use 'src/scss/theme';

.base-button--action {
  border: 0;
  background-color: theme.$color-primary;
  color: theme.$color-white;
  text-transform: uppercase;
  &:hover:not(:disabled) {
    background-color: theme.$color-primary-light;
  }
}
</style>
