import { useRouter } from '@kpler/terminal-utils';
import { Module, MutationTree, ActionTree, GetterTree } from 'vuex-typescript-interface';

import { RootState } from 'src/store/types';

import { apolloClient } from 'src/services/apollo.service';
import { axiosApi, unauthenticatedAxiosApi } from 'src/services/axios.service';
import TrackingService from 'src/services/tracking.service';

import { RequestStatus } from 'types/legacy-globals';
import { Unit, UnitName, UnitDimension, UnitKey } from 'types/unit';
import { User, SettingsResponse } from 'types/user';

export type AuthState = {
  tokenIsValid: boolean;
  units: Unit[];
  changePasswordStatus: RequestStatus;
  requestPasswordResetStatus: RequestStatus;
  redirectTo: string | null;
  errorMessage: string | null;
  errorMessageChangePassword: string | null;
};

export type AuthModule = AuthState & {
  // getters
  readonly getUnit: (unitName: UnitName) => Unit;

  // mutations
  SET_UNITS: (units: Unit[]) => void;
  SET_CHANGE_PASSWORD_STATUS: (status: RequestStatus) => void;
  SET_REQUEST_PASSWORD_RESET_STATUS: (status: RequestStatus) => void;
  SET_ERROR_MESSAGE: (message: string | null) => void;
  SET_ERROR_MESSAGE_CHANGE_PASSWORD: (message: string | null) => void;

  // actions
  setUser: (user: User) => Promise<void>;
  setUnits: (units: Unit[]) => Promise<void>;
  onLogout: () => Promise<void>;
  changePasswordWithToken: (payload: { emailToken: string; password: string }) => Promise<void>;
  requestPasswordReset: (email: string) => Promise<void>;
  resetErrorMessage: () => Promise<void>;
};

// @todo move this to the user data service
const additionalUnits: Unit[] = [
  {
    conversionFormula: 'x',
    dimension: UnitDimension.MASS,
    key: UnitKey.MASS,
    name: UnitName.TON_MILE,
    symbol: UnitName.TON_MILE,
  },
  {
    conversionFormula: 'x/1000',
    dimension: UnitDimension.MASS,
    key: UnitKey.MASS,
    name: UnitName.KTON_MILE,
    symbol: UnitName.KTON_MILE,
  },
  {
    conversionFormula: 'x/1000000',
    dimension: UnitDimension.MASS,
    key: UnitKey.MASS,
    name: UnitName.MTON_MILE,
    symbol: UnitName.MTON_MILE,
  },
  {
    conversionFormula: 'x',
    dimension: UnitDimension.MASS,
    key: UnitKey.MASS,
    name: UnitName.TON_DAY,
    symbol: UnitName.TON_DAY,
  },
  {
    conversionFormula: 'x/1000',
    dimension: UnitDimension.MASS,
    key: UnitKey.MASS,
    name: UnitName.KTON_DAY,
    symbol: UnitName.KTON_DAY,
  },
  {
    conversionFormula: 'x/1000000',
    dimension: UnitDimension.MASS,
    key: UnitKey.MASS,
    name: UnitName.MTON_DAY,
    symbol: UnitName.MTON_DAY,
  },
  {
    conversionFormula: 'x',
    dimension: UnitDimension.MASS,
    key: UnitKey.MASS,
    name: UnitName.KNOT,
    symbol: UnitName.KNOT,
  },
  {
    conversionFormula: 'x',
    dimension: UnitDimension.MASS,
    key: UnitKey.MASS,
    name: UnitName.NAUTICAL_MILE,
    symbol: UnitName.NAUTICAL_MILE,
  },
  {
    conversionFormula: 'x',
    dimension: UnitDimension.VOLUME,
    key: UnitKey.VOLUME_GAS,
    name: UnitName.NCM,
    symbol: 'Nm<sup>3</sup>',
  },
  {
    conversionFormula: 'x/0.0283168',
    dimension: UnitDimension.VOLUME,
    key: UnitKey.VOLUME_GAS,
    name: UnitName.SCF,
    symbol: 'Scf',
  },
  {
    conversionFormula: 'x/28.3168',
    dimension: UnitDimension.VOLUME,
    key: UnitKey.VOLUME_GAS,
    name: UnitName.SMCF,
    symbol: 'SMcf',
  },
];

const moduleState: AuthState = {
  tokenIsValid: false,
  units: [],
  changePasswordStatus: RequestStatus.IDLE,
  requestPasswordResetStatus: RequestStatus.IDLE,
  redirectTo: null,
  errorMessage: null,
  errorMessageChangePassword: null,
};

const moduleMutations: MutationTree<AuthModule> = {
  SET_UNITS: (state, units) => {
    state.units = units;
  },
  SET_REQUEST_PASSWORD_RESET_STATUS: (state, status) => {
    state.requestPasswordResetStatus = status;
  },
  SET_CHANGE_PASSWORD_STATUS: (state, status) => {
    state.changePasswordStatus = status;
  },
  SET_ERROR_MESSAGE_CHANGE_PASSWORD: (state, message = null) => {
    state.errorMessageChangePassword = message;
  },
  SET_ERROR_MESSAGE: (state, message = null) => {
    state.errorMessage = message;
  },
};

const moduleActions: ActionTree<AuthModule, RootState> = {
  async setUser({ commit, dispatch }, user: User) {
    TrackingService.identify(user);
    const settings = await axiosApi.get<SettingsResponse>('users/current/settings');
    dispatch('loadSettings', settings, { root: true });

    commit('SET_USER', user, { root: true });
  },
  async onLogout({ commit }) {
    TrackingService.reset();
    commit('RESET_USER', null, { root: true });
    apolloClient.clearStore();
  },
  async changePasswordWithToken({ commit }, { emailToken: mailToken, password: newPassword }) {
    const router = useRouter();
    commit('SET_CHANGE_PASSWORD_STATUS', RequestStatus.LOADING);
    commit('SET_ERROR_MESSAGE_CHANGE_PASSWORD', null);
    return unauthenticatedAxiosApi
      .post('password/reset', { mailToken, newPassword })
      .then(() => {
        commit('SET_CHANGE_PASSWORD_STATUS', RequestStatus.IDLE);
        router.push('/');
      })
      .catch(error => {
        commit('SET_CHANGE_PASSWORD_STATUS', RequestStatus.ERROR);
        commit('SET_ERROR_MESSAGE_CHANGE_PASSWORD', error.message);
      });
  },
  async requestPasswordReset({ commit }, email) {
    const router = useRouter();
    commit('SET_REQUEST_PASSWORD_RESET_STATUS', RequestStatus.LOADING);
    return unauthenticatedAxiosApi
      .get('password/reset', { params: { email, redesign: true } })
      .then(() => {
        commit('SET_REQUEST_PASSWORD_RESET_STATUS', RequestStatus.IDLE);
        router.push('/');
      })
      .catch(() => {
        commit('SET_REQUEST_PASSWORD_RESET_STATUS', RequestStatus.ERROR);
      });
  },
  async setUnits({ commit }, units: Unit[]) {
    commit('SET_UNITS', units.concat(additionalUnits));
  },
  async resetErrorMessage({ commit }) {
    commit('SET_ERROR_MESSAGE', null);
  },
};

const moduleGetters: GetterTree<AuthModule, RootState> = {
  getUnit: state => unitName => {
    const unit = state.units.find(u => u.name === unitName);
    if (!unit) {
      throw new TypeError(`Invalid unitName: ${unitName}`);
    }
    return unit;
  },
};

const authModule: Module<AuthModule, RootState> = {
  state: moduleState,
  mutations: moduleMutations,
  actions: moduleActions,
  getters: moduleGetters,
};

export default authModule;
