export const HOMEPAGE_REPORTS_COUNT = 3;
export const HOMEPAGE_RESEARCH_UPDATES_COUNT = 6;
export const HOMEPAGE_NEWS_COUNT = 6;
export const HOMEPAGE_WEBINARS_COUNT = 5;
export const HOMEPAGE_MOST_READ_COUNT = 6;
export const HOMEPAGE_DATA_ADDONS_COUNT = 6;
export const INSIGHT_REQUEST_PACKAGE = 'https://www.kpler.com/request-insight-demo';

export const INSIGHT_HOMEPAGE_ROUTE = 'insight-homepage';
export const INSIGHT_NEWS_ROUTE = 'insight-news';
export const INSIGHT_NEWS_DETAILS_ROUTE = 'insight-news-details';
export const INSIGHT_RESEARCH_UPDATES_ROUTE = 'insight-research-updates';
export const INSIGHT_RESEARCH_UPDATE_DETAILS_ROUTE = 'insight-research-update-details';
export const INSIGHT_REPORTS_ROUTE = 'insight-reports';
export const INSIGHT_REPORT_DETAILS_ROUTE = 'insight-report-details';
export const INSIGHT_WEBINARS_ROUTE = 'insight-webinars';
export const INSIGHT_WEBINAR_DETAILS_ROUTE = 'insight-webinar-details';
export const INSIGHT_DATA_ROUTE = 'insight-data';
export const INSIGHT_DATA_ADDON_DETAILS_ROUTE = 'insight-data-addon-details';
export const INSIGHT_SEARCH_ROUTE = 'insight-search';
export const INSIGHT_AUTHOR_DETAILS_ROUTE = 'insight-author-details';

export const INSIGHT_DRAFT_PREVIEWS_ROUTE = 'insight-draft-previews';
