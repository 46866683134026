import { inject } from 'vue';

export const usePermissions = () => {
  const hasPermission = inject<(permission: string) => boolean>('hasPermission');
  if (hasPermission === undefined) {
    throw new Error('hasPermission must be provided.');
  }

  const hasOnePermission = (permissions: string[]) => permissions.some(x => hasPermission(x));

  return {
    hasPermission,
    hasOnePermission,
  };
};
