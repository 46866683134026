// eslint-disable-next-line no-restricted-imports
import { isDefined } from '../isDefined';

export function assertDefined<T>(
  value: T | undefined | null,
  customMessage?: string,
): asserts value is T {
  if (!isDefined(value)) {
    const message = customMessage ?? `Expected value to be defined, got ${value}`;
    throw new Error(message);
  }
}
