import { isDefined } from '@kpler/generic-utils';

import { Platform } from 'types/legacy-globals';

export class PlatformService {
  static readonly INJECT_KEY = 'platformService';
  static #singleton: PlatformService;

  static create() {
    if (!isDefined(PlatformService.#singleton)) {
      PlatformService.#singleton = new PlatformService();
    }

    return PlatformService.#singleton;
  }

  get injectKey() {
    return PlatformService.INJECT_KEY;
  }

  get platform(): Platform {
    return Platform.MERGE;
  }
}
