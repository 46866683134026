import { AppRouter } from '@kpler/terminal-utils';

/**
 * Lazy initialization of modules
 */
export const lazyInit = () => {
  setTimeout(() => {
    /** Fetch Highcharts chunck lazily and run initializeHighcharts() */
    import(/* webpackChunkName: "highcharts" */ 'src/highcharts').then(chunk => chunk.default());

    if (process.env.NX_PUBLIC_SENTRY === 'true') {
      /** Fetch Sentry chunck lazily and run initializeSentry() */
      import(/* webpackChunkName: "sentry" */ 'src/sentry').then(chunk =>
        chunk.initializeSentry(AppRouter.getRouter()),
      );
    }
  }, 0);
};
