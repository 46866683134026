export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateRange: string | { startDate: string; endDate: string };
  DateTime: string;
  InventoriesTimeseries: {
    readonly __typename?: 'InventoriesTimeSeries';
    readonly metadata: {
      readonly __typename?: 'InventoriesMetadata';
      readonly granularity: Granularity;
      readonly forecastSince?: Maybe<string>;
    };
    readonly series: ReadonlyArray<{
      readonly __typename?: 'InventoriesTimeSeriesItem';
      readonly date: string;
      readonly datasets: ReadonlyArray<{
        readonly __typename?: 'InventoriesDataset';
        readonly datasetName: string;
        readonly values: {
          readonly __typename?: 'InventoriesValues';
          readonly volume?: Maybe<number>;
          readonly energy?: Maybe<number>;
          readonly volume_gas?: Maybe<number>;
          readonly providerName?: Maybe<string>;
        };
        readonly splitValues?: Maybe<
          ReadonlyArray<{
            readonly __typename?: 'InventoriesSplitValue';
            readonly splitId: string;
            readonly name: string;
            readonly values: {
              readonly __typename?: 'InventoriesValues';
              readonly volume?: Maybe<number>;
              readonly energy?: Maybe<number>;
              readonly volume_gas?: Maybe<number>;
            };
          }>
        >;
      }>;
    }>;
  };
  KpDateTime: string;
  NonEmptyString: string;
};

export type ArrivalEventInput = {
  readonly eta: Scalars['KpDateTime'];
  readonly location: EventLocationInput;
  readonly timestamp: Scalars['KpDateTime'];
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  readonly vesselId: Scalars['ID'];
};

export type AvailableCapacityAnalyticsParams = {
  readonly granularity: Scalars['String'];
  readonly groupByFamily?: Scalars['Boolean'];
  readonly includeNewBuilds?: Scalars['Boolean'];
  readonly installationIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly periodEnd?: InputMaybe<Scalars['KpDateTime']>;
  readonly periodStart?: InputMaybe<Scalars['KpDateTime']>;
  readonly unitTypeSubFamilies?: ReadonlyArray<Scalars['String']>;
  readonly zoneIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum BallastCapacityAggMetric {
  Capacity = 'CAPACITY',
  Count = 'COUNT',
  Deadweight = 'DEADWEIGHT',
}

export enum BallastCapacitySplit {
  Country = 'COUNTRY',
  EngineType = 'ENGINE_TYPE',
  EtaWindow = 'ETA_WINDOW',
  Source = 'SOURCE',
  Total = 'TOTAL',
  VesselAvailability = 'VESSEL_AVAILABILITY',
  VesselType = 'VESSEL_TYPE',
}

export enum BetaStatus {
  Beta = 'BETA',
  Regular = 'REGULAR',
}

export type BookmarkBallastCapacityCreateInput = {
  readonly data: BookmarkBallastCapacityDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkBallastCapacityDataInput = {
  readonly aggMetric: Scalars['String'];
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates: Scalars['DateRange'];
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly etaType?: InputMaybe<Scalars['String']>;
  readonly etaWindow?: InputMaybe<Scalars['String']>;
  readonly forecast?: Scalars['Boolean'];
  readonly granularity: Scalars['String'];
  readonly nDaysAhead?: InputMaybe<Scalars['Int']>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly split: Scalars['String'];
  readonly truncateY: Scalars['Boolean'];
  readonly unit: Scalars['String'];
  readonly vesselAvailability?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: Scalars['String'];
  readonly zones: ReadonlyArray<Scalars['Int']>;
};

export type BookmarkBallastCapacityUpdateInput = {
  readonly data?: InputMaybe<BookmarkBallastCapacityDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BookmarkCongestionCreateInput = {
  readonly data: BookmarkCongestionDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkCongestionDataInput = {
  readonly allVesselsInWaitingZone: Scalars['Boolean'];
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates: Scalars['DateRange'];
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity: Scalars['String'];
  readonly metric: Scalars['String'];
  readonly movingAverage: Scalars['Boolean'];
  readonly originZones?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly seasonal: Scalars['Boolean'];
  readonly split: Scalars['String'];
  readonly truncateY: Scalars['Boolean'];
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vesselOperation: ReadonlyArray<Scalars['String']>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: Scalars['String'];
  readonly waitingDuration?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly zones: ReadonlyArray<Scalars['Int']>;
};

export type BookmarkCongestionUpdateInput = {
  readonly data?: InputMaybe<BookmarkCongestionDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BookmarkFleetDevelopmentCreateInput = {
  readonly data: BookmarkFleetDevelopmentDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkFleetDevelopmentDataInput = {
  readonly aggMetric: Scalars['String'];
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly coating?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly complianceMethod: ReadonlyArray<Scalars['String']>;
  readonly dates: Scalars['DateRange'];
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity: Scalars['String'];
  readonly metric: Scalars['String'];
  readonly movingAverage: Scalars['Boolean'];
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly projection?: InputMaybe<Scalars['String']>;
  readonly seasonal: Scalars['Boolean'];
  readonly splitOn: Scalars['String'];
  readonly truncateY: Scalars['Boolean'];
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: Scalars['String'];
};

export type BookmarkFleetDevelopmentUpdateInput = {
  readonly data?: InputMaybe<BookmarkFleetDevelopmentDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BookmarkFleetMetricsCreateInput = {
  readonly data: BookmarkFleetMetricsDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkFleetMetricsDataInput = {
  readonly algo: Scalars['String'];
  readonly areas: ReadonlyArray<Scalars['Int']>;
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates: Scalars['DateRange'];
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly floatingDays?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity: Scalars['String'];
  readonly metric: Scalars['String'];
  readonly movingAverage: Scalars['Boolean'];
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly seasonal: Scalars['Boolean'];
  readonly split: Scalars['String'];
  readonly truncateY: Scalars['Boolean'];
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly view: Scalars['String'];
};

export type BookmarkFleetMetricsUpdateInput = {
  readonly data?: InputMaybe<BookmarkFleetMetricsDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BookmarkFleetUtilizationCreateInput = {
  readonly data: BookmarkFleetUtilizationDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkFleetUtilizationDataInput = {
  readonly aggMetric: Scalars['String'];
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates: Scalars['DateRange'];
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly distinctAggregation?: InputMaybe<Scalars['Boolean']>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity: Scalars['String'];
  readonly lastFamilyProducts: ReadonlyArray<Scalars['String']>;
  readonly movingAverage: Scalars['Boolean'];
  readonly nextZoneIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly percent: Scalars['Boolean'];
  readonly previousZoneIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly seasonal: Scalars['Boolean'];
  readonly showSubStates?: InputMaybe<Scalars['Boolean']>;
  readonly split: Scalars['String'];
  readonly truncateY: Scalars['Boolean'];
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vesselDirection?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vesselState: ReadonlyArray<Scalars['String']>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: Scalars['String'];
  readonly zoneIds: ReadonlyArray<Scalars['Int']>;
};

export type BookmarkFleetUtilizationUpdateInput = {
  readonly data?: InputMaybe<BookmarkFleetUtilizationDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BookmarkFlowsCreateInput = {
  readonly data: BookmarkFlowsDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkFlowsDataInput = {
  readonly beta: Scalars['Boolean'];
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates: Scalars['DateRange'];
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dir: Scalars['String'];
  readonly forecast?: Scalars['Boolean'];
  readonly granularity: Scalars['String'];
  readonly intra: Scalars['Boolean'];
  readonly intraRegion?: Scalars['Boolean'];
  readonly mInstallations: ReadonlyArray<Scalars['Int']>;
  readonly mZones: ReadonlyArray<Scalars['Int']>;
  readonly movingAverage: Scalars['Boolean'];
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly productEstimation?: Scalars['Boolean'];
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly projection: Scalars['String'];
  readonly sInstallations: ReadonlyArray<Scalars['Int']>;
  readonly sZones: ReadonlyArray<Scalars['Int']>;
  readonly seasonal: Scalars['Boolean'];
  readonly split: Scalars['String'];
  readonly truncateY: Scalars['Boolean'];
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: Scalars['String'];
};

export type BookmarkFlowsUpdateInput = {
  readonly data?: InputMaybe<BookmarkFlowsDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BookmarkFreightMetricsCreateInput = {
  readonly data: BookmarkFreightMetricsDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkFreightMetricsDataInput = {
  readonly beta: Scalars['Boolean'];
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates: Scalars['DateRange'];
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity: Scalars['String'];
  readonly intra: Scalars['Boolean'];
  readonly mZones: ReadonlyArray<Scalars['Int']>;
  readonly metric: Scalars['String'];
  readonly movingAverage: Scalars['Boolean'];
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly sZones: ReadonlyArray<Scalars['Int']>;
  readonly seasonal: Scalars['Boolean'];
  readonly speedRange?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly splitOn: Scalars['String'];
  readonly truncateY: Scalars['Boolean'];
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vesselStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: Scalars['String'];
};

export type BookmarkFreightMetricsUpdateInput = {
  readonly data?: InputMaybe<BookmarkFreightMetricsDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BookmarkInventoriesCreateInput = {
  readonly data: BookmarkInventoriesDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkInventoriesDataInput = {
  readonly addEiaDatasets?: InputMaybe<Scalars['Boolean']>;
  readonly capacityUtilization: Scalars['Boolean'];
  readonly cargoTrackingEnhancement: Scalars['Boolean'];
  readonly dates: Scalars['DateRange'];
  readonly deltaLevel?: InputMaybe<Scalars['Boolean']>;
  readonly droneData?: InputMaybe<Scalars['Boolean']>;
  readonly droneDataAtmEnhancement?: Scalars['Boolean'];
  readonly fleetMetricsEnhancement: Scalars['Boolean'];
  readonly floatingDays?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly floatingFrom?: InputMaybe<Scalars['Int']>;
  readonly granularity: Scalars['String'];
  readonly installations: ReadonlyArray<Scalars['Int']>;
  readonly market?: InputMaybe<InventoriesMarket>;
  readonly movingAverage: Scalars['Boolean'];
  readonly pricesFilters?: InputMaybe<PricesBaseFiltersDataInput>;
  readonly seasonal: Scalars['Boolean'];
  readonly split: Scalars['String'];
  readonly truncateY: Scalars['Boolean'];
  readonly unit: Scalars['String'];
  readonly view: Scalars['String'];
  readonly zones: ReadonlyArray<Scalars['Int']>;
};

export type BookmarkInventoriesUpdateInput = {
  readonly data?: InputMaybe<BookmarkInventoriesDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BookmarkMapCreateInput = {
  readonly data: BookmarkMapDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkMapDataInput = {
  readonly commodityTypes?: InputMaybe<ReadonlyArray<CommodityType>>;
  readonly discharges: ReadonlyArray<Scalars['ID']>;
  readonly fields: ReadonlyArray<Scalars['String']>;
  readonly loads: ReadonlyArray<Scalars['ID']>;
  readonly locations: ReadonlyArray<Scalars['ID']>;
  readonly players: ReadonlyArray<Scalars['Int']>;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly vessels: ReadonlyArray<Scalars['Int']>;
};

export type BookmarkMapUpdateInput = {
  readonly data?: InputMaybe<BookmarkMapDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type BookmarkPricesCreateInput = {
  readonly data: BookmarkPricesDataInput;
  readonly title: Scalars['String'];
};

export type BookmarkPricesDataInput = {
  /** @deprecated Use `pricesContractFilters` */
  readonly contractMarket?: InputMaybe<Scalars['String']>;
  /** @deprecated Use `pricesContractFilters` */
  readonly contractType?: InputMaybe<Scalars['String']>;
  /** @deprecated Use `pricesContractFilters` */
  readonly contracts?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly dateRange: Scalars['DateRange'];
  readonly granularity: Scalars['String'];
  /** @deprecated Use `pricesContractFilters` */
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly pricesContractFilters?: InputMaybe<ReadonlyArray<PricesContractFilterParamsInput>>;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Use `pricesContractFilters` */
  readonly unit?: InputMaybe<Scalars['String']>;
  readonly view: Scalars['String'];
};

export type BookmarkPricesUpdateInput = {
  readonly data?: InputMaybe<BookmarkPricesDataInput>;
  readonly id: Scalars['ID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export enum CargoState {
  Ballast = 'BALLAST',
  Loaded = 'LOADED',
}

export enum CommodityType {
  Dry = 'DRY',
  Liquids = 'LIQUIDS',
  Lng = 'LNG',
  Lpg = 'LPG',
}

export type ConfigOptionsInput = {
  readonly background: MapBackground;
  readonly cyclones: Scalars['Boolean'];
  readonly labels: Scalars['Boolean'];
  readonly portsAndBerths: Scalars['Boolean'];
  readonly unit: MapUnitName;
};

export type ConfigSearchInput = {
  readonly discharges: ReadonlyArray<Scalars['String']>;
  readonly loads: ReadonlyArray<Scalars['String']>;
  readonly locations: ReadonlyArray<Scalars['String']>;
  readonly players: ReadonlyArray<Scalars['Int']>;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly vessels: ReadonlyArray<Scalars['Int']>;
};

export enum CongestionMetric {
  Capacity = 'CAPACITY',
  Count = 'COUNT',
  Deadweight = 'DEADWEIGHT',
  Duration = 'DURATION',
}

export enum CongestionSplit {
  Country = 'COUNTRY',
  EngineType = 'ENGINE_TYPE',
  Grade = 'GRADE',
  Installation = 'INSTALLATION',
  OriginCountry = 'ORIGIN_COUNTRY',
  OriginPort = 'ORIGIN_PORT',
  Port = 'PORT',
  Product = 'PRODUCT',
  Total = 'TOTAL',
  VesselOperation = 'VESSEL_OPERATION',
  VesselType = 'VESSEL_TYPE',
  WaitingStatus = 'WAITING_STATUS',
}

export enum CongestionVesselOperation {
  Discharge = 'DISCHARGE',
  Load = 'LOAD',
}

export enum ContractMarket {
  Commodity = 'COMMODITY',
  Freight = 'FREIGHT',
}

export enum ContractType {
  Futures = 'FUTURES',
  Spot = 'SPOT',
}

export enum CurrentOrFuture {
  Current = 'CURRENT',
  Future = 'FUTURE',
}

export type DashboardCreateInput = {
  readonly months: Scalars['Int'];
  readonly title: Scalars['NonEmptyString'];
  readonly widgets?: InputMaybe<ReadonlyArray<WidgetInput>>;
};

export type DashboardUpdateInput = {
  readonly layout?: InputMaybe<ReadonlyArray<WidgetPositionInput>>;
  readonly months?: InputMaybe<Scalars['Int']>;
  readonly title?: InputMaybe<Scalars['NonEmptyString']>;
};

export type DepartureEventInput = {
  readonly eta: Scalars['KpDateTime'];
  readonly location: EventLocationInput;
  readonly timestamp: Scalars['KpDateTime'];
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  readonly vesselId: Scalars['ID'];
};

export type DestinationAddedEventInput = {
  readonly eta: Scalars['KpDateTime'];
  readonly location: EventLocationInput;
  readonly timestamp: Scalars['KpDateTime'];
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  readonly vesselId: Scalars['ID'];
};

export type DestinationCanceledEventInput = {
  readonly eta: Scalars['KpDateTime'];
  readonly location: EventLocationInput;
  readonly timestamp: Scalars['KpDateTime'];
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  readonly vesselId: Scalars['ID'];
};

export type DestinationChangedEventInput = {
  readonly currentEta: Scalars['KpDateTime'];
  readonly currentLocation: EventLocationInput;
  readonly previousEta: Scalars['KpDateTime'];
  readonly previousLocation: EventLocationInput;
  readonly timestamp: Scalars['KpDateTime'];
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  readonly vesselId: Scalars['ID'];
};

export enum Direction {
  Export = 'EXPORT',
  Import = 'IMPORT',
  NetExport = 'NET_EXPORT',
}

export enum DroneInventoriesGranularity {
  Days = 'DAYS',
  Eias = 'EIAS',
  MidWeeks = 'MID_WEEKS',
}

export type EtaEventInput = {
  readonly currentEta: Scalars['KpDateTime'];
  readonly location: EventLocationInput;
  readonly previousEta: Scalars['KpDateTime'];
  readonly timestamp: Scalars['KpDateTime'];
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  readonly vesselId: Scalars['ID'];
};

export type EventLocationInput = {
  readonly installationId?: InputMaybe<Scalars['ID']>;
  readonly zoneId: Scalars['ID'];
};

export enum ExcludeOrOnly {
  Exclude = 'EXCLUDE',
  Only = 'ONLY',
}

export type ExpandableFieldInput = {
  readonly field: Scalars['String'];
  readonly value: Scalars['String'];
};

export enum FixtureStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Finished = 'FINISHED',
  FullyFixed = 'FULLY_FIXED',
  InProgress = 'IN_PROGRESS',
  OnSubs = 'ON_SUBS',
}

export enum FleetDevelopmentAggMetric {
  Capacity = 'CAPACITY',
  Count = 'COUNT',
  Deadweight = 'DEADWEIGHT',
}

export enum FleetDevelopmentCoating {
  FullyCoated = 'FULLY_COATED',
  PartlyCoated = 'PARTLY_COATED',
  Uncoated = 'UNCOATED',
  Unknown = 'UNKNOWN',
}

export enum FleetDevelopmentMetric {
  Available = 'AVAILABLE',
  Contracting = 'CONTRACTING',
  Deliveries = 'DELIVERIES',
  Scrapping = 'SCRAPPING',
}

export enum FleetDevelopmentSplit {
  ComplianceMethod = 'COMPLIANCE_METHOD',
  EngineType = 'ENGINE_TYPE',
  Total = 'TOTAL',
  VesselCoating = 'VESSEL_COATING',
  VesselType = 'VESSEL_TYPE',
}

export enum FleetMetricsAlgo {
  CommodityOnWater = 'COMMODITY_ON_WATER',
  FloatingStorage = 'FLOATING_STORAGE',
}

export enum FleetMetricsMetric {
  Cargo = 'Cargo',
  VesselCount = 'VesselCount',
}

export enum FleetMetricsSplit {
  Buyers = 'BUYERS',
  Charterer = 'CHARTERER',
  Commodity = 'COMMODITY',
  CrudeQuality = 'CRUDE_QUALITY',
  CurrentContinents = 'CURRENT_CONTINENTS',
  CurrentCountries = 'CURRENT_COUNTRIES',
  CurrentSeas = 'CURRENT_SEAS',
  CurrentSubcontinents = 'CURRENT_SUBCONTINENTS',
  CurrentSubregions = 'CURRENT_SUBREGIONS',
  DestinationContinent = 'DESTINATION_CONTINENT',
  DestinationCountry = 'DESTINATION_COUNTRY',
  DestinationInstallation = 'DESTINATION_INSTALLATION',
  DestinationPort = 'DESTINATION_PORT',
  DestinationSubcontinent = 'DESTINATION_SUBCONTINENT',
  DestinationTradingRegion = 'DESTINATION_TRADING_REGION',
  FloatingDays = 'FLOATING_DAYS',
  Grade = 'GRADE',
  OriginContinent = 'ORIGIN_CONTINENT',
  OriginCountry = 'ORIGIN_COUNTRY',
  OriginInstallation = 'ORIGIN_INSTALLATION',
  OriginPort = 'ORIGIN_PORT',
  OriginSubcontinent = 'ORIGIN_SUBCONTINENT',
  OriginTradingRegion = 'ORIGIN_TRADING_REGION',
  Sellers = 'SELLERS',
  Total = 'TOTAL',
  TradeStatus = 'TRADE_STATUS',
  VesselType = 'VESSEL_TYPE',
}

export enum FleetUtilizationAggMetric {
  Capacity = 'CAPACITY',
  Count = 'COUNT',
  Deadweight = 'DEADWEIGHT',
}

export enum FleetUtilizationSplit {
  CurrentContinents = 'CURRENT_CONTINENTS',
  CurrentCountries = 'CURRENT_COUNTRIES',
  CurrentSeas = 'CURRENT_SEAS',
  CurrentSubcontinents = 'CURRENT_SUBCONTINENTS',
  CurrentSubregions = 'CURRENT_SUBREGIONS',
  EngineType = 'ENGINE_TYPE',
  NextContinents = 'NEXT_CONTINENTS',
  NextCountries = 'NEXT_COUNTRIES',
  NextPorts = 'NEXT_PORTS',
  NextSubcontinents = 'NEXT_SUBCONTINENTS',
  NextTradingRegions = 'NEXT_TRADING_REGIONS',
  PreviousContinents = 'PREVIOUS_CONTINENTS',
  PreviousCountries = 'PREVIOUS_COUNTRIES',
  PreviousPorts = 'PREVIOUS_PORTS',
  PreviousSubcontinents = 'PREVIOUS_SUBCONTINENTS',
  PreviousTradingRegions = 'PREVIOUS_TRADING_REGIONS',
  Product = 'PRODUCT',
  Total = 'TOTAL',
  VesselDirection = 'VESSEL_DIRECTION',
  VesselState = 'VESSEL_STATE',
  VesselSubState = 'VESSEL_SUB_STATE',
  VesselType = 'VESSEL_TYPE',
}

export enum FleetUtilizationVesselDirection {
  East = 'EAST',
  North = 'NORTH',
  NorthEast = 'NORTH_EAST',
  NorthWest = 'NORTH_WEST',
  South = 'SOUTH',
  SouthEast = 'SOUTH_EAST',
  SouthWest = 'SOUTH_WEST',
  Unknown = 'UNKNOWN',
  West = 'WEST',
}

export enum FleetUtilizationVesselState {
  Ballast = 'BALLAST',
  Floating = 'FLOATING',
  Loaded = 'LOADED',
  Maintenance = 'MAINTENANCE',
  Other = 'OTHER',
  Others = 'OTHERS',
}

export type FloatingStorageDuration = {
  readonly max?: InputMaybe<Scalars['Float']>;
  readonly min: Scalars['Float'];
};

export enum FlowsSplit {
  Buyers = 'BUYERS',
  CargoType = 'CARGO_TYPE',
  Charterer = 'CHARTERER',
  Commodity = 'COMMODITY',
  CrudeQuality = 'CRUDE_QUALITY',
  DestinationContinent = 'DESTINATION_CONTINENT',
  DestinationCountry = 'DESTINATION_COUNTRY',
  DestinationInstallation = 'DESTINATION_INSTALLATION',
  DestinationPadd = 'DESTINATION_PADD',
  DestinationPort = 'DESTINATION_PORT',
  DestinationSubcontinent = 'DESTINATION_SUBCONTINENT',
  DestinationTradingRegion = 'DESTINATION_TRADING_REGION',
  Grade = 'GRADE',
  LongHaulVesselType = 'LONG_HAUL_VESSEL_TYPE',
  OriginContinent = 'ORIGIN_CONTINENT',
  OriginCountry = 'ORIGIN_COUNTRY',
  OriginInstallation = 'ORIGIN_INSTALLATION',
  OriginPadd = 'ORIGIN_PADD',
  OriginPort = 'ORIGIN_PORT',
  OriginSubcontinent = 'ORIGIN_SUBCONTINENT',
  OriginTradingRegion = 'ORIGIN_TRADING_REGION',
  Route = 'ROUTE',
  Sellers = 'SELLERS',
  SourceEta = 'SOURCE_ETA',
  Total = 'TOTAL',
  TradeStatus = 'TRADE_STATUS',
  TradeType = 'TRADE_TYPE',
  VesselType = 'VESSEL_TYPE',
}

export enum FreightMetricsMetric {
  AvgDistance = 'AVG_DISTANCE',
  AvgSpeed = 'AVG_SPEED',
  TonDays = 'TON_DAYS',
  TonMiles = 'TON_MILES',
}

export enum FreightMetricsSplit {
  Commodity = 'COMMODITY',
  DestinationCountry = 'DESTINATION_COUNTRY',
  DestinationInstallation = 'DESTINATION_INSTALLATION',
  DestinationPadd = 'DESTINATION_PADD',
  DestinationSourceEta = 'DESTINATION_SOURCE_ETA',
  DestinationTradingRegion = 'DESTINATION_TRADING_REGION',
  EngineType = 'ENGINE_TYPE',
  OriginCountry = 'ORIGIN_COUNTRY',
  OriginInstallation = 'ORIGIN_INSTALLATION',
  OriginPadd = 'ORIGIN_PADD',
  OriginSourceEta = 'ORIGIN_SOURCE_ETA',
  OriginTradingRegion = 'ORIGIN_TRADING_REGION',
  Total = 'TOTAL',
  VesselState = 'VESSEL_STATE',
  VesselType = 'VESSEL_TYPE',
}

export enum Granularity {
  Days = 'DAYS',
  Eias = 'EIAS',
  MidWeeks = 'MID_WEEKS',
  Months = 'MONTHS',
  Quarters = 'QUARTERS',
  Weeks = 'WEEKS',
  Years = 'YEARS',
}

export enum InstallationTypeFilter {
  Anchorage = 'ANCHORAGE',
  Export = 'EXPORT',
  Import = 'IMPORT',
  Refinery = 'REFINERY',
  Shipyard = 'SHIPYARD',
  Storage = 'STORAGE',
}

export enum InventoriesDatasetName {
  Capacity = 'CAPACITY',
  DeltaLevel = 'DELTA_LEVEL',
  Demand = 'DEMAND',
  EiaDeltaLevel = 'EIA_DELTA_LEVEL',
  EiaLevel = 'EIA_LEVEL',
  Level = 'LEVEL',
  RevisitRate = 'REVISIT_RATE',
  Supply = 'SUPPLY',
  Utilization = 'UTILIZATION',
}

export enum InventoriesMarket {
  Liquids = 'LIQUIDS',
  Lng = 'LNG',
}

export enum InventoriesSnapshotSplit {
  Country = 'COUNTRY',
  Installation = 'INSTALLATION',
  Tank = 'TANK',
}

export enum InventoriesSplit {
  Country = 'COUNTRY',
  Installation = 'INSTALLATION',
  Player = 'PLAYER',
  Status = 'STATUS',
  Tank = 'TANK',
  TankStatus = 'TANK_STATUS',
  TankType = 'TANK_TYPE',
  Total = 'TOTAL',
}

export enum LayerState {
  All = 'ALL',
  Default = 'DEFAULT',
  Hidden = 'HIDDEN',
}

export type LocationInput = {
  readonly id: Scalars['ID'];
  readonly type: LocationType;
};

export enum LocationType {
  Installation = 'INSTALLATION',
  Zone = 'ZONE',
}

export enum MapBackground {
  Default = 'DEFAULT',
  Map = 'MAP',
  Satellite = 'SATELLITE',
}

export type MapInstallationFiltersInput = {
  readonly betaStatus?: ReadonlyArray<BetaStatus>;
  readonly installationLayer?: LayerState;
  readonly status?: ReadonlyArray<Scalars['String']>;
  readonly type?: ReadonlyArray<InstallationTypeFilter>;
};

export type MapItemsInput = {
  readonly commodityTypes?: ReadonlyArray<CommodityType>;
  readonly installationFilters: MapInstallationFiltersInput;
  readonly search: MapItemsSearchInput;
  readonly vesselFilters: MapVesselFiltersInput;
  readonly withFreightView?: Scalars['Boolean'];
};

export type MapItemsSearchInput = {
  readonly fromLocations?: ReadonlyArray<LocationInput>;
  readonly locations?: ReadonlyArray<LocationInput>;
  readonly playerIds?: ReadonlyArray<Scalars['ID']>;
  readonly productIds?: ReadonlyArray<Scalars['ID']>;
  readonly toLocations?: ReadonlyArray<LocationInput>;
  readonly vesselIds?: ReadonlyArray<Scalars['ID']>;
};

export enum MapUnitName {
  Barrel = 'BARREL',
  Cf = 'CF',
  Cm = 'CM',
  Kb = 'KB',
  Ktons = 'KTONS',
  Mcf = 'MCF',
  Mmbbl = 'MMBBL',
  Mtons = 'MTONS',
  Tons = 'TONS',
}

export type MapVesselFiltersInput = {
  readonly asphaltBitumenCapable?: ReadonlyArray<YesNo>;
  readonly betaStatus?: ReadonlyArray<BetaStatus>;
  readonly buildYear?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly cargoState?: ReadonlyArray<CargoState>;
  readonly cargoType?: ReadonlyArray<Scalars['String']>;
  readonly carrierType?: ReadonlyArray<Scalars['String']>;
  readonly classification?: ReadonlyArray<Scalars['String']>;
  readonly classificationCpp?: ReadonlyArray<Scalars['String']>;
  readonly classificationOil?: ReadonlyArray<Scalars['String']>;
  readonly draught?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  readonly engine?: ReadonlyArray<Scalars['String']>;
  readonly ethyleneCapable?: ReadonlyArray<YesNo>;
  readonly speed?: ReadonlyArray<Speed>;
  readonly status?: ReadonlyArray<VesselStatus>;
  readonly vesselLayer?: LayerState;
};

export type MapZoneFiltersInput = {
  readonly type?: InputMaybe<ReadonlyArray<ZoneTypeFilter>>;
  readonly zoneLayer?: LayerState;
};

export type NotificationSettingsDeleteInput = {
  readonly category: ResearchNewsNotificationsCategory;
};

export type NotificationSettingsFilters = {
  readonly category?: InputMaybe<ResearchNewsNotificationsCategory>;
  readonly frequency?: InputMaybe<ResearchNewsNotificationsFrequency>;
};

export type NotificationSettingsInput = {
  readonly category: ResearchNewsNotificationsCategory;
  readonly frequency: ResearchNewsNotificationsFrequency;
};

export type OfflineEventFiltersInput = {
  readonly eventCauses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly eventStatuses?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly eventTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly installationIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly periodEnd?: InputMaybe<Scalars['String']>;
  readonly periodStart?: InputMaybe<Scalars['String']>;
  readonly unitTypeSubFamilies?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly zoneIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type OfflineEventInput = {
  readonly page?: Scalars['Int'];
  readonly size?: Scalars['Int'];
  readonly where?: OfflineEventFiltersInput;
};

export type OfflineEventsAnalyticsParams = {
  readonly granularity: Scalars['String'];
  readonly groupByFamily?: Scalars['Boolean'];
  readonly installationIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly periodEnd?: InputMaybe<Scalars['KpDateTime']>;
  readonly periodStart?: InputMaybe<Scalars['KpDateTime']>;
  readonly splitBy?: InputMaybe<Scalars['String']>;
  readonly unitTypeSubFamilies?: ReadonlyArray<Scalars['String']>;
  readonly zoneIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum OperationType {
  Discharge = 'DISCHARGE',
  Load = 'LOAD',
  Other = 'OTHER',
}

export enum OutageType {
  Any = 'ANY',
  Planned = 'PLANNED',
  Unplanned = 'UNPLANNED',
}

export enum PeriodType {
  FutureOrientedExpiry = 'FUTURE_ORIENTED_EXPIRY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  TimeSpread = 'TIME_SPREAD',
  Week = 'WEEK',
  Year = 'YEAR',
}

export enum Platform {
  Commodities = 'COMMODITIES',
  Dry = 'DRY',
  Lng = 'LNG',
  Lpg = 'LPG',
  Merge = 'MERGE',
}

export type PortCallFiltersInput = {
  readonly canalCrossings?: InputMaybe<ExcludeOrOnly>;
  readonly canceled?: InputMaybe<ExcludeOrOnly>;
  readonly forecast?: InputMaybe<ExcludeOrOnly>;
  readonly freightView?: Scalars['Boolean'];
  /** @deprecated Use `intersectOn.locations` */
  readonly installationIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly intersectOn?: ReadonlyArray<PortCallResourceFilters>;
  readonly partialCargoes?: InputMaybe<ExcludeOrOnly>;
  readonly portCallId?: InputMaybe<Scalars['ID']>;
  /** @deprecated Use `intersectOn.productIds` */
  readonly productIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly reexports?: InputMaybe<ExcludeOrOnly>;
  readonly shipToShips?: InputMaybe<ExcludeOrOnly>;
  /** @deprecated Use `intersectOn.subLocations` */
  readonly subInstallationIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** @deprecated Use `intersectOn.subLocations` */
  readonly subZoneIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** @deprecated Use `intersectOn.vesselIds` */
  readonly vesselIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly voyageId?: InputMaybe<Scalars['ID']>;
  /** @deprecated Use `intersectOn.locations` */
  readonly zoneIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type PortCallResourceFilters = {
  readonly locations?: InputMaybe<ReadonlyArray<LocationInput>>;
  readonly portCallId?: InputMaybe<Scalars['ID']>;
  readonly productIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly vesselIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly voyageId?: InputMaybe<Scalars['ID']>;
};

export type PricesBaseFiltersDataInput = {
  readonly contracts: ReadonlyArray<PricesContractFilterParamsInput>;
};

export type PricesContractFilter = {
  readonly continuousPeriods?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly isTrial?: InputMaybe<Scalars['Boolean']>;
  readonly market: ContractMarket;
  readonly maxValidFrom?: InputMaybe<Scalars['KpDateTime']>;
  readonly minValidFrom?: InputMaybe<Scalars['KpDateTime']>;
  readonly name: Scalars['String'];
  readonly periodType?: InputMaybe<PeriodType>;
  readonly provider?: InputMaybe<PricesProvider>;
  readonly shortName?: InputMaybe<Scalars['String']>;
  readonly type: ContractType;
};

export type PricesContractFilterParamsInput = {
  readonly continuousPeriods?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly name: Scalars['String'];
  readonly periodType?: InputMaybe<PeriodType>;
};

export type PricesDetailsInput = {
  readonly date: Scalars['KpDateTime'];
  readonly endDate: Scalars['KpDateTime'];
  readonly granularity: PricesGranularity;
  readonly pricesContractFilters?: ReadonlyArray<PricesContractFilter>;
  readonly startDate: Scalars['KpDateTime'];
};

export enum PricesGranularity {
  Days = 'DAYS',
  Eias = 'EIAS',
  MidWeeks = 'MID_WEEKS',
  Months = 'MONTHS',
  Quarters = 'QUARTERS',
  Weeks = 'WEEKS',
  Years = 'YEARS',
}

export type PricesInputParams = {
  readonly contracts: ReadonlyArray<PricesContractFilterParamsInput>;
  readonly endDate: Scalars['KpDateTime'];
  readonly granularity: PricesGranularity;
  readonly isExport?: InputMaybe<Scalars['Boolean']>;
  readonly startDate: Scalars['KpDateTime'];
};

export enum PricesProvider {
  Baltic = 'BALTIC',
  Cme = 'CME',
  Parameta = 'PARAMETA',
  Spark = 'SPARK',
}

export enum ProductType {
  Family = 'FAMILY',
  Grade = 'GRADE',
  Group = 'GROUP',
  Product = 'PRODUCT',
  Subgrade = 'SUBGRADE',
}

export enum Projection {
  Actual = 'ACTUAL',
  Predictive = 'PREDICTIVE',
}

export type ReadingListInput = {
  readonly contentId: Scalars['ID'];
  readonly contentType: Scalars['String'];
};

export enum RefineriesCapacitiesMetric {
  Capacity = 'CAPACITY',
  Events = 'EVENTS',
}

export enum RefineriesCapacitiesSplit {
  EventType = 'EVENT_TYPE',
  ECause = 'E_CAUSE',
  EStatus = 'E_STATUS',
  Total = 'TOTAL',
}

export enum ResearchNewsNotificationsCategory {
  News = 'NEWS',
  Reports = 'REPORTS',
  ResearchUpdates = 'RESEARCH_UPDATES',
}

export enum ResearchNewsNotificationsFrequency {
  Daily = 'DAILY',
  Immediately = 'IMMEDIATELY',
  Weekly = 'WEEKLY',
}

export enum ResourceType {
  Installation = 'INSTALLATION',
  Zone = 'ZONE',
}

export enum SearchCategory {
  Installation = 'INSTALLATION',
  Player = 'PLAYER',
  Product = 'PRODUCT',
  Vessel = 'VESSEL',
  Zone = 'ZONE',
}

export type SearchOptionsInput = {
  readonly installation?: SearchOptionsInstallationInput;
  readonly player?: SearchOptionsPlayerInput;
  readonly product?: SearchOptionsProductInput;
  readonly vessel?: SearchOptionsVesselInput;
  readonly zone?: SearchOptionsZoneInput;
};

export type SearchOptionsInstallationInput = {
  readonly commodityTypes?: InputMaybe<ReadonlyArray<CommodityType>>;
  readonly ids?: ReadonlyArray<Scalars['ID']>;
  readonly isIir?: InputMaybe<Scalars['Boolean']>;
  readonly isStorage?: InputMaybe<Scalars['Boolean']>;
  readonly parentZoneIds?: ReadonlyArray<Scalars['ID']>;
};

export type SearchOptionsPlayerInput = {
  readonly ids?: ReadonlyArray<Scalars['ID']>;
};

export type SearchOptionsProductInput = {
  readonly ids?: ReadonlyArray<Scalars['ID']>;
};

export type SearchOptionsVesselInput = {
  readonly commodityTypes?: InputMaybe<ReadonlyArray<CommodityType>>;
  readonly ids?: ReadonlyArray<Scalars['ID']>;
};

export type SearchOptionsZoneInput = {
  readonly hasShape?: InputMaybe<Scalars['Boolean']>;
  readonly includeMetrics?: Scalars['Boolean'];
  readonly isFleetMetrics?: InputMaybe<Scalars['Boolean']>;
  readonly isStorage?: InputMaybe<Scalars['Boolean']>;
  readonly isSupplyDemand?: InputMaybe<Scalars['Boolean']>;
  readonly isViaPoint?: InputMaybe<Scalars['Boolean']>;
  readonly parentZoneIds?: ReadonlyArray<Scalars['ID']>;
};

export type Selection = {
  readonly datasetsSplitValues: ReadonlyArray<InventoriesDatasetName>;
  readonly splitValuesMetadata: ReadonlyArray<Scalars['String']>;
};

export type ShipToShipEventInput = {
  readonly eta: Scalars['KpDateTime'];
  readonly location: EventLocationInput;
  readonly shipToShipVesselId: Scalars['String'];
  readonly timestamp: Scalars['KpDateTime'];
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  readonly vesselId: Scalars['ID'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Speed {
  Moving = 'MOVING',
  Stopped = 'STOPPED',
}

export type StorageFilter = {
  readonly byCountry?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly byInstallation?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly byOnshoreOffshoreStatus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly byPlayer?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly byTankType?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export enum TankRoofType {
  Fixed = 'FIXED',
  Floating = 'FLOATING',
  Underground = 'UNDERGROUND',
}

export enum TankType {
  Oil = 'OIL',
  Products = 'PRODUCTS',
}

export type TradeActivityInput = {
  readonly fromLocations?: ReadonlyArray<LocationInput>;
  readonly locations?: ReadonlyArray<LocationInput>;
  readonly playerIds?: ReadonlyArray<Scalars['ID']>;
  readonly productIds?: ReadonlyArray<Scalars['ID']>;
  readonly toLocations?: ReadonlyArray<LocationInput>;
  readonly vesselIds?: ReadonlyArray<Scalars['ID']>;
  readonly withFreightView?: Scalars['Boolean'];
};

export type UnitCapacityFiltersInput = {
  readonly includeNewBuilds?: Scalars['Boolean'];
  readonly installationIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly periodEnd?: InputMaybe<Scalars['KpDateTime']>;
  readonly periodStart?: InputMaybe<Scalars['KpDateTime']>;
  readonly unitTypeSubFamilies?: ReadonlyArray<Scalars['String']>;
  readonly zoneIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type UnitCapacityInput = {
  readonly page?: Scalars['Int'];
  readonly size?: Scalars['Int'];
  readonly where?: UnitCapacityFiltersInput;
};

export type UserInfoInput = {
  readonly interestIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly location?: InputMaybe<UserLocationInput>;
  readonly profile?: InputMaybe<UserProfileInput>;
};

export type UserLocationInput = {
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
};

export type UserProfileInput = {
  readonly hasRequestedBalticAccess?: InputMaybe<Scalars['Boolean']>;
  readonly hasSignedTermsOfUse?: InputMaybe<Scalars['Boolean']>;
  readonly iceNumber?: InputMaybe<Scalars['String']>;
  readonly isBalticUser?: InputMaybe<Scalars['Boolean']>;
  readonly jobCategoryId?: InputMaybe<Scalars['ID']>;
  readonly jobCategoryOther?: InputMaybe<Scalars['String']>;
  readonly jobTitle: Scalars['String'];
  readonly linkedinProfileUrl?: InputMaybe<Scalars['String']>;
  readonly phoneNumber: Scalars['String'];
};

export enum VesselStatus {
  FloatingStorage = 'FLOATING_STORAGE',
  Inactive = 'INACTIVE',
  InService = 'IN_SERVICE',
  LaidUp = 'LAID_UP',
  Open = 'OPEN',
  UnderConstruction = 'UNDER_CONSTRUCTION',
}

export enum View {
  ChartArea = 'CHART_AREA',
  ChartBar = 'CHART_BAR',
  ChartLine = 'CHART_LINE',
  Table = 'TABLE',
}

export type VoyageFiltersInput = {
  readonly canalCrossings?: InputMaybe<ExcludeOrOnly>;
  readonly forecast?: InputMaybe<ExcludeOrOnly>;
  readonly freightView?: Scalars['Boolean'];
  readonly fromLocations?: ReadonlyArray<LocationInput>;
  readonly intersectOn?: ReadonlyArray<VoyageResourceFilters>;
  readonly locations?: ReadonlyArray<LocationInput>;
  readonly partialCargoes?: InputMaybe<ExcludeOrOnly>;
  readonly portCallId?: InputMaybe<Scalars['ID']>;
  readonly productIds?: ReadonlyArray<Scalars['ID']>;
  readonly reexports?: InputMaybe<ExcludeOrOnly>;
  readonly shipToShips?: InputMaybe<ExcludeOrOnly>;
  readonly subLocations?: ReadonlyArray<LocationInput>;
  readonly toLocations?: ReadonlyArray<LocationInput>;
  readonly vesselIds?: ReadonlyArray<Scalars['ID']>;
  readonly voyageId?: InputMaybe<Scalars['ID']>;
};

export type VoyageResourceFilters = {
  readonly fromLocations?: ReadonlyArray<LocationInput>;
  readonly locations?: ReadonlyArray<LocationInput>;
  readonly portCallId?: InputMaybe<Scalars['ID']>;
  readonly productIds?: ReadonlyArray<Scalars['ID']>;
  readonly subLocations?: ReadonlyArray<LocationInput>;
  readonly toLocations?: ReadonlyArray<LocationInput>;
  readonly vesselIds?: ReadonlyArray<Scalars['ID']>;
  readonly voyageId?: InputMaybe<Scalars['ID']>;
};

export enum VoyageSortBy {
  End = 'END',
  Start = 'START',
}

export type VoyageSortsInput = {
  readonly direction?: SortDirection;
  readonly sortBy?: VoyageSortBy;
};

export type WidgetBallastCapacityConfigInput = {
  readonly aggMetric: BallastCapacityAggMetric;
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly deadWeight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly etaType?: InputMaybe<Scalars['String']>;
  readonly etaWindow?: InputMaybe<Scalars['String']>;
  readonly forecast?: Scalars['Boolean'];
  readonly granularity?: InputMaybe<Granularity>;
  readonly nDaysAhead?: InputMaybe<Scalars['Int']>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly split: BallastCapacitySplit;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit: Scalars['String'];
  readonly vesselAvailability?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: View;
  readonly zones: ReadonlyArray<Scalars['Int']>;
};

export type WidgetBallastCapacityCreateInput = {
  readonly config: WidgetBallastCapacityConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetBallastCapacityUpdateInput = {
  readonly config?: InputMaybe<WidgetBallastCapacityConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetCongestionConfigInput = {
  readonly allVesselsInWaitingZone: Scalars['Boolean'];
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly deadWeight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity?: InputMaybe<Granularity>;
  readonly metric: CongestionMetric;
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly originZones?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly split: CongestionSplit;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vesselOperation: ReadonlyArray<CongestionVesselOperation>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: View;
  readonly waitingDuration?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly zones: ReadonlyArray<Scalars['Int']>;
};

export type WidgetCongestionCreateInput = {
  readonly config: WidgetCongestionConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetCongestionUpdateInput = {
  readonly config?: InputMaybe<WidgetCongestionConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetFixturesConfigInput = {
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly columns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly fZones: ReadonlyArray<Scalars['Int']>;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly statuses: ReadonlyArray<FixtureStatus>;
  readonly tZones: ReadonlyArray<Scalars['Int']>;
  readonly vesselClassifications?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly view: View;
};

export type WidgetFixturesCreateInput = {
  readonly config: WidgetFixturesConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetFixturesUpdateInput = {
  readonly config?: InputMaybe<WidgetFixturesConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetFleetDevelopmentConfigInput = {
  readonly aggMetric: FleetDevelopmentAggMetric;
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly coating?: InputMaybe<ReadonlyArray<FleetDevelopmentCoating>>;
  readonly complianceMethod: ReadonlyArray<Scalars['String']>;
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly deadWeight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity?: InputMaybe<Granularity>;
  readonly metric: FleetDevelopmentMetric;
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly projection: Projection;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly splitOn: FleetDevelopmentSplit;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: View;
};

export type WidgetFleetDevelopmentCreateInput = {
  readonly config: WidgetFleetDevelopmentConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetFleetDevelopmentUpdateInput = {
  readonly config?: InputMaybe<WidgetFleetDevelopmentConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetFleetMetricsConfigInput = {
  readonly algo: FleetMetricsAlgo;
  readonly areas: ReadonlyArray<Scalars['Int']>;
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly floatingDays?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity?: InputMaybe<Granularity>;
  readonly isEia: Scalars['Boolean'];
  readonly metric: FleetMetricsMetric;
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly split: FleetMetricsSplit;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly view: View;
};

export type WidgetFleetMetricsCreateInput = {
  readonly config: WidgetFleetMetricsConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetFleetMetricsUpdateInput = {
  readonly config?: InputMaybe<WidgetFleetMetricsConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetFleetUtilizationConfigInput = {
  readonly aggMetric: FleetUtilizationAggMetric;
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly deadWeight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly distinctAggregation?: InputMaybe<Scalars['Boolean']>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity?: InputMaybe<Granularity>;
  readonly lastFamilyProducts: ReadonlyArray<Scalars['String']>;
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly nextZoneIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly percent: Scalars['Boolean'];
  readonly previousZoneIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly showSubStates?: InputMaybe<Scalars['Boolean']>;
  readonly split: FleetUtilizationSplit;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vesselDirection?: InputMaybe<ReadonlyArray<FleetUtilizationVesselDirection>>;
  readonly vesselState: ReadonlyArray<FleetUtilizationVesselState>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: View;
  readonly zoneIds: ReadonlyArray<Scalars['Int']>;
};

export type WidgetFleetUtilizationCreateInput = {
  readonly config: WidgetFleetUtilizationConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetFleetUtilizationUpdateInput = {
  readonly config?: InputMaybe<WidgetFleetUtilizationConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetFlowsConfigInput = {
  readonly beta: Scalars['Boolean'];
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dir: Direction;
  readonly forecast?: Scalars['Boolean'];
  readonly granularity?: InputMaybe<Granularity>;
  readonly intra: Scalars['Boolean'];
  readonly intraRegion?: Scalars['Boolean'];
  readonly isEia: Scalars['Boolean'];
  readonly mInstallations: ReadonlyArray<Scalars['Int']>;
  readonly mZones: ReadonlyArray<Scalars['Int']>;
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly productEstimation?: Scalars['Boolean'];
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly projection: Projection;
  readonly sInstallations: ReadonlyArray<Scalars['Int']>;
  readonly sZones: ReadonlyArray<Scalars['Int']>;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly split: FlowsSplit;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: View;
};

export type WidgetFlowsCreateInput = {
  readonly config: WidgetFlowsConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetFlowsUpdateInput = {
  readonly config?: InputMaybe<WidgetFlowsConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetFreightMetricsConfigInput = {
  readonly beta: Scalars['Boolean'];
  readonly capacity?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly deadWeight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly deadweight?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly engineTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly granularity?: InputMaybe<Granularity>;
  readonly intra: Scalars['Boolean'];
  readonly mZones: ReadonlyArray<Scalars['Int']>;
  readonly metric: FreightMetricsMetric;
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly pricesFilters: PricesBaseFiltersDataInput;
  readonly products: ReadonlyArray<Scalars['Int']>;
  readonly sZones: ReadonlyArray<Scalars['Int']>;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly speedRange?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly splitOn: FreightMetricsSplit;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit: Scalars['String'];
  readonly vesselClassifications: ReadonlyArray<Scalars['String']>;
  readonly vesselStates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly vessels?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly view: View;
};

export type WidgetFreightMetricsCreateInput = {
  readonly config: WidgetFreightMetricsConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetFreightMetricsUpdateInput = {
  readonly config?: InputMaybe<WidgetFreightMetricsConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetInput = {
  readonly configBallastCapacity?: InputMaybe<WidgetBallastCapacityConfigInput>;
  readonly configCongestion?: InputMaybe<WidgetCongestionConfigInput>;
  readonly configFixtures?: InputMaybe<WidgetFixturesConfigInput>;
  readonly configFleetDevelopment?: InputMaybe<WidgetFleetDevelopmentConfigInput>;
  readonly configFleetMetrics?: InputMaybe<WidgetFleetMetricsConfigInput>;
  readonly configFleetUtilization?: InputMaybe<WidgetFleetUtilizationConfigInput>;
  readonly configFlows?: InputMaybe<WidgetFlowsConfigInput>;
  readonly configFreightMetrics?: InputMaybe<WidgetFreightMetricsConfigInput>;
  readonly configInventories?: InputMaybe<WidgetInventoriesConfigInput>;
  readonly configMapSearch?: InputMaybe<WidgetMapSearchConfigInput>;
  readonly configPrices?: InputMaybe<WidgetPricesConfigInput>;
  readonly configRefineriesCapacities?: InputMaybe<WidgetRefineriesCapacitiesConfigInput>;
  readonly title: Scalars['String'];
};

export type WidgetInventoriesConfigInput = {
  readonly addEiaDatasets?: InputMaybe<Scalars['Boolean']>;
  readonly capacityUtilization: Scalars['Boolean'];
  readonly cargoTrackingEnhancement: Scalars['Boolean'];
  readonly columns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly deltaLevel?: InputMaybe<Scalars['Boolean']>;
  readonly droneData?: InputMaybe<Scalars['Boolean']>;
  readonly droneDataAtmEnhancement?: Scalars['Boolean'];
  readonly fleetMetricsEnhancement?: Scalars['Boolean'];
  readonly floatingDays?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly floatingFrom?: InputMaybe<Scalars['Int']>;
  readonly granularity?: InputMaybe<Granularity>;
  readonly installations: ReadonlyArray<Scalars['Int']>;
  readonly isEia: Scalars['Boolean'];
  readonly market?: InputMaybe<InventoriesMarket>;
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly pricesFilters?: InputMaybe<PricesBaseFiltersDataInput>;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly split: InventoriesSplit;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit: Scalars['String'];
  readonly view: View;
  readonly zones: ReadonlyArray<Scalars['Int']>;
};

export type WidgetInventoriesCreateInput = {
  readonly config: WidgetInventoriesConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetInventoriesUpdateInput = {
  readonly config?: InputMaybe<WidgetInventoriesConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetMapSearchConfigInput = {
  readonly commodityTypes?: InputMaybe<ReadonlyArray<CommodityType>>;
  readonly installationFilters: MapInstallationFiltersInput;
  readonly options: ConfigOptionsInput;
  readonly search: ConfigSearchInput;
  readonly vesselFilters: MapVesselFiltersInput;
  readonly zoneFilters: MapZoneFiltersInput;
};

export type WidgetMapSearchCreateInput = {
  readonly config: WidgetMapSearchConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetMapSearchUpdateInput = {
  readonly config?: InputMaybe<WidgetMapSearchConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetPositionInput = {
  readonly h: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly w: Scalars['Int'];
  readonly x: Scalars['Int'];
  readonly y: Scalars['Int'];
};

export type WidgetPositionUnsavedInput = {
  readonly h: Scalars['Int'];
  readonly w: Scalars['Int'];
  readonly x: Scalars['Int'];
  readonly y: Scalars['Int'];
};

export type WidgetPricesConfigInput = {
  readonly contractMarket?: InputMaybe<Scalars['String']>;
  readonly contractType?: InputMaybe<Scalars['String']>;
  readonly contracts?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly granularity?: InputMaybe<Granularity>;
  readonly isForwardView?: InputMaybe<Scalars['Boolean']>;
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly pricesContractFilters?: InputMaybe<ReadonlyArray<PricesContractFilterParamsInput>>;
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit?: InputMaybe<Scalars['String']>;
  readonly view: View;
};

export type WidgetPricesCreateInput = {
  readonly config: WidgetPricesConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetPricesUpdateInput = {
  readonly config?: InputMaybe<WidgetPricesConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WidgetRefineriesCapacitiesConfigInput = {
  readonly dates?: InputMaybe<Scalars['String']>;
  readonly granularity?: InputMaybe<Granularity>;
  readonly groupByFamily?: Scalars['Boolean'];
  readonly includeNewBuilds?: InputMaybe<Scalars['Boolean']>;
  readonly installationIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly metric: RefineriesCapacitiesMetric;
  readonly movingAverage?: InputMaybe<Scalars['Boolean']>;
  readonly projection: Scalars['String'];
  readonly seasonal?: InputMaybe<Scalars['Boolean']>;
  readonly splitBy?: InputMaybe<RefineriesCapacitiesSplit>;
  readonly truncateY?: InputMaybe<Scalars['Boolean']>;
  readonly unit: Scalars['String'];
  readonly unitTypeSubFamilies?: ReadonlyArray<Scalars['String']>;
  readonly view: View;
  readonly zoneIds: ReadonlyArray<Scalars['Int']>;
};

export type WidgetRefineriesCapacitiesCreateInput = {
  readonly config: WidgetRefineriesCapacitiesConfigInput;
  readonly dashboardId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type WidgetRefineriesCapacitiesUpdateInput = {
  readonly config?: InputMaybe<WidgetRefineriesCapacitiesConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type WorksheetConfigInput = {
  readonly columns: ReadonlyArray<Scalars['String']>;
  readonly display: WorksheetDisplayInput;
  readonly expanded: WorksheetExpandedInput;
  readonly filters: ReadonlyArray<WorksheetFilterInput>;
  readonly measures: ReadonlyArray<Scalars['String']>;
  readonly rows: ReadonlyArray<Scalars['String']>;
  readonly sorting?: InputMaybe<WorksheetSortInput>;
};

export type WorksheetCreateInput = {
  readonly config: WorksheetConfigInput;
  readonly title: Scalars['String'];
  readonly type?: WorksheetType;
};

export type WorksheetDisplayInput = {
  readonly showGrandTotal: Scalars['Boolean'];
  readonly showSubtotal: Scalars['Boolean'];
};

export type WorksheetExpandedInput = {
  readonly all: Scalars['Boolean'];
  readonly columns: ReadonlyArray<ReadonlyArray<ExpandableFieldInput>>;
  readonly rows: ReadonlyArray<ReadonlyArray<ExpandableFieldInput>>;
};

export enum WorksheetFilterAction {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE',
}

export type WorksheetFilterInput = {
  readonly action: WorksheetFilterAction;
  readonly field: Scalars['String'];
  readonly values: ReadonlyArray<Scalars['String']>;
};

export type WorksheetSortConfigInput = {
  readonly direction: SortDirection;
  readonly field: Scalars['String'];
  readonly values: ReadonlyArray<Scalars['String']>;
};

export type WorksheetSortInput = {
  readonly column?: InputMaybe<WorksheetSortConfigInput>;
  readonly row?: InputMaybe<WorksheetSortConfigInput>;
};

export enum WorksheetType {
  FleetMetrics = 'FLEET_METRICS',
  Inventories = 'INVENTORIES',
  Trades = 'TRADES',
}

export type WorksheetUpdateInput = {
  readonly config?: InputMaybe<WorksheetConfigInput>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export enum YesNo {
  No = 'NO',
  Yes = 'YES',
}

export enum ZoneTypeFilter {
  Country = 'COUNTRY',
  Sea = 'SEA',
  Subregion = 'SUBREGION',
}

export type FleetMetricsZoneFragment = {
  readonly __typename: 'Zone';
  readonly id: string;
  readonly name: string;
  readonly isInUnitedStates: boolean;
};

export type HydrateFleetMetricsQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  products: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateFleetMetricsQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
    readonly isInUnitedStates: boolean;
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
    readonly hasEstimation: boolean;
    readonly parentId: string | null;
    readonly type: ProductType;
  }>;
};

export type FleetUtilizationZoneFragment = {
  readonly __typename: 'Zone';
  readonly id: string;
  readonly name: string;
  readonly bounds: {
    readonly __typename: 'ZoneBounds';
    readonly northEast: {
      readonly __typename: 'LatLon';
      readonly lat: number;
      readonly lon: number;
    };
    readonly southWest: {
      readonly __typename: 'LatLon';
      readonly lat: number;
      readonly lon: number;
    };
  } | null;
};

export type HydrateFleetUtilizationZonesQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateFleetUtilizationZonesQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
    readonly bounds: {
      readonly __typename: 'ZoneBounds';
      readonly northEast: {
        readonly __typename: 'LatLon';
        readonly lat: number;
        readonly lon: number;
      };
      readonly southWest: {
        readonly __typename: 'LatLon';
        readonly lat: number;
        readonly lon: number;
      };
    } | null;
  }>;
};

export type FlowingPathQueryVariables = Exact<{
  pipelineEdgeId?: InputMaybe<Scalars['Int']>;
  installationId?: InputMaybe<Scalars['Int']>;
}>;

export type FlowingPathQuery = {
  readonly __typename: 'Query';
  readonly flowingPath: {
    readonly __typename: 'FlowingPath';
    readonly connectedNodes: ReadonlyArray<{
      readonly __typename: 'ConnectedNode';
      readonly id: string;
    }>;
    readonly connectedInstallations: ReadonlyArray<{
      readonly __typename: 'ConnectedInstallation';
      readonly id: string;
    }>;
  };
};

export type RefineriesOverviewStatisticsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  zoneIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
  players: ReadonlyArray<Scalars['String']> | Scalars['String'];
  installationIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
}>;

export type RefineriesOverviewStatisticsQuery = {
  readonly __typename: 'Query';
  readonly refineriesOverviewStatistics: {
    readonly __typename: 'RefineriesOverviewStatistics';
    readonly newBuildRefineries: number;
    readonly operationRefineries: number;
    readonly shutteredRefineries: number;
    readonly averageNci: number;
    readonly averageAge: string;
    readonly activeUnits: number;
    readonly offlineEvents: number;
    readonly events: ReadonlyArray<{
      readonly __typename: 'Events';
      readonly name: string;
      readonly value: number;
    }>;
    readonly statisticsByRefinery: ReadonlyArray<{
      readonly __typename: 'StatisticsByRefinery';
      readonly refineryName: string | null;
      readonly country: string | null;
      readonly state: string | null;
      readonly owner: string | null;
      readonly age: string | null;
      readonly nci: string | null;
      readonly type: string | null;
      readonly status: string | null;
      readonly startup: string | null;
      readonly shutdown: string | null;
      readonly eventsAvgDuration: string | null;
      readonly plannedOverPeriod: string | null;
      readonly unplannedOverPeriod: string | null;
    }>;
  };
};

export type RefineriesUnitMetricsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  zoneIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
  installationIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
  players: ReadonlyArray<Scalars['String']> | Scalars['String'];
  unitFeedInputSplit: Scalars['String'];
  unitFeedInputGranularity: Scalars['String'];
  unitFeedInputUnit: Scalars['String'];
  unitFeedInputView: Scalars['String'];
}>;

export type RefineriesUnitMetricsQuery = {
  readonly __typename: 'Query';
  readonly refineriesUnitMetrics: {
    readonly __typename: 'RefineriesUnitMetrics';
    readonly units: ReadonlyArray<{
      readonly __typename: 'UnitsDataset';
      readonly date: string;
      readonly datasets: {
        readonly __typename: 'Units';
        readonly catalyticReforming: { readonly __typename: 'UnitsValue'; readonly value: number };
        readonly catalyticCracking: { readonly __typename: 'UnitsValue'; readonly value: number };
        readonly catHydrocracking: { readonly __typename: 'UnitsValue'; readonly value: number };
        readonly cocking: { readonly __typename: 'UnitsValue'; readonly value: number };
      };
    }>;
    readonly variations: {
      readonly __typename: 'Variations';
      readonly month: {
        readonly __typename: 'VariationsUnits';
        readonly catalyticReforming: {
          readonly __typename: 'VariationsValue';
          readonly value: number;
          readonly max: number;
        };
        readonly catalyticCracking: {
          readonly __typename: 'VariationsValue';
          readonly value: number;
          readonly max: number;
        };
        readonly catHydrocracking: {
          readonly __typename: 'VariationsValue';
          readonly value: number;
          readonly max: number;
        };
        readonly cocking: {
          readonly __typename: 'VariationsValue';
          readonly value: number;
          readonly max: number;
        };
      };
      readonly year: {
        readonly __typename: 'VariationsUnits';
        readonly catalyticReforming: {
          readonly __typename: 'VariationsValue';
          readonly value: number;
          readonly max: number;
        };
        readonly catalyticCracking: {
          readonly __typename: 'VariationsValue';
          readonly value: number;
          readonly max: number;
        };
        readonly catHydrocracking: {
          readonly __typename: 'VariationsValue';
          readonly value: number;
          readonly max: number;
        };
        readonly cocking: {
          readonly __typename: 'VariationsValue';
          readonly value: number;
          readonly max: number;
        };
      };
    };
  };
};

export type GetProductsTreeQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductsTreeQuery = {
  readonly __typename: 'Query';
  readonly productsTree: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
    readonly hasEstimation: boolean;
    readonly parentId: string | null;
    readonly type: ProductType;
  }>;
};

export type HasInAppAccessRequestQueryVariables = Exact<{
  appFeature: Scalars['String'];
}>;

export type HasInAppAccessRequestQuery = {
  readonly __typename: 'Query';
  readonly userAccessRequest: {
    readonly __typename: 'UserRequest';
    readonly id: string;
    readonly created: boolean;
  };
};

export type CreateInAppAccessRequestMutationVariables = Exact<{
  appFeature: Scalars['String'];
  url: Scalars['String'];
}>;

export type CreateInAppAccessRequestMutation = {
  readonly __typename: 'Mutation';
  readonly createUserAccessRequest: {
    readonly __typename: 'UserRequest';
    readonly id: string;
    readonly created: boolean;
  };
};

export type GetContractByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractByIdQuery = {
  readonly __typename: 'Query';
  readonly contractsById: ReadonlyArray<{
    readonly __typename: 'Contract';
    readonly id: string;
    readonly type: string;
    readonly start: string | null;
    readonly end: string | null;
    readonly open: boolean;
    readonly slot: number | null;
    readonly capacity: number | null;
    readonly delivery: string | null;
    readonly isFlexible: boolean | null;
    readonly lowPriority: boolean;
    readonly comment: string | null;
    readonly buyer: {
      readonly __typename: 'Player';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly seller: {
      readonly __typename: 'Player';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly originInstallations: ReadonlyArray<{
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
    }>;
    readonly destinationInstallations: ReadonlyArray<{
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
    }>;
    readonly editInfo: {
      readonly __typename: 'EditInfo';
      readonly createdAt: string | null;
      readonly createdBy: string | null;
      readonly updatedBy: string | null;
      readonly updatedAt: string | null;
    };
  }>;
};

export type InventoriesSeriesQueryVariables = Exact<{
  cargoTrackingEnhancement: Scalars['Boolean'];
  endDate: Scalars['String'];
  fleetMetricsEnhancement: Scalars['Boolean'];
  floatingStorageDuration?: InputMaybe<FloatingStorageDuration>;
  granularity: Granularity;
  locationIds: ReadonlyArray<Scalars['Int']> | Scalars['Int'];
  locationResourceType: ResourceType;
  numberOfSplits?: InputMaybe<Scalars['Float']>;
  splitCriteria: InventoriesSplit;
  startDate: Scalars['String'];
  platform?: InputMaybe<Platform>;
  droneData?: InputMaybe<Scalars['Boolean']>;
  addEiaDatasets?: InputMaybe<Scalars['Boolean']>;
  selection?: InputMaybe<Selection>;
  droneDataAtmEnhancement?: InputMaybe<Scalars['Boolean']>;
}>;

export type InventoriesSeriesQuery = {
  readonly __typename: 'Query';
  readonly inventoriesSeries: {
    readonly __typename?: 'InventoriesTimeSeries';
    readonly metadata: {
      readonly __typename?: 'InventoriesMetadata';
      readonly granularity: Granularity;
      readonly forecastSince?: Maybe<string>;
    };
    readonly series: ReadonlyArray<{
      readonly __typename?: 'InventoriesTimeSeriesItem';
      readonly date: string;
      readonly datasets: ReadonlyArray<{
        readonly __typename?: 'InventoriesDataset';
        readonly datasetName: string;
        readonly values: {
          readonly __typename?: 'InventoriesValues';
          readonly volume?: Maybe<number>;
          readonly energy?: Maybe<number>;
          readonly volume_gas?: Maybe<number>;
          readonly providerName?: Maybe<string>;
        };
        readonly splitValues?: Maybe<
          ReadonlyArray<{
            readonly __typename?: 'InventoriesSplitValue';
            readonly splitId: string;
            readonly name: string;
            readonly values: {
              readonly __typename?: 'InventoriesValues';
              readonly volume?: Maybe<number>;
              readonly energy?: Maybe<number>;
              readonly volume_gas?: Maybe<number>;
            };
          }>
        >;
      }>;
    }>;
  };
};

export type InventoriesSnapshotQueryVariables = Exact<{
  cargoTrackingEnhancement: Scalars['Boolean'];
  endDate: Scalars['String'];
  fleetMetricsEnhancement?: InputMaybe<Scalars['Boolean']>;
  floatingStorageDuration?: InputMaybe<FloatingStorageDuration>;
  granularity: Granularity;
  locationIds: ReadonlyArray<Scalars['Int']> | Scalars['Int'];
  locationResourceType: ResourceType;
  splitCriteria: InventoriesSnapshotSplit;
  platform: Platform;
  filters: StorageFilter;
  notFilters: StorageFilter;
  droneData?: InputMaybe<Scalars['Boolean']>;
}>;

export type InventoriesSnapshotQuery = {
  readonly __typename: 'Query';
  readonly inventoriesSnapshot:
    | {
        readonly __typename: 'InventoriesSnapshotCountry';
        readonly volume: number;
        readonly granularity: Granularity;
        readonly endDate: string;
        readonly deltaVolume: number | null;
        readonly capacity: number;
        readonly revisitRate: number | null;
        readonly splitBy: InventoriesSplit;
        readonly onshoreOutflow: number | null;
        readonly onshoreInflow: number | null;
        readonly details: ReadonlyArray<{
          readonly __typename: 'InventoriesSnapshotDetailCountry';
          readonly id: number;
          readonly name: string;
          readonly capacity: number;
          readonly volume: number | null;
          readonly deltaVolume: number | null;
          readonly revisitRate: number | null;
        }> | null;
      }
    | {
        readonly __typename: 'InventoriesSnapshotInstallation';
        readonly volume: number;
        readonly granularity: Granularity;
        readonly endDate: string;
        readonly deltaVolume: number | null;
        readonly capacity: number;
        readonly revisitRate: number | null;
        readonly splitBy: InventoriesSplit;
        readonly onshoreOutflow: number | null;
        readonly onshoreInflow: number | null;
        readonly details: ReadonlyArray<
          | {
              readonly __typename: 'InventoriesSnapshotDetailInstallationBase';
              readonly id: number;
              readonly name: string;
              readonly capacity: number;
              readonly volume: number | null;
              readonly deltaVolume: number | null;
              readonly lastImageDate: string | null;
              readonly penultimateImageDate: string | null;
            }
          | {
              readonly __typename: 'InventoriesSnapshotDetailInstallationWithCargoTrackingEnhancementExtraProperties';
              readonly id: number;
              readonly name: string;
              readonly capacity: number;
              readonly volume: number | null;
              readonly deltaVolume: number | null;
              readonly lastImageDate: string | null;
              readonly penultimateImageDate: string | null;
              readonly onshoreOutflow: number;
              readonly onshoreInflow: number;
            }
        > | null;
      }
    | {
        readonly __typename: 'InventoriesSnapshotTank';
        readonly volume: number;
        readonly granularity: Granularity;
        readonly endDate: string;
        readonly deltaVolume: number | null;
        readonly capacity: number;
        readonly revisitRate: number | null;
        readonly splitBy: InventoriesSplit;
        readonly onshoreOutflow: number | null;
        readonly onshoreInflow: number | null;
        readonly details: ReadonlyArray<
          | {
              readonly __typename: 'InventoriesSnapshotDetailTankOil';
              readonly id: number;
              readonly name: string;
              readonly capacity: number;
              readonly volume: number | null;
              readonly deltaVolume: number | null;
              readonly roofType: TankRoofType;
              readonly tankType: TankType;
              readonly lastImageDate: string | null;
            }
          | {
              readonly __typename: 'InventoriesSnapshotDetailTankProducts';
              readonly id: number;
              readonly name: string;
              readonly capacity: number;
              readonly volume: number | null;
              readonly deltaVolume: number | null;
              readonly roofType: TankRoofType;
              readonly tankType: TankType;
            }
        > | null;
      };
};

export type BasePartFragment = {
  readonly __typename: 'OfflineEvent';
  readonly installationId: string | null;
  readonly installationName: string | null;
  readonly eventId: string;
  readonly unitName: string | null;
  readonly unitTypeShortName: string | null;
  readonly utypeDesc: string | null;
  readonly eStatus: string | null;
  readonly eCause: string | null;
  readonly uCapacity: number | null;
  readonly capOffline: number | null;
  readonly startDate: string | null;
  readonly endDate: string | null;
};

export type OfflineEventsQueryVariables = Exact<{
  input: OfflineEventInput;
}>;

export type OfflineEventsQuery = {
  readonly __typename: 'Query';
  readonly offlineEvents: {
    readonly __typename: 'OfflineEventResponse';
    readonly total: number;
    readonly items: ReadonlyArray<{
      readonly __typename: 'OfflineEvent';
      readonly plantName: string | null;
      readonly unitId: string | null;
      readonly ownerName: string | null;
      readonly plantId: string | null;
      readonly physCity: string | null;
      readonly pCounty: string | null;
      readonly plantState: string | null;
      readonly eventType: string | null;
      readonly country: string | null;
      readonly marketReg: string | null;
      readonly worldReg: string | null;
      readonly uStatus: string | null;
      readonly primFuel: string | null;
      readonly secndFuel: string | null;
      readonly fuelGroup: string | null;
      readonly capUom: string | null;
      readonly powerUsag: string | null;
      readonly eDuration: number | null;
      readonly eventKind: string | null;
      readonly precision: string | null;
      readonly eStatus: string | null;
      readonly prevStart: string | null;
      readonly prevEnd: string | null;
      readonly heatrate: string | null;
      readonly elecondesc: string | null;
      readonly paddReg: string | null;
      readonly isortoregion: string | null;
      readonly pTradeRegion: string | null;
      readonly mmcfDemand: string | null;
      readonly gasRegion: string | null;
      readonly comments: string | null;
      readonly confirm: string | null;
      readonly parentname: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly liveDate: string | null;
      readonly releaseDt: string | null;
      readonly installationId: string | null;
      readonly installationName: string | null;
      readonly eventId: string;
      readonly unitName: string | null;
      readonly unitTypeShortName: string | null;
      readonly utypeDesc: string | null;
      readonly eCause: string | null;
      readonly uCapacity: number | null;
      readonly capOffline: number | null;
      readonly startDate: string | null;
      readonly endDate: string | null;
    }>;
  };
};

export type RspOfflineEventsQueryVariables = Exact<{
  input: OfflineEventInput;
}>;

export type RspOfflineEventsQuery = {
  readonly __typename: 'Query';
  readonly offlineEvents: {
    readonly __typename: 'OfflineEventResponse';
    readonly total: number;
    readonly items: ReadonlyArray<{
      readonly __typename: 'OfflineEvent';
      readonly installationId: string | null;
      readonly installationName: string | null;
      readonly eventId: string;
      readonly unitName: string | null;
      readonly unitTypeShortName: string | null;
      readonly utypeDesc: string | null;
      readonly eStatus: string | null;
      readonly eCause: string | null;
      readonly uCapacity: number | null;
      readonly capOffline: number | null;
      readonly startDate: string | null;
      readonly endDate: string | null;
    }>;
  };
};

export type InstallationOfflineEventsQueryVariables = Exact<{ [key: string]: never }>;

export type InstallationOfflineEventsQuery = {
  readonly __typename: 'Query';
  readonly plants: ReadonlyArray<{
    readonly __typename: 'Plant';
    readonly id: string;
    readonly hasPlanned: boolean;
    readonly hasUnplanned: boolean;
  }>;
};

export type UnitTypeSubFamiliesQueryVariables = Exact<{ [key: string]: never }>;

export type UnitTypeSubFamiliesQuery = {
  readonly __typename: 'Query';
  readonly unitTypeSubFamilies: ReadonlyArray<{
    readonly __typename: 'UnitTypeSubFamily';
    readonly name: string;
    readonly family: string;
  }>;
};

export type OfflineEventTimeSeriesQueryVariables = Exact<{
  input: OfflineEventsAnalyticsParams;
}>;

export type OfflineEventTimeSeriesQuery = {
  readonly __typename: 'Query';
  readonly offlineEventAnalytics: {
    readonly __typename: 'OfflineEventTimeSeries';
    readonly metadata: {
      readonly __typename: 'OfflineEventsMetadata';
      readonly datasetNames: ReadonlyArray<string>;
      readonly splitValueNames: ReadonlyArray<string>;
    };
    readonly series: ReadonlyArray<{
      readonly __typename: 'OfflineEventsTimeSeriesItem';
      readonly date: string;
      readonly datasets: ReadonlyArray<{
        readonly __typename: 'OfflineEventsDataset';
        readonly datasetName: string;
        readonly values: { readonly __typename: 'RefinerieValues'; readonly volume: number };
        readonly splitValues: ReadonlyArray<{
          readonly __typename: 'OfflineEventsSplitValue';
          readonly splitId: string;
          readonly name: string;
          readonly values: { readonly __typename: 'RefinerieValues'; readonly volume: number };
        }> | null;
      }>;
    }>;
  };
};

export type AvailableCapacityTimeSeriesQueryVariables = Exact<{
  input: AvailableCapacityAnalyticsParams;
}>;

export type AvailableCapacityTimeSeriesQuery = {
  readonly __typename: 'Query';
  readonly availableCapacitiesAnalytics: {
    readonly __typename: 'AvailableCapacityTimeSeries';
    readonly metadata: {
      readonly __typename: 'AvailableCapacityMetadata';
      readonly datasetNames: ReadonlyArray<string>;
    };
    readonly series: ReadonlyArray<{
      readonly __typename: 'AvailableCapacityTimeSeriesItem';
      readonly date: string;
      readonly datasets: ReadonlyArray<{
        readonly __typename: 'AvailableCapacityDataset';
        readonly datasetName: string;
        readonly values: { readonly __typename: 'RefinerieValues'; readonly volume: number };
        readonly splitValues: ReadonlyArray<{
          readonly __typename: 'AvailableCapacitySplitValue';
          readonly splitId: string;
          readonly name: string;
          readonly values: { readonly __typename: 'RefinerieValues'; readonly volume: number };
        }> | null;
      }>;
    }>;
  };
};

export type UnitCapacitiesQueryVariables = Exact<{
  input: UnitCapacityInput;
}>;

export type UnitCapacitiesQuery = {
  readonly __typename: 'Query';
  readonly unitCapacities: ReadonlyArray<{
    readonly __typename: 'UnitCapacity';
    readonly installationId: string | null;
    readonly installationName: string | null;
    readonly name: string;
    readonly unitType: string;
    readonly maxCapacity: number;
    readonly availableCapacity: number;
  }>;
};

export type RefineriesInstallationsQueryVariables = Exact<{ [key: string]: never }>;

export type RefineriesInstallationsQuery = {
  readonly __typename: 'Query';
  readonly refineriesInstallations: ReadonlyArray<{
    readonly __typename: 'RefineriesInstallations';
    readonly id: string;
    readonly name: string;
  }>;
};

export type SlotsQueryVariables = Exact<{
  installationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type SlotsQuery = {
  readonly __typename: 'Query';
  readonly slots: ReadonlyArray<{
    readonly __typename: 'Slot';
    readonly onOffer: boolean;
    readonly date: string;
    readonly installation: {
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
    };
    readonly seller: {
      readonly __typename: 'Player';
      readonly name: string;
      readonly id: string;
    } | null;
  }>;
};

export type NotificationSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationSettingsQuery = {
  readonly __typename: 'Query';
  readonly notificationSettingsMine: ReadonlyArray<{
    readonly __typename: 'NotificationSettings';
    readonly id: string;
    readonly category: ResearchNewsNotificationsCategory;
    readonly frequency: ResearchNewsNotificationsFrequency;
  }>;
};

export type NotificationSettingsDeleteMutationVariables = Exact<{
  content: NotificationSettingsDeleteInput;
}>;

export type NotificationSettingsDeleteMutation = {
  readonly __typename: 'Mutation';
  readonly notificationSettingsDelete: boolean;
};

export type NotificationSettingsAddMutationVariables = Exact<{
  content: NotificationSettingsInput;
}>;

export type NotificationSettingsAddMutation = {
  readonly __typename: 'Mutation';
  readonly notificationSettingsAdd: {
    readonly __typename: 'NotificationSettings';
    readonly id: string;
    readonly category: ResearchNewsNotificationsCategory;
    readonly frequency: ResearchNewsNotificationsFrequency;
  };
};

export type NotificationSettingsUpdateMutationVariables = Exact<{
  content: NotificationSettingsInput;
}>;

export type NotificationSettingsUpdateMutation = {
  readonly __typename: 'Mutation';
  readonly notificationSettingsUpdate: {
    readonly __typename: 'NotificationSettings';
    readonly id: string;
    readonly category: ResearchNewsNotificationsCategory;
    readonly frequency: ResearchNewsNotificationsFrequency;
  };
};

export type UpdateTermsOfUseMutationVariables = Exact<{
  accepted: Scalars['Boolean'];
}>;

export type UpdateTermsOfUseMutation = {
  readonly __typename: 'Mutation';
  readonly updateTermsOfUse: {
    readonly __typename: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly companyName: string;
    readonly interestIds: ReadonlyArray<string> | null;
    readonly hasSignedTermsOfUse: boolean | null;
    readonly location: {
      readonly __typename: 'UserLocation';
      readonly city: string;
      readonly country: string;
    } | null;
    readonly profile: {
      readonly __typename: 'UserProfile';
      readonly jobTitle: string;
      readonly jobCategoryId: string | null;
      readonly jobCategoryOther: string | null;
      readonly phoneNumber: string;
      readonly linkedinProfileUrl: string | null;
      readonly iceNumber: string | null;
      readonly isBalticUser: boolean | null;
      readonly hasRequestedBalticAccess: boolean | null;
    } | null;
  };
};

export type InventoriesZoneFragment = {
  readonly __typename: 'Zone';
  readonly containsUnitedStates: boolean;
  readonly id: string;
  readonly hasStorage: boolean;
  readonly isInUnitedStates: boolean;
  readonly name: string;
  readonly type: string;
};

export type HydrateInventoriesQueryVariables = Exact<{
  installationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  zoneIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateInventoriesQuery = {
  readonly __typename: 'Query';
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly hasCargoTracking: boolean;
    readonly hasStorage: boolean;
    readonly id: string;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly iirName: string | null;
    readonly portCost: number | null;
    readonly type: string;
    readonly unlocodes: ReadonlyArray<string>;
    readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    readonly port: { readonly __typename: 'Zone'; readonly id: string };
  }>;
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly containsUnitedStates: boolean;
    readonly id: string;
    readonly hasStorage: boolean;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly type: string;
  }>;
};

export type PricesByContractsQueryVariables = Exact<{
  query: PricesInputParams;
}>;

export type PricesByContractsQuery = {
  readonly __typename: 'Query';
  readonly pricesTimeSeries: {
    readonly __typename: 'PricesTimeSeries';
    readonly metadata: {
      readonly __typename: 'PricesMetadata';
      readonly datasets: ReadonlyArray<string>;
      readonly commonUnit: string | null;
    };
    readonly series: ReadonlyArray<{
      readonly __typename: 'PricesTimeSeriesItem';
      readonly date: string;
      readonly datasets: ReadonlyArray<{
        readonly __typename: 'PricesDataset';
        readonly datasetName: string;
        readonly values: {
          readonly __typename: 'PricesValues';
          readonly unit: string | null;
          readonly pricePerDay: {
            readonly __typename: 'PricesOhlcObject';
            readonly open: number | null;
            readonly high: number | null;
            readonly low: number | null;
            readonly close: number;
          } | null;
          readonly pricePerUnit: {
            readonly __typename: 'PricesOhlcObject';
            readonly open: number | null;
            readonly high: number | null;
            readonly low: number | null;
            readonly close: number;
          } | null;
        };
      }>;
    }>;
  };
};

export type ContractsForCurrentPlatformQueryVariables = Exact<{ [key: string]: never }>;

export type ContractsForCurrentPlatformQuery = {
  readonly __typename: 'Query';
  readonly contractsForCurrentPlatform: ReadonlyArray<{
    readonly __typename: 'PricesContract';
    readonly type: ContractType | null;
    readonly periodType: PeriodType | null;
    readonly name: string;
    readonly shortName: string;
    readonly market: ContractMarket | null;
    readonly provider: PricesProvider | null;
    readonly minValidFrom: string | null;
    readonly maxValidFrom: string | null;
    readonly continuousPeriods: ReadonlyArray<number>;
    readonly vesselTypes: ReadonlyArray<string>;
    readonly products: ReadonlyArray<string>;
    readonly contractSpecifications: {
      readonly __typename: 'PricesContractSpecifications';
      readonly description: string | null;
      readonly ticker: string | null;
      readonly exchange: string | null;
      readonly listedExchange: string | null;
      readonly currency: string | null;
      readonly sector: string | null;
      readonly industry: string | null;
      readonly timezone: string | null;
      readonly session: string | null;
    };
  }>;
};

export type PricesDetailsByContractsQueryVariables = Exact<{
  query: PricesDetailsInput;
}>;

export type PricesDetailsByContractsQuery = {
  readonly __typename: 'Query';
  readonly pricesDetailsByContracts: ReadonlyArray<{
    readonly __typename: 'PricesDetailsData';
    readonly contract: string;
    readonly unit: string | null;
    readonly date: string;
    readonly pricePerDay: {
      readonly __typename: 'PricesOhlcObject';
      readonly high: number | null;
      readonly low: number | null;
      readonly close: number;
    } | null;
    readonly pricePerUnit: {
      readonly __typename: 'PricesOhlcObject';
      readonly high: number | null;
      readonly low: number | null;
      readonly close: number;
    } | null;
  }>;
};

export type FreightCalcLastPricesByContractsQueryVariables = Exact<{ [key: string]: never }>;

export type FreightCalcLastPricesByContractsQuery = {
  readonly __typename: 'Query';
  readonly freightCalcLastPricesByContracts: ReadonlyArray<{
    readonly __typename: 'FreightCalcFuelPrices';
    readonly contract: string;
    readonly date: string;
    readonly validFrom: string | null;
    readonly validTo: string | null;
    readonly unit: string | null;
    readonly priceUsdPerUnit: number | null;
  }>;
};

export type FreightCalcLastPricesQueryVariables = Exact<{ [key: string]: never }>;

export type FreightCalcLastPricesQuery = {
  readonly __typename: 'Query';
  readonly freightCalcLastPrices: ReadonlyArray<{
    readonly __typename: 'FreightCalcBalticPrices';
    readonly shortName: string;
    readonly date: string;
    readonly unit: string | null;
    readonly priceUsdPerUnit: number | null;
    readonly change: number | null;
    readonly clean: boolean | null;
    readonly dirty: boolean | null;
    readonly dry: boolean | null;
    readonly lpg: boolean | null;
    readonly lng: boolean | null;
  }>;
};

export type ProductDrilldownQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  type: ProductType;
}>;

export type ProductDrilldownQuery = {
  readonly __typename: 'Query';
  readonly productsChildren: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
    readonly hasEstimation: boolean;
    readonly parentId: string | null;
    readonly type: ProductType;
  }>;
};

export type ZoneCountriesQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type ZoneCountriesQuery = {
  readonly __typename: 'Query';
  readonly zoneCountries: ReadonlyArray<{ readonly __typename: 'Zone'; readonly id: string }>;
};

export type ZonesDrilldownQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  withPorts: Scalars['Boolean'];
  withInstallations: Scalars['Boolean'];
}>;

export type ZonesDrilldownQuery = {
  readonly __typename: 'Query';
  readonly zoneCountries: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly isInUnitedStates: boolean;
  }>;
  readonly zonePorts?: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly isInUnitedStates: boolean;
  }>;
  readonly zoneInstallations?: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly id: string;
    readonly name: string;
    readonly isInUnitedStates: boolean;
  }>;
};

export type LatLonFragment = {
  readonly __typename: 'LatLon';
  readonly lat: number;
  readonly lon: number;
};

export type CurrentCyclonesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentCyclonesQuery = {
  readonly __typename: 'Query';
  readonly cyclonesCurrent: ReadonlyArray<{
    readonly __typename: 'Cyclone';
    readonly id: string;
    readonly name: string;
    readonly windsGust: number | null;
    readonly windsSustained: number;
    readonly reportDate: string;
    readonly position: {
      readonly __typename: 'LatLon';
      readonly lat: number;
      readonly lon: number;
    };
    readonly forecastPositions: ReadonlyArray<{
      readonly __typename: 'CycloneForecast';
      readonly wind: number;
      readonly date: string;
      readonly position: {
        readonly __typename: 'LatLon';
        readonly lat: number;
        readonly lon: number;
      };
    }>;
    readonly forecastShape: ReadonlyArray<{
      readonly __typename: 'LatLon';
      readonly lat: number;
      readonly lon: number;
    }>;
  }>;
};

export type HydrateFixturesTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateFixturesTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateRefineriesCapacitiesTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  installations: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateRefineriesCapacitiesTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateBallastCapacityTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  products: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateBallastCapacityTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateCongestionTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  products: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateCongestionTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateFleetMetricsTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  products: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateFleetMetricsTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateFleetUtilizationTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateFleetUtilizationTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateFlowsTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  installations: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  products: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateFlowsTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly id: string;
    readonly name: string;
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateFreightMetricsTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  products: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateFreightMetricsTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateInventoriesTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  installations: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateInventoriesTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateMapTitleQueryVariables = Exact<{
  zones: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  installations: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  products: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  vessels: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  players: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateMapTitleQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly id: string;
    readonly name: string;
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
  }>;
  readonly vesselsById: ReadonlyArray<{
    readonly __typename: 'Vessel';
    readonly id: string;
    readonly name: string;
  }>;
  readonly playersById: ReadonlyArray<{
    readonly __typename: 'Player';
    readonly id: string;
    readonly name: string;
  }>;
};

export type DiversionPortCallFragment = {
  readonly __typename: 'PortCall';
  readonly id: string;
  readonly start: string | null;
  readonly eta: string | null;
  readonly end: string | null;
  readonly source: string | null;
  readonly installation: {
    readonly __typename: 'Installation';
    readonly id: string;
    readonly name: string;
  } | null;
  readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
};

export type LatestDiversionsQueryVariables = Exact<{ [key: string]: never }>;

export type LatestDiversionsQuery = {
  readonly __typename: 'Query';
  readonly diversionsLatest: ReadonlyArray<{
    readonly __typename: 'Diversion';
    readonly id: string;
    readonly isBallast: boolean;
    readonly divertedAt: string;
    readonly vessel: { readonly __typename: 'Vessel'; readonly id: string; readonly name: string };
    readonly player: {
      readonly __typename: 'Player';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly cancelledPortCall: {
      readonly __typename: 'PortCall';
      readonly id: string;
      readonly start: string | null;
      readonly eta: string | null;
      readonly end: string | null;
      readonly source: string | null;
      readonly installation: {
        readonly __typename: 'Installation';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
    };
    readonly originPortCall: {
      readonly __typename: 'PortCall';
      readonly id: string;
      readonly start: string | null;
      readonly eta: string | null;
      readonly end: string | null;
      readonly source: string | null;
      readonly installation: {
        readonly __typename: 'Installation';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
    };
    readonly destinationPortCall: {
      readonly __typename: 'PortCall';
      readonly id: string;
      readonly start: string | null;
      readonly eta: string | null;
      readonly end: string | null;
      readonly source: string | null;
      readonly installation: {
        readonly __typename: 'Installation';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
    };
  }>;
};

export type LatestOutagesQueryVariables = Exact<{ [key: string]: never }>;

export type LatestOutagesQuery = {
  readonly __typename: 'Query';
  readonly outagesLatest: ReadonlyArray<{
    readonly __typename: 'Outage';
    readonly id: string;
    readonly type: OutageType;
    readonly start: string;
    readonly end: string | null;
    readonly comment: string | null;
    readonly installation: {
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
    };
  }>;
};

export type TenderInstallationFragment = {
  readonly __typename: 'Installation';
  readonly id: string;
  readonly name: string;
  readonly type: string;
};

export type LatestTendersQueryVariables = Exact<{
  commodityType?: InputMaybe<CommodityType>;
  marketId?: InputMaybe<Scalars['ID']>;
}>;

export type LatestTendersQuery = {
  readonly __typename: 'Query';
  readonly tendersLatest: ReadonlyArray<{
    readonly __typename: 'Contract';
    readonly id: string;
    readonly start: string | null;
    readonly end: string | null;
    readonly open: boolean;
    readonly closedDate: string | null;
    readonly status: string | null;
    readonly delivery: string | null;
    readonly slot: number | null;
    readonly comment: string | null;
    readonly seller: {
      readonly __typename: 'Player';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly buyer: {
      readonly __typename: 'Player';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly originInstallations: ReadonlyArray<{
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
      readonly type: string;
    }>;
    readonly destinationInstallations: ReadonlyArray<{
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
      readonly type: string;
    }>;
    readonly products: ReadonlyArray<{
      readonly __typename: 'ContractProduct';
      readonly id: string;
      readonly name: string;
    }> | null;
  }>;
};

export type WidgetVesselFragment = {
  readonly __typename: 'Vessel';
  readonly beta: boolean;
  readonly currentCommodityType: CommodityType | null;
  readonly id: string;
  readonly isBunker: boolean;
  readonly isFeeder: boolean;
  readonly isFeederStorage: boolean;
  readonly isOpen: boolean;
  readonly isFloatingStorage: boolean;
  readonly name: string;
  readonly state: string;
  readonly capacity: {
    readonly __typename: 'QuantityObject';
    readonly energy: number;
    readonly mass: number;
    readonly volume: number;
    readonly volume_gas: number;
  };
  readonly controller: {
    readonly __typename: 'Player';
    readonly id: string;
    readonly name: string;
  } | null;
  readonly lastPosition: {
    readonly __typename: 'Position';
    readonly course: number;
    readonly receivedTime: string;
    readonly speed: number;
    readonly currentCargo: {
      readonly __typename: 'PositionCargo';
      readonly products: ReadonlyArray<{
        readonly __typename: 'Product';
        readonly ancestorIds: ReadonlyArray<string>;
        readonly name: string;
      }>;
      readonly quantity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      };
    } | null;
    readonly geo: { readonly __typename: 'LatLon'; readonly lat: number; readonly lon: number };
  } | null;
};

export type WidgetInstallationFragment = {
  readonly __typename: 'Installation';
  readonly id: string;
  readonly name: string;
  readonly hasStorage: boolean;
  readonly hasCargoTracking: boolean;
  readonly hasOpenTenders: boolean;
  readonly geo: {
    readonly __typename: 'LatLon';
    readonly lat: number;
    readonly lon: number;
  } | null;
};

export type MapItemsQueryVariables = Exact<{
  where: MapItemsInput;
}>;

export type MapItemsQuery = {
  readonly __typename: 'Query';
  readonly mapItems: {
    readonly __typename: 'MapItems';
    readonly vessels: ReadonlyArray<{
      readonly __typename: 'Vessel';
      readonly beta: boolean;
      readonly currentCommodityType: CommodityType | null;
      readonly id: string;
      readonly isBunker: boolean;
      readonly isFeeder: boolean;
      readonly isFeederStorage: boolean;
      readonly isOpen: boolean;
      readonly isFloatingStorage: boolean;
      readonly name: string;
      readonly state: string;
      readonly capacity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      };
      readonly controller: {
        readonly __typename: 'Player';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly lastPosition: {
        readonly __typename: 'Position';
        readonly course: number;
        readonly receivedTime: string;
        readonly speed: number;
        readonly currentCargo: {
          readonly __typename: 'PositionCargo';
          readonly products: ReadonlyArray<{
            readonly __typename: 'Product';
            readonly ancestorIds: ReadonlyArray<string>;
            readonly name: string;
          }>;
          readonly quantity: {
            readonly __typename: 'QuantityObject';
            readonly energy: number;
            readonly mass: number;
            readonly volume: number;
            readonly volume_gas: number;
          };
        } | null;
        readonly geo: { readonly __typename: 'LatLon'; readonly lat: number; readonly lon: number };
      } | null;
    }>;
    readonly installations: ReadonlyArray<{
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
      readonly hasStorage: boolean;
      readonly hasCargoTracking: boolean;
      readonly hasOpenTenders: boolean;
      readonly geo: {
        readonly __typename: 'LatLon';
        readonly lat: number;
        readonly lon: number;
      } | null;
    }>;
  };
};

export type InstallationsMapItemsQueryVariables = Exact<{
  where: MapItemsInput;
}>;

export type InstallationsMapItemsQuery = {
  readonly __typename: 'Query';
  readonly mapItems: {
    readonly __typename: 'MapItems';
    readonly installations: ReadonlyArray<{
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
      readonly hasStorage: boolean;
      readonly hasCargoTracking: boolean;
      readonly hasOpenTenders: boolean;
      readonly geo: {
        readonly __typename: 'LatLon';
        readonly lat: number;
        readonly lon: number;
      } | null;
    }>;
  };
};

export type PlayerMapItemsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type PlayerMapItemsQuery = {
  readonly __typename: 'Query';
  readonly playersById: ReadonlyArray<{
    readonly __typename: 'Player';
    readonly id: string;
    readonly vessels: ReadonlyArray<{
      readonly __typename: 'Vessel';
      readonly beta: boolean;
      readonly currentCommodityType: CommodityType | null;
      readonly id: string;
      readonly isBunker: boolean;
      readonly isFeeder: boolean;
      readonly isFeederStorage: boolean;
      readonly isOpen: boolean;
      readonly isFloatingStorage: boolean;
      readonly name: string;
      readonly state: string;
      readonly capacity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      };
      readonly controller: {
        readonly __typename: 'Player';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly lastPosition: {
        readonly __typename: 'Position';
        readonly course: number;
        readonly receivedTime: string;
        readonly speed: number;
        readonly currentCargo: {
          readonly __typename: 'PositionCargo';
          readonly products: ReadonlyArray<{
            readonly __typename: 'Product';
            readonly ancestorIds: ReadonlyArray<string>;
            readonly name: string;
          }>;
          readonly quantity: {
            readonly __typename: 'QuantityObject';
            readonly energy: number;
            readonly mass: number;
            readonly volume: number;
            readonly volume_gas: number;
          };
        } | null;
        readonly geo: { readonly __typename: 'LatLon'; readonly lat: number; readonly lon: number };
      } | null;
    }>;
    readonly installations: ReadonlyArray<{
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
      readonly hasStorage: boolean;
      readonly hasCargoTracking: boolean;
      readonly hasOpenTenders: boolean;
      readonly geo: {
        readonly __typename: 'LatLon';
        readonly lat: number;
        readonly lon: number;
      } | null;
    }>;
  }>;
};

export type ZoomOnZoneQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type ZoomOnZoneQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly bounds: {
      readonly __typename: 'ZoneBounds';
      readonly northEast: {
        readonly __typename: 'LatLon';
        readonly lat: number;
        readonly lon: number;
      };
      readonly southWest: {
        readonly __typename: 'LatLon';
        readonly lat: number;
        readonly lon: number;
      };
    } | null;
    readonly location: {
      readonly __typename: 'ZoneLocation';
      readonly range: number;
      readonly geo: { readonly __typename: 'LatLon'; readonly lat: number; readonly lon: number };
    } | null;
  }>;
  readonly zoneInstallations: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly geo: {
      readonly __typename: 'LatLon';
      readonly lat: number;
      readonly lon: number;
    } | null;
  }>;
};

export type ZoomOnInstallationQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type ZoomOnInstallationQuery = {
  readonly __typename: 'Query';
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly geo: {
      readonly __typename: 'LatLon';
      readonly lat: number;
      readonly lon: number;
    } | null;
  }>;
};

export type ZoomOnVesselQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type ZoomOnVesselQuery = {
  readonly __typename: 'Query';
  readonly vesselsById: ReadonlyArray<{
    readonly __typename: 'Vessel';
    readonly lastPosition: {
      readonly __typename: 'Position';
      readonly geo: { readonly __typename: 'LatLon'; readonly lat: number; readonly lon: number };
    } | null;
  }>;
};

export type MyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type MyProfileQuery = {
  readonly __typename: 'Query';
  readonly userInfo: {
    readonly __typename: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly companyName: string;
    readonly interestIds: ReadonlyArray<string> | null;
    readonly hasSignedTermsOfUse: boolean | null;
    readonly location: {
      readonly __typename: 'UserLocation';
      readonly city: string;
      readonly country: string;
    } | null;
    readonly profile: {
      readonly __typename: 'UserProfile';
      readonly jobTitle: string;
      readonly jobCategoryId: string | null;
      readonly jobCategoryOther: string | null;
      readonly phoneNumber: string;
      readonly linkedinProfileUrl: string | null;
      readonly iceNumber: string | null;
      readonly isBalticUser: boolean | null;
      readonly hasRequestedBalticAccess: boolean | null;
    } | null;
  };
};

export type UserInfoFragment = {
  readonly __typename: 'User';
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly companyName: string;
  readonly interestIds: ReadonlyArray<string> | null;
  readonly hasSignedTermsOfUse: boolean | null;
  readonly location: {
    readonly __typename: 'UserLocation';
    readonly city: string;
    readonly country: string;
  } | null;
  readonly profile: {
    readonly __typename: 'UserProfile';
    readonly jobTitle: string;
    readonly jobCategoryId: string | null;
    readonly jobCategoryOther: string | null;
    readonly phoneNumber: string;
    readonly linkedinProfileUrl: string | null;
    readonly iceNumber: string | null;
    readonly isBalticUser: boolean | null;
    readonly hasRequestedBalticAccess: boolean | null;
  } | null;
};

export type UserOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type UserOptionsQuery = {
  readonly __typename: 'Query';
  readonly jobCategories: ReadonlyArray<{
    readonly __typename: 'JobCategory';
    readonly id: string;
    readonly name: string;
  }>;
  readonly worldCountries: ReadonlyArray<{
    readonly __typename: 'Country';
    readonly id: string;
    readonly name: string;
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  profile: UserInfoInput;
}>;

export type UpdateUserMutation = {
  readonly __typename: 'Mutation';
  readonly userInfoUpdate: {
    readonly __typename: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly companyName: string;
    readonly interestIds: ReadonlyArray<string> | null;
    readonly hasSignedTermsOfUse: boolean | null;
    readonly location: {
      readonly __typename: 'UserLocation';
      readonly city: string;
      readonly country: string;
    } | null;
    readonly profile: {
      readonly __typename: 'UserProfile';
      readonly jobTitle: string;
      readonly jobCategoryId: string | null;
      readonly jobCategoryOther: string | null;
      readonly phoneNumber: string;
      readonly linkedinProfileUrl: string | null;
      readonly iceNumber: string | null;
      readonly isBalticUser: boolean | null;
      readonly hasRequestedBalticAccess: boolean | null;
    } | null;
  };
};

export type AllBookmarksQueryVariables = Exact<{ [key: string]: never }>;

export type AllBookmarksQuery = {
  readonly __typename: 'Query';
  readonly bookmarksMap: ReadonlyArray<{
    readonly __typename: 'BookmarkMap';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly hasAlert: boolean;
    readonly data: {
      readonly __typename: 'BookmarkMapData';
      readonly products: ReadonlyArray<number>;
      readonly vessels: ReadonlyArray<number>;
      readonly locations: ReadonlyArray<string>;
      readonly loads: ReadonlyArray<string>;
      readonly players: ReadonlyArray<number>;
      readonly discharges: ReadonlyArray<string>;
      readonly fields: ReadonlyArray<string>;
      readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    };
  }>;
  readonly bookmarksFlows: ReadonlyArray<{
    readonly __typename: 'BookmarkFlows';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFlowsData';
      readonly mZones: ReadonlyArray<number>;
      readonly mInstallations: ReadonlyArray<number>;
      readonly sZones: ReadonlyArray<number>;
      readonly sInstallations: ReadonlyArray<number>;
      readonly products: ReadonlyArray<number>;
      readonly dir: string;
      readonly intra: boolean;
      readonly intraRegion: boolean;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly beta: boolean;
      readonly forecast: boolean;
      readonly split: string;
      readonly projection: string;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly vessels: ReadonlyArray<number> | null;
      readonly dates: string | { startDate: string; endDate: string };
      readonly productEstimation: boolean;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  }>;
  readonly bookmarksFleetMetrics: ReadonlyArray<{
    readonly __typename: 'BookmarkFleetMetrics';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFleetMetricsData';
      readonly areas: ReadonlyArray<number>;
      readonly algo: string;
      readonly products: ReadonlyArray<number>;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly floatingDays: ReadonlyArray<string> | null;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly metric: string;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  }>;
  readonly bookmarksInventories: ReadonlyArray<{
    readonly __typename: 'BookmarkInventories';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkInventoriesData';
      readonly zones: ReadonlyArray<number>;
      readonly installations: ReadonlyArray<number>;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly cargoTrackingEnhancement: boolean;
      readonly capacityUtilization: boolean;
      readonly fleetMetricsEnhancement: boolean;
      readonly floatingFrom: number | null;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly droneData: boolean | null;
      readonly droneDataAtmEnhancement: boolean;
      readonly addEiaDatasets: boolean | null;
      readonly deltaLevel: boolean | null;
      readonly market: InventoriesMarket | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      } | null;
    };
  }>;
  readonly bookmarksBallastCapacity: ReadonlyArray<{
    readonly __typename: 'BookmarkBallastCapacity';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkBallastCapacityData';
      readonly zones: ReadonlyArray<number>;
      readonly aggMetric: string;
      readonly granularity: string;
      readonly split: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly view: string;
      readonly unit: string;
      readonly truncateY: boolean;
      readonly products: ReadonlyArray<number>;
      readonly dates: string | { startDate: string; endDate: string };
      readonly etaType: string | null;
      readonly etaWindow: string | null;
      readonly nDaysAhead: number | null;
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly vesselAvailability: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  }>;
  readonly bookmarksCongestion: ReadonlyArray<{
    readonly __typename: 'BookmarkCongestion';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkCongestionData';
      readonly zones: ReadonlyArray<number>;
      readonly originZones: ReadonlyArray<number> | null;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly metric: string;
      readonly products: ReadonlyArray<number>;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly allVesselsInWaitingZone: boolean;
      readonly vesselOperation: ReadonlyArray<string>;
      readonly waitingDuration: ReadonlyArray<string> | null;
      readonly dates: string | { startDate: string; endDate: string };
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  }>;
  readonly bookmarksFleetDevelopment: ReadonlyArray<{
    readonly __typename: 'BookmarkFleetDevelopment';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFleetDevelopmentData';
      readonly metric: string;
      readonly aggMetric: string;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly splitOn: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly complianceMethod: ReadonlyArray<string>;
      readonly coating: ReadonlyArray<string> | null;
      readonly projection: string | null;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  }>;
  readonly bookmarksFleetUtilization: ReadonlyArray<{
    readonly __typename: 'BookmarkFleetUtilization';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFleetUtilizationData';
      readonly zoneIds: ReadonlyArray<number>;
      readonly aggMetric: string;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly lastFamilyProducts: ReadonlyArray<string>;
      readonly vesselState: ReadonlyArray<string>;
      readonly percent: boolean;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly previousZoneIds: ReadonlyArray<number> | null;
      readonly nextZoneIds: ReadonlyArray<number> | null;
      readonly vesselDirection: ReadonlyArray<string> | null;
      readonly showSubStates: boolean | null;
      readonly distinctAggregation: boolean | null;
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  }>;
  readonly bookmarksFreightMetrics: ReadonlyArray<{
    readonly __typename: 'BookmarkFreightMetrics';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFreightMetricsData';
      readonly mZones: ReadonlyArray<number>;
      readonly sZones: ReadonlyArray<number>;
      readonly metric: string;
      readonly products: ReadonlyArray<number>;
      readonly intra: boolean;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly beta: boolean;
      readonly splitOn: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly speedRange: ReadonlyArray<string> | null;
      readonly vesselStates: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  }>;
  readonly bookmarksPrices: ReadonlyArray<{
    readonly __typename: 'BookmarkPrices';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkPricesData';
      readonly movingAverage: boolean | null;
      readonly truncateY: boolean | null;
      readonly granularity: string;
      readonly dateRange: string | { startDate: string; endDate: string };
      readonly view: string;
      readonly seasonal: boolean | null;
      readonly pricesContractFilters: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }> | null;
    };
  }>;
};

export type BallastCapacityBookmarkFragment = {
  readonly __typename: 'BookmarkBallastCapacity';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly data: {
    readonly __typename: 'BookmarkBallastCapacityData';
    readonly zones: ReadonlyArray<number>;
    readonly aggMetric: string;
    readonly granularity: string;
    readonly split: string;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly view: string;
    readonly unit: string;
    readonly truncateY: boolean;
    readonly products: ReadonlyArray<number>;
    readonly dates: string | { startDate: string; endDate: string };
    readonly etaType: string | null;
    readonly etaWindow: string | null;
    readonly nDaysAhead: number | null;
    readonly vessels: ReadonlyArray<number> | null;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly vesselAvailability: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateBallastCapacityBookmarkMutationVariables = Exact<{
  input: BookmarkBallastCapacityCreateInput;
}>;

export type CreateBallastCapacityBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkBallastCapacityCreate: {
    readonly __typename: 'BookmarkBallastCapacity';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkBallastCapacityData';
      readonly zones: ReadonlyArray<number>;
      readonly aggMetric: string;
      readonly granularity: string;
      readonly split: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly view: string;
      readonly unit: string;
      readonly truncateY: boolean;
      readonly products: ReadonlyArray<number>;
      readonly dates: string | { startDate: string; endDate: string };
      readonly etaType: string | null;
      readonly etaWindow: string | null;
      readonly nDaysAhead: number | null;
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly vesselAvailability: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateBallastCapacityBookmarkMutationVariables = Exact<{
  input: BookmarkBallastCapacityUpdateInput;
}>;

export type UpdateBallastCapacityBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkBallastCapacityUpdate: {
    readonly __typename: 'BookmarkBallastCapacity';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkBallastCapacityData';
      readonly zones: ReadonlyArray<number>;
      readonly aggMetric: string;
      readonly granularity: string;
      readonly split: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly view: string;
      readonly unit: string;
      readonly truncateY: boolean;
      readonly products: ReadonlyArray<number>;
      readonly dates: string | { startDate: string; endDate: string };
      readonly etaType: string | null;
      readonly etaWindow: string | null;
      readonly nDaysAhead: number | null;
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly vesselAvailability: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type DeleteBallastCapacityBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBallastCapacityBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkBallastCapacityDelete: boolean;
};

export type CongestionBookmarkFragment = {
  readonly __typename: 'BookmarkCongestion';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly data: {
    readonly __typename: 'BookmarkCongestionData';
    readonly zones: ReadonlyArray<number>;
    readonly originZones: ReadonlyArray<number> | null;
    readonly granularity: string;
    readonly seasonal: boolean;
    readonly split: string;
    readonly metric: string;
    readonly products: ReadonlyArray<number>;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly view: string;
    readonly unit: string;
    readonly movingAverage: boolean;
    readonly truncateY: boolean;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly allVesselsInWaitingZone: boolean;
    readonly vesselOperation: ReadonlyArray<string>;
    readonly waitingDuration: ReadonlyArray<string> | null;
    readonly dates: string | { startDate: string; endDate: string };
    readonly vessels: ReadonlyArray<number> | null;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateCongestionBookmarkMutationVariables = Exact<{
  input: BookmarkCongestionCreateInput;
}>;

export type CreateCongestionBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkCongestionCreate: {
    readonly __typename: 'BookmarkCongestion';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkCongestionData';
      readonly zones: ReadonlyArray<number>;
      readonly originZones: ReadonlyArray<number> | null;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly metric: string;
      readonly products: ReadonlyArray<number>;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly allVesselsInWaitingZone: boolean;
      readonly vesselOperation: ReadonlyArray<string>;
      readonly waitingDuration: ReadonlyArray<string> | null;
      readonly dates: string | { startDate: string; endDate: string };
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateCongestionBookmarkMutationVariables = Exact<{
  input: BookmarkCongestionUpdateInput;
}>;

export type UpdateCongestionBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkCongestionUpdate: {
    readonly __typename: 'BookmarkCongestion';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkCongestionData';
      readonly zones: ReadonlyArray<number>;
      readonly originZones: ReadonlyArray<number> | null;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly metric: string;
      readonly products: ReadonlyArray<number>;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly allVesselsInWaitingZone: boolean;
      readonly vesselOperation: ReadonlyArray<string>;
      readonly waitingDuration: ReadonlyArray<string> | null;
      readonly dates: string | { startDate: string; endDate: string };
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type DeleteCongestionBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCongestionBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkCongestionDelete: boolean;
};

export type FleetDevelopmentBookmarkFragment = {
  readonly __typename: 'BookmarkFleetDevelopment';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly data: {
    readonly __typename: 'BookmarkFleetDevelopmentData';
    readonly metric: string;
    readonly aggMetric: string;
    readonly granularity: string;
    readonly seasonal: boolean;
    readonly splitOn: string;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly complianceMethod: ReadonlyArray<string>;
    readonly coating: ReadonlyArray<string> | null;
    readonly projection: string | null;
    readonly view: string;
    readonly unit: string;
    readonly movingAverage: boolean;
    readonly truncateY: boolean;
    readonly dates: string | { startDate: string; endDate: string };
    readonly vessels: ReadonlyArray<number> | null;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFleetDevelopmentBookmarkMutationVariables = Exact<{
  input: BookmarkFleetDevelopmentCreateInput;
}>;

export type CreateFleetDevelopmentBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFleetDevelopmentCreate: {
    readonly __typename: 'BookmarkFleetDevelopment';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFleetDevelopmentData';
      readonly metric: string;
      readonly aggMetric: string;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly splitOn: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly complianceMethod: ReadonlyArray<string>;
      readonly coating: ReadonlyArray<string> | null;
      readonly projection: string | null;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateFleetDevelopmentBookmarkMutationVariables = Exact<{
  input: BookmarkFleetDevelopmentUpdateInput;
}>;

export type UpdateFleetDevelopmentBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFleetDevelopmentUpdate: {
    readonly __typename: 'BookmarkFleetDevelopment';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFleetDevelopmentData';
      readonly metric: string;
      readonly aggMetric: string;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly splitOn: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly complianceMethod: ReadonlyArray<string>;
      readonly coating: ReadonlyArray<string> | null;
      readonly projection: string | null;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type DeleteFleetDevelopmentBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFleetDevelopmentBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFleetDevelopmentDelete: boolean;
};

export type FleetMetricsBookmarkFragment = {
  readonly __typename: 'BookmarkFleetMetrics';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly data: {
    readonly __typename: 'BookmarkFleetMetricsData';
    readonly areas: ReadonlyArray<number>;
    readonly algo: string;
    readonly products: ReadonlyArray<number>;
    readonly granularity: string;
    readonly seasonal: boolean;
    readonly split: string;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly floatingDays: ReadonlyArray<string> | null;
    readonly view: string;
    readonly unit: string;
    readonly movingAverage: boolean;
    readonly truncateY: boolean;
    readonly dates: string | { startDate: string; endDate: string };
    readonly metric: string;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFleetMetricsBookmarkMutationVariables = Exact<{
  input: BookmarkFleetMetricsCreateInput;
}>;

export type CreateFleetMetricsBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFleetMetricsCreate: {
    readonly __typename: 'BookmarkFleetMetrics';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFleetMetricsData';
      readonly areas: ReadonlyArray<number>;
      readonly algo: string;
      readonly products: ReadonlyArray<number>;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly floatingDays: ReadonlyArray<string> | null;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly metric: string;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateFleetMetricsBookmarkMutationVariables = Exact<{
  input: BookmarkFleetMetricsUpdateInput;
}>;

export type UpdateFleetMetricsBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFleetMetricsUpdate: {
    readonly __typename: 'BookmarkFleetMetrics';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFleetMetricsData';
      readonly areas: ReadonlyArray<number>;
      readonly algo: string;
      readonly products: ReadonlyArray<number>;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly floatingDays: ReadonlyArray<string> | null;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly metric: string;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type DeleteFleetMetricsBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFleetMetricsBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFleetMetricsDelete: boolean;
};

export type FleetUtilizationBookmarkFragment = {
  readonly __typename: 'BookmarkFleetUtilization';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly data: {
    readonly __typename: 'BookmarkFleetUtilizationData';
    readonly zoneIds: ReadonlyArray<number>;
    readonly aggMetric: string;
    readonly granularity: string;
    readonly seasonal: boolean;
    readonly split: string;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly lastFamilyProducts: ReadonlyArray<string>;
    readonly vesselState: ReadonlyArray<string>;
    readonly percent: boolean;
    readonly view: string;
    readonly unit: string;
    readonly movingAverage: boolean;
    readonly truncateY: boolean;
    readonly dates: string | { startDate: string; endDate: string };
    readonly previousZoneIds: ReadonlyArray<number> | null;
    readonly nextZoneIds: ReadonlyArray<number> | null;
    readonly vesselDirection: ReadonlyArray<string> | null;
    readonly showSubStates: boolean | null;
    readonly distinctAggregation: boolean | null;
    readonly vessels: ReadonlyArray<number> | null;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFleetUtilizationBookmarkMutationVariables = Exact<{
  input: BookmarkFleetUtilizationCreateInput;
}>;

export type CreateFleetUtilizationBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFleetUtilizationCreate: {
    readonly __typename: 'BookmarkFleetUtilization';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFleetUtilizationData';
      readonly zoneIds: ReadonlyArray<number>;
      readonly aggMetric: string;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly lastFamilyProducts: ReadonlyArray<string>;
      readonly vesselState: ReadonlyArray<string>;
      readonly percent: boolean;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly previousZoneIds: ReadonlyArray<number> | null;
      readonly nextZoneIds: ReadonlyArray<number> | null;
      readonly vesselDirection: ReadonlyArray<string> | null;
      readonly showSubStates: boolean | null;
      readonly distinctAggregation: boolean | null;
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateFleetUtilizationBookmarkMutationVariables = Exact<{
  input: BookmarkFleetUtilizationUpdateInput;
}>;

export type UpdateFleetUtilizationBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFleetUtilizationUpdate: {
    readonly __typename: 'BookmarkFleetUtilization';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFleetUtilizationData';
      readonly zoneIds: ReadonlyArray<number>;
      readonly aggMetric: string;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly lastFamilyProducts: ReadonlyArray<string>;
      readonly vesselState: ReadonlyArray<string>;
      readonly percent: boolean;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly previousZoneIds: ReadonlyArray<number> | null;
      readonly nextZoneIds: ReadonlyArray<number> | null;
      readonly vesselDirection: ReadonlyArray<string> | null;
      readonly showSubStates: boolean | null;
      readonly distinctAggregation: boolean | null;
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type DeleteFleetUtilizationBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFleetUtilizationBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFleetUtilizationDelete: boolean;
};

export type FlowsBookmarkFragment = {
  readonly __typename: 'BookmarkFlows';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly data: {
    readonly __typename: 'BookmarkFlowsData';
    readonly mZones: ReadonlyArray<number>;
    readonly mInstallations: ReadonlyArray<number>;
    readonly sZones: ReadonlyArray<number>;
    readonly sInstallations: ReadonlyArray<number>;
    readonly products: ReadonlyArray<number>;
    readonly dir: string;
    readonly intra: boolean;
    readonly intraRegion: boolean;
    readonly granularity: string;
    readonly seasonal: boolean;
    readonly beta: boolean;
    readonly forecast: boolean;
    readonly split: string;
    readonly projection: string;
    readonly view: string;
    readonly unit: string;
    readonly movingAverage: boolean;
    readonly truncateY: boolean;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly vessels: ReadonlyArray<number> | null;
    readonly dates: string | { startDate: string; endDate: string };
    readonly productEstimation: boolean;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFlowsBookmarkMutationVariables = Exact<{
  input: BookmarkFlowsCreateInput;
}>;

export type CreateFlowsBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFlowsCreate: {
    readonly __typename: 'BookmarkFlows';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFlowsData';
      readonly mZones: ReadonlyArray<number>;
      readonly mInstallations: ReadonlyArray<number>;
      readonly sZones: ReadonlyArray<number>;
      readonly sInstallations: ReadonlyArray<number>;
      readonly products: ReadonlyArray<number>;
      readonly dir: string;
      readonly intra: boolean;
      readonly intraRegion: boolean;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly beta: boolean;
      readonly forecast: boolean;
      readonly split: string;
      readonly projection: string;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly vessels: ReadonlyArray<number> | null;
      readonly dates: string | { startDate: string; endDate: string };
      readonly productEstimation: boolean;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateFlowsBookmarkMutationVariables = Exact<{
  input: BookmarkFlowsUpdateInput;
}>;

export type UpdateFlowsBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFlowsUpdate: {
    readonly __typename: 'BookmarkFlows';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFlowsData';
      readonly mZones: ReadonlyArray<number>;
      readonly mInstallations: ReadonlyArray<number>;
      readonly sZones: ReadonlyArray<number>;
      readonly sInstallations: ReadonlyArray<number>;
      readonly products: ReadonlyArray<number>;
      readonly dir: string;
      readonly intra: boolean;
      readonly intraRegion: boolean;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly beta: boolean;
      readonly forecast: boolean;
      readonly split: string;
      readonly projection: string;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly vessels: ReadonlyArray<number> | null;
      readonly dates: string | { startDate: string; endDate: string };
      readonly productEstimation: boolean;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type DeleteFlowsBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFlowsBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFlowsDelete: boolean;
};

export type FreightMetricsBookmarkFragment = {
  readonly __typename: 'BookmarkFreightMetrics';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly data: {
    readonly __typename: 'BookmarkFreightMetricsData';
    readonly mZones: ReadonlyArray<number>;
    readonly sZones: ReadonlyArray<number>;
    readonly metric: string;
    readonly products: ReadonlyArray<number>;
    readonly intra: boolean;
    readonly granularity: string;
    readonly seasonal: boolean;
    readonly beta: boolean;
    readonly splitOn: string;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly view: string;
    readonly unit: string;
    readonly movingAverage: boolean;
    readonly truncateY: boolean;
    readonly dates: string | { startDate: string; endDate: string };
    readonly vessels: ReadonlyArray<number> | null;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly speedRange: ReadonlyArray<string> | null;
    readonly vesselStates: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFreightMetricsBookmarkMutationVariables = Exact<{
  input: BookmarkFreightMetricsCreateInput;
}>;

export type CreateFreightMetricsBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFreightMetricsCreate: {
    readonly __typename: 'BookmarkFreightMetrics';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFreightMetricsData';
      readonly mZones: ReadonlyArray<number>;
      readonly sZones: ReadonlyArray<number>;
      readonly metric: string;
      readonly products: ReadonlyArray<number>;
      readonly intra: boolean;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly beta: boolean;
      readonly splitOn: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly speedRange: ReadonlyArray<string> | null;
      readonly vesselStates: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateFreightMetricsBookmarkMutationVariables = Exact<{
  input: BookmarkFreightMetricsUpdateInput;
}>;

export type UpdateFreightMetricsBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFreightMetricsUpdate: {
    readonly __typename: 'BookmarkFreightMetrics';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkFreightMetricsData';
      readonly mZones: ReadonlyArray<number>;
      readonly sZones: ReadonlyArray<number>;
      readonly metric: string;
      readonly products: ReadonlyArray<number>;
      readonly intra: boolean;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly beta: boolean;
      readonly splitOn: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly vessels: ReadonlyArray<number> | null;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly speedRange: ReadonlyArray<string> | null;
      readonly vesselStates: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type DeleteFreightMetricsBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFreightMetricsBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkFreightMetricsDelete: boolean;
};

export type InventoriesBookmarkFragment = {
  readonly __typename: 'BookmarkInventories';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly data: {
    readonly __typename: 'BookmarkInventoriesData';
    readonly zones: ReadonlyArray<number>;
    readonly installations: ReadonlyArray<number>;
    readonly granularity: string;
    readonly seasonal: boolean;
    readonly split: string;
    readonly cargoTrackingEnhancement: boolean;
    readonly capacityUtilization: boolean;
    readonly fleetMetricsEnhancement: boolean;
    readonly floatingFrom: number | null;
    readonly view: string;
    readonly unit: string;
    readonly movingAverage: boolean;
    readonly truncateY: boolean;
    readonly dates: string | { startDate: string; endDate: string };
    readonly droneData: boolean | null;
    readonly droneDataAtmEnhancement: boolean;
    readonly addEiaDatasets: boolean | null;
    readonly deltaLevel: boolean | null;
    readonly market: InventoriesMarket | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    } | null;
  };
};

export type CreateInventoriesBookmarkMutationVariables = Exact<{
  input: BookmarkInventoriesCreateInput;
}>;

export type CreateInventoriesBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkInventoriesCreate: {
    readonly __typename: 'BookmarkInventories';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkInventoriesData';
      readonly zones: ReadonlyArray<number>;
      readonly installations: ReadonlyArray<number>;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly cargoTrackingEnhancement: boolean;
      readonly capacityUtilization: boolean;
      readonly fleetMetricsEnhancement: boolean;
      readonly floatingFrom: number | null;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly droneData: boolean | null;
      readonly droneDataAtmEnhancement: boolean;
      readonly addEiaDatasets: boolean | null;
      readonly deltaLevel: boolean | null;
      readonly market: InventoriesMarket | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      } | null;
    };
  };
};

export type UpdateInventoriesBookmarkMutationVariables = Exact<{
  input: BookmarkInventoriesUpdateInput;
}>;

export type UpdateInventoriesBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkInventoriesUpdate: {
    readonly __typename: 'BookmarkInventories';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkInventoriesData';
      readonly zones: ReadonlyArray<number>;
      readonly installations: ReadonlyArray<number>;
      readonly granularity: string;
      readonly seasonal: boolean;
      readonly split: string;
      readonly cargoTrackingEnhancement: boolean;
      readonly capacityUtilization: boolean;
      readonly fleetMetricsEnhancement: boolean;
      readonly floatingFrom: number | null;
      readonly view: string;
      readonly unit: string;
      readonly movingAverage: boolean;
      readonly truncateY: boolean;
      readonly dates: string | { startDate: string; endDate: string };
      readonly droneData: boolean | null;
      readonly droneDataAtmEnhancement: boolean;
      readonly addEiaDatasets: boolean | null;
      readonly deltaLevel: boolean | null;
      readonly market: InventoriesMarket | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      } | null;
    };
  };
};

export type DeleteInventoriesBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteInventoriesBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkInventoriesDelete: boolean;
};

export type MapBookmarkFragment = {
  readonly __typename: 'BookmarkMap';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly hasAlert: boolean;
  readonly data: {
    readonly __typename: 'BookmarkMapData';
    readonly products: ReadonlyArray<number>;
    readonly vessels: ReadonlyArray<number>;
    readonly locations: ReadonlyArray<string>;
    readonly loads: ReadonlyArray<string>;
    readonly players: ReadonlyArray<number>;
    readonly discharges: ReadonlyArray<string>;
    readonly fields: ReadonlyArray<string>;
    readonly commodityTypes: ReadonlyArray<CommodityType> | null;
  };
};

export type CreateMapBookmarkMutationVariables = Exact<{
  input: BookmarkMapCreateInput;
}>;

export type CreateMapBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkMapCreate: {
    readonly __typename: 'BookmarkMap';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly hasAlert: boolean;
    readonly data: {
      readonly __typename: 'BookmarkMapData';
      readonly products: ReadonlyArray<number>;
      readonly vessels: ReadonlyArray<number>;
      readonly locations: ReadonlyArray<string>;
      readonly loads: ReadonlyArray<string>;
      readonly players: ReadonlyArray<number>;
      readonly discharges: ReadonlyArray<string>;
      readonly fields: ReadonlyArray<string>;
      readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    };
  };
};

export type UpdateMapBookmarkMutationVariables = Exact<{
  input: BookmarkMapUpdateInput;
}>;

export type UpdateMapBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkMapUpdate: {
    readonly __typename: 'BookmarkMap';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly hasAlert: boolean;
    readonly data: {
      readonly __typename: 'BookmarkMapData';
      readonly products: ReadonlyArray<number>;
      readonly vessels: ReadonlyArray<number>;
      readonly locations: ReadonlyArray<string>;
      readonly loads: ReadonlyArray<string>;
      readonly players: ReadonlyArray<number>;
      readonly discharges: ReadonlyArray<string>;
      readonly fields: ReadonlyArray<string>;
      readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    };
  };
};

export type DeleteMapBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteMapBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkMapDelete: boolean;
};

export type SetAlertMutationVariables = Exact<{
  id: Scalars['ID'];
  hasAlert: Scalars['Boolean'];
}>;

export type SetAlertMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkSetAlert: {
    readonly __typename: 'BookmarkMap';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly hasAlert: boolean;
    readonly data: {
      readonly __typename: 'BookmarkMapData';
      readonly products: ReadonlyArray<number>;
      readonly vessels: ReadonlyArray<number>;
      readonly locations: ReadonlyArray<string>;
      readonly loads: ReadonlyArray<string>;
      readonly players: ReadonlyArray<number>;
      readonly discharges: ReadonlyArray<string>;
      readonly fields: ReadonlyArray<string>;
      readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    };
  };
};

export type PricesBookmarkFragment = {
  readonly __typename: 'BookmarkPrices';
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly addedAt: string;
  readonly data: {
    readonly __typename: 'BookmarkPricesData';
    readonly movingAverage: boolean | null;
    readonly truncateY: boolean | null;
    readonly granularity: string;
    readonly dateRange: string | { startDate: string; endDate: string };
    readonly view: string;
    readonly seasonal: boolean | null;
    readonly pricesContractFilters: ReadonlyArray<{
      readonly __typename: 'PricesContractFilterParams';
      readonly name: string;
      readonly periodType: PeriodType | null;
      readonly dates: ReadonlyArray<string> | null;
      readonly continuousPeriods: ReadonlyArray<number> | null;
    }> | null;
  };
};

export type CreatePricesBookmarkMutationVariables = Exact<{
  input: BookmarkPricesCreateInput;
}>;

export type CreatePricesBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkPricesCreate: {
    readonly __typename: 'BookmarkPrices';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkPricesData';
      readonly movingAverage: boolean | null;
      readonly truncateY: boolean | null;
      readonly granularity: string;
      readonly dateRange: string | { startDate: string; endDate: string };
      readonly view: string;
      readonly seasonal: boolean | null;
      readonly pricesContractFilters: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }> | null;
    };
  };
};

export type UpdatePricesBookmarkMutationVariables = Exact<{
  input: BookmarkPricesUpdateInput;
}>;

export type UpdatePricesBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkPricesUpdate: {
    readonly __typename: 'BookmarkPrices';
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly addedAt: string;
    readonly data: {
      readonly __typename: 'BookmarkPricesData';
      readonly movingAverage: boolean | null;
      readonly truncateY: boolean | null;
      readonly granularity: string;
      readonly dateRange: string | { startDate: string; endDate: string };
      readonly view: string;
      readonly seasonal: boolean | null;
      readonly pricesContractFilters: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }> | null;
    };
  };
};

export type DeletePricesBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePricesBookmarkMutation = {
  readonly __typename: 'Mutation';
  readonly bookmarkPricesDelete: boolean;
};

export type DashboardFragmentFragment = {
  readonly __typename: 'Dashboard';
  readonly id: string;
  readonly title: string;
  readonly months: number;
  readonly addedAt: string;
  readonly isMine: boolean;
  readonly accountId: string;
  readonly layout: ReadonlyArray<{
    readonly __typename: 'WidgetPosition';
    readonly id: string;
    readonly x: number;
    readonly y: number;
    readonly w: number;
    readonly h: number;
  }>;
  readonly widgets: ReadonlyArray<
    | {
        readonly __typename: 'WidgetBallastCapacity';
        readonly id: string;
        readonly title: string;
        readonly configBallastCapacity: {
          readonly __typename: 'WidgetBallastCapacityConfig';
          readonly zones: ReadonlyArray<number>;
          readonly aggMetric: BallastCapacityAggMetric;
          readonly split: BallastCapacitySplit;
          readonly deadweight: ReadonlyArray<number> | null;
          readonly capacity: ReadonlyArray<number> | null;
          readonly vesselClassifications: ReadonlyArray<string>;
          readonly unit: string;
          readonly products: ReadonlyArray<number>;
          readonly view: View;
          readonly etaType: string | null;
          readonly etaWindow: string | null;
          readonly nDaysAhead: number | null;
          readonly vessels: ReadonlyArray<number>;
          readonly engineTypes: ReadonlyArray<string> | null;
          readonly vesselAvailability: ReadonlyArray<string> | null;
          readonly pricesFilters: {
            readonly __typename: 'PricesBaseFiltersData';
            readonly contracts: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'WidgetCongestion';
        readonly id: string;
        readonly title: string;
        readonly configCongestion: {
          readonly __typename: 'WidgetCongestionConfig';
          readonly zones: ReadonlyArray<number>;
          readonly originZones: ReadonlyArray<number> | null;
          readonly metric: CongestionMetric;
          readonly products: ReadonlyArray<number>;
          readonly deadweight: ReadonlyArray<number> | null;
          readonly capacity: ReadonlyArray<number> | null;
          readonly vesselClassifications: ReadonlyArray<string>;
          readonly allVesselsInWaitingZone: boolean;
          readonly vesselOperation: ReadonlyArray<CongestionVesselOperation>;
          readonly waitingDuration: ReadonlyArray<string> | null;
          readonly split: CongestionSplit;
          readonly unit: string;
          readonly view: View;
          readonly vessels: ReadonlyArray<number>;
          readonly engineTypes: ReadonlyArray<string> | null;
          readonly pricesFilters: {
            readonly __typename: 'PricesBaseFiltersData';
            readonly contracts: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'WidgetFixtures';
        readonly id: string;
        readonly title: string;
        readonly configFixtures: {
          readonly __typename: 'WidgetFixturesConfig';
          readonly fZones: ReadonlyArray<number>;
          readonly tZones: ReadonlyArray<number>;
          readonly products: ReadonlyArray<number>;
          readonly statuses: ReadonlyArray<FixtureStatus>;
          readonly deadweight: ReadonlyArray<number> | null;
          readonly capacity: ReadonlyArray<number> | null;
          readonly view: View;
          readonly columns: ReadonlyArray<string> | null;
          readonly vesselClassifications: ReadonlyArray<string> | null;
        };
      }
    | {
        readonly __typename: 'WidgetFleetDevelopment';
        readonly id: string;
        readonly title: string;
        readonly configFleetDevelopment: {
          readonly __typename: 'WidgetFleetDevelopmentConfig';
          readonly metric: FleetDevelopmentMetric;
          readonly aggMetric: FleetDevelopmentAggMetric;
          readonly splitOn: FleetDevelopmentSplit;
          readonly deadweight: ReadonlyArray<number> | null;
          readonly capacity: ReadonlyArray<number> | null;
          readonly vesselClassifications: ReadonlyArray<string>;
          readonly complianceMethod: ReadonlyArray<string>;
          readonly coating: ReadonlyArray<FleetDevelopmentCoating> | null;
          readonly projection: Projection;
          readonly unit: string;
          readonly view: View;
          readonly vessels: ReadonlyArray<number>;
          readonly engineTypes: ReadonlyArray<string> | null;
          readonly pricesFilters: {
            readonly __typename: 'PricesBaseFiltersData';
            readonly contracts: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'WidgetFleetMetrics';
        readonly id: string;
        readonly title: string;
        readonly canBeEia: boolean;
        readonly configFleetMetrics: {
          readonly __typename: 'WidgetFleetMetricsConfig';
          readonly areas: ReadonlyArray<number>;
          readonly algo: FleetMetricsAlgo;
          readonly products: ReadonlyArray<number>;
          readonly split: FleetMetricsSplit;
          readonly deadweight: ReadonlyArray<number> | null;
          readonly capacity: ReadonlyArray<number> | null;
          readonly vesselClassifications: ReadonlyArray<string>;
          readonly floatingDays: ReadonlyArray<string> | null;
          readonly unit: string;
          readonly isEia: boolean;
          readonly view: View;
          readonly dates: string | null;
          readonly granularity: Granularity | null;
          readonly truncateY: boolean | null;
          readonly movingAverage: boolean | null;
          readonly seasonal: boolean | null;
          readonly metric: FleetMetricsMetric;
          readonly pricesFilters: {
            readonly __typename: 'PricesBaseFiltersData';
            readonly contracts: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'WidgetFleetUtilization';
        readonly id: string;
        readonly title: string;
        readonly configFleetUtilization: {
          readonly __typename: 'WidgetFleetUtilizationConfig';
          readonly zoneIds: ReadonlyArray<number>;
          readonly aggMetric: FleetUtilizationAggMetric;
          readonly split: FleetUtilizationSplit;
          readonly deadweight: ReadonlyArray<number> | null;
          readonly capacity: ReadonlyArray<number> | null;
          readonly vesselClassifications: ReadonlyArray<string>;
          readonly lastFamilyProducts: ReadonlyArray<string>;
          readonly vesselState: ReadonlyArray<FleetUtilizationVesselState>;
          readonly percent: boolean;
          readonly unit: string;
          readonly view: View;
          readonly previousZoneIds: ReadonlyArray<number> | null;
          readonly nextZoneIds: ReadonlyArray<number> | null;
          readonly vesselDirection: ReadonlyArray<FleetUtilizationVesselDirection> | null;
          readonly showSubStates: boolean | null;
          readonly distinctAggregation: boolean | null;
          readonly vessels: ReadonlyArray<number>;
          readonly engineTypes: ReadonlyArray<string> | null;
          readonly pricesFilters: {
            readonly __typename: 'PricesBaseFiltersData';
            readonly contracts: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'WidgetFlows';
        readonly id: string;
        readonly title: string;
        readonly canBeEia: boolean;
        readonly configFlows: {
          readonly __typename: 'WidgetFlowsConfig';
          readonly mZones: ReadonlyArray<number>;
          readonly mInstallations: ReadonlyArray<number>;
          readonly sZones: ReadonlyArray<number>;
          readonly sInstallations: ReadonlyArray<number>;
          readonly products: ReadonlyArray<number>;
          readonly dir: Direction;
          readonly intra: boolean;
          readonly intraRegion: boolean;
          readonly beta: boolean;
          readonly forecast: boolean;
          readonly split: FlowsSplit;
          readonly projection: Projection;
          readonly unit: string;
          readonly deadweight: ReadonlyArray<number> | null;
          readonly capacity: ReadonlyArray<number> | null;
          readonly vesselClassifications: ReadonlyArray<string>;
          readonly vessels: ReadonlyArray<number>;
          readonly isEia: boolean;
          readonly view: View;
          readonly productEstimation: boolean;
          readonly dates: string | null;
          readonly granularity: Granularity | null;
          readonly truncateY: boolean | null;
          readonly movingAverage: boolean | null;
          readonly seasonal: boolean | null;
          readonly pricesFilters: {
            readonly __typename: 'PricesBaseFiltersData';
            readonly contracts: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'WidgetFreightMetrics';
        readonly id: string;
        readonly title: string;
        readonly configFreightMetrics: {
          readonly __typename: 'WidgetFreightMetricsConfig';
          readonly mZones: ReadonlyArray<number>;
          readonly sZones: ReadonlyArray<number>;
          readonly metric: FreightMetricsMetric;
          readonly products: ReadonlyArray<number>;
          readonly intra: boolean;
          readonly beta: boolean;
          readonly splitOn: FreightMetricsSplit;
          readonly deadweight: ReadonlyArray<number> | null;
          readonly capacity: ReadonlyArray<number> | null;
          readonly vesselClassifications: ReadonlyArray<string>;
          readonly unit: string;
          readonly view: View;
          readonly vessels: ReadonlyArray<number>;
          readonly engineTypes: ReadonlyArray<string> | null;
          readonly speedRange: ReadonlyArray<string> | null;
          readonly vesselStates: ReadonlyArray<string> | null;
          readonly pricesFilters: {
            readonly __typename: 'PricesBaseFiltersData';
            readonly contracts: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'WidgetInventories';
        readonly id: string;
        readonly title: string;
        readonly canBeEia: boolean;
        readonly configInventories: {
          readonly __typename: 'WidgetInventoriesConfig';
          readonly zones: ReadonlyArray<number>;
          readonly installations: ReadonlyArray<number>;
          readonly cargoTrackingEnhancement: boolean;
          readonly fleetMetricsEnhancement: boolean;
          readonly capacityUtilization: boolean;
          readonly floatingFrom: number | null;
          readonly split: InventoriesSplit;
          readonly unit: string;
          readonly isEia: boolean;
          readonly droneData: boolean;
          readonly droneDataAtmEnhancement: boolean;
          readonly view: View;
          readonly addEiaDatasets: boolean;
          readonly deltaLevel: boolean;
          readonly columns: ReadonlyArray<string> | null;
          readonly market: InventoriesMarket | null;
          readonly pricesFilters: {
            readonly __typename: 'PricesBaseFiltersData';
            readonly contracts: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'WidgetMapSearch';
        readonly id: string;
        readonly title: string;
        readonly configMapSearch: {
          readonly __typename: 'WidgetMapSearchConfig';
          readonly commodityTypes: ReadonlyArray<CommodityType> | null;
          readonly search: {
            readonly __typename: 'ConfigSearch';
            readonly locations: ReadonlyArray<string>;
            readonly loads: ReadonlyArray<string>;
            readonly discharges: ReadonlyArray<string>;
            readonly vessels: ReadonlyArray<number>;
            readonly products: ReadonlyArray<number>;
            readonly players: ReadonlyArray<number>;
          };
          readonly vesselFilters: {
            readonly __typename: 'MapVesselFilters';
            readonly cargoState: ReadonlyArray<CargoState>;
            readonly status: ReadonlyArray<VesselStatus>;
            readonly classification: ReadonlyArray<string>;
            readonly classificationOil: ReadonlyArray<string>;
            readonly classificationCpp: ReadonlyArray<string>;
            readonly speed: ReadonlyArray<Speed>;
            readonly carrierType: ReadonlyArray<string>;
            readonly engine: ReadonlyArray<string>;
            readonly ethyleneCapable: ReadonlyArray<YesNo>;
            readonly asphaltBitumenCapable: ReadonlyArray<YesNo>;
            readonly betaStatus: ReadonlyArray<BetaStatus>;
            readonly capacity: ReadonlyArray<number> | null;
            readonly buildYear: ReadonlyArray<number> | null;
            readonly draught: ReadonlyArray<number> | null;
            readonly cargoType: ReadonlyArray<string>;
            readonly vesselLayer: LayerState;
          };
          readonly installationFilters: {
            readonly __typename: 'MapInstallationFilters';
            readonly type: ReadonlyArray<InstallationTypeFilter>;
            readonly status: ReadonlyArray<string>;
            readonly betaStatus: ReadonlyArray<BetaStatus>;
            readonly installationLayer: LayerState;
          };
          readonly zoneFilters: {
            readonly __typename: 'MapZoneFilters';
            readonly type: ReadonlyArray<ZoneTypeFilter> | null;
            readonly zoneLayer: LayerState;
          };
          readonly options: {
            readonly __typename: 'ConfigOptions';
            readonly cyclones: boolean;
            readonly portsAndBerths: boolean;
            readonly labels: boolean;
            readonly background: MapBackground;
            readonly unit: MapUnitName;
          };
        };
      }
    | {
        readonly __typename: 'WidgetPrices';
        readonly id: string;
        readonly title: string;
        readonly configPrices: {
          readonly __typename: 'WidgetPricesConfig';
          readonly movingAverage: boolean | null;
          readonly truncateY: boolean | null;
          readonly view: View;
          readonly isForwardView: boolean | null;
          readonly pricesContractFilters: ReadonlyArray<{
            readonly __typename: 'PricesContractFilterParams';
            readonly name: string;
            readonly periodType: PeriodType | null;
            readonly dates: ReadonlyArray<string> | null;
            readonly continuousPeriods: ReadonlyArray<number> | null;
          }> | null;
        };
      }
    | {
        readonly __typename: 'WidgetRefineriesCapacities';
        readonly id: string;
        readonly title: string;
        readonly configRefineriesCapacities: {
          readonly __typename: 'WidgetRefineriesCapacitiesConfig';
          readonly metric: RefineriesCapacitiesMetric;
          readonly unitTypeSubFamilies: ReadonlyArray<string>;
          readonly splitBy: RefineriesCapacitiesSplit | null;
          readonly installationIds: ReadonlyArray<number> | null;
          readonly zoneIds: ReadonlyArray<number>;
          readonly projection: string;
          readonly groupByFamily: boolean;
          readonly unit: string;
          readonly view: View;
        };
      }
  >;
};

export type DashboardQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DashboardQuery = {
  readonly __typename: 'Query';
  readonly dashboard: {
    readonly __typename: 'Dashboard';
    readonly id: string;
    readonly title: string;
    readonly months: number;
    readonly addedAt: string;
    readonly isMine: boolean;
    readonly accountId: string;
    readonly layout: ReadonlyArray<{
      readonly __typename: 'WidgetPosition';
      readonly id: string;
      readonly x: number;
      readonly y: number;
      readonly w: number;
      readonly h: number;
    }>;
    readonly widgets: ReadonlyArray<
      | {
          readonly __typename: 'WidgetBallastCapacity';
          readonly id: string;
          readonly title: string;
          readonly configBallastCapacity: {
            readonly __typename: 'WidgetBallastCapacityConfig';
            readonly zones: ReadonlyArray<number>;
            readonly aggMetric: BallastCapacityAggMetric;
            readonly split: BallastCapacitySplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly unit: string;
            readonly products: ReadonlyArray<number>;
            readonly view: View;
            readonly etaType: string | null;
            readonly etaWindow: string | null;
            readonly nDaysAhead: number | null;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly vesselAvailability: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetCongestion';
          readonly id: string;
          readonly title: string;
          readonly configCongestion: {
            readonly __typename: 'WidgetCongestionConfig';
            readonly zones: ReadonlyArray<number>;
            readonly originZones: ReadonlyArray<number> | null;
            readonly metric: CongestionMetric;
            readonly products: ReadonlyArray<number>;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly allVesselsInWaitingZone: boolean;
            readonly vesselOperation: ReadonlyArray<CongestionVesselOperation>;
            readonly waitingDuration: ReadonlyArray<string> | null;
            readonly split: CongestionSplit;
            readonly unit: string;
            readonly view: View;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFixtures';
          readonly id: string;
          readonly title: string;
          readonly configFixtures: {
            readonly __typename: 'WidgetFixturesConfig';
            readonly fZones: ReadonlyArray<number>;
            readonly tZones: ReadonlyArray<number>;
            readonly products: ReadonlyArray<number>;
            readonly statuses: ReadonlyArray<FixtureStatus>;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly view: View;
            readonly columns: ReadonlyArray<string> | null;
            readonly vesselClassifications: ReadonlyArray<string> | null;
          };
        }
      | {
          readonly __typename: 'WidgetFleetDevelopment';
          readonly id: string;
          readonly title: string;
          readonly configFleetDevelopment: {
            readonly __typename: 'WidgetFleetDevelopmentConfig';
            readonly metric: FleetDevelopmentMetric;
            readonly aggMetric: FleetDevelopmentAggMetric;
            readonly splitOn: FleetDevelopmentSplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly complianceMethod: ReadonlyArray<string>;
            readonly coating: ReadonlyArray<FleetDevelopmentCoating> | null;
            readonly projection: Projection;
            readonly unit: string;
            readonly view: View;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFleetMetrics';
          readonly id: string;
          readonly title: string;
          readonly canBeEia: boolean;
          readonly configFleetMetrics: {
            readonly __typename: 'WidgetFleetMetricsConfig';
            readonly areas: ReadonlyArray<number>;
            readonly algo: FleetMetricsAlgo;
            readonly products: ReadonlyArray<number>;
            readonly split: FleetMetricsSplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly floatingDays: ReadonlyArray<string> | null;
            readonly unit: string;
            readonly isEia: boolean;
            readonly view: View;
            readonly dates: string | null;
            readonly granularity: Granularity | null;
            readonly truncateY: boolean | null;
            readonly movingAverage: boolean | null;
            readonly seasonal: boolean | null;
            readonly metric: FleetMetricsMetric;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFleetUtilization';
          readonly id: string;
          readonly title: string;
          readonly configFleetUtilization: {
            readonly __typename: 'WidgetFleetUtilizationConfig';
            readonly zoneIds: ReadonlyArray<number>;
            readonly aggMetric: FleetUtilizationAggMetric;
            readonly split: FleetUtilizationSplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly lastFamilyProducts: ReadonlyArray<string>;
            readonly vesselState: ReadonlyArray<FleetUtilizationVesselState>;
            readonly percent: boolean;
            readonly unit: string;
            readonly view: View;
            readonly previousZoneIds: ReadonlyArray<number> | null;
            readonly nextZoneIds: ReadonlyArray<number> | null;
            readonly vesselDirection: ReadonlyArray<FleetUtilizationVesselDirection> | null;
            readonly showSubStates: boolean | null;
            readonly distinctAggregation: boolean | null;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFlows';
          readonly id: string;
          readonly title: string;
          readonly canBeEia: boolean;
          readonly configFlows: {
            readonly __typename: 'WidgetFlowsConfig';
            readonly mZones: ReadonlyArray<number>;
            readonly mInstallations: ReadonlyArray<number>;
            readonly sZones: ReadonlyArray<number>;
            readonly sInstallations: ReadonlyArray<number>;
            readonly products: ReadonlyArray<number>;
            readonly dir: Direction;
            readonly intra: boolean;
            readonly intraRegion: boolean;
            readonly beta: boolean;
            readonly forecast: boolean;
            readonly split: FlowsSplit;
            readonly projection: Projection;
            readonly unit: string;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly vessels: ReadonlyArray<number>;
            readonly isEia: boolean;
            readonly view: View;
            readonly productEstimation: boolean;
            readonly dates: string | null;
            readonly granularity: Granularity | null;
            readonly truncateY: boolean | null;
            readonly movingAverage: boolean | null;
            readonly seasonal: boolean | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFreightMetrics';
          readonly id: string;
          readonly title: string;
          readonly configFreightMetrics: {
            readonly __typename: 'WidgetFreightMetricsConfig';
            readonly mZones: ReadonlyArray<number>;
            readonly sZones: ReadonlyArray<number>;
            readonly metric: FreightMetricsMetric;
            readonly products: ReadonlyArray<number>;
            readonly intra: boolean;
            readonly beta: boolean;
            readonly splitOn: FreightMetricsSplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly unit: string;
            readonly view: View;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly speedRange: ReadonlyArray<string> | null;
            readonly vesselStates: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetInventories';
          readonly id: string;
          readonly title: string;
          readonly canBeEia: boolean;
          readonly configInventories: {
            readonly __typename: 'WidgetInventoriesConfig';
            readonly zones: ReadonlyArray<number>;
            readonly installations: ReadonlyArray<number>;
            readonly cargoTrackingEnhancement: boolean;
            readonly fleetMetricsEnhancement: boolean;
            readonly capacityUtilization: boolean;
            readonly floatingFrom: number | null;
            readonly split: InventoriesSplit;
            readonly unit: string;
            readonly isEia: boolean;
            readonly droneData: boolean;
            readonly droneDataAtmEnhancement: boolean;
            readonly view: View;
            readonly addEiaDatasets: boolean;
            readonly deltaLevel: boolean;
            readonly columns: ReadonlyArray<string> | null;
            readonly market: InventoriesMarket | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetMapSearch';
          readonly id: string;
          readonly title: string;
          readonly configMapSearch: {
            readonly __typename: 'WidgetMapSearchConfig';
            readonly commodityTypes: ReadonlyArray<CommodityType> | null;
            readonly search: {
              readonly __typename: 'ConfigSearch';
              readonly locations: ReadonlyArray<string>;
              readonly loads: ReadonlyArray<string>;
              readonly discharges: ReadonlyArray<string>;
              readonly vessels: ReadonlyArray<number>;
              readonly products: ReadonlyArray<number>;
              readonly players: ReadonlyArray<number>;
            };
            readonly vesselFilters: {
              readonly __typename: 'MapVesselFilters';
              readonly cargoState: ReadonlyArray<CargoState>;
              readonly status: ReadonlyArray<VesselStatus>;
              readonly classification: ReadonlyArray<string>;
              readonly classificationOil: ReadonlyArray<string>;
              readonly classificationCpp: ReadonlyArray<string>;
              readonly speed: ReadonlyArray<Speed>;
              readonly carrierType: ReadonlyArray<string>;
              readonly engine: ReadonlyArray<string>;
              readonly ethyleneCapable: ReadonlyArray<YesNo>;
              readonly asphaltBitumenCapable: ReadonlyArray<YesNo>;
              readonly betaStatus: ReadonlyArray<BetaStatus>;
              readonly capacity: ReadonlyArray<number> | null;
              readonly buildYear: ReadonlyArray<number> | null;
              readonly draught: ReadonlyArray<number> | null;
              readonly cargoType: ReadonlyArray<string>;
              readonly vesselLayer: LayerState;
            };
            readonly installationFilters: {
              readonly __typename: 'MapInstallationFilters';
              readonly type: ReadonlyArray<InstallationTypeFilter>;
              readonly status: ReadonlyArray<string>;
              readonly betaStatus: ReadonlyArray<BetaStatus>;
              readonly installationLayer: LayerState;
            };
            readonly zoneFilters: {
              readonly __typename: 'MapZoneFilters';
              readonly type: ReadonlyArray<ZoneTypeFilter> | null;
              readonly zoneLayer: LayerState;
            };
            readonly options: {
              readonly __typename: 'ConfigOptions';
              readonly cyclones: boolean;
              readonly portsAndBerths: boolean;
              readonly labels: boolean;
              readonly background: MapBackground;
              readonly unit: MapUnitName;
            };
          };
        }
      | {
          readonly __typename: 'WidgetPrices';
          readonly id: string;
          readonly title: string;
          readonly configPrices: {
            readonly __typename: 'WidgetPricesConfig';
            readonly movingAverage: boolean | null;
            readonly truncateY: boolean | null;
            readonly view: View;
            readonly isForwardView: boolean | null;
            readonly pricesContractFilters: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }> | null;
          };
        }
      | {
          readonly __typename: 'WidgetRefineriesCapacities';
          readonly id: string;
          readonly title: string;
          readonly configRefineriesCapacities: {
            readonly __typename: 'WidgetRefineriesCapacitiesConfig';
            readonly metric: RefineriesCapacitiesMetric;
            readonly unitTypeSubFamilies: ReadonlyArray<string>;
            readonly splitBy: RefineriesCapacitiesSplit | null;
            readonly installationIds: ReadonlyArray<number> | null;
            readonly zoneIds: ReadonlyArray<number>;
            readonly projection: string;
            readonly groupByFamily: boolean;
            readonly unit: string;
            readonly view: View;
          };
        }
    >;
  } | null;
};

export type CreateDashboardMutationVariables = Exact<{
  input: DashboardCreateInput;
}>;

export type CreateDashboardMutation = {
  readonly __typename: 'Mutation';
  readonly dashboardCreate: {
    readonly __typename: 'Dashboard';
    readonly id: string;
    readonly title: string;
    readonly widgets: ReadonlyArray<
      | { readonly __typename: 'WidgetBallastCapacity'; readonly id: string }
      | { readonly __typename: 'WidgetCongestion'; readonly id: string }
      | { readonly __typename: 'WidgetFixtures'; readonly id: string }
      | { readonly __typename: 'WidgetFleetDevelopment'; readonly id: string }
      | { readonly __typename: 'WidgetFleetMetrics'; readonly id: string }
      | { readonly __typename: 'WidgetFleetUtilization'; readonly id: string }
      | { readonly __typename: 'WidgetFlows'; readonly id: string }
      | { readonly __typename: 'WidgetFreightMetrics'; readonly id: string }
      | { readonly __typename: 'WidgetInventories'; readonly id: string }
      | { readonly __typename: 'WidgetMapSearch'; readonly id: string }
      | { readonly __typename: 'WidgetPrices'; readonly id: string }
      | { readonly __typename: 'WidgetRefineriesCapacities'; readonly id: string }
    >;
  };
};

export type DeleteDashboardMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteDashboardMutation = {
  readonly __typename: 'Mutation';
  readonly dashboardDelete: boolean;
};

export type UpdateDashboardMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DashboardUpdateInput;
}>;

export type UpdateDashboardMutation = {
  readonly __typename: 'Mutation';
  readonly dashboardUpdate: {
    readonly __typename: 'Dashboard';
    readonly id: string;
    readonly title: string;
    readonly layout: ReadonlyArray<{
      readonly __typename: 'WidgetPosition';
      readonly id: string;
      readonly x: number;
      readonly y: number;
      readonly w: number;
      readonly h: number;
    }>;
  };
};

export type UpdateDashboardMonthsMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DashboardUpdateInput;
}>;

export type UpdateDashboardMonthsMutation = {
  readonly __typename: 'Mutation';
  readonly dashboardUpdate: {
    readonly __typename: 'Dashboard';
    readonly id: string;
    readonly months: number;
  };
};

export type UpdateDashboardTitleMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['NonEmptyString'];
}>;

export type UpdateDashboardTitleMutation = {
  readonly __typename: 'Mutation';
  readonly dashboardUpdate: {
    readonly __typename: 'Dashboard';
    readonly id: string;
    readonly title: string;
  };
};

export type MyDashboardsQueryVariables = Exact<{ [key: string]: never }>;

export type MyDashboardsQuery = {
  readonly __typename: 'Query';
  readonly dashboards: ReadonlyArray<{
    readonly __typename: 'Dashboard';
    readonly id: string;
    readonly title: string;
  }>;
};

export type AllInterestsQueryVariables = Exact<{ [key: string]: never }>;

export type AllInterestsQuery = {
  readonly __typename: 'Query';
  readonly interests: ReadonlyArray<{
    readonly __typename: 'Interest';
    readonly id: string;
    readonly name: string;
  }>;
};

export type BallastCapacityWidgetFragment = {
  readonly __typename: 'WidgetBallastCapacity';
  readonly id: string;
  readonly title: string;
  readonly configBallastCapacity: {
    readonly __typename: 'WidgetBallastCapacityConfig';
    readonly zones: ReadonlyArray<number>;
    readonly aggMetric: BallastCapacityAggMetric;
    readonly split: BallastCapacitySplit;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly unit: string;
    readonly products: ReadonlyArray<number>;
    readonly view: View;
    readonly etaType: string | null;
    readonly etaWindow: string | null;
    readonly nDaysAhead: number | null;
    readonly vessels: ReadonlyArray<number>;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly vesselAvailability: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateBallastCapacityWidgetMutationVariables = Exact<{
  input: WidgetBallastCapacityCreateInput;
}>;

export type CreateBallastCapacityWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetBallastCapacityCreate: {
    readonly __typename: 'WidgetBallastCapacity';
    readonly id: string;
    readonly title: string;
    readonly configBallastCapacity: {
      readonly __typename: 'WidgetBallastCapacityConfig';
      readonly zones: ReadonlyArray<number>;
      readonly aggMetric: BallastCapacityAggMetric;
      readonly split: BallastCapacitySplit;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly unit: string;
      readonly products: ReadonlyArray<number>;
      readonly view: View;
      readonly etaType: string | null;
      readonly etaWindow: string | null;
      readonly nDaysAhead: number | null;
      readonly vessels: ReadonlyArray<number>;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly vesselAvailability: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type CongestionWidgetFragment = {
  readonly __typename: 'WidgetCongestion';
  readonly id: string;
  readonly title: string;
  readonly configCongestion: {
    readonly __typename: 'WidgetCongestionConfig';
    readonly zones: ReadonlyArray<number>;
    readonly originZones: ReadonlyArray<number> | null;
    readonly metric: CongestionMetric;
    readonly products: ReadonlyArray<number>;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly allVesselsInWaitingZone: boolean;
    readonly vesselOperation: ReadonlyArray<CongestionVesselOperation>;
    readonly waitingDuration: ReadonlyArray<string> | null;
    readonly split: CongestionSplit;
    readonly unit: string;
    readonly view: View;
    readonly vessels: ReadonlyArray<number>;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateCongestionWidgetMutationVariables = Exact<{
  input: WidgetCongestionCreateInput;
}>;

export type CreateCongestionWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetCongestionCreate: {
    readonly __typename: 'WidgetCongestion';
    readonly id: string;
    readonly title: string;
    readonly configCongestion: {
      readonly __typename: 'WidgetCongestionConfig';
      readonly zones: ReadonlyArray<number>;
      readonly originZones: ReadonlyArray<number> | null;
      readonly metric: CongestionMetric;
      readonly products: ReadonlyArray<number>;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly allVesselsInWaitingZone: boolean;
      readonly vesselOperation: ReadonlyArray<CongestionVesselOperation>;
      readonly waitingDuration: ReadonlyArray<string> | null;
      readonly split: CongestionSplit;
      readonly unit: string;
      readonly view: View;
      readonly vessels: ReadonlyArray<number>;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type FixturesWidgetFragment = {
  readonly __typename: 'WidgetFixtures';
  readonly id: string;
  readonly title: string;
  readonly configFixtures: {
    readonly __typename: 'WidgetFixturesConfig';
    readonly fZones: ReadonlyArray<number>;
    readonly tZones: ReadonlyArray<number>;
    readonly products: ReadonlyArray<number>;
    readonly statuses: ReadonlyArray<FixtureStatus>;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly view: View;
    readonly columns: ReadonlyArray<string> | null;
    readonly vesselClassifications: ReadonlyArray<string> | null;
  };
};

export type CreateFixturesWidgetMutationVariables = Exact<{
  input: WidgetFixturesCreateInput;
}>;

export type CreateFixturesWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFixturesCreate: {
    readonly __typename: 'WidgetFixtures';
    readonly id: string;
    readonly title: string;
    readonly configFixtures: {
      readonly __typename: 'WidgetFixturesConfig';
      readonly fZones: ReadonlyArray<number>;
      readonly tZones: ReadonlyArray<number>;
      readonly products: ReadonlyArray<number>;
      readonly statuses: ReadonlyArray<FixtureStatus>;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly view: View;
      readonly columns: ReadonlyArray<string> | null;
      readonly vesselClassifications: ReadonlyArray<string> | null;
    };
  };
};

export type UpdateFixturesWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WidgetFixturesUpdateInput;
}>;

export type UpdateFixturesWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFixturesUpdate: {
    readonly __typename: 'WidgetFixtures';
    readonly id: string;
    readonly title: string;
    readonly configFixtures: {
      readonly __typename: 'WidgetFixturesConfig';
      readonly fZones: ReadonlyArray<number>;
      readonly tZones: ReadonlyArray<number>;
      readonly products: ReadonlyArray<number>;
      readonly statuses: ReadonlyArray<FixtureStatus>;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly view: View;
      readonly columns: ReadonlyArray<string> | null;
      readonly vesselClassifications: ReadonlyArray<string> | null;
    };
  };
};

export type FleetDevelopmentWidgetFragment = {
  readonly __typename: 'WidgetFleetDevelopment';
  readonly id: string;
  readonly title: string;
  readonly configFleetDevelopment: {
    readonly __typename: 'WidgetFleetDevelopmentConfig';
    readonly metric: FleetDevelopmentMetric;
    readonly aggMetric: FleetDevelopmentAggMetric;
    readonly splitOn: FleetDevelopmentSplit;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly complianceMethod: ReadonlyArray<string>;
    readonly coating: ReadonlyArray<FleetDevelopmentCoating> | null;
    readonly projection: Projection;
    readonly unit: string;
    readonly view: View;
    readonly vessels: ReadonlyArray<number>;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFleetDevelopmentWidgetMutationVariables = Exact<{
  input: WidgetFleetDevelopmentCreateInput;
}>;

export type CreateFleetDevelopmentWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFleetDevelopmentCreate: {
    readonly __typename: 'WidgetFleetDevelopment';
    readonly id: string;
    readonly title: string;
    readonly configFleetDevelopment: {
      readonly __typename: 'WidgetFleetDevelopmentConfig';
      readonly metric: FleetDevelopmentMetric;
      readonly aggMetric: FleetDevelopmentAggMetric;
      readonly splitOn: FleetDevelopmentSplit;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly complianceMethod: ReadonlyArray<string>;
      readonly coating: ReadonlyArray<FleetDevelopmentCoating> | null;
      readonly projection: Projection;
      readonly unit: string;
      readonly view: View;
      readonly vessels: ReadonlyArray<number>;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type FleetMetricsWidgetFragment = {
  readonly __typename: 'WidgetFleetMetrics';
  readonly id: string;
  readonly title: string;
  readonly canBeEia: boolean;
  readonly configFleetMetrics: {
    readonly __typename: 'WidgetFleetMetricsConfig';
    readonly areas: ReadonlyArray<number>;
    readonly algo: FleetMetricsAlgo;
    readonly products: ReadonlyArray<number>;
    readonly split: FleetMetricsSplit;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly floatingDays: ReadonlyArray<string> | null;
    readonly unit: string;
    readonly isEia: boolean;
    readonly view: View;
    readonly dates: string | null;
    readonly granularity: Granularity | null;
    readonly truncateY: boolean | null;
    readonly movingAverage: boolean | null;
    readonly seasonal: boolean | null;
    readonly metric: FleetMetricsMetric;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFleetMetricsWidgetMutationVariables = Exact<{
  input: WidgetFleetMetricsCreateInput;
}>;

export type CreateFleetMetricsWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFleetMetricsCreate: {
    readonly __typename: 'WidgetFleetMetrics';
    readonly id: string;
    readonly title: string;
    readonly canBeEia: boolean;
    readonly configFleetMetrics: {
      readonly __typename: 'WidgetFleetMetricsConfig';
      readonly areas: ReadonlyArray<number>;
      readonly algo: FleetMetricsAlgo;
      readonly products: ReadonlyArray<number>;
      readonly split: FleetMetricsSplit;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly floatingDays: ReadonlyArray<string> | null;
      readonly unit: string;
      readonly isEia: boolean;
      readonly view: View;
      readonly dates: string | null;
      readonly granularity: Granularity | null;
      readonly truncateY: boolean | null;
      readonly movingAverage: boolean | null;
      readonly seasonal: boolean | null;
      readonly metric: FleetMetricsMetric;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateFleetMetricsWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WidgetFleetMetricsUpdateInput;
}>;

export type UpdateFleetMetricsWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFleetMetricsUpdate: {
    readonly __typename: 'WidgetFleetMetrics';
    readonly id: string;
    readonly title: string;
    readonly canBeEia: boolean;
    readonly configFleetMetrics: {
      readonly __typename: 'WidgetFleetMetricsConfig';
      readonly areas: ReadonlyArray<number>;
      readonly algo: FleetMetricsAlgo;
      readonly products: ReadonlyArray<number>;
      readonly split: FleetMetricsSplit;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly floatingDays: ReadonlyArray<string> | null;
      readonly unit: string;
      readonly isEia: boolean;
      readonly view: View;
      readonly dates: string | null;
      readonly granularity: Granularity | null;
      readonly truncateY: boolean | null;
      readonly movingAverage: boolean | null;
      readonly seasonal: boolean | null;
      readonly metric: FleetMetricsMetric;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type FleetUtilizationWidgetFragment = {
  readonly __typename: 'WidgetFleetUtilization';
  readonly id: string;
  readonly title: string;
  readonly configFleetUtilization: {
    readonly __typename: 'WidgetFleetUtilizationConfig';
    readonly zoneIds: ReadonlyArray<number>;
    readonly aggMetric: FleetUtilizationAggMetric;
    readonly split: FleetUtilizationSplit;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly lastFamilyProducts: ReadonlyArray<string>;
    readonly vesselState: ReadonlyArray<FleetUtilizationVesselState>;
    readonly percent: boolean;
    readonly unit: string;
    readonly view: View;
    readonly previousZoneIds: ReadonlyArray<number> | null;
    readonly nextZoneIds: ReadonlyArray<number> | null;
    readonly vesselDirection: ReadonlyArray<FleetUtilizationVesselDirection> | null;
    readonly showSubStates: boolean | null;
    readonly distinctAggregation: boolean | null;
    readonly vessels: ReadonlyArray<number>;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFleetUtilizationWidgetMutationVariables = Exact<{
  input: WidgetFleetUtilizationCreateInput;
}>;

export type CreateFleetUtilizationWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFleetUtilizationCreate: {
    readonly __typename: 'WidgetFleetUtilization';
    readonly id: string;
    readonly title: string;
    readonly configFleetUtilization: {
      readonly __typename: 'WidgetFleetUtilizationConfig';
      readonly zoneIds: ReadonlyArray<number>;
      readonly aggMetric: FleetUtilizationAggMetric;
      readonly split: FleetUtilizationSplit;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly lastFamilyProducts: ReadonlyArray<string>;
      readonly vesselState: ReadonlyArray<FleetUtilizationVesselState>;
      readonly percent: boolean;
      readonly unit: string;
      readonly view: View;
      readonly previousZoneIds: ReadonlyArray<number> | null;
      readonly nextZoneIds: ReadonlyArray<number> | null;
      readonly vesselDirection: ReadonlyArray<FleetUtilizationVesselDirection> | null;
      readonly showSubStates: boolean | null;
      readonly distinctAggregation: boolean | null;
      readonly vessels: ReadonlyArray<number>;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateFleetUtilizationWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WidgetFleetUtilizationUpdateInput;
}>;

export type UpdateFleetUtilizationWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFleetUtilizationUpdate: {
    readonly __typename: 'WidgetFleetUtilization';
    readonly id: string;
    readonly title: string;
    readonly configFleetUtilization: {
      readonly __typename: 'WidgetFleetUtilizationConfig';
      readonly zoneIds: ReadonlyArray<number>;
      readonly aggMetric: FleetUtilizationAggMetric;
      readonly split: FleetUtilizationSplit;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly lastFamilyProducts: ReadonlyArray<string>;
      readonly vesselState: ReadonlyArray<FleetUtilizationVesselState>;
      readonly percent: boolean;
      readonly unit: string;
      readonly view: View;
      readonly previousZoneIds: ReadonlyArray<number> | null;
      readonly nextZoneIds: ReadonlyArray<number> | null;
      readonly vesselDirection: ReadonlyArray<FleetUtilizationVesselDirection> | null;
      readonly showSubStates: boolean | null;
      readonly distinctAggregation: boolean | null;
      readonly vessels: ReadonlyArray<number>;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type FlowsWidgetFragment = {
  readonly __typename: 'WidgetFlows';
  readonly id: string;
  readonly title: string;
  readonly canBeEia: boolean;
  readonly configFlows: {
    readonly __typename: 'WidgetFlowsConfig';
    readonly mZones: ReadonlyArray<number>;
    readonly mInstallations: ReadonlyArray<number>;
    readonly sZones: ReadonlyArray<number>;
    readonly sInstallations: ReadonlyArray<number>;
    readonly products: ReadonlyArray<number>;
    readonly dir: Direction;
    readonly intra: boolean;
    readonly intraRegion: boolean;
    readonly beta: boolean;
    readonly forecast: boolean;
    readonly split: FlowsSplit;
    readonly projection: Projection;
    readonly unit: string;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly vessels: ReadonlyArray<number>;
    readonly isEia: boolean;
    readonly view: View;
    readonly productEstimation: boolean;
    readonly dates: string | null;
    readonly granularity: Granularity | null;
    readonly truncateY: boolean | null;
    readonly movingAverage: boolean | null;
    readonly seasonal: boolean | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFlowsWidgetMutationVariables = Exact<{
  input: WidgetFlowsCreateInput;
}>;

export type CreateFlowsWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFlowsCreate: {
    readonly __typename: 'WidgetFlows';
    readonly id: string;
    readonly title: string;
    readonly canBeEia: boolean;
    readonly configFlows: {
      readonly __typename: 'WidgetFlowsConfig';
      readonly mZones: ReadonlyArray<number>;
      readonly mInstallations: ReadonlyArray<number>;
      readonly sZones: ReadonlyArray<number>;
      readonly sInstallations: ReadonlyArray<number>;
      readonly products: ReadonlyArray<number>;
      readonly dir: Direction;
      readonly intra: boolean;
      readonly intraRegion: boolean;
      readonly beta: boolean;
      readonly forecast: boolean;
      readonly split: FlowsSplit;
      readonly projection: Projection;
      readonly unit: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly vessels: ReadonlyArray<number>;
      readonly isEia: boolean;
      readonly view: View;
      readonly productEstimation: boolean;
      readonly dates: string | null;
      readonly granularity: Granularity | null;
      readonly truncateY: boolean | null;
      readonly movingAverage: boolean | null;
      readonly seasonal: boolean | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateFlowsWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WidgetFlowsUpdateInput;
}>;

export type UpdateFlowsWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFlowsUpdate: {
    readonly __typename: 'WidgetFlows';
    readonly id: string;
    readonly title: string;
    readonly canBeEia: boolean;
    readonly configFlows: {
      readonly __typename: 'WidgetFlowsConfig';
      readonly mZones: ReadonlyArray<number>;
      readonly mInstallations: ReadonlyArray<number>;
      readonly sZones: ReadonlyArray<number>;
      readonly sInstallations: ReadonlyArray<number>;
      readonly products: ReadonlyArray<number>;
      readonly dir: Direction;
      readonly intra: boolean;
      readonly intraRegion: boolean;
      readonly beta: boolean;
      readonly forecast: boolean;
      readonly split: FlowsSplit;
      readonly projection: Projection;
      readonly unit: string;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly vessels: ReadonlyArray<number>;
      readonly isEia: boolean;
      readonly view: View;
      readonly productEstimation: boolean;
      readonly dates: string | null;
      readonly granularity: Granularity | null;
      readonly truncateY: boolean | null;
      readonly movingAverage: boolean | null;
      readonly seasonal: boolean | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type FreightMetricsWidgetFragment = {
  readonly __typename: 'WidgetFreightMetrics';
  readonly id: string;
  readonly title: string;
  readonly configFreightMetrics: {
    readonly __typename: 'WidgetFreightMetricsConfig';
    readonly mZones: ReadonlyArray<number>;
    readonly sZones: ReadonlyArray<number>;
    readonly metric: FreightMetricsMetric;
    readonly products: ReadonlyArray<number>;
    readonly intra: boolean;
    readonly beta: boolean;
    readonly splitOn: FreightMetricsSplit;
    readonly deadweight: ReadonlyArray<number> | null;
    readonly capacity: ReadonlyArray<number> | null;
    readonly vesselClassifications: ReadonlyArray<string>;
    readonly unit: string;
    readonly view: View;
    readonly vessels: ReadonlyArray<number>;
    readonly engineTypes: ReadonlyArray<string> | null;
    readonly speedRange: ReadonlyArray<string> | null;
    readonly vesselStates: ReadonlyArray<string> | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateFreightMetricsWidgetMutationVariables = Exact<{
  input: WidgetFreightMetricsCreateInput;
}>;

export type CreateFreightMetricsWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetFreightMetricsCreate: {
    readonly __typename: 'WidgetFreightMetrics';
    readonly id: string;
    readonly title: string;
    readonly configFreightMetrics: {
      readonly __typename: 'WidgetFreightMetricsConfig';
      readonly mZones: ReadonlyArray<number>;
      readonly sZones: ReadonlyArray<number>;
      readonly metric: FreightMetricsMetric;
      readonly products: ReadonlyArray<number>;
      readonly intra: boolean;
      readonly beta: boolean;
      readonly splitOn: FreightMetricsSplit;
      readonly deadweight: ReadonlyArray<number> | null;
      readonly capacity: ReadonlyArray<number> | null;
      readonly vesselClassifications: ReadonlyArray<string>;
      readonly unit: string;
      readonly view: View;
      readonly vessels: ReadonlyArray<number>;
      readonly engineTypes: ReadonlyArray<string> | null;
      readonly speedRange: ReadonlyArray<string> | null;
      readonly vesselStates: ReadonlyArray<string> | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type InsertWidgetIntoDashboardLayoutMutationVariables = Exact<{
  dashboardId: Scalars['ID'];
  widget: WidgetInput;
  layout: ReadonlyArray<WidgetPositionInput> | WidgetPositionInput;
  position: WidgetPositionUnsavedInput;
}>;

export type InsertWidgetIntoDashboardLayoutMutation = {
  readonly __typename: 'Mutation';
  readonly dashboard: {
    readonly __typename: 'Dashboard';
    readonly id: string;
    readonly title: string;
    readonly months: number;
    readonly addedAt: string;
    readonly isMine: boolean;
    readonly accountId: string;
    readonly layout: ReadonlyArray<{
      readonly __typename: 'WidgetPosition';
      readonly id: string;
      readonly x: number;
      readonly y: number;
      readonly w: number;
      readonly h: number;
    }>;
    readonly widgets: ReadonlyArray<
      | {
          readonly __typename: 'WidgetBallastCapacity';
          readonly id: string;
          readonly title: string;
          readonly configBallastCapacity: {
            readonly __typename: 'WidgetBallastCapacityConfig';
            readonly zones: ReadonlyArray<number>;
            readonly aggMetric: BallastCapacityAggMetric;
            readonly split: BallastCapacitySplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly unit: string;
            readonly products: ReadonlyArray<number>;
            readonly view: View;
            readonly etaType: string | null;
            readonly etaWindow: string | null;
            readonly nDaysAhead: number | null;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly vesselAvailability: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetCongestion';
          readonly id: string;
          readonly title: string;
          readonly configCongestion: {
            readonly __typename: 'WidgetCongestionConfig';
            readonly zones: ReadonlyArray<number>;
            readonly originZones: ReadonlyArray<number> | null;
            readonly metric: CongestionMetric;
            readonly products: ReadonlyArray<number>;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly allVesselsInWaitingZone: boolean;
            readonly vesselOperation: ReadonlyArray<CongestionVesselOperation>;
            readonly waitingDuration: ReadonlyArray<string> | null;
            readonly split: CongestionSplit;
            readonly unit: string;
            readonly view: View;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFixtures';
          readonly id: string;
          readonly title: string;
          readonly configFixtures: {
            readonly __typename: 'WidgetFixturesConfig';
            readonly fZones: ReadonlyArray<number>;
            readonly tZones: ReadonlyArray<number>;
            readonly products: ReadonlyArray<number>;
            readonly statuses: ReadonlyArray<FixtureStatus>;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly view: View;
            readonly columns: ReadonlyArray<string> | null;
            readonly vesselClassifications: ReadonlyArray<string> | null;
          };
        }
      | {
          readonly __typename: 'WidgetFleetDevelopment';
          readonly id: string;
          readonly title: string;
          readonly configFleetDevelopment: {
            readonly __typename: 'WidgetFleetDevelopmentConfig';
            readonly metric: FleetDevelopmentMetric;
            readonly aggMetric: FleetDevelopmentAggMetric;
            readonly splitOn: FleetDevelopmentSplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly complianceMethod: ReadonlyArray<string>;
            readonly coating: ReadonlyArray<FleetDevelopmentCoating> | null;
            readonly projection: Projection;
            readonly unit: string;
            readonly view: View;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFleetMetrics';
          readonly id: string;
          readonly title: string;
          readonly canBeEia: boolean;
          readonly configFleetMetrics: {
            readonly __typename: 'WidgetFleetMetricsConfig';
            readonly areas: ReadonlyArray<number>;
            readonly algo: FleetMetricsAlgo;
            readonly products: ReadonlyArray<number>;
            readonly split: FleetMetricsSplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly floatingDays: ReadonlyArray<string> | null;
            readonly unit: string;
            readonly isEia: boolean;
            readonly view: View;
            readonly dates: string | null;
            readonly granularity: Granularity | null;
            readonly truncateY: boolean | null;
            readonly movingAverage: boolean | null;
            readonly seasonal: boolean | null;
            readonly metric: FleetMetricsMetric;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFleetUtilization';
          readonly id: string;
          readonly title: string;
          readonly configFleetUtilization: {
            readonly __typename: 'WidgetFleetUtilizationConfig';
            readonly zoneIds: ReadonlyArray<number>;
            readonly aggMetric: FleetUtilizationAggMetric;
            readonly split: FleetUtilizationSplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly lastFamilyProducts: ReadonlyArray<string>;
            readonly vesselState: ReadonlyArray<FleetUtilizationVesselState>;
            readonly percent: boolean;
            readonly unit: string;
            readonly view: View;
            readonly previousZoneIds: ReadonlyArray<number> | null;
            readonly nextZoneIds: ReadonlyArray<number> | null;
            readonly vesselDirection: ReadonlyArray<FleetUtilizationVesselDirection> | null;
            readonly showSubStates: boolean | null;
            readonly distinctAggregation: boolean | null;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFlows';
          readonly id: string;
          readonly title: string;
          readonly canBeEia: boolean;
          readonly configFlows: {
            readonly __typename: 'WidgetFlowsConfig';
            readonly mZones: ReadonlyArray<number>;
            readonly mInstallations: ReadonlyArray<number>;
            readonly sZones: ReadonlyArray<number>;
            readonly sInstallations: ReadonlyArray<number>;
            readonly products: ReadonlyArray<number>;
            readonly dir: Direction;
            readonly intra: boolean;
            readonly intraRegion: boolean;
            readonly beta: boolean;
            readonly forecast: boolean;
            readonly split: FlowsSplit;
            readonly projection: Projection;
            readonly unit: string;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly vessels: ReadonlyArray<number>;
            readonly isEia: boolean;
            readonly view: View;
            readonly productEstimation: boolean;
            readonly dates: string | null;
            readonly granularity: Granularity | null;
            readonly truncateY: boolean | null;
            readonly movingAverage: boolean | null;
            readonly seasonal: boolean | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetFreightMetrics';
          readonly id: string;
          readonly title: string;
          readonly configFreightMetrics: {
            readonly __typename: 'WidgetFreightMetricsConfig';
            readonly mZones: ReadonlyArray<number>;
            readonly sZones: ReadonlyArray<number>;
            readonly metric: FreightMetricsMetric;
            readonly products: ReadonlyArray<number>;
            readonly intra: boolean;
            readonly beta: boolean;
            readonly splitOn: FreightMetricsSplit;
            readonly deadweight: ReadonlyArray<number> | null;
            readonly capacity: ReadonlyArray<number> | null;
            readonly vesselClassifications: ReadonlyArray<string>;
            readonly unit: string;
            readonly view: View;
            readonly vessels: ReadonlyArray<number>;
            readonly engineTypes: ReadonlyArray<string> | null;
            readonly speedRange: ReadonlyArray<string> | null;
            readonly vesselStates: ReadonlyArray<string> | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetInventories';
          readonly id: string;
          readonly title: string;
          readonly canBeEia: boolean;
          readonly configInventories: {
            readonly __typename: 'WidgetInventoriesConfig';
            readonly zones: ReadonlyArray<number>;
            readonly installations: ReadonlyArray<number>;
            readonly cargoTrackingEnhancement: boolean;
            readonly fleetMetricsEnhancement: boolean;
            readonly capacityUtilization: boolean;
            readonly floatingFrom: number | null;
            readonly split: InventoriesSplit;
            readonly unit: string;
            readonly isEia: boolean;
            readonly droneData: boolean;
            readonly droneDataAtmEnhancement: boolean;
            readonly view: View;
            readonly addEiaDatasets: boolean;
            readonly deltaLevel: boolean;
            readonly columns: ReadonlyArray<string> | null;
            readonly market: InventoriesMarket | null;
            readonly pricesFilters: {
              readonly __typename: 'PricesBaseFiltersData';
              readonly contracts: ReadonlyArray<{
                readonly __typename: 'PricesContractFilterParams';
                readonly name: string;
                readonly periodType: PeriodType | null;
                readonly dates: ReadonlyArray<string> | null;
                readonly continuousPeriods: ReadonlyArray<number> | null;
              }>;
            };
          };
        }
      | {
          readonly __typename: 'WidgetMapSearch';
          readonly id: string;
          readonly title: string;
          readonly configMapSearch: {
            readonly __typename: 'WidgetMapSearchConfig';
            readonly commodityTypes: ReadonlyArray<CommodityType> | null;
            readonly search: {
              readonly __typename: 'ConfigSearch';
              readonly locations: ReadonlyArray<string>;
              readonly loads: ReadonlyArray<string>;
              readonly discharges: ReadonlyArray<string>;
              readonly vessels: ReadonlyArray<number>;
              readonly products: ReadonlyArray<number>;
              readonly players: ReadonlyArray<number>;
            };
            readonly vesselFilters: {
              readonly __typename: 'MapVesselFilters';
              readonly cargoState: ReadonlyArray<CargoState>;
              readonly status: ReadonlyArray<VesselStatus>;
              readonly classification: ReadonlyArray<string>;
              readonly classificationOil: ReadonlyArray<string>;
              readonly classificationCpp: ReadonlyArray<string>;
              readonly speed: ReadonlyArray<Speed>;
              readonly carrierType: ReadonlyArray<string>;
              readonly engine: ReadonlyArray<string>;
              readonly ethyleneCapable: ReadonlyArray<YesNo>;
              readonly asphaltBitumenCapable: ReadonlyArray<YesNo>;
              readonly betaStatus: ReadonlyArray<BetaStatus>;
              readonly capacity: ReadonlyArray<number> | null;
              readonly buildYear: ReadonlyArray<number> | null;
              readonly draught: ReadonlyArray<number> | null;
              readonly cargoType: ReadonlyArray<string>;
              readonly vesselLayer: LayerState;
            };
            readonly installationFilters: {
              readonly __typename: 'MapInstallationFilters';
              readonly type: ReadonlyArray<InstallationTypeFilter>;
              readonly status: ReadonlyArray<string>;
              readonly betaStatus: ReadonlyArray<BetaStatus>;
              readonly installationLayer: LayerState;
            };
            readonly zoneFilters: {
              readonly __typename: 'MapZoneFilters';
              readonly type: ReadonlyArray<ZoneTypeFilter> | null;
              readonly zoneLayer: LayerState;
            };
            readonly options: {
              readonly __typename: 'ConfigOptions';
              readonly cyclones: boolean;
              readonly portsAndBerths: boolean;
              readonly labels: boolean;
              readonly background: MapBackground;
              readonly unit: MapUnitName;
            };
          };
        }
      | {
          readonly __typename: 'WidgetPrices';
          readonly id: string;
          readonly title: string;
          readonly configPrices: {
            readonly __typename: 'WidgetPricesConfig';
            readonly movingAverage: boolean | null;
            readonly truncateY: boolean | null;
            readonly view: View;
            readonly isForwardView: boolean | null;
            readonly pricesContractFilters: ReadonlyArray<{
              readonly __typename: 'PricesContractFilterParams';
              readonly name: string;
              readonly periodType: PeriodType | null;
              readonly dates: ReadonlyArray<string> | null;
              readonly continuousPeriods: ReadonlyArray<number> | null;
            }> | null;
          };
        }
      | {
          readonly __typename: 'WidgetRefineriesCapacities';
          readonly id: string;
          readonly title: string;
          readonly configRefineriesCapacities: {
            readonly __typename: 'WidgetRefineriesCapacitiesConfig';
            readonly metric: RefineriesCapacitiesMetric;
            readonly unitTypeSubFamilies: ReadonlyArray<string>;
            readonly splitBy: RefineriesCapacitiesSplit | null;
            readonly installationIds: ReadonlyArray<number> | null;
            readonly zoneIds: ReadonlyArray<number>;
            readonly projection: string;
            readonly groupByFamily: boolean;
            readonly unit: string;
            readonly view: View;
          };
        }
    >;
  };
};

export type InventoriesWidgetFragment = {
  readonly __typename: 'WidgetInventories';
  readonly id: string;
  readonly title: string;
  readonly canBeEia: boolean;
  readonly configInventories: {
    readonly __typename: 'WidgetInventoriesConfig';
    readonly zones: ReadonlyArray<number>;
    readonly installations: ReadonlyArray<number>;
    readonly cargoTrackingEnhancement: boolean;
    readonly fleetMetricsEnhancement: boolean;
    readonly capacityUtilization: boolean;
    readonly floatingFrom: number | null;
    readonly split: InventoriesSplit;
    readonly unit: string;
    readonly isEia: boolean;
    readonly droneData: boolean;
    readonly droneDataAtmEnhancement: boolean;
    readonly view: View;
    readonly addEiaDatasets: boolean;
    readonly deltaLevel: boolean;
    readonly columns: ReadonlyArray<string> | null;
    readonly market: InventoriesMarket | null;
    readonly pricesFilters: {
      readonly __typename: 'PricesBaseFiltersData';
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }>;
    };
  };
};

export type CreateInventoriesWidgetMutationVariables = Exact<{
  input: WidgetInventoriesCreateInput;
}>;

export type CreateInventoriesWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetInventoriesCreate: {
    readonly __typename: 'WidgetInventories';
    readonly id: string;
    readonly title: string;
    readonly canBeEia: boolean;
    readonly configInventories: {
      readonly __typename: 'WidgetInventoriesConfig';
      readonly zones: ReadonlyArray<number>;
      readonly installations: ReadonlyArray<number>;
      readonly cargoTrackingEnhancement: boolean;
      readonly fleetMetricsEnhancement: boolean;
      readonly capacityUtilization: boolean;
      readonly floatingFrom: number | null;
      readonly split: InventoriesSplit;
      readonly unit: string;
      readonly isEia: boolean;
      readonly droneData: boolean;
      readonly droneDataAtmEnhancement: boolean;
      readonly view: View;
      readonly addEiaDatasets: boolean;
      readonly deltaLevel: boolean;
      readonly columns: ReadonlyArray<string> | null;
      readonly market: InventoriesMarket | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type UpdateInventoriesWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WidgetInventoriesUpdateInput;
}>;

export type UpdateInventoriesWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetInventoriesUpdate: {
    readonly __typename: 'WidgetInventories';
    readonly id: string;
    readonly title: string;
    readonly canBeEia: boolean;
    readonly configInventories: {
      readonly __typename: 'WidgetInventoriesConfig';
      readonly zones: ReadonlyArray<number>;
      readonly installations: ReadonlyArray<number>;
      readonly cargoTrackingEnhancement: boolean;
      readonly fleetMetricsEnhancement: boolean;
      readonly capacityUtilization: boolean;
      readonly floatingFrom: number | null;
      readonly split: InventoriesSplit;
      readonly unit: string;
      readonly isEia: boolean;
      readonly droneData: boolean;
      readonly droneDataAtmEnhancement: boolean;
      readonly view: View;
      readonly addEiaDatasets: boolean;
      readonly deltaLevel: boolean;
      readonly columns: ReadonlyArray<string> | null;
      readonly market: InventoriesMarket | null;
      readonly pricesFilters: {
        readonly __typename: 'PricesBaseFiltersData';
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'PricesContractFilterParams';
          readonly name: string;
          readonly periodType: PeriodType | null;
          readonly dates: ReadonlyArray<string> | null;
          readonly continuousPeriods: ReadonlyArray<number> | null;
        }>;
      };
    };
  };
};

export type MapSearchWidgetFragment = {
  readonly __typename: 'WidgetMapSearch';
  readonly id: string;
  readonly title: string;
  readonly configMapSearch: {
    readonly __typename: 'WidgetMapSearchConfig';
    readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    readonly search: {
      readonly __typename: 'ConfigSearch';
      readonly locations: ReadonlyArray<string>;
      readonly loads: ReadonlyArray<string>;
      readonly discharges: ReadonlyArray<string>;
      readonly vessels: ReadonlyArray<number>;
      readonly products: ReadonlyArray<number>;
      readonly players: ReadonlyArray<number>;
    };
    readonly vesselFilters: {
      readonly __typename: 'MapVesselFilters';
      readonly cargoState: ReadonlyArray<CargoState>;
      readonly status: ReadonlyArray<VesselStatus>;
      readonly classification: ReadonlyArray<string>;
      readonly classificationOil: ReadonlyArray<string>;
      readonly classificationCpp: ReadonlyArray<string>;
      readonly speed: ReadonlyArray<Speed>;
      readonly carrierType: ReadonlyArray<string>;
      readonly engine: ReadonlyArray<string>;
      readonly ethyleneCapable: ReadonlyArray<YesNo>;
      readonly asphaltBitumenCapable: ReadonlyArray<YesNo>;
      readonly betaStatus: ReadonlyArray<BetaStatus>;
      readonly capacity: ReadonlyArray<number> | null;
      readonly buildYear: ReadonlyArray<number> | null;
      readonly draught: ReadonlyArray<number> | null;
      readonly cargoType: ReadonlyArray<string>;
      readonly vesselLayer: LayerState;
    };
    readonly installationFilters: {
      readonly __typename: 'MapInstallationFilters';
      readonly type: ReadonlyArray<InstallationTypeFilter>;
      readonly status: ReadonlyArray<string>;
      readonly betaStatus: ReadonlyArray<BetaStatus>;
      readonly installationLayer: LayerState;
    };
    readonly zoneFilters: {
      readonly __typename: 'MapZoneFilters';
      readonly type: ReadonlyArray<ZoneTypeFilter> | null;
      readonly zoneLayer: LayerState;
    };
    readonly options: {
      readonly __typename: 'ConfigOptions';
      readonly cyclones: boolean;
      readonly portsAndBerths: boolean;
      readonly labels: boolean;
      readonly background: MapBackground;
      readonly unit: MapUnitName;
    };
  };
};

export type CreateMapSearchWidgetMutationVariables = Exact<{
  input: WidgetMapSearchCreateInput;
}>;

export type CreateMapSearchWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetMapSearchCreate: {
    readonly __typename: 'WidgetMapSearch';
    readonly id: string;
    readonly title: string;
    readonly configMapSearch: {
      readonly __typename: 'WidgetMapSearchConfig';
      readonly commodityTypes: ReadonlyArray<CommodityType> | null;
      readonly search: {
        readonly __typename: 'ConfigSearch';
        readonly locations: ReadonlyArray<string>;
        readonly loads: ReadonlyArray<string>;
        readonly discharges: ReadonlyArray<string>;
        readonly vessels: ReadonlyArray<number>;
        readonly products: ReadonlyArray<number>;
        readonly players: ReadonlyArray<number>;
      };
      readonly vesselFilters: {
        readonly __typename: 'MapVesselFilters';
        readonly cargoState: ReadonlyArray<CargoState>;
        readonly status: ReadonlyArray<VesselStatus>;
        readonly classification: ReadonlyArray<string>;
        readonly classificationOil: ReadonlyArray<string>;
        readonly classificationCpp: ReadonlyArray<string>;
        readonly speed: ReadonlyArray<Speed>;
        readonly carrierType: ReadonlyArray<string>;
        readonly engine: ReadonlyArray<string>;
        readonly ethyleneCapable: ReadonlyArray<YesNo>;
        readonly asphaltBitumenCapable: ReadonlyArray<YesNo>;
        readonly betaStatus: ReadonlyArray<BetaStatus>;
        readonly capacity: ReadonlyArray<number> | null;
        readonly buildYear: ReadonlyArray<number> | null;
        readonly draught: ReadonlyArray<number> | null;
        readonly cargoType: ReadonlyArray<string>;
        readonly vesselLayer: LayerState;
      };
      readonly installationFilters: {
        readonly __typename: 'MapInstallationFilters';
        readonly type: ReadonlyArray<InstallationTypeFilter>;
        readonly status: ReadonlyArray<string>;
        readonly betaStatus: ReadonlyArray<BetaStatus>;
        readonly installationLayer: LayerState;
      };
      readonly zoneFilters: {
        readonly __typename: 'MapZoneFilters';
        readonly type: ReadonlyArray<ZoneTypeFilter> | null;
        readonly zoneLayer: LayerState;
      };
      readonly options: {
        readonly __typename: 'ConfigOptions';
        readonly cyclones: boolean;
        readonly portsAndBerths: boolean;
        readonly labels: boolean;
        readonly background: MapBackground;
        readonly unit: MapUnitName;
      };
    };
  };
};

export type UpdateMapSearchWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WidgetMapSearchUpdateInput;
}>;

export type UpdateMapSearchWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetMapSearchUpdate: {
    readonly __typename: 'WidgetMapSearch';
    readonly id: string;
    readonly title: string;
    readonly configMapSearch: {
      readonly __typename: 'WidgetMapSearchConfig';
      readonly commodityTypes: ReadonlyArray<CommodityType> | null;
      readonly search: {
        readonly __typename: 'ConfigSearch';
        readonly locations: ReadonlyArray<string>;
        readonly loads: ReadonlyArray<string>;
        readonly discharges: ReadonlyArray<string>;
        readonly vessels: ReadonlyArray<number>;
        readonly products: ReadonlyArray<number>;
        readonly players: ReadonlyArray<number>;
      };
      readonly vesselFilters: {
        readonly __typename: 'MapVesselFilters';
        readonly cargoState: ReadonlyArray<CargoState>;
        readonly status: ReadonlyArray<VesselStatus>;
        readonly classification: ReadonlyArray<string>;
        readonly classificationOil: ReadonlyArray<string>;
        readonly classificationCpp: ReadonlyArray<string>;
        readonly speed: ReadonlyArray<Speed>;
        readonly carrierType: ReadonlyArray<string>;
        readonly engine: ReadonlyArray<string>;
        readonly ethyleneCapable: ReadonlyArray<YesNo>;
        readonly asphaltBitumenCapable: ReadonlyArray<YesNo>;
        readonly betaStatus: ReadonlyArray<BetaStatus>;
        readonly capacity: ReadonlyArray<number> | null;
        readonly buildYear: ReadonlyArray<number> | null;
        readonly draught: ReadonlyArray<number> | null;
        readonly cargoType: ReadonlyArray<string>;
        readonly vesselLayer: LayerState;
      };
      readonly installationFilters: {
        readonly __typename: 'MapInstallationFilters';
        readonly type: ReadonlyArray<InstallationTypeFilter>;
        readonly status: ReadonlyArray<string>;
        readonly betaStatus: ReadonlyArray<BetaStatus>;
        readonly installationLayer: LayerState;
      };
      readonly zoneFilters: {
        readonly __typename: 'MapZoneFilters';
        readonly type: ReadonlyArray<ZoneTypeFilter> | null;
        readonly zoneLayer: LayerState;
      };
      readonly options: {
        readonly __typename: 'ConfigOptions';
        readonly cyclones: boolean;
        readonly portsAndBerths: boolean;
        readonly labels: boolean;
        readonly background: MapBackground;
        readonly unit: MapUnitName;
      };
    };
  };
};

export type PricesWidgetFragment = {
  readonly __typename: 'WidgetPrices';
  readonly id: string;
  readonly title: string;
  readonly configPrices: {
    readonly __typename: 'WidgetPricesConfig';
    readonly movingAverage: boolean | null;
    readonly truncateY: boolean | null;
    readonly view: View;
    readonly isForwardView: boolean | null;
    readonly pricesContractFilters: ReadonlyArray<{
      readonly __typename: 'PricesContractFilterParams';
      readonly name: string;
      readonly periodType: PeriodType | null;
      readonly dates: ReadonlyArray<string> | null;
      readonly continuousPeriods: ReadonlyArray<number> | null;
    }> | null;
  };
};

export type CreatePricesWidgetMutationVariables = Exact<{
  input: WidgetPricesCreateInput;
}>;

export type CreatePricesWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetPricesCreate: {
    readonly __typename: 'WidgetPrices';
    readonly id: string;
    readonly title: string;
    readonly configPrices: {
      readonly __typename: 'WidgetPricesConfig';
      readonly movingAverage: boolean | null;
      readonly truncateY: boolean | null;
      readonly view: View;
      readonly isForwardView: boolean | null;
      readonly pricesContractFilters: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }> | null;
    };
  };
};

export type UpdatePricesWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WidgetPricesUpdateInput;
}>;

export type UpdatePricesWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetPricesUpdate: {
    readonly __typename: 'WidgetPrices';
    readonly id: string;
    readonly title: string;
    readonly configPrices: {
      readonly __typename: 'WidgetPricesConfig';
      readonly movingAverage: boolean | null;
      readonly truncateY: boolean | null;
      readonly view: View;
      readonly isForwardView: boolean | null;
      readonly pricesContractFilters: ReadonlyArray<{
        readonly __typename: 'PricesContractFilterParams';
        readonly name: string;
        readonly periodType: PeriodType | null;
        readonly dates: ReadonlyArray<string> | null;
        readonly continuousPeriods: ReadonlyArray<number> | null;
      }> | null;
    };
  };
};

export type RefineriesCapacitiesWidgetFragment = {
  readonly __typename: 'WidgetRefineriesCapacities';
  readonly id: string;
  readonly title: string;
  readonly configRefineriesCapacities: {
    readonly __typename: 'WidgetRefineriesCapacitiesConfig';
    readonly metric: RefineriesCapacitiesMetric;
    readonly unitTypeSubFamilies: ReadonlyArray<string>;
    readonly splitBy: RefineriesCapacitiesSplit | null;
    readonly installationIds: ReadonlyArray<number> | null;
    readonly zoneIds: ReadonlyArray<number>;
    readonly projection: string;
    readonly groupByFamily: boolean;
    readonly unit: string;
    readonly view: View;
  };
};

export type CreateRefineriesCapacitiesWidgetMutationVariables = Exact<{
  input: WidgetRefineriesCapacitiesCreateInput;
}>;

export type CreateRefineriesCapacitiesWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetRefineriesCapacitiesCreate: {
    readonly __typename: 'WidgetRefineriesCapacities';
    readonly id: string;
    readonly title: string;
    readonly configRefineriesCapacities: {
      readonly __typename: 'WidgetRefineriesCapacitiesConfig';
      readonly metric: RefineriesCapacitiesMetric;
      readonly unitTypeSubFamilies: ReadonlyArray<string>;
      readonly splitBy: RefineriesCapacitiesSplit | null;
      readonly installationIds: ReadonlyArray<number> | null;
      readonly zoneIds: ReadonlyArray<number>;
      readonly projection: string;
      readonly groupByFamily: boolean;
      readonly unit: string;
      readonly view: View;
    };
  };
};

export type UpdateRefineriesCapacitiesWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WidgetRefineriesCapacitiesUpdateInput;
}>;

export type UpdateRefineriesCapacitiesWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetRefineriesCapacitiesUpdate: {
    readonly __typename: 'WidgetRefineriesCapacities';
    readonly id: string;
    readonly title: string;
    readonly configRefineriesCapacities: {
      readonly __typename: 'WidgetRefineriesCapacitiesConfig';
      readonly metric: RefineriesCapacitiesMetric;
      readonly unitTypeSubFamilies: ReadonlyArray<string>;
      readonly splitBy: RefineriesCapacitiesSplit | null;
      readonly installationIds: ReadonlyArray<number> | null;
      readonly zoneIds: ReadonlyArray<number>;
      readonly projection: string;
      readonly groupByFamily: boolean;
      readonly unit: string;
      readonly view: View;
    };
  };
};

export type RenameWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
}>;

export type RenameWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetRename:
    | { readonly __typename: 'WidgetBallastCapacity'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetCongestion'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetFixtures'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetFleetDevelopment'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetFleetMetrics'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetFleetUtilization'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetFlows'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetFreightMetrics'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetInventories'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetMapSearch'; readonly title: string; readonly id: string }
    | { readonly __typename: 'WidgetPrices'; readonly title: string; readonly id: string }
    | {
        readonly __typename: 'WidgetRefineriesCapacities';
        readonly title: string;
        readonly id: string;
      };
};

export type DeleteWidgetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteWidgetMutation = {
  readonly __typename: 'Mutation';
  readonly widgetDelete: string;
};

export type ProductFragment = {
  readonly __typename: 'Product';
  readonly id: string;
  readonly name: string;
  readonly hasEstimation: boolean;
  readonly parentId: string | null;
  readonly type: ProductType;
};

export type GetProductsByIdQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type GetProductsByIdQuery = {
  readonly __typename: 'Query';
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
    readonly hasEstimation: boolean;
    readonly parentId: string | null;
    readonly type: ProductType;
  }>;
};

export type HydrateQueryVariables = Exact<{
  installationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  productIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  zoneIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateQuery = {
  readonly __typename: 'Query';
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly hasCargoTracking: boolean;
    readonly hasStorage: boolean;
    readonly id: string;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly iirName: string | null;
    readonly portCost: number | null;
    readonly type: string;
    readonly unlocodes: ReadonlyArray<string>;
    readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    readonly port: { readonly __typename: 'Zone'; readonly id: string };
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
    readonly hasEstimation: boolean;
    readonly parentId: string | null;
    readonly type: ProductType;
  }>;
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly hasStorage: boolean;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly type: string;
  }>;
};

export type HydrateLocationsQueryVariables = Exact<{
  installationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  zoneIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateLocationsQuery = {
  readonly __typename: 'Query';
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly hasCargoTracking: boolean;
    readonly hasStorage: boolean;
    readonly id: string;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly iirName: string | null;
    readonly portCost: number | null;
    readonly type: string;
    readonly unlocodes: ReadonlyArray<string>;
    readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    readonly port: { readonly __typename: 'Zone'; readonly id: string };
  }>;
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly hasStorage: boolean;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly type: string;
  }>;
};

export type HydrateVesselsQueryVariables = Exact<{
  vesselIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateVesselsQuery = {
  readonly __typename: 'Query';
  readonly vesselsById: ReadonlyArray<{
    readonly __typename: 'Vessel';
    readonly id: string;
    readonly name: string;
    readonly status: string;
    readonly imo: string;
    readonly hasLastPosition: boolean;
  }>;
};

export type HydrateZonesQueryVariables = Exact<{
  zoneIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateZonesQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly hasStorage: boolean;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly type: string;
  }>;
};

export type HydrateZonesPlayersQueryVariables = Exact<{
  installationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  playerIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  zoneIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateZonesPlayersQuery = {
  readonly __typename: 'Query';
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly hasCargoTracking: boolean;
    readonly hasStorage: boolean;
    readonly id: string;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly iirName: string | null;
    readonly portCost: number | null;
    readonly type: string;
    readonly unlocodes: ReadonlyArray<string>;
    readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    readonly port: { readonly __typename: 'Zone'; readonly id: string };
  }>;
  readonly playersById: ReadonlyArray<{
    readonly __typename: 'Player';
    readonly id: string;
    readonly name: string;
  }>;
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly hasStorage: boolean;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly type: string;
  }>;
};

export type SearchCompletionQueryVariables = Exact<{
  text: Scalars['String'];
  options?: InputMaybe<SearchOptionsInput>;
  category: ReadonlyArray<SearchCategory> | SearchCategory;
}>;

export type SearchCompletionQuery = {
  readonly __typename: 'Query';
  readonly completionSearch: ReadonlyArray<
    | {
        readonly __typename: 'InstallationSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly installation: {
          readonly __typename: 'Installation';
          readonly hasCargoTracking: boolean;
          readonly hasStorage: boolean;
          readonly id: string;
          readonly isInUnitedStates: boolean;
          readonly name: string;
          readonly iirName: string | null;
          readonly portCost: number | null;
          readonly type: string;
          readonly unlocodes: ReadonlyArray<string>;
          readonly commodityTypes: ReadonlyArray<CommodityType> | null;
          readonly port: { readonly __typename: 'Zone'; readonly id: string };
        };
      }
    | {
        readonly __typename: 'PlayerSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly player: {
          readonly __typename: 'Player';
          readonly id: string;
          readonly name: string;
        };
      }
    | {
        readonly __typename: 'ProductSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly product: {
          readonly __typename: 'Product';
          readonly id: string;
          readonly name: string;
          readonly hasEstimation: boolean;
          readonly parentId: string | null;
          readonly type: ProductType;
        };
      }
    | {
        readonly __typename: 'VesselSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly vessel: {
          readonly __typename: 'Vessel';
          readonly id: string;
          readonly name: string;
          readonly status: string;
          readonly imo: string;
          readonly hasLastPosition: boolean;
          readonly currentCommodityType: CommodityType | null;
        };
      }
    | {
        readonly __typename: 'ZoneSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly zone: {
          readonly __typename: 'Zone';
          readonly id: string;
          readonly hasStorage: boolean;
          readonly isInUnitedStates: boolean;
          readonly name: string;
          readonly type: string;
        };
      }
  >;
};

export type SearchAreasQueryVariables = Exact<{
  text: Scalars['String'];
  options?: InputMaybe<SearchOptionsInput>;
}>;

export type SearchAreasQuery = {
  readonly __typename: 'Query';
  readonly completionSearch: ReadonlyArray<
    | {
        readonly __typename: 'InstallationSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly installation: {
          readonly __typename: 'Installation';
          readonly hasCargoTracking: boolean;
          readonly hasStorage: boolean;
          readonly id: string;
          readonly isInUnitedStates: boolean;
          readonly name: string;
          readonly iirName: string | null;
          readonly portCost: number | null;
          readonly type: string;
          readonly unlocodes: ReadonlyArray<string>;
          readonly commodityTypes: ReadonlyArray<CommodityType> | null;
          readonly port: { readonly __typename: 'Zone'; readonly id: string };
        };
      }
    | { readonly __typename: 'PlayerSearch' }
    | { readonly __typename: 'ProductSearch' }
    | { readonly __typename: 'VesselSearch' }
    | {
        readonly __typename: 'ZoneSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly zone: {
          readonly __typename: 'Zone';
          readonly id: string;
          readonly hasStorage: boolean;
          readonly isInUnitedStates: boolean;
          readonly name: string;
          readonly type: string;
        };
      }
  >;
};

export type InstallationFragment = {
  readonly __typename: 'Installation';
  readonly hasCargoTracking: boolean;
  readonly hasStorage: boolean;
  readonly id: string;
  readonly isInUnitedStates: boolean;
  readonly name: string;
  readonly iirName: string | null;
  readonly portCost: number | null;
  readonly type: string;
  readonly unlocodes: ReadonlyArray<string>;
  readonly commodityTypes: ReadonlyArray<CommodityType> | null;
  readonly port: { readonly __typename: 'Zone'; readonly id: string };
};

export type InstallationSearchFragment = {
  readonly __typename: 'InstallationSearch';
  readonly score: number;
  readonly highlight: {
    readonly __typename: 'Highlight';
    readonly field: string;
    readonly value: string;
  } | null;
  readonly installation: {
    readonly __typename: 'Installation';
    readonly hasCargoTracking: boolean;
    readonly hasStorage: boolean;
    readonly id: string;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly iirName: string | null;
    readonly portCost: number | null;
    readonly type: string;
    readonly unlocodes: ReadonlyArray<string>;
    readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    readonly port: { readonly __typename: 'Zone'; readonly id: string };
  };
};

export type InstallationWithCapacitiesFragment = {
  readonly __typename: 'Installation';
  readonly id: string;
  readonly name: string;
  readonly status: string;
  readonly fullName: string;
  readonly commodityTypes: ReadonlyArray<CommodityType> | null;
  readonly nominalAnnualCapacity: {
    readonly __typename: 'QuantityObject';
    readonly energy: number;
    readonly mass: number;
    readonly volume: number;
    readonly volume_gas: number;
  } | null;
  readonly capacityHolders: ReadonlyArray<{
    readonly __typename: 'CapacityHolder';
    readonly holdingCapacity: {
      readonly __typename: 'QuantityObject';
      readonly energy: number;
      readonly mass: number;
      readonly volume: number;
      readonly volume_gas: number;
    } | null;
  }>;
};

export type SearchInstallationsQueryVariables = Exact<{
  text: Scalars['String'];
  options?: InputMaybe<SearchOptionsInput>;
}>;

export type SearchInstallationsQuery = {
  readonly __typename: 'Query';
  readonly completionSearch: ReadonlyArray<
    | {
        readonly __typename: 'InstallationSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly installation: {
          readonly __typename: 'Installation';
          readonly hasCargoTracking: boolean;
          readonly hasStorage: boolean;
          readonly id: string;
          readonly isInUnitedStates: boolean;
          readonly name: string;
          readonly iirName: string | null;
          readonly portCost: number | null;
          readonly type: string;
          readonly unlocodes: ReadonlyArray<string>;
          readonly commodityTypes: ReadonlyArray<CommodityType> | null;
          readonly port: { readonly __typename: 'Zone'; readonly id: string };
        };
      }
    | { readonly __typename: 'PlayerSearch' }
    | { readonly __typename: 'ProductSearch' }
    | { readonly __typename: 'VesselSearch' }
    | { readonly __typename: 'ZoneSearch' }
  >;
};

export type SearchInstallationsByCapacityHolderQueryVariables = Exact<{
  capacityHolderId: Scalars['ID'];
}>;

export type SearchInstallationsByCapacityHolderQuery = {
  readonly __typename: 'Query';
  readonly playerHoldingCapacities: {
    readonly __typename: 'PlayerHoldingCapacities';
    readonly export: ReadonlyArray<{
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
      readonly status: string;
      readonly fullName: string;
      readonly commodityTypes: ReadonlyArray<CommodityType> | null;
      readonly nominalAnnualCapacity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      } | null;
      readonly capacityHolders: ReadonlyArray<{
        readonly __typename: 'CapacityHolder';
        readonly holdingCapacity: {
          readonly __typename: 'QuantityObject';
          readonly energy: number;
          readonly mass: number;
          readonly volume: number;
          readonly volume_gas: number;
        } | null;
      }>;
    }>;
    readonly import: ReadonlyArray<{
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
      readonly status: string;
      readonly fullName: string;
      readonly commodityTypes: ReadonlyArray<CommodityType> | null;
      readonly nominalAnnualCapacity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      } | null;
      readonly capacityHolders: ReadonlyArray<{
        readonly __typename: 'CapacityHolder';
        readonly holdingCapacity: {
          readonly __typename: 'QuantityObject';
          readonly energy: number;
          readonly mass: number;
          readonly volume: number;
          readonly volume_gas: number;
        } | null;
      }>;
    }>;
  };
};

export type SearchOriginsQueryVariables = Exact<{
  text: Scalars['String'];
  options?: InputMaybe<SearchOptionsInput>;
}>;

export type SearchOriginsQuery = {
  readonly __typename: 'Query';
  readonly completionSearch: ReadonlyArray<
    | {
        readonly __typename: 'InstallationSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly installation: {
          readonly __typename: 'Installation';
          readonly hasCargoTracking: boolean;
          readonly hasStorage: boolean;
          readonly id: string;
          readonly isInUnitedStates: boolean;
          readonly name: string;
          readonly iirName: string | null;
          readonly portCost: number | null;
          readonly type: string;
          readonly unlocodes: ReadonlyArray<string>;
          readonly commodityTypes: ReadonlyArray<CommodityType> | null;
          readonly port: { readonly __typename: 'Zone'; readonly id: string };
        };
      }
    | { readonly __typename: 'PlayerSearch' }
    | { readonly __typename: 'ProductSearch' }
    | {
        readonly __typename: 'VesselSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly vessel: {
          readonly __typename: 'Vessel';
          readonly id: string;
          readonly name: string;
          readonly status: string;
          readonly imo: string;
          readonly hasLastPosition: boolean;
          readonly currentCommodityType: CommodityType | null;
        };
      }
    | {
        readonly __typename: 'ZoneSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly zone: {
          readonly __typename: 'Zone';
          readonly id: string;
          readonly hasStorage: boolean;
          readonly isInUnitedStates: boolean;
          readonly name: string;
          readonly type: string;
        };
      }
  >;
};

export type PlayerFragment = {
  readonly __typename: 'Player';
  readonly id: string;
  readonly name: string;
};

export type PlayerSearchFragment = {
  readonly __typename: 'PlayerSearch';
  readonly score: number;
  readonly highlight: {
    readonly __typename: 'Highlight';
    readonly field: string;
    readonly value: string;
  } | null;
  readonly player: { readonly __typename: 'Player'; readonly id: string; readonly name: string };
};

export type SearchPlayersQueryVariables = Exact<{
  text: Scalars['String'];
  options?: InputMaybe<SearchOptionsInput>;
}>;

export type SearchPlayersQuery = {
  readonly __typename: 'Query';
  readonly completionSearch: ReadonlyArray<
    | { readonly __typename: 'InstallationSearch' }
    | {
        readonly __typename: 'PlayerSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly player: {
          readonly __typename: 'Player';
          readonly id: string;
          readonly name: string;
        };
      }
    | { readonly __typename: 'ProductSearch' }
    | { readonly __typename: 'VesselSearch' }
    | { readonly __typename: 'ZoneSearch' }
  >;
};

export type ProductSearchFragment = {
  readonly __typename: 'ProductSearch';
  readonly score: number;
  readonly highlight: {
    readonly __typename: 'Highlight';
    readonly field: string;
    readonly value: string;
  } | null;
  readonly product: {
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
    readonly hasEstimation: boolean;
    readonly parentId: string | null;
    readonly type: ProductType;
  };
};

export type SearchProductsQueryVariables = Exact<{
  text: Scalars['String'];
  options?: InputMaybe<SearchOptionsInput>;
}>;

export type SearchProductsQuery = {
  readonly __typename: 'Query';
  readonly completionSearch: ReadonlyArray<
    | { readonly __typename: 'InstallationSearch' }
    | { readonly __typename: 'PlayerSearch' }
    | {
        readonly __typename: 'ProductSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly product: {
          readonly __typename: 'Product';
          readonly id: string;
          readonly name: string;
          readonly hasEstimation: boolean;
          readonly parentId: string | null;
          readonly type: ProductType;
        };
      }
    | { readonly __typename: 'VesselSearch' }
    | { readonly __typename: 'ZoneSearch' }
  >;
};

export type VesselFragment = {
  readonly __typename: 'Vessel';
  readonly id: string;
  readonly name: string;
  readonly status: string;
  readonly imo: string;
  readonly hasLastPosition: boolean;
  readonly currentCommodityType: CommodityType | null;
};

export type VesselSearchFragment = {
  readonly __typename: 'VesselSearch';
  readonly score: number;
  readonly highlight: {
    readonly __typename: 'Highlight';
    readonly field: string;
    readonly value: string;
  } | null;
  readonly vessel: {
    readonly __typename: 'Vessel';
    readonly id: string;
    readonly name: string;
    readonly status: string;
    readonly imo: string;
    readonly hasLastPosition: boolean;
    readonly currentCommodityType: CommodityType | null;
  };
};

export type VesselWithCapacityFragment = {
  readonly __typename: 'Vessel';
  readonly id: string;
  readonly name: string;
  readonly status: string;
  readonly imo: string;
  readonly hasLastPosition: boolean;
  readonly currentCommodityType: CommodityType | null;
  readonly capacity: {
    readonly __typename: 'QuantityObject';
    readonly energy: number;
    readonly mass: number;
    readonly volume: number;
    readonly volume_gas: number;
  };
};

export type VesselWithOwnersAncestorsFragment = {
  readonly __typename: 'Vessel';
  readonly id: string;
  readonly name: string;
  readonly status: string;
  readonly imo: string;
  readonly hasLastPosition: boolean;
  readonly currentCommodityType: CommodityType | null;
  readonly ownersAncestors: ReadonlyArray<{
    readonly __typename: 'VesselOwner';
    readonly id: number;
    readonly share: number | null;
  }>;
  readonly capacity: {
    readonly __typename: 'QuantityObject';
    readonly energy: number;
    readonly mass: number;
    readonly volume: number;
    readonly volume_gas: number;
  };
};

export type SearchVesselsQueryVariables = Exact<{
  text: Scalars['String'];
  options?: InputMaybe<SearchOptionsInput>;
  size?: InputMaybe<Scalars['Int']>;
}>;

export type SearchVesselsQuery = {
  readonly __typename: 'Query';
  readonly completionSearch: ReadonlyArray<
    | { readonly __typename: 'InstallationSearch' }
    | { readonly __typename: 'PlayerSearch' }
    | { readonly __typename: 'ProductSearch' }
    | {
        readonly __typename: 'VesselSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly vessel: {
          readonly __typename: 'Vessel';
          readonly id: string;
          readonly name: string;
          readonly status: string;
          readonly imo: string;
          readonly hasLastPosition: boolean;
          readonly currentCommodityType: CommodityType | null;
        };
      }
    | { readonly __typename: 'ZoneSearch' }
  >;
};

export type SearchVesselsControlledByPlayerQueryVariables = Exact<{
  playerId: Scalars['ID'];
}>;

export type SearchVesselsControlledByPlayerQuery = {
  readonly __typename: 'Query';
  readonly vesselsControlledByPlayer: {
    readonly __typename: 'VesselsByPlayerResponse';
    readonly averageAge: number | null;
    readonly vessels: ReadonlyArray<{
      readonly __typename: 'Vessel';
      readonly id: string;
      readonly name: string;
      readonly status: string;
      readonly imo: string;
      readonly hasLastPosition: boolean;
      readonly currentCommodityType: CommodityType | null;
      readonly capacity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      };
    }>;
  };
};

export type SearchVesselsOwnedByPlayerQueryVariables = Exact<{
  playerId: Scalars['ID'];
}>;

export type SearchVesselsOwnedByPlayerQuery = {
  readonly __typename: 'Query';
  readonly vesselsOwnedByPlayer: {
    readonly __typename: 'VesselsByPlayerResponse';
    readonly averageAge: number | null;
    readonly vessels: ReadonlyArray<{
      readonly __typename: 'Vessel';
      readonly id: string;
      readonly name: string;
      readonly status: string;
      readonly imo: string;
      readonly hasLastPosition: boolean;
      readonly currentCommodityType: CommodityType | null;
      readonly ownersAncestors: ReadonlyArray<{
        readonly __typename: 'VesselOwner';
        readonly id: number;
        readonly share: number | null;
      }>;
      readonly capacity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      };
    }>;
  };
};

export type ZoneFragment = {
  readonly __typename: 'Zone';
  readonly id: string;
  readonly hasStorage: boolean;
  readonly isInUnitedStates: boolean;
  readonly name: string;
  readonly type: string;
};

export type ZoneSearchFragment = {
  readonly __typename: 'ZoneSearch';
  readonly score: number;
  readonly highlight: {
    readonly __typename: 'Highlight';
    readonly field: string;
    readonly value: string;
  } | null;
  readonly zone: {
    readonly __typename: 'Zone';
    readonly id: string;
    readonly hasStorage: boolean;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly type: string;
  };
};

export type SearchZonesQueryVariables = Exact<{
  text: Scalars['String'];
  options?: InputMaybe<SearchOptionsInput>;
}>;

export type SearchZonesQuery = {
  readonly __typename: 'Query';
  readonly completionSearch: ReadonlyArray<
    | { readonly __typename: 'InstallationSearch' }
    | { readonly __typename: 'PlayerSearch' }
    | { readonly __typename: 'ProductSearch' }
    | { readonly __typename: 'VesselSearch' }
    | {
        readonly __typename: 'ZoneSearch';
        readonly score: number;
        readonly highlight: {
          readonly __typename: 'Highlight';
          readonly field: string;
          readonly value: string;
        } | null;
        readonly zone: {
          readonly __typename: 'Zone';
          readonly id: string;
          readonly hasStorage: boolean;
          readonly isInUnitedStates: boolean;
          readonly name: string;
          readonly type: string;
        };
      }
  >;
};

export type WorksheetFragment = {
  readonly __typename: 'Worksheet';
  readonly id: string;
  readonly type: WorksheetType;
  readonly title: string;
  readonly updatedAt: string;
  readonly isMine: boolean;
  readonly config: {
    readonly __typename: 'WorksheetConfig';
    readonly measures: ReadonlyArray<string>;
    readonly columns: ReadonlyArray<string>;
    readonly rows: ReadonlyArray<string>;
    readonly filters: ReadonlyArray<{
      readonly __typename: 'WorksheetFilter';
      readonly action: WorksheetFilterAction;
      readonly field: string;
      readonly values: ReadonlyArray<string>;
    }>;
    readonly sorting: {
      readonly __typename: 'WorksheetSort';
      readonly row: {
        readonly __typename: 'WorksheetSortConfig';
        readonly direction: SortDirection;
        readonly field: string;
        readonly values: ReadonlyArray<string>;
      } | null;
      readonly column: {
        readonly __typename: 'WorksheetSortConfig';
        readonly direction: SortDirection;
        readonly field: string;
        readonly values: ReadonlyArray<string>;
      } | null;
    } | null;
    readonly expanded: {
      readonly __typename: 'WorksheetExpanded';
      readonly all: boolean;
      readonly rows: ReadonlyArray<
        ReadonlyArray<{
          readonly __typename: 'ExpandableField';
          readonly field: string;
          readonly value: string;
        }>
      >;
      readonly columns: ReadonlyArray<
        ReadonlyArray<{
          readonly __typename: 'ExpandableField';
          readonly field: string;
          readonly value: string;
        }>
      >;
    };
    readonly display: {
      readonly __typename: 'WorksheetDisplay';
      readonly showGrandTotal: boolean;
      readonly showSubtotal: boolean;
    };
  };
};

export type GetWorksheetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetWorksheetQuery = {
  readonly __typename: 'Query';
  readonly worksheet: {
    readonly __typename: 'Worksheet';
    readonly id: string;
    readonly type: WorksheetType;
    readonly title: string;
    readonly updatedAt: string;
    readonly isMine: boolean;
    readonly config: {
      readonly __typename: 'WorksheetConfig';
      readonly measures: ReadonlyArray<string>;
      readonly columns: ReadonlyArray<string>;
      readonly rows: ReadonlyArray<string>;
      readonly filters: ReadonlyArray<{
        readonly __typename: 'WorksheetFilter';
        readonly action: WorksheetFilterAction;
        readonly field: string;
        readonly values: ReadonlyArray<string>;
      }>;
      readonly sorting: {
        readonly __typename: 'WorksheetSort';
        readonly row: {
          readonly __typename: 'WorksheetSortConfig';
          readonly direction: SortDirection;
          readonly field: string;
          readonly values: ReadonlyArray<string>;
        } | null;
        readonly column: {
          readonly __typename: 'WorksheetSortConfig';
          readonly direction: SortDirection;
          readonly field: string;
          readonly values: ReadonlyArray<string>;
        } | null;
      } | null;
      readonly expanded: {
        readonly __typename: 'WorksheetExpanded';
        readonly all: boolean;
        readonly rows: ReadonlyArray<
          ReadonlyArray<{
            readonly __typename: 'ExpandableField';
            readonly field: string;
            readonly value: string;
          }>
        >;
        readonly columns: ReadonlyArray<
          ReadonlyArray<{
            readonly __typename: 'ExpandableField';
            readonly field: string;
            readonly value: string;
          }>
        >;
      };
      readonly display: {
        readonly __typename: 'WorksheetDisplay';
        readonly showGrandTotal: boolean;
        readonly showSubtotal: boolean;
      };
    };
  };
};

export type CreateWorksheetMutationVariables = Exact<{
  input: WorksheetCreateInput;
}>;

export type CreateWorksheetMutation = {
  readonly __typename: 'Mutation';
  readonly worksheetCreate: {
    readonly __typename: 'Worksheet';
    readonly id: string;
    readonly type: WorksheetType;
    readonly title: string;
    readonly updatedAt: string;
    readonly isMine: boolean;
    readonly config: {
      readonly __typename: 'WorksheetConfig';
      readonly measures: ReadonlyArray<string>;
      readonly columns: ReadonlyArray<string>;
      readonly rows: ReadonlyArray<string>;
      readonly filters: ReadonlyArray<{
        readonly __typename: 'WorksheetFilter';
        readonly action: WorksheetFilterAction;
        readonly field: string;
        readonly values: ReadonlyArray<string>;
      }>;
      readonly sorting: {
        readonly __typename: 'WorksheetSort';
        readonly row: {
          readonly __typename: 'WorksheetSortConfig';
          readonly direction: SortDirection;
          readonly field: string;
          readonly values: ReadonlyArray<string>;
        } | null;
        readonly column: {
          readonly __typename: 'WorksheetSortConfig';
          readonly direction: SortDirection;
          readonly field: string;
          readonly values: ReadonlyArray<string>;
        } | null;
      } | null;
      readonly expanded: {
        readonly __typename: 'WorksheetExpanded';
        readonly all: boolean;
        readonly rows: ReadonlyArray<
          ReadonlyArray<{
            readonly __typename: 'ExpandableField';
            readonly field: string;
            readonly value: string;
          }>
        >;
        readonly columns: ReadonlyArray<
          ReadonlyArray<{
            readonly __typename: 'ExpandableField';
            readonly field: string;
            readonly value: string;
          }>
        >;
      };
      readonly display: {
        readonly __typename: 'WorksheetDisplay';
        readonly showGrandTotal: boolean;
        readonly showSubtotal: boolean;
      };
    };
  };
};

export type DeleteWorksheetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteWorksheetMutation = {
  readonly __typename: 'Mutation';
  readonly worksheetDelete: boolean;
};

export type UpdateWorksheetMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WorksheetUpdateInput;
}>;

export type UpdateWorksheetMutation = {
  readonly __typename: 'Mutation';
  readonly worksheetUpdate: {
    readonly __typename: 'Worksheet';
    readonly id: string;
    readonly type: WorksheetType;
    readonly title: string;
    readonly updatedAt: string;
    readonly isMine: boolean;
    readonly config: {
      readonly __typename: 'WorksheetConfig';
      readonly measures: ReadonlyArray<string>;
      readonly columns: ReadonlyArray<string>;
      readonly rows: ReadonlyArray<string>;
      readonly filters: ReadonlyArray<{
        readonly __typename: 'WorksheetFilter';
        readonly action: WorksheetFilterAction;
        readonly field: string;
        readonly values: ReadonlyArray<string>;
      }>;
      readonly sorting: {
        readonly __typename: 'WorksheetSort';
        readonly row: {
          readonly __typename: 'WorksheetSortConfig';
          readonly direction: SortDirection;
          readonly field: string;
          readonly values: ReadonlyArray<string>;
        } | null;
        readonly column: {
          readonly __typename: 'WorksheetSortConfig';
          readonly direction: SortDirection;
          readonly field: string;
          readonly values: ReadonlyArray<string>;
        } | null;
      } | null;
      readonly expanded: {
        readonly __typename: 'WorksheetExpanded';
        readonly all: boolean;
        readonly rows: ReadonlyArray<
          ReadonlyArray<{
            readonly __typename: 'ExpandableField';
            readonly field: string;
            readonly value: string;
          }>
        >;
        readonly columns: ReadonlyArray<
          ReadonlyArray<{
            readonly __typename: 'ExpandableField';
            readonly field: string;
            readonly value: string;
          }>
        >;
      };
      readonly display: {
        readonly __typename: 'WorksheetDisplay';
        readonly showGrandTotal: boolean;
        readonly showSubtotal: boolean;
      };
    };
  };
};

export type MyWorksheetsQueryVariables = Exact<{ [key: string]: never }>;

export type MyWorksheetsQuery = {
  readonly __typename: 'Query';
  readonly worksheets: ReadonlyArray<{
    readonly __typename: 'Worksheet';
    readonly id: string;
    readonly type: WorksheetType;
    readonly title: string;
    readonly updatedAt: string;
  }>;
};

export type HydrateNameQueryVariables = Exact<{
  installationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  playerIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  productIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  vesselIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  zoneIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateNameQuery = {
  readonly __typename: 'Query';
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly id: string;
    readonly name: string;
  }>;
  readonly playersById: ReadonlyArray<{
    readonly __typename: 'Player';
    readonly id: string;
    readonly name: string;
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
  }>;
  readonly vesselsById: ReadonlyArray<{
    readonly __typename: 'Vessel';
    readonly id: string;
    readonly name: string;
  }>;
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
  }>;
};

export type HydrateSearchQueryVariables = Exact<{
  zoneIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  installationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  vesselIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  productIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  playerIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type HydrateSearchQuery = {
  readonly __typename: 'Query';
  readonly zonesById: ReadonlyArray<{
    readonly __typename: 'Zone';
    readonly id: string;
    readonly hasStorage: boolean;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly type: string;
  }>;
  readonly installationsById: ReadonlyArray<{
    readonly __typename: 'Installation';
    readonly hasCargoTracking: boolean;
    readonly hasStorage: boolean;
    readonly id: string;
    readonly isInUnitedStates: boolean;
    readonly name: string;
    readonly iirName: string | null;
    readonly portCost: number | null;
    readonly type: string;
    readonly unlocodes: ReadonlyArray<string>;
    readonly commodityTypes: ReadonlyArray<CommodityType> | null;
    readonly port: { readonly __typename: 'Zone'; readonly id: string };
  }>;
  readonly vesselsById: ReadonlyArray<{
    readonly __typename: 'Vessel';
    readonly id: string;
    readonly name: string;
    readonly status: string;
    readonly imo: string;
    readonly hasLastPosition: boolean;
    readonly currentCommodityType: CommodityType | null;
  }>;
  readonly productsById: ReadonlyArray<{
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
    readonly hasEstimation: boolean;
    readonly parentId: string | null;
    readonly type: ProductType;
  }>;
  readonly playersById: ReadonlyArray<{
    readonly __typename: 'Player';
    readonly id: string;
    readonly name: string;
  }>;
};

export type MapFiltersQueryVariables = Exact<{
  where: TradeActivityInput;
}>;

export type MapFiltersQuery = {
  readonly __typename: 'Query';
  readonly tradeActivity: {
    readonly __typename: 'TradeActivity';
    readonly vesselIds: ReadonlyArray<string>;
    readonly installationIds: ReadonlyArray<string>;
  };
};

export type ShipToShipFragment = {
  readonly __typename: 'PortCall';
  readonly analystDate: string | null;
  readonly badCoverageEstimated: boolean;
  readonly berthId: string | null;
  readonly confidence: number | null;
  readonly customsBillOfLadingDate: string | null;
  readonly customsEntranceDate: string | null;
  readonly customsClearanceDate: string | null;
  readonly draughtChange: number | null;
  readonly end: string | null;
  readonly eta: string | null;
  readonly estimatedBerthArrival: string;
  readonly estimatedBerthDeparture: string;
  readonly fixedCargoVolumeDate: string | null;
  readonly fixedVolumeDate: string | null;
  readonly forecasted: boolean;
  readonly id: string;
  readonly isGhost: boolean;
  readonly isVesselBunker: boolean;
  readonly isVesselFeeder: boolean;
  readonly isVesselFeederStorage: boolean;
  readonly operation: OperationType;
  readonly rawEta: string | null;
  readonly reexport: boolean;
  readonly start: string | null;
  readonly source: string | null;
  readonly voyageId: string | null;
  readonly cancelled: boolean;
  readonly constraints: {
    readonly __typename: 'PortCallConstraint';
    readonly providerId: string | null;
  } | null;
  readonly currentCargo: {
    readonly __typename: 'CurrentCargo';
    readonly status: string | null;
    readonly cargoVolume: {
      readonly __typename: 'QuantityObject';
      readonly energy: number;
      readonly mass: number;
      readonly volume: number;
      readonly volume_gas: number;
    } | null;
  };
  readonly draughtChangeQuantity: {
    readonly __typename: 'QuantityObject';
    readonly energy: number;
    readonly mass: number;
    readonly volume: number;
    readonly volume_gas: number;
  } | null;
  readonly flowQuantities: ReadonlyArray<{
    readonly __typename: 'FlowQuantity';
    readonly product: {
      readonly __typename: 'Product';
      readonly ancestorIds: ReadonlyArray<string>;
      readonly api: number | null;
      readonly id: string;
      readonly name: string;
      readonly sulfur: number | null;
    };
    readonly flowQuantity: {
      readonly __typename: 'QuantityObject';
      readonly energy: number;
      readonly mass: number;
      readonly volume: number;
      readonly volume_gas: number;
    };
  }> | null;
  readonly forecastedTree: ReadonlyArray<{
    readonly __typename: 'ForecastedTree';
    readonly confidence: number;
    readonly installation: {
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
  }> | null;
  readonly locationConstraint: {
    readonly __typename: 'LocationConstraint';
    readonly locationConstraintGroups: ReadonlyArray<{
      readonly __typename: 'LocationConstraintGroups';
      readonly minDwt: number | null;
      readonly maxDwt: number | null;
    }>;
    readonly locationConstraintProducts: ReadonlyArray<{
      readonly __typename: 'LocationConstraintProducts';
      readonly parcelTolerance: number | null;
      readonly parcelMin: {
        readonly __typename: 'UnitObj';
        readonly unit: string;
        readonly value: number;
      } | null;
      readonly parcelNominal: {
        readonly __typename: 'UnitObj';
        readonly unit: string;
        readonly value: number;
      } | null;
      readonly parcelMax: {
        readonly __typename: 'UnitObj';
        readonly unit: string;
        readonly value: number;
      } | null;
    }>;
  };
  readonly spotCharter: {
    readonly __typename: 'SpotCharter';
    readonly id: string;
    readonly charterer: string | null;
  } | null;
  readonly vessel: {
    readonly __typename: 'Vessel';
    readonly id: string;
    readonly name: string;
    readonly capacity: {
      readonly __typename: 'QuantityObject';
      readonly energy: number;
      readonly mass: number;
      readonly volume: number;
      readonly volume_gas: number;
    };
  };
};

export type PortCallFragment = {
  readonly __typename: 'PortCall';
  readonly shipToShip: boolean;
  readonly analystDate: string | null;
  readonly badCoverageEstimated: boolean;
  readonly berthId: string | null;
  readonly confidence: number | null;
  readonly customsBillOfLadingDate: string | null;
  readonly customsEntranceDate: string | null;
  readonly customsClearanceDate: string | null;
  readonly draughtChange: number | null;
  readonly end: string | null;
  readonly eta: string | null;
  readonly estimatedBerthArrival: string;
  readonly estimatedBerthDeparture: string;
  readonly fixedCargoVolumeDate: string | null;
  readonly fixedVolumeDate: string | null;
  readonly forecasted: boolean;
  readonly id: string;
  readonly isGhost: boolean;
  readonly isVesselBunker: boolean;
  readonly isVesselFeeder: boolean;
  readonly isVesselFeederStorage: boolean;
  readonly operation: OperationType;
  readonly rawEta: string | null;
  readonly reexport: boolean;
  readonly start: string | null;
  readonly source: string | null;
  readonly voyageId: string | null;
  readonly cancelled: boolean;
  readonly installation: {
    readonly __typename: 'Installation';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly fullname: string;
  } | null;
  readonly shipToShipInfo: {
    readonly __typename: 'PortCall';
    readonly analystDate: string | null;
    readonly badCoverageEstimated: boolean;
    readonly berthId: string | null;
    readonly confidence: number | null;
    readonly customsBillOfLadingDate: string | null;
    readonly customsEntranceDate: string | null;
    readonly customsClearanceDate: string | null;
    readonly draughtChange: number | null;
    readonly end: string | null;
    readonly eta: string | null;
    readonly estimatedBerthArrival: string;
    readonly estimatedBerthDeparture: string;
    readonly fixedCargoVolumeDate: string | null;
    readonly fixedVolumeDate: string | null;
    readonly forecasted: boolean;
    readonly id: string;
    readonly isGhost: boolean;
    readonly isVesselBunker: boolean;
    readonly isVesselFeeder: boolean;
    readonly isVesselFeederStorage: boolean;
    readonly operation: OperationType;
    readonly rawEta: string | null;
    readonly reexport: boolean;
    readonly start: string | null;
    readonly source: string | null;
    readonly voyageId: string | null;
    readonly cancelled: boolean;
    readonly constraints: {
      readonly __typename: 'PortCallConstraint';
      readonly providerId: string | null;
    } | null;
    readonly currentCargo: {
      readonly __typename: 'CurrentCargo';
      readonly status: string | null;
      readonly cargoVolume: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      } | null;
    };
    readonly draughtChangeQuantity: {
      readonly __typename: 'QuantityObject';
      readonly energy: number;
      readonly mass: number;
      readonly volume: number;
      readonly volume_gas: number;
    } | null;
    readonly flowQuantities: ReadonlyArray<{
      readonly __typename: 'FlowQuantity';
      readonly product: {
        readonly __typename: 'Product';
        readonly ancestorIds: ReadonlyArray<string>;
        readonly api: number | null;
        readonly id: string;
        readonly name: string;
        readonly sulfur: number | null;
      };
      readonly flowQuantity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      };
    }> | null;
    readonly forecastedTree: ReadonlyArray<{
      readonly __typename: 'ForecastedTree';
      readonly confidence: number;
      readonly installation: {
        readonly __typename: 'Installation';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
    }> | null;
    readonly locationConstraint: {
      readonly __typename: 'LocationConstraint';
      readonly locationConstraintGroups: ReadonlyArray<{
        readonly __typename: 'LocationConstraintGroups';
        readonly minDwt: number | null;
        readonly maxDwt: number | null;
      }>;
      readonly locationConstraintProducts: ReadonlyArray<{
        readonly __typename: 'LocationConstraintProducts';
        readonly parcelTolerance: number | null;
        readonly parcelMin: {
          readonly __typename: 'UnitObj';
          readonly unit: string;
          readonly value: number;
        } | null;
        readonly parcelNominal: {
          readonly __typename: 'UnitObj';
          readonly unit: string;
          readonly value: number;
        } | null;
        readonly parcelMax: {
          readonly __typename: 'UnitObj';
          readonly unit: string;
          readonly value: number;
        } | null;
      }>;
    };
    readonly spotCharter: {
      readonly __typename: 'SpotCharter';
      readonly id: string;
      readonly charterer: string | null;
    } | null;
    readonly vessel: {
      readonly __typename: 'Vessel';
      readonly id: string;
      readonly name: string;
      readonly capacity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      };
    };
  } | null;
  readonly zone: {
    readonly __typename: 'Zone';
    readonly id: string;
    readonly name: string;
    readonly type: string;
  };
  readonly constraints: {
    readonly __typename: 'PortCallConstraint';
    readonly providerId: string | null;
  } | null;
  readonly currentCargo: {
    readonly __typename: 'CurrentCargo';
    readonly status: string | null;
    readonly cargoVolume: {
      readonly __typename: 'QuantityObject';
      readonly energy: number;
      readonly mass: number;
      readonly volume: number;
      readonly volume_gas: number;
    } | null;
  };
  readonly draughtChangeQuantity: {
    readonly __typename: 'QuantityObject';
    readonly energy: number;
    readonly mass: number;
    readonly volume: number;
    readonly volume_gas: number;
  } | null;
  readonly flowQuantities: ReadonlyArray<{
    readonly __typename: 'FlowQuantity';
    readonly product: {
      readonly __typename: 'Product';
      readonly ancestorIds: ReadonlyArray<string>;
      readonly api: number | null;
      readonly id: string;
      readonly name: string;
      readonly sulfur: number | null;
    };
    readonly flowQuantity: {
      readonly __typename: 'QuantityObject';
      readonly energy: number;
      readonly mass: number;
      readonly volume: number;
      readonly volume_gas: number;
    };
  }> | null;
  readonly forecastedTree: ReadonlyArray<{
    readonly __typename: 'ForecastedTree';
    readonly confidence: number;
    readonly installation: {
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
  }> | null;
  readonly locationConstraint: {
    readonly __typename: 'LocationConstraint';
    readonly locationConstraintGroups: ReadonlyArray<{
      readonly __typename: 'LocationConstraintGroups';
      readonly minDwt: number | null;
      readonly maxDwt: number | null;
    }>;
    readonly locationConstraintProducts: ReadonlyArray<{
      readonly __typename: 'LocationConstraintProducts';
      readonly parcelTolerance: number | null;
      readonly parcelMin: {
        readonly __typename: 'UnitObj';
        readonly unit: string;
        readonly value: number;
      } | null;
      readonly parcelNominal: {
        readonly __typename: 'UnitObj';
        readonly unit: string;
        readonly value: number;
      } | null;
      readonly parcelMax: {
        readonly __typename: 'UnitObj';
        readonly unit: string;
        readonly value: number;
      } | null;
    }>;
  };
  readonly spotCharter: {
    readonly __typename: 'SpotCharter';
    readonly id: string;
    readonly charterer: string | null;
  } | null;
  readonly vessel: {
    readonly __typename: 'Vessel';
    readonly id: string;
    readonly name: string;
    readonly capacity: {
      readonly __typename: 'QuantityObject';
      readonly energy: number;
      readonly mass: number;
      readonly volume: number;
      readonly volume_gas: number;
    };
  };
};

export type PortCallsQueryVariables = Exact<{
  size: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PortCallFiltersInput>;
  jumpToId?: InputMaybe<Scalars['String']>;
}>;

export type PortCallsQuery = {
  readonly __typename: 'Query';
  readonly portCalls: {
    readonly __typename: 'PortCallsResponse';
    readonly hasMore: boolean;
    readonly items: ReadonlyArray<{
      readonly __typename: 'PortCall';
      readonly shipToShip: boolean;
      readonly analystDate: string | null;
      readonly badCoverageEstimated: boolean;
      readonly berthId: string | null;
      readonly confidence: number | null;
      readonly customsBillOfLadingDate: string | null;
      readonly customsEntranceDate: string | null;
      readonly customsClearanceDate: string | null;
      readonly draughtChange: number | null;
      readonly end: string | null;
      readonly eta: string | null;
      readonly estimatedBerthArrival: string;
      readonly estimatedBerthDeparture: string;
      readonly fixedCargoVolumeDate: string | null;
      readonly fixedVolumeDate: string | null;
      readonly forecasted: boolean;
      readonly id: string;
      readonly isGhost: boolean;
      readonly isVesselBunker: boolean;
      readonly isVesselFeeder: boolean;
      readonly isVesselFeederStorage: boolean;
      readonly operation: OperationType;
      readonly rawEta: string | null;
      readonly reexport: boolean;
      readonly start: string | null;
      readonly source: string | null;
      readonly voyageId: string | null;
      readonly cancelled: boolean;
      readonly installation: {
        readonly __typename: 'Installation';
        readonly id: string;
        readonly name: string;
        readonly type: string;
        readonly fullname: string;
      } | null;
      readonly shipToShipInfo: {
        readonly __typename: 'PortCall';
        readonly analystDate: string | null;
        readonly badCoverageEstimated: boolean;
        readonly berthId: string | null;
        readonly confidence: number | null;
        readonly customsBillOfLadingDate: string | null;
        readonly customsEntranceDate: string | null;
        readonly customsClearanceDate: string | null;
        readonly draughtChange: number | null;
        readonly end: string | null;
        readonly eta: string | null;
        readonly estimatedBerthArrival: string;
        readonly estimatedBerthDeparture: string;
        readonly fixedCargoVolumeDate: string | null;
        readonly fixedVolumeDate: string | null;
        readonly forecasted: boolean;
        readonly id: string;
        readonly isGhost: boolean;
        readonly isVesselBunker: boolean;
        readonly isVesselFeeder: boolean;
        readonly isVesselFeederStorage: boolean;
        readonly operation: OperationType;
        readonly rawEta: string | null;
        readonly reexport: boolean;
        readonly start: string | null;
        readonly source: string | null;
        readonly voyageId: string | null;
        readonly cancelled: boolean;
        readonly constraints: {
          readonly __typename: 'PortCallConstraint';
          readonly providerId: string | null;
        } | null;
        readonly currentCargo: {
          readonly __typename: 'CurrentCargo';
          readonly status: string | null;
          readonly cargoVolume: {
            readonly __typename: 'QuantityObject';
            readonly energy: number;
            readonly mass: number;
            readonly volume: number;
            readonly volume_gas: number;
          } | null;
        };
        readonly draughtChangeQuantity: {
          readonly __typename: 'QuantityObject';
          readonly energy: number;
          readonly mass: number;
          readonly volume: number;
          readonly volume_gas: number;
        } | null;
        readonly flowQuantities: ReadonlyArray<{
          readonly __typename: 'FlowQuantity';
          readonly product: {
            readonly __typename: 'Product';
            readonly ancestorIds: ReadonlyArray<string>;
            readonly api: number | null;
            readonly id: string;
            readonly name: string;
            readonly sulfur: number | null;
          };
          readonly flowQuantity: {
            readonly __typename: 'QuantityObject';
            readonly energy: number;
            readonly mass: number;
            readonly volume: number;
            readonly volume_gas: number;
          };
        }> | null;
        readonly forecastedTree: ReadonlyArray<{
          readonly __typename: 'ForecastedTree';
          readonly confidence: number;
          readonly installation: {
            readonly __typename: 'Installation';
            readonly id: string;
            readonly name: string;
          } | null;
          readonly zone: {
            readonly __typename: 'Zone';
            readonly id: string;
            readonly name: string;
          };
        }> | null;
        readonly locationConstraint: {
          readonly __typename: 'LocationConstraint';
          readonly locationConstraintGroups: ReadonlyArray<{
            readonly __typename: 'LocationConstraintGroups';
            readonly minDwt: number | null;
            readonly maxDwt: number | null;
          }>;
          readonly locationConstraintProducts: ReadonlyArray<{
            readonly __typename: 'LocationConstraintProducts';
            readonly parcelTolerance: number | null;
            readonly parcelMin: {
              readonly __typename: 'UnitObj';
              readonly unit: string;
              readonly value: number;
            } | null;
            readonly parcelNominal: {
              readonly __typename: 'UnitObj';
              readonly unit: string;
              readonly value: number;
            } | null;
            readonly parcelMax: {
              readonly __typename: 'UnitObj';
              readonly unit: string;
              readonly value: number;
            } | null;
          }>;
        };
        readonly spotCharter: {
          readonly __typename: 'SpotCharter';
          readonly id: string;
          readonly charterer: string | null;
        } | null;
        readonly vessel: {
          readonly __typename: 'Vessel';
          readonly id: string;
          readonly name: string;
          readonly capacity: {
            readonly __typename: 'QuantityObject';
            readonly energy: number;
            readonly mass: number;
            readonly volume: number;
            readonly volume_gas: number;
          };
        };
      } | null;
      readonly zone: {
        readonly __typename: 'Zone';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      };
      readonly constraints: {
        readonly __typename: 'PortCallConstraint';
        readonly providerId: string | null;
      } | null;
      readonly currentCargo: {
        readonly __typename: 'CurrentCargo';
        readonly status: string | null;
        readonly cargoVolume: {
          readonly __typename: 'QuantityObject';
          readonly energy: number;
          readonly mass: number;
          readonly volume: number;
          readonly volume_gas: number;
        } | null;
      };
      readonly draughtChangeQuantity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      } | null;
      readonly flowQuantities: ReadonlyArray<{
        readonly __typename: 'FlowQuantity';
        readonly product: {
          readonly __typename: 'Product';
          readonly ancestorIds: ReadonlyArray<string>;
          readonly api: number | null;
          readonly id: string;
          readonly name: string;
          readonly sulfur: number | null;
        };
        readonly flowQuantity: {
          readonly __typename: 'QuantityObject';
          readonly energy: number;
          readonly mass: number;
          readonly volume: number;
          readonly volume_gas: number;
        };
      }> | null;
      readonly forecastedTree: ReadonlyArray<{
        readonly __typename: 'ForecastedTree';
        readonly confidence: number;
        readonly installation: {
          readonly __typename: 'Installation';
          readonly id: string;
          readonly name: string;
        } | null;
        readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
      }> | null;
      readonly locationConstraint: {
        readonly __typename: 'LocationConstraint';
        readonly locationConstraintGroups: ReadonlyArray<{
          readonly __typename: 'LocationConstraintGroups';
          readonly minDwt: number | null;
          readonly maxDwt: number | null;
        }>;
        readonly locationConstraintProducts: ReadonlyArray<{
          readonly __typename: 'LocationConstraintProducts';
          readonly parcelTolerance: number | null;
          readonly parcelMin: {
            readonly __typename: 'UnitObj';
            readonly unit: string;
            readonly value: number;
          } | null;
          readonly parcelNominal: {
            readonly __typename: 'UnitObj';
            readonly unit: string;
            readonly value: number;
          } | null;
          readonly parcelMax: {
            readonly __typename: 'UnitObj';
            readonly unit: string;
            readonly value: number;
          } | null;
        }>;
      };
      readonly spotCharter: {
        readonly __typename: 'SpotCharter';
        readonly id: string;
        readonly charterer: string | null;
      } | null;
      readonly vessel: {
        readonly __typename: 'Vessel';
        readonly id: string;
        readonly name: string;
        readonly capacity: {
          readonly __typename: 'QuantityObject';
          readonly energy: number;
          readonly mass: number;
          readonly volume: number;
          readonly volume_gas: number;
        };
      };
    }>;
    readonly cursors: { readonly __typename: 'Cursors'; readonly after: string | null };
  };
};

export type PortCallsForExportQueryVariables = Exact<{
  size: Scalars['Int'];
  where?: InputMaybe<PortCallFiltersInput>;
}>;

export type PortCallsForExportQuery = {
  readonly __typename: 'Query';
  readonly portCalls: {
    readonly __typename: 'PortCallsResponse';
    readonly items: ReadonlyArray<{
      readonly __typename: 'PortCall';
      readonly eta: string | null;
      readonly end: string | null;
      readonly id: string;
      readonly start: string | null;
      readonly flowQuantities: ReadonlyArray<{
        readonly __typename: 'FlowQuantity';
        readonly quantity: {
          readonly __typename: 'QuantityObject';
          readonly energy: number;
          readonly mass: number;
          readonly volume: number;
          readonly volume_gas: number;
        };
        readonly product: {
          readonly __typename: 'Product';
          readonly id: string;
          readonly closestFamilyAncestor: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
          } | null;
          readonly closestGradeAncestor: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
          } | null;
          readonly closestGroupAncestor: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
          } | null;
          readonly closestProductAncestor: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
          } | null;
        };
      }> | null;
      readonly installation: {
        readonly __typename: 'Installation';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly vessel: {
        readonly __typename: 'Vessel';
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};

export type VoyageEmissionsMetricsFragment = {
  readonly __typename: 'VoyageEmissionsMetrics';
  readonly euEts: number;
  readonly eua: number;
  readonly distanceTravelled: number;
  readonly co2Emissions: number;
};

export type VoyageWithEmissionsFragment = {
  readonly __typename: 'Voyage';
  readonly emissions: {
    readonly __typename: 'VoyageEmissions';
    readonly laden: {
      readonly __typename: 'VoyageEmissionsMetrics';
      readonly euEts: number;
      readonly eua: number;
      readonly distanceTravelled: number;
      readonly co2Emissions: number;
    };
    readonly ballast: {
      readonly __typename: 'VoyageEmissionsMetrics';
      readonly euEts: number;
      readonly eua: number;
      readonly distanceTravelled: number;
      readonly co2Emissions: number;
    };
    readonly total: {
      readonly __typename: 'VoyageEmissionsMetrics';
      readonly euEts: number;
      readonly eua: number;
      readonly distanceTravelled: number;
      readonly co2Emissions: number;
    };
  } | null;
};

export type VoyageFragment = {
  readonly __typename: 'Voyage';
  readonly end: string | null;
  readonly id: string;
  readonly start: string;
  readonly charter: {
    readonly __typename: 'Charter';
    readonly id: string;
    readonly spotCharterId: string | null;
    readonly charterer: {
      readonly __typename: 'Charterer';
      readonly id: string;
      readonly name: string;
    } | null;
  } | null;
  readonly portCalls: ReadonlyArray<{
    readonly __typename: 'PortCall';
    readonly analystDate: string | null;
    readonly berthId: string | null;
    readonly billOfLadingCheckedByAnalyst: boolean | null;
    readonly confidence: number | null;
    readonly customsBillOfLadingDate: string | null;
    readonly customsEntranceDate: string | null;
    readonly customsClearanceDate: string | null;
    readonly end: string | null;
    readonly eta: string | null;
    readonly estimatedBerthArrival: string;
    readonly estimatedBerthDeparture: string;
    readonly forecasted: boolean;
    readonly id: string;
    readonly isGhost: boolean;
    readonly operation: OperationType;
    readonly reexport: boolean;
    readonly start: string | null;
    readonly source: string | null;
    readonly shipToShip: boolean;
    readonly constraints: {
      readonly __typename: 'PortCallConstraint';
      readonly providerId: string | null;
    } | null;
    readonly flowQuantities: ReadonlyArray<{
      readonly __typename: 'FlowQuantity';
      readonly product: {
        readonly __typename: 'Product';
        readonly ancestorIds: ReadonlyArray<string>;
        readonly api: number | null;
        readonly id: string;
        readonly name: string;
        readonly sulfur: number | null;
      };
      readonly flowQuantity: {
        readonly __typename: 'QuantityObject';
        readonly energy: number;
        readonly mass: number;
        readonly volume: number;
        readonly volume_gas: number;
      };
    }> | null;
    readonly forecastedTree: ReadonlyArray<{
      readonly __typename: 'ForecastedTree';
      readonly confidence: number;
      readonly installation: {
        readonly __typename: 'Installation';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
    }> | null;
    readonly installation: {
      readonly __typename: 'Installation';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly shipToShipInfo: {
      readonly __typename: 'PortCall';
      readonly id: string;
      readonly vessel: {
        readonly __typename: 'Vessel';
        readonly id: string;
        readonly name: string;
      };
    } | null;
    readonly zone: {
      readonly __typename: 'Zone';
      readonly id: string;
      readonly name: string;
      readonly type: string;
    };
  }>;
  readonly vessel: {
    readonly __typename: 'Vessel';
    readonly id: string;
    readonly name: string;
    readonly capacity: {
      readonly __typename: 'QuantityObject';
      readonly energy: number;
      readonly mass: number;
      readonly volume: number;
      readonly volume_gas: number;
    };
  };
  readonly emissions: {
    readonly __typename: 'VoyageEmissions';
    readonly laden: {
      readonly __typename: 'VoyageEmissionsMetrics';
      readonly euEts: number;
      readonly eua: number;
      readonly distanceTravelled: number;
      readonly co2Emissions: number;
    };
    readonly ballast: {
      readonly __typename: 'VoyageEmissionsMetrics';
      readonly euEts: number;
      readonly eua: number;
      readonly distanceTravelled: number;
      readonly co2Emissions: number;
    };
    readonly total: {
      readonly __typename: 'VoyageEmissionsMetrics';
      readonly euEts: number;
      readonly eua: number;
      readonly distanceTravelled: number;
      readonly co2Emissions: number;
    };
  } | null;
};

export type VoyagesQueryVariables = Exact<{
  size: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  where: VoyageFiltersInput;
  sort?: InputMaybe<VoyageSortsInput>;
}>;

export type VoyagesQuery = {
  readonly __typename: 'Query';
  readonly voyages: {
    readonly __typename: 'VoyagesResponse';
    readonly hasMore: boolean;
    readonly cursors: { readonly __typename: 'Cursors'; readonly after: string | null };
    readonly items: ReadonlyArray<{
      readonly __typename: 'Voyage';
      readonly end: string | null;
      readonly id: string;
      readonly start: string;
      readonly charter: {
        readonly __typename: 'Charter';
        readonly id: string;
        readonly spotCharterId: string | null;
        readonly charterer: {
          readonly __typename: 'Charterer';
          readonly id: string;
          readonly name: string;
        } | null;
      } | null;
      readonly portCalls: ReadonlyArray<{
        readonly __typename: 'PortCall';
        readonly analystDate: string | null;
        readonly berthId: string | null;
        readonly billOfLadingCheckedByAnalyst: boolean | null;
        readonly confidence: number | null;
        readonly customsBillOfLadingDate: string | null;
        readonly customsEntranceDate: string | null;
        readonly customsClearanceDate: string | null;
        readonly end: string | null;
        readonly eta: string | null;
        readonly estimatedBerthArrival: string;
        readonly estimatedBerthDeparture: string;
        readonly forecasted: boolean;
        readonly id: string;
        readonly isGhost: boolean;
        readonly operation: OperationType;
        readonly reexport: boolean;
        readonly start: string | null;
        readonly source: string | null;
        readonly shipToShip: boolean;
        readonly constraints: {
          readonly __typename: 'PortCallConstraint';
          readonly providerId: string | null;
        } | null;
        readonly flowQuantities: ReadonlyArray<{
          readonly __typename: 'FlowQuantity';
          readonly product: {
            readonly __typename: 'Product';
            readonly ancestorIds: ReadonlyArray<string>;
            readonly api: number | null;
            readonly id: string;
            readonly name: string;
            readonly sulfur: number | null;
          };
          readonly flowQuantity: {
            readonly __typename: 'QuantityObject';
            readonly energy: number;
            readonly mass: number;
            readonly volume: number;
            readonly volume_gas: number;
          };
        }> | null;
        readonly forecastedTree: ReadonlyArray<{
          readonly __typename: 'ForecastedTree';
          readonly confidence: number;
          readonly installation: {
            readonly __typename: 'Installation';
            readonly id: string;
            readonly name: string;
          } | null;
          readonly zone: {
            readonly __typename: 'Zone';
            readonly id: string;
            readonly name: string;
          };
        }> | null;
        readonly installation: {
          readonly __typename: 'Installation';
          readonly id: string;
          readonly name: string;
        } | null;
        readonly shipToShipInfo: {
          readonly __typename: 'PortCall';
          readonly id: string;
          readonly vessel: {
            readonly __typename: 'Vessel';
            readonly id: string;
            readonly name: string;
          };
        } | null;
        readonly zone: {
          readonly __typename: 'Zone';
          readonly id: string;
          readonly name: string;
          readonly type: string;
        };
      }>;
      readonly vessel: {
        readonly __typename: 'Vessel';
        readonly id: string;
        readonly name: string;
        readonly capacity: {
          readonly __typename: 'QuantityObject';
          readonly energy: number;
          readonly mass: number;
          readonly volume: number;
          readonly volume_gas: number;
        };
      };
      readonly emissions: {
        readonly __typename: 'VoyageEmissions';
        readonly laden: {
          readonly __typename: 'VoyageEmissionsMetrics';
          readonly euEts: number;
          readonly eua: number;
          readonly distanceTravelled: number;
          readonly co2Emissions: number;
        };
        readonly ballast: {
          readonly __typename: 'VoyageEmissionsMetrics';
          readonly euEts: number;
          readonly eua: number;
          readonly distanceTravelled: number;
          readonly co2Emissions: number;
        };
        readonly total: {
          readonly __typename: 'VoyageEmissionsMetrics';
          readonly euEts: number;
          readonly eua: number;
          readonly distanceTravelled: number;
          readonly co2Emissions: number;
        };
      } | null;
    }>;
  };
};

export type VoyagesForExportQueryVariables = Exact<{
  size: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  where: VoyageFiltersInput;
}>;

export type VoyagesForExportQuery = {
  readonly __typename: 'Query';
  readonly voyages: {
    readonly __typename: 'VoyagesResponse';
    readonly items: ReadonlyArray<{
      readonly __typename: 'Voyage';
      readonly id: string;
      readonly portCalls: ReadonlyArray<{
        readonly __typename: 'PortCall';
        readonly end: string | null;
        readonly eta: string | null;
        readonly id: string;
        readonly start: string | null;
        readonly flowQuantities: ReadonlyArray<{
          readonly __typename: 'FlowQuantity';
          readonly flowQuantity: {
            readonly __typename: 'QuantityObject';
            readonly energy: number;
            readonly mass: number;
            readonly volume: number;
            readonly volume_gas: number;
          };
        }> | null;
        readonly installation: {
          readonly __typename: 'Installation';
          readonly id: string;
          readonly name: string;
        } | null;
        readonly zone: {
          readonly __typename: 'Zone';
          readonly id: string;
          readonly name: string;
          readonly country: {
            readonly __typename: 'Zone';
            readonly id: string;
            readonly name: string;
          } | null;
        };
      }>;
      readonly vessel: {
        readonly __typename: 'Vessel';
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};

export type EventLocationFragment = {
  readonly __typename: 'EventLocation';
  readonly zone: { readonly __typename: 'Zone'; readonly id: string; readonly name: string };
  readonly installation: {
    readonly __typename: 'Installation';
    readonly id: string;
    readonly name: string;
    readonly type: string;
  } | null;
};

export type UnreadNotificationsCountQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
}>;

export type UnreadNotificationsCountQuery = {
  readonly __typename: 'Query';
  readonly notificationsUnreadCount: number;
};

export type MyNotificationsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  size: Scalars['Int'];
}>;

export type MyNotificationsQuery = {
  readonly __typename: 'Query';
  readonly notifications: {
    readonly __typename: 'NotificationResponse';
    readonly hasMore: boolean;
    readonly cursors: {
      readonly __typename: 'Cursors';
      readonly first: string | null;
      readonly last: string | null;
    };
    readonly items: ReadonlyArray<
      | {
          readonly __typename: 'ArrivalNotification';
          readonly eta: string;
          readonly id: string;
          readonly timestamp: string;
          readonly location: {
            readonly __typename: 'EventLocation';
            readonly zone: {
              readonly __typename: 'Zone';
              readonly id: string;
              readonly name: string;
            };
            readonly installation: {
              readonly __typename: 'Installation';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null;
          };
          readonly vessel: {
            readonly __typename: 'Vessel';
            readonly id: string;
            readonly name: string;
          };
        }
      | {
          readonly __typename: 'DepartureNotification';
          readonly eta: string;
          readonly id: string;
          readonly timestamp: string;
          readonly location: {
            readonly __typename: 'EventLocation';
            readonly zone: {
              readonly __typename: 'Zone';
              readonly id: string;
              readonly name: string;
            };
            readonly installation: {
              readonly __typename: 'Installation';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null;
          };
          readonly vessel: {
            readonly __typename: 'Vessel';
            readonly id: string;
            readonly name: string;
          };
        }
      | {
          readonly __typename: 'DestinationAddedNotification';
          readonly eta: string;
          readonly id: string;
          readonly timestamp: string;
          readonly location: {
            readonly __typename: 'EventLocation';
            readonly zone: {
              readonly __typename: 'Zone';
              readonly id: string;
              readonly name: string;
            };
            readonly installation: {
              readonly __typename: 'Installation';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null;
          };
          readonly vessel: {
            readonly __typename: 'Vessel';
            readonly id: string;
            readonly name: string;
          };
        }
      | {
          readonly __typename: 'DestinationCanceledNotification';
          readonly eta: string;
          readonly id: string;
          readonly timestamp: string;
          readonly location: {
            readonly __typename: 'EventLocation';
            readonly zone: {
              readonly __typename: 'Zone';
              readonly id: string;
              readonly name: string;
            };
            readonly installation: {
              readonly __typename: 'Installation';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null;
          };
          readonly vessel: {
            readonly __typename: 'Vessel';
            readonly id: string;
            readonly name: string;
          };
        }
      | {
          readonly __typename: 'DestinationChangedNotification';
          readonly previousEta: string;
          readonly currentEta: string;
          readonly id: string;
          readonly timestamp: string;
          readonly previousLocation: {
            readonly __typename: 'EventLocation';
            readonly zone: {
              readonly __typename: 'Zone';
              readonly id: string;
              readonly name: string;
            };
            readonly installation: {
              readonly __typename: 'Installation';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null;
          };
          readonly currentLocation: {
            readonly __typename: 'EventLocation';
            readonly zone: {
              readonly __typename: 'Zone';
              readonly id: string;
              readonly name: string;
            };
            readonly installation: {
              readonly __typename: 'Installation';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null;
          };
          readonly vessel: {
            readonly __typename: 'Vessel';
            readonly id: string;
            readonly name: string;
          };
        }
      | {
          readonly __typename: 'EtaNotification';
          readonly previousEta: string;
          readonly currentEta: string;
          readonly id: string;
          readonly timestamp: string;
          readonly location: {
            readonly __typename: 'EventLocation';
            readonly zone: {
              readonly __typename: 'Zone';
              readonly id: string;
              readonly name: string;
            };
            readonly installation: {
              readonly __typename: 'Installation';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null;
          };
          readonly vessel: {
            readonly __typename: 'Vessel';
            readonly id: string;
            readonly name: string;
          };
        }
      | {
          readonly __typename: 'ShipToShipNotification';
          readonly eta: string;
          readonly id: string;
          readonly timestamp: string;
          readonly shipToShipVessel: {
            readonly __typename: 'Vessel';
            readonly id: string;
            readonly name: string;
          };
          readonly location: {
            readonly __typename: 'EventLocation';
            readonly zone: {
              readonly __typename: 'Zone';
              readonly id: string;
              readonly name: string;
            };
            readonly installation: {
              readonly __typename: 'Installation';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null;
          };
          readonly vessel: {
            readonly __typename: 'Vessel';
            readonly id: string;
            readonly name: string;
          };
        }
    >;
  };
};

export type UserAttributesQueryVariables = Exact<{ [key: string]: never }>;

export type UserAttributesQuery = { readonly __typename: 'Query'; readonly userAttributes: string };

export type UpdateUserAttributesMutationVariables = Exact<{
  attributes: Scalars['String'];
}>;

export type UpdateUserAttributesMutation = {
  readonly __typename: 'Mutation';
  readonly userAttributesUpdate: string;
};
