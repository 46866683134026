import { Options as PopperOptions, VirtualElement } from '@popperjs/core';

import { WidgetView } from './dashboard';

export type XYPoint = {
  x: number;
  y: number;
};

export type XYPointNullable = {
  x: number;
  y: number | null;
};

export type XYSlice = {
  x: number;
  y0: number;
  y1: number;
};

export type DataItemStackable = {
  x: string;
  [key: string]: any;
};

export type XTickConfig = {
  label: string;
  secondaryLabel?: string;
  hasTickBefore: boolean;
  tickHeight: number;
  offsetLeft: number;
  textIsBold: boolean;
};

export enum TickStepSize {
  SMALL = 45,
  MEDIUM = 60,
  LARGE = 90,
}

export enum ChartGrouping {
  STACKED = 'stacked',
  BASELINE = 'baseline',
}

export enum ChartType {
  BAR = 'bar',
  AREA = 'area',
  LINE = 'line',
}

export type TimeSeriesAverageConfig = {
  key: string;
  size?: number;
};

export type TimelineEvent = {
  date: string;
  value: number | null;
  props?: Record<string, any>;
};

export type LegendItemType = 'blob' | 'line';

export type LegendItem = {
  name: string;
  id: string;
  color: string;
  type?: LegendItemType;
};

export enum ChartSize {
  SMALL = 'small',
  LARGE = 'large',
}

export type TimeSerieConfig = {
  type: ChartType | WidgetView;
  key: string;
  withMarkers?: boolean;
  color?: string;
  label?: string;
  useSecondaryYAxis?: boolean;
};

export type TimeSeriesFacetConfig = {
  series: readonly TimeSerieConfig[];
  label?: string;
  key?: string;
  grouping: ChartGrouping;
  average: TimeSeriesAverageConfig | null;
  showUnit?: boolean;
};

export type TimeSeriesConfig = {
  facets: readonly TimeSeriesFacetConfig[];
  useTimeScale?: boolean;
  hidePointerOnChart?: boolean;
  chartSize?: ChartSize;
};

export type TimeSerieSplit = {
  id: string;
  name: string;
  color?: string;
};
export type ConvertedTimeSeriesSplitValue = {
  name: string;
  value: number | null;
  id: string;
  extraDataValue?: number;
};

export type ConvertedTimeSeriesEntry<T = string> = {
  key: T;
  value: number | null;
  props?: Record<string, any>;
  extraDataValue?: number;
  splitValues?: ConvertedTimeSeriesSplitValue[];
};

export type ConvertedTimeSeries<U = string> = {
  date: string;
  series: ReadonlyArray<ConvertedTimeSeriesEntry<U>>;
};

export type ConvertedTimeSeriesAsMap<T = string> = {
  date: string;
  series: Map<T, ConvertedTimeSeriesEntry<T>>;
};

export type ChartClickEvent = {
  date: string;
  splitId?: string;
  seriesId?: string;
  facetId?: string;
};

export type ChartTooltipEventSplit = {
  id: string;
  value: number | null;
  name: string;
  color?: string;
  extraDataValue?: number;
};

export type ChartTooltipEventSerie = {
  key: string;
  value: number | null;
  extraDataValue?: number;
  // Would ideally be optional, but TS doesn't throw errors
  // on optional keys when converting between similar types,
  // eg. `ConvertedTimeSeriesEntry` and `ChartTooltipEvent`
  splits: ChartTooltipEventSplit[] | null;
  currentSplitId?: string;
  color?: string;
  props?: Record<string, any>;
};

export type ChartTooltipEventFacet = {
  series: ChartTooltipEventSerie[];
  currentSeriesId?: string;
  average?: number;
  averageExtraData?: number;
  label?: string;
};

export type ChartTooltipEvent = {
  date: string;
  facets: ChartTooltipEventFacet[];
  currentFacetIndex: number;
  props?: Record<string, any>;
  average?: number;
  color?: string;
  value?: number;
};

type ChartTooltipManagerEventBase = {
  item: ChartTooltipEvent;
  config?: Partial<PopperOptions>;
};
type ChartTooltipManagerEventWithEvent = ChartTooltipManagerEventBase & {
  event: MouseEvent;
  tooltipType: 'event';
};
type ChartTooltipManagerEventWithTarget = ChartTooltipManagerEventBase & {
  target: SVGElement | VirtualElement;
  tooltipType: 'target';
};
export type ChartTooltipManagerEvent =
  | ChartTooltipManagerEventWithEvent
  | ChartTooltipManagerEventWithTarget;

export type TextureParameter = {
  colorIdx: number;
  hatched: boolean;
};

export type Circle = {
  cx: number;
  cy: number;
  idx: number;
  color: string;
  display: 'block' | 'none';
};

export type Marker = XYPoint & {
  height?: number;
  width?: number;
  color?: string;
};
