<script setup lang="ts">
import { computed } from 'vue';

import type { IconName } from './types.codegen';

const props = defineProps<{ name: IconName }>();
defineEmits<{ (e: 'click'): void }>();

const className = computed<string>(() => `icon-${props.name}`);
</script>

<template>
  <i class="Icon" :class="className" @click="$emit('click')" />
</template>
