export enum ValidationStatus {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export type ValidationResult = {
  status: ValidationStatus;
  message: string | null;
};

export const VALID_RESULT = {
  status: ValidationStatus.SUCCESS,
  message: null,
};
