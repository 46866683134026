<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseTooltip',
  props: {
    classes: {
      type: Array as () => string[],
      default: () => [],
    },
  },
});
</script>

<template>
  <div class="vue-tooltip" :class="classes" theme="light">
    <div class="tooltip-inner" data-qa="tooltip">
      <slot></slot>
    </div>
    <div class="tooltip-arrow">
      <div class="arrowhead"></div>
    </div>
  </div>
</template>
